import Select, { OptionTypeBase, StylesConfig } from 'react-select';
import { useMediaQuery } from 'react-responsive';
import VARS from 'css/export-vars.module.scss';

interface TStyles {
  isSmall?: boolean;
}

const getDefaultStyles = ({ isSmall }: TStyles): StylesConfig<OptionTypeBase, false> => ({
  control: (styles, { isFocused }) => ({
    ...styles,
    borderColor: `${VARS.midGrey} !important`,
    minHeight: `${isSmall ? 35 : 45}px !important`,
    ...(isFocused
      ? {
          borderColor: `${VARS.blue} !important`,
          boxShadow: 'none !important'
        }
      : {})
  }),
  valueContainer: (styles) => ({
    ...styles,
    fontSize: VARS.fontSizeNormal
  }),
  option: (styles) => ({
    ...styles,
    fontSize: VARS.fontSizeNormal
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  // Scroll bar stylings
  menuList: (base) => ({
    ...base,

    '::-webkit-scrollbar': {
      width: '8px',
      height: '10px'
    },

    // Track
    '::-webkit-scrollbar-track': {
      background: VARS.background
    },

    // Handle
    '::-webkit-scrollbar-thumb': {
      background: VARS.midGrey,
      borderRadius: '5px'
    },

    // Handle on hover
    '::-webkit-scrollbar-thumb:hover': {
      background: VARS.darkGrey
    }
  })
});

export default function ReactSelect({ ...props }) {
  const isSmall = useMediaQuery({ query: `(max-width: ${VARS.softphoneWidth})` });

  return (
    <Select
      styles={getDefaultStyles({ isSmall })}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: VARS.blue,
          primary75: VARS.blue,
          primary: VARS.blue
        }
      })}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      {...props}
    />
  );
}
