import { useEffect, useState, createRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import AnnounceKit from 'announcekit-react';
import { Tooltip } from '@material-ui/core';
import {
  faBars,
  faBolt,
  faStickyNote,
  faUserFriends,
  faSlidersH,
  faChartLine
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import getUserName from 'lib/common/utils/getUserName';
import useLayout from 'lib/common/contexts/LayoutContext';
import { GLOBAL_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { ReactComponent as Logo } from 'assets/vectors/neon-logo.svg';
import { useDirectoryContext } from 'lib/common/contexts/DirectoryContext';
import { ClickableIcon, DraggableOverlay, DraggableOverlaySize, UserProfile } from 'lib/common/components';
import { Widgets, AgentStats, AgentStatusTimer, Preferences } from 'lib/common/components/molecules';
import packageJson from '../../../../../../../../package.json';
import HelpMenu from './HelpMenu';
import Tasks from './Tasks';

import '../styles/header.scss';

type THeader = RouteComponentProps<any> & {
  pagename: string;
  user: any;
};

const WIDGETS_ID = 'widgets-overlay';
const PREFERENCES_ID = 'preferences-overlay';
const AGENT_STATS_ID = 'agent-stats-overlay';

const overlayVisibleId = (id) => `${id}-visible`;

function overlayWasOpen(id) {
  return Boolean(localStorage.getItem(overlayVisibleId(id)));
}

function Header({ pagename, match, user }: THeader) {
  const { toggleIsSidebarOpen, isSidebarOpen } = useLayout();
  const [numberOfNewUpdates, setNumberOfNewUpdates] = useState(0);
  const [isWidgetOverlayOpen, setWidgetOverlayOpen] = useState(overlayWasOpen(WIDGETS_ID));
  const [isPreferencesOverlayOpen, setPreferencesOverlayOpen] = useState(overlayWasOpen(PREFERENCES_ID));
  const [isAgentStatsOverlayOpen, setAgentStatsOverlayOpen] = useState(overlayWasOpen(AGENT_STATS_ID));
  const { openDirectory, closeDirectory, isDirectoryOverlayOpen } = useDirectoryContext();
  const {
    state: { widgetModules }
  } = useModulesContext();

  const openPersonalDirectory = () => openDirectory({ selectedTabIndex: 0 });
  const openCompanyDirectory = () => openDirectory({ selectedTabIndex: 1 });
  const openDialpadDirectory = () => openDirectory({ selectedTabIndex: 2 });

  useHotkeys(GLOBAL_HOTKEYS.PERSONAL, openPersonalDirectory, [openDirectory, openPersonalDirectory]);
  useHotkeys(GLOBAL_HOTKEYS.COMPANY, openCompanyDirectory, [openDirectory, openCompanyDirectory]);
  useHotkeys(GLOBAL_HOTKEYS.DIAL_PAD, openDialpadDirectory, [openDirectory, openDialpadDirectory]);

  const whatsNewRef = createRef<AnnounceKit>();

  const onCloseWhatsNew = async () => {
    setNumberOfNewUpdates(0);
  };

  const onToggleOverlayOpen = (id, setVisibleFn, isOpen) => () => {
    if (isOpen) {
      return void onCloseOverlay(id, setVisibleFn)();
    }

    localStorage.setItem(overlayVisibleId(id), 'true');

    setVisibleFn(true);
  };

  const onCloseOverlay = (id, closeFn) => () => {
    localStorage.removeItem(overlayVisibleId(id));

    closeFn(false);
  };

  useEffect(() => {
    if (!whatsNewRef) {
      return;
    }

    (async () => {
      setNumberOfNewUpdates(((await whatsNewRef?.current?.unread()) as number) || 0);
    })();
  }, [whatsNewRef]);

  return (
    <div className="header">
      <AnnounceKit
        onWidgetClose={onCloseWhatsNew}
        ref={whatsNewRef}
        widget="https://announcekit.app/widgets/v2/1sV8Bi"
        user={{
          id: user?.username,
          name: getUserName(user),
          email: user?.email,
          role: user?.role,
          organisation: user?.organization
        }}
      />
      <div className="header__burger">
        <ClickableIcon
          icon={faBars}
          className="header__burger__icon"
          onClick={toggleIsSidebarOpen}
          tooltip={`${isSidebarOpen ? 'Minimise' : 'Expand'} the navigation menu`}
        />
      </div>
      <Tooltip enterDelay={1000} title={`NEON version ${packageJson.version}`}>
        <Logo className="header__logo" />
      </Tooltip>
      <AgentStatusTimer />
      <Tasks />
      {Boolean(widgetModules?.length) && (
        <ClickableIcon
          className="header__widget-icon"
          icon={faStickyNote}
          onClick={onToggleOverlayOpen(WIDGETS_ID, setWidgetOverlayOpen, isWidgetOverlayOpen)}
          tooltip="Widgets"
        />
      )}
      <ClickableIcon
        icon={faBolt}
        className={cx('header__whats-new', { 'header__whats-new--unread': numberOfNewUpdates })}
        onClick={() => whatsNewRef?.current?.open()}
        tooltip="What's New"
      />
      <HelpMenu pagename={pagename} match={match} />
      <ClickableIcon
        className="header__agent-stats-icon"
        icon={faChartLine}
        onClick={onToggleOverlayOpen(AGENT_STATS_ID, setAgentStatsOverlayOpen, isAgentStatsOverlayOpen)}
        tooltip="Your Queues"
      />
      <ClickableIcon
        className="header__directory-icon"
        icon={faUserFriends}
        onClick={!isDirectoryOverlayOpen ? openDirectory : closeDirectory}
        tooltip="Directory"
      />
      <ClickableIcon
        className="header__preferences-icon"
        icon={faSlidersH}
        onClick={onToggleOverlayOpen(PREFERENCES_ID, setPreferencesOverlayOpen, isPreferencesOverlayOpen)}
        tooltip="Preferences"
      />
      <UserProfile user={user} />
      {Boolean(widgetModules.length) ? (
        <DraggableOverlay
          title="Widgets"
          open={isWidgetOverlayOpen}
          id={WIDGETS_ID}
          onClose={onCloseOverlay(WIDGETS_ID, setWidgetOverlayOpen)}
          size={DraggableOverlaySize.LARGE}
          className="header__widget-overlay"
          triggerSelector=".header__widget-icon"
        >
          <Widgets />
        </DraggableOverlay>
      ) : null}
      <DraggableOverlay
        title="Preferences"
        open={isPreferencesOverlayOpen}
        id={PREFERENCES_ID}
        onClose={onCloseOverlay(PREFERENCES_ID, setPreferencesOverlayOpen)}
        size={DraggableOverlaySize.SMALL}
        triggerSelector=".header__preferences-icon"
      >
        <Preferences />
      </DraggableOverlay>
      {isAgentStatsOverlayOpen && (
        <DraggableOverlay
          title="Your Queues"
          canShowLoading
          open={isAgentStatsOverlayOpen}
          id={AGENT_STATS_ID}
          onClose={onCloseOverlay(AGENT_STATS_ID, setAgentStatsOverlayOpen)}
          size={DraggableOverlaySize.LARGE}
          triggerSelector=".header__agent-stats-icon"
        >
          <AgentStats />
        </DraggableOverlay>
      )}
    </div>
  );
}

export default withRouter(Header);
