import { useEffect } from 'react';
import { TourProvider, useTour } from '@reactour/tour';
import { useLocation } from 'react-router-dom';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useMediaQuery } from 'react-responsive';
import VARS from 'css/export-vars.module.scss';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import ClickableIcon from '../ClickableIcon';
import { Button } from '../index';
import { desktopSteps } from './constants/desktopTour';
import { softPhoneSteps } from './constants/softphoneTour';

import './styles/tour.scss';

const tourHasBeenFinishedForPage = (pathname: string) => localStorage.getItem(`tour:${pathname}`) === 'true';
const setTourViewedForPage = (pathname: string) => localStorage.setItem(`tour:${pathname}`, 'true');

const getSteps = ({ isSoftphone, hasPersonalContactPermission }) => {
  if (!isSoftphone) {
    return desktopSteps;
  }

  if (!hasPersonalContactPermission) {
    return {
      ...softPhoneSteps,
      [SOFTPHONE_PAGE_ROUTES.DIRECTORY]: softPhoneSteps[SOFTPHONE_PAGE_ROUTES.DIRECTORY].filter(
        (step) => step.selector !== '[data-tour="Personal"]'
      )
    };
  }

  return softPhoneSteps;
};

const Navigation = ({ currentStep, setCurrentStep, steps }) => {
  const onBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = () => {
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className="reactour__navigation-button-container">
      {currentStep !== 0 && (
        <Button styleType="SECONDARY" onClick={onBack} className="navigation-button--first">
          Back
        </Button>
      )}
      <Button styleType="PRIMARY" onClick={onNext} className="navigation-button">
        {steps?.length === currentStep + 1 ? 'Finish' : 'Next'}
      </Button>
    </div>
  );
};

const Close: React.ComponentType<{ onClick?: () => void }> = ({ onClick }) => {
  const isSoftphone = useMediaQuery({ query: `(max-width: ${VARS.softphoneWidth})` });

  return (
    <ClickableIcon
      onClick={onClick}
      icon={faTimes}
      size={20}
      style={{
        position: 'absolute',
        right: isSoftphone ? '10px' : '40px',
        top: isSoftphone ? '10px' : '40px'
      }}
    />
  );
};

export default function ReactTourProvider({ children }) {
  return (
    <TourProvider
      steps={[]}
      showBadge={false}
      components={{ Navigation, Close }}
      position="right"
      scrollSmooth
      disableFocusLock
      disableInteraction
    >
      {children}
    </TourProvider>
  );
}

// Trigger
export const TourControl = ({ isSoftphone = false }) => {
  const { setIsOpen, setSteps, setCurrentStep } = useTour();
  const { pathname } = useLocation();
  const { hasPermission } = usePermissionsContext();

  const hasPersonalContactPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.PERSONAL_CONTACTS
  });

  useEffect(() => {
    if (tourHasBeenFinishedForPage(isSoftphone ? `softphone${pathname}` : pathname)) {
      return;
    }

    setIsOpen(true);
    setCurrentStep(0);
    setSteps(getSteps({ hasPersonalContactPermission, isSoftphone })[pathname]);

    setTourViewedForPage(isSoftphone ? `softphone${pathname}` : pathname);
  }, [pathname, hasPersonalContactPermission, setSteps, setCurrentStep]);
  return null;
};
