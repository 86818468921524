import Task from 'lib/common/types/Task';
import TUser from 'lib/common/types/User';
import TaskFooter from '../../TaskFooter';
import Call from './Call';
import Conference from './Conference';

interface IConnectedCallFooter extends Pick<Task, 'connectionState' | 'taskId' | 'connectionValue' | 'type'> {
  inConference?: boolean;
  allConnectionsOnHold: boolean;
  allConnectionsConnected: boolean;
  isConferenceConnecting: boolean;
  thirdPartyConnectionsDisconnected: boolean;
  initialConnectionDisconnected: boolean;
  thirdPartyConnection?: connect.BaseConnection | null;
  isSmallWidth: boolean;
  user: TUser;
  isSoftphone?: boolean;
}

export default function ConnectedCallFooter({
  inConference = false,
  connectionState,
  taskId,
  connectionValue,
  allConnectionsOnHold,
  allConnectionsConnected,
  isConferenceConnecting,
  thirdPartyConnectionsDisconnected,
  thirdPartyConnection,
  initialConnectionDisconnected,
  type,
  isSmallWidth,
  user,
  isSoftphone
}: IConnectedCallFooter) {
  const showCallControls = !inConference || (inConference && thirdPartyConnectionsDisconnected);

  return (
    <TaskFooter isSmallWidth={isSmallWidth}>
      {showCallControls ? (
        <Call
          connectionState={connectionState}
          connectionValue={connectionValue}
          taskId={taskId}
          type={type}
          isSmallWidth={isSmallWidth}
          user={user}
          isSoftphone={isSoftphone}
        />
      ) : (
        <Conference
          taskId={taskId}
          allConnectionsConnected={allConnectionsConnected}
          allConnectionsOnHold={allConnectionsOnHold}
          thirdPartyConnection={thirdPartyConnection}
          isConferenceConnecting={isConferenceConnecting}
          thirdPartyConnectionsDisconnected={thirdPartyConnectionsDisconnected}
          initialConnectionDisconnected={initialConnectionDisconnected}
          isSmallWidth={isSmallWidth}
        />
      )}
    </TaskFooter>
  );
}
