import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import LAYOUTS from '../constants/layouts';
import Column from '../../Column/Column';
import get2ColLayoutId from '../utils/get2ColLayoutId';
import COLUMN_IDS from '../constants/columnIds';
import TaskList from '../../TaskList/TaskList';
import TASK_FILTER_OPTIONS from '../constants/taskFilterOptions';
import NO_TASK_PLACEHOLDERS from '../constants/noTaskPlaceholders';
import TaskDetail from '../../TaskDetail';
import ResizableGroup from '../../ResizableGroup';

export default function TasksAndControls({
  selectedTask,
  tasks,
  filter,
  selectedTaskId,
  setSelectedTaskId,
  onFilterTasks,
  setCreateAgentTaskOpen,
  setCreateTaskDefaults,
  user
}) {
  return (
    <ResizableGroup
      data-testid="tasks-and-controls-group"
      containerClassName="agent-workspace"
      layoutOptions={LAYOUTS.TASKS_AND_CONTROLS}
      vertical
    >
      <Column minWidth={250} key={get2ColLayoutId(COLUMN_IDS.TASKS)} vertical>
        <TaskList
          tasks={tasks}
          selectedFilter={filter}
          selectedTaskId={selectedTaskId}
          setSelectedTaskId={setSelectedTaskId}
          onFilter={onFilterTasks}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          filterOptions={TASK_FILTER_OPTIONS}
          noTasksPlaceholder={NO_TASK_PLACEHOLDERS[filter.value || 'default']}
        />
      </Column>
      <Column
        key={get2ColLayoutId(COLUMN_IDS.CONTROLS)}
        minimisedIcon={faCircleInfo}
        minWidth={150}
        isResizable
        vertical
      >
        <TaskDetail
          selectedTask={selectedTask}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setCreateTaskDefaults={setCreateTaskDefaults}
          user={user}
        />
      </Column>
    </ResizableGroup>
  );
}
