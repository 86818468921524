import { faLayerGroup, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import { Icon } from 'lib/common/components';
import useHover from 'lib/common/hooks/useHover';
import '../styles/add-agent-task-button.scss';

type AddAgentTaskButtonProps = {
  setCreateAgentTaskOpen: (open: boolean) => void;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  className?: string;
};

export default function AddAgentTaskButton({
  setCreateAgentTaskOpen,
  tooltipPlacement = 'left',
  className
}: AddAgentTaskButtonProps) {
  const [hoverRef, isHovered] = useHover();

  return (
    <Tooltip enterDelay={1000} title="Add a new agent task" placement={tooltipPlacement}>
      <button
        // @ts-ignore
        ref={hoverRef}
        className={cx('add-agent-task-button panel--small', className)}
        onClick={() => setCreateAgentTaskOpen(true)}
      >
        <Icon color="white" icon={isHovered ? faLayerGroup : faLayerPlus} size={25} />
      </button>
    </Tooltip>
  );
}
