import { useState } from 'react';
import { format, formatDistanceStrict } from 'date-fns';
import { useHotkeys } from 'react-hotkeys-hook';
import { faClock, faComments, faInfoCircle, faPlug, faUser } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { Icon, Modal } from 'lib/common/components';
import getUserName from 'lib/common/utils/getUserName';
import ChatTask from 'lib/common/types/ChatTask';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import TUser from 'lib/common/types/User';
import getContactConnection from 'lib/common/utils/getContactConnection';
import connectGetter from 'lib/common/utils/connectGetter';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';
import { TaskFooter, ACWOutcome } from 'lib/common/components/molecules';
import Chat from '../Chat';
import styles from './after-contact-work.module.scss';

type TAfterContactWork = Pick<
  ChatTask,
  'contact' | 'profile' | 'connectionValue' | 'taskId' | 'type' | 'connectedAtTime' | 'time'
> & {
  messages?: ChatTask['messages'];
  setCreateAgentTaskOpen: (boolean) => void;
  setCreateTaskDefaults: (any) => void;
  user: TUser;
  queueName?: string;
};

export default function AfterContactWork(props: TAfterContactWork) {
  const { setCreateAgentTaskOpen, setCreateTaskDefaults, user, queueName, ...task } = props;
  const { contact, profile, connectionValue, type, time = new Date() } = task;
  const connectedAtTime = task.connectedAtTime || new Date();
  const endedAt = getContactConnection(contact)
    ? connectGetter('getState', getContactConnection(contact))?.timestamp ?? new Date()
    : new Date();

  const [campaign, setCampaign] = useState<string | null>(null);
  const [outcome, setOutcome] = useState<string | null>(null);
  const [chatTranscriptionVisible, setChatTranscriptionVisible] = useState<boolean>(false);

  const onToggleChatTranscriptionModal = () => {
    setChatTranscriptionVisible(!chatTranscriptionVisible);
  };

  const scheduleCallback = () => {
    setCreateTaskDefaults({
      name: 'Call A Customer Back',
      description:
        type === CONTACT_TYPES.TASK
          ? ''
          : getScheduleCallbackDescription(connectionValue, profile, connectedAtTime, user)
    });
    setCreateAgentTaskOpen(true);
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      {type === CONTACT_TYPES.CHAT && (
        <Modal
          onClose={onToggleChatTranscriptionModal}
          title="Chat History"
          open={chatTranscriptionVisible}
          secondaryButtonText="Close"
          small
        >
          <Chat {...(task as unknown as ChatTask)} minimised showEmptyPlaceholder user={user} />
          <hr className={styles['after-contact-work__divider']} />
        </Modal>
      )}
      <div className={styles['after-contact-work']}>
        <div className={styles['after-contact-work__info']}>
          <QueueNameAndTimer queueName={queueName} time={time} />
          <h4 className={styles['after-contact-work__title']}>Interaction Information</h4>
          <div className={styles['after-contact-work__info__row']}>
            <Icon
              icon={type === CONTACT_TYPES.TASK ? faInfoCircle : faUser}
              tooltip={type === CONTACT_TYPES.TASK ? 'Task Name' : 'Customer'}
            />
            <span>{profile && profile.firstName ? getUserName(profile) : connectionValue}</span>
          </div>
          <div className={styles['after-contact-work__info__row']}>
            <Icon icon={faPlug} tooltip="The time the interaction started" />
            <span>{format(connectedAtTime, 'h:mm aa')}</span>
          </div>
          <div className={styles['after-contact-work__info__row']}>
            <Icon icon={faClock} tooltip="Interaction duration" />
            <span>{formatDistanceStrict(connectedAtTime, endedAt)}</span>
          </div>
          {type === CONTACT_TYPES.CHAT && (
            <div className={cx(styles['after-contact-work__info__row'], styles['after-contact-work__info__row-chat'])}>
              <Icon icon={faComments} />
              <a onClick={onToggleChatTranscriptionModal}>View Chat History</a>
            </div>
          )}
        </div>
        <ACWOutcome campaign={campaign} outcome={outcome} setCampaign={setCampaign} setOutcome={setOutcome} />
        <p className="mb-20">You can add notes about this interaction in the customer’s profile.</p>
      </div>
      <TaskFooter.ACW outcome={outcome} campaign={campaign} scheduleCallback={scheduleCallback} />
    </>
  );
}
