const ENTER_KEY = 'Enter';

export default function onEnterKeyPressed(onPress) {
  return (event) => {
    if (event.key !== ENTER_KEY) {
      return;
    }

    event.preventDefault();

    onPress();
  };
}
