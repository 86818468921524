import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

import { Button, Icon, Text } from 'lib/common/components';
import { ReactComponent as SignedOutImage } from './assets/signed-out.svg';

import styles from './signed-out-overlay.module.scss';

export default function SignedOutOverlay() {
  return (
    <div className={styles['signed-out-overlay']}>
      <div>
        <Text className="mb-20" type="heading1">
          We've Signed You Out <Icon className="ml-10" size={25} icon={faCheckCircle} />
        </Text>
        <Text>
          You’ve been set to <strong>offline</strong> and we’ve ended your session. You can sign back in by clicking the
          button below or reloading NEON.
        </Text>
        <Text className="mt-20 mb-40">
          <strong>We recommend always signing out of NEON when you finish for the day.</strong>
        </Text>
        <Button
          onClick={() => {
            window.location.reload();
          }}
        >
          Sign Back In
        </Button>
      </div>
      <SignedOutImage />
    </div>
  );
}
