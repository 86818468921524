// @ts-nocheck
import * as Icons from '@fortawesome/pro-regular-svg-icons';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Button as MUIButton, Tooltip } from '@material-ui/core';
import cx from 'classnames';
import { CSSProperties, forwardRef, ReactElement, useState, useEffect } from 'react';
import useActionState from 'lib/common/hooks/useActionState';
import { Icon } from '../';

import './button.scss';

const TIME_BETWEEN_CLICKS_MS = 1000; // 1s

const STYLE_TYPES = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
  DANGER: 'DANGER',
  SUCCESS: 'SUCCESS',
  NEUTRAL: 'NEUTRAL',
  WARNING: 'WARNING'
};

const ICON_COLOUR_MAP = {
  [STYLE_TYPES.PRIMARY]: 'white',
  [STYLE_TYPES.SECONDARY]: 'primary',
  [STYLE_TYPES.DANGER]: 'white',
  [STYLE_TYPES.SUCCESS]: 'white',
  [STYLE_TYPES.WARNING]: 'white',
  [STYLE_TYPES.NEUTRAL]: void 0
};

interface ButtonProps {
  styleType?: ValueOf<typeof STYLE_TYPES>;
  busy?: boolean;
  icon?: string;
  disabled?: boolean;
  children?: any;
  className?: string;
  onClick?: (any) => any;
  small?: boolean;
  large?: boolean;
  onSuccess?: (response: any) => void;
  onFailure?: (e: any) => void;
  onFinally?: () => void;
  loading?: boolean;
  endIcon?: ReactElement;
  href?: string | null | undefined;
  style?: CSSProperties;
  type?: string;
  asyncAction?: boolean;
  round?: boolean;
  tooltip?: string;
  successTimeoutSeconds?: number;
  fullWidth?: boolean;
  preventDoubleClick?: boolean;
  thin?: boolean;
}

function getIconSize({ small, large, round }) {
  if (large) {
    return 25;
  }

  if (small) {
    return 15;
  }

  if (round) {
    return 18;
  }

  return 20;
}

function Button(
  {
    styleType = STYLE_TYPES.PRIMARY,
    children,
    icon,
    className = '',
    small,
    large,
    onClick,
    style,
    onFailure,
    onSuccess,
    onFinally,
    busy: loading = false,
    asyncAction,
    tooltip,
    round,
    successTimeoutSeconds,
    fullWidth = false,
    preventDoubleClick = false,
    thin = false,
    ...props
  }: ButtonProps,
  ref?: Ref<any>
) {
  const {
    icon: actionIcon,
    handleInteraction,
    busy,
    icons
  } = useActionState({
    onSuccess,
    onAction: onClick,
    onFailure,
    onFinally,
    loading,
    successTimeoutSeconds
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const prefixIcon = actionIcon ? actionIcon.name : icon;

  useEffect(() => {
    if (!preventDoubleClick || !isButtonDisabled) {
      return;
    }

    const handler = setTimeout(() => setIsButtonDisabled(false), TIME_BETWEEN_CLICKS_MS);
    return () => clearTimeout(handler);
  }, [isButtonDisabled, preventDoubleClick]);

  const onButtonClick = (e) => {
    if (!onClick) {
      return;
    }

    if (asyncAction) {
      return handleInteraction();
    }

    onClick(e);
  };

  const onPreventDoubleClick = (e) => {
    if (isButtonDisabled) {
      return;
    }

    setIsButtonDisabled(true);

    onButtonClick(e);
  };

  const iconOnly = !children && icon;

  const button = (
    <MUIButton
      data-testid={`button-${styleType?.toLowerCase()}`}
      className={cx(
        'button',
        {
          [`button--${styleType.toLowerCase()}`]: !style,
          'button--busy': busy,
          'button--disabled': busy || props.disabled,
          'button--small': small,
          'button--large': large,
          'button--icon-only': iconOnly,
          'button--round': round,
          'button--thin': thin
        },
        className
      )}
      disabled={Boolean(props.disabled || busy) || isButtonDisabled}
      style={style}
      fullWidth={fullWidth}
      endIcon={!busy && props.endIcon ? props.endIcon : void 0}
      onClick={preventDoubleClick ? onPreventDoubleClick : onButtonClick}
      disableRipple
      ref={ref}
      {...props}
    >
      {prefixIcon && (
        <Icon
          className="button__icon"
          spin={prefixIcon === icons.busy.name}
          color={ICON_COLOUR_MAP[styleType]}
          icon={Icons[prefixIcon] || ['far', icon as IconName]}
          size={getIconSize({ small, large, round })}
        />
      )}
      {children && <div className="button__content">{children}</div>}
    </MUIButton>
  );

  return tooltip && !busy ? (
    <Tooltip enterDelay={1000} title={tooltip}>
      <div>{button}</div>
    </Tooltip>
  ) : (
    button
  );
}

export default forwardRef(Button);
