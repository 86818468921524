import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

import cx from 'classnames';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { Icon } from '../../../../components';
import SidebarMenuItemComponent from './SidebarMenuComponent';

type SidebarMenuItemPropTypes = {
  name: string;
  link: string;
  icon: any;
  type: string;
  activeClassName: string;
  permission: string;
  pageHasSubMenu: boolean;
  permissionType: string;
  isSidebarOpen: boolean;
};

type SidebarMenuItemProps = SidebarMenuItemPropTypes & {
  items?: SidebarMenuItemProps[];
};

const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    fontColor: 'rgba(88, 88, 88, 0.7)',
    borderRadius: '10px',
    padding: '10px'
  }
}))(Tooltip);

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = (props) => {
  const { pathname } = useLocation();
  const {
    name,
    link,
    icon,
    items = [],
    activeClassName,
    permission,
    pageHasSubMenu,
    permissionType,
    isSidebarOpen
  } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);
  const { hasPermission } = usePermissionsContext();

  const hasSubMenuActive = items.some(({ link }) => link === pathname);

  useEffect(() => {
    if (!hasSubMenuActive) {
      return;
    }

    setOpen(true);
  }, []);

  useEffect(() => {
    if (!hasSubMenuActive) {
      setOpen(false);
      return;
    }

    setOpen(true);
  }, [hasSubMenuActive, pathname]);

  const subMenuActiveWithSidebarOpen = hasSubMenuActive && isSidebarOpen;
  const subMenuActiveWithSidebarClosed = hasSubMenuActive && !isSidebarOpen;

  const MenuItemRoot = hasPermission({ type: permissionType || 'tenant', permission }) && icon && (
    <SidebarMenuItemComponent
      pageHasSubMenu={Boolean(pageHasSubMenu)}
      link={link}
      activeClassName={activeClassName}
      className={cx({
        'sidebar__submenu-active': subMenuActiveWithSidebarOpen,
        sidebar__closed: !isSidebarOpen
      })}
    >
      <div
        className={cx('sidebar__menu-items', {
          'sidebar__menu-items__active': subMenuActiveWithSidebarOpen,
          'sidebar__menu-items__active__sidebar-closed': subMenuActiveWithSidebarClosed
        })}
      >
        <HtmlTooltip title={name} aria-label={name} placement="bottom-end" enterDelay={1000}>
          <ListItemIcon data-testid={`list-icon${link}`}>
            <Icon icon={icon} />
          </ListItemIcon>
        </HtmlTooltip>
        <ListItemText className="nav-text" primary={name} />
      </div>
    </SidebarMenuItemComponent>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      className={cx({
        'sidebar__collapse-active': subMenuActiveWithSidebarOpen,
        'sidebar__collapse-active__sidebar-closed': subMenuActiveWithSidebarClosed
      })}
    >
      {items.map((item, index) => (
        <SidebarMenuItem {...item} key={index} isSidebarOpen={isSidebarOpen} />
      ))}
    </Collapse>
  ) : null;

  return (
    <>
      <List
        component="div"
        disablePadding
        className={cx('sidebar__parent-menu', {
          'sidebar__parent-menu__submenu-active__sidebar-closed': subMenuActiveWithSidebarClosed
        })}
      >
        {MenuItemRoot}
      </List>
      {MenuItemChildren}
    </>
  );
};

export default SidebarMenuItem;
