import PhoneNumber from 'awesome-phonenumber';
import { Text } from 'lib/common/components';
import { useContactContext } from '../contexts/ContactContext';

export default function ClickToCall({ children }) {
  const {
    actions: { makeOutboundCall }
  } = useContactContext();

  const number = children && PhoneNumber(children);
  const isValidNumber = number && number.isValid();

  return <Text onClick={isValidNumber ? () => makeOutboundCall(children) : void 0}>{children}</Text>;
}
