import { PermissionsConsumer } from 'lib/common/contexts/PermissionsContext';
import PureUsers from '../components/Users';

const DASHBOARDS_PERMISSION = 'tailoreddashboards';

export default function Users(props) {
  return (
    <PermissionsConsumer>
      {({ hasPermission }) => (
        <PureUsers {...props} hasDashboards={hasPermission({ type: 'user', permission: DASHBOARDS_PERMISSION })} />
      )}
    </PermissionsConsumer>
  );
}
