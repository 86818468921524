import { useContext, useState, useCallback, ChangeEventHandler, useMemo } from 'react';
import _capitalise from 'lodash.capitalize';
import _isEmpty from 'lodash.isempty';
import { parsePhoneNumber } from 'awesome-phonenumber';
import { TextField, FormHelperText } from '@material-ui/core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';

import { AuthContext } from 'lib/core/context/AuthProvider';
import getUserName from 'lib/common/utils/getUserName';
import { Button, ClickableIcon, Label, ReactSelect } from 'lib/common/components';
import VALIDATION_TEXT from 'lib/common/constants/validationText';
import { PhoneInput } from 'lib/common/components/index';
import { ContactActions, IContact, IContactAction } from '../../types';
import { NUMBER_TYPE_OPTIONS } from '../../constants/numberTypes';
import './upsert-contact.scss';

interface AgentDbKey {
  tenantId: string;
  uuid: string;
  objectKey: string;
  objectId: string;
}

interface IProps {
  show: boolean;
  closeModal: (...args: any) => any;
  contactAction: IContactAction;
  currentTab: number;
}

export type TErrors = {
  phoneNumber?: string;
  numberType?: string;
};

const validateData = (fieldName: string, value: string) => {
  if (fieldName === 'phoneNumber' && value) {
    return {
      [fieldName]: !parsePhoneNumber(value).isValid() ? VALIDATION_TEXT.PHONE_NUMBER : ''
    };
  }

  return {
    [fieldName]: _isEmpty(value) ? 'This field is required' : ''
  };
};

const inputValueParser = ({ target: { value } }) => value;
const numberParser = (number) => number;

const UpsertContact: React.FunctionComponent<IProps> = ({ contactAction, closeModal, currentTab, show }) => {
  const { config, fetch_ } = useContext(AuthContext);
  const [data, setData] = useState(contactAction.contact);
  const [validationErrors, setValidationErrors] = useState<TErrors>({});

  const toCreate = contactAction.action === ContactActions.CREATE;

  const save = async (body, action?: string) => {
    const contactCreateUrl = `${config.AGENT_SERVICE_HOST}/agent?type=contact`;

    const res = await fetch_(contactCreateUrl, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: action === ContactActions.DELETE ? 'DELETE' : 'POST',
      body: JSON.stringify(body)
    });

    return res.json();
  };

  const onCreateOrUpdate = () => {
    let body: IContact | AgentDbKey = data;

    if (toCreate) {
      body = {
        ...body,
        tenantId: process.env.REACT_APP_TENANT_ID || '',
        uuid: uuid(),
        objectKey:
          currentTab === 0
            ? `${process.env.REACT_APP_TENANT_ID}__${sessionStorage.getItem('c_user')}`
            : `${process.env.REACT_APP_TENANT_ID}__${uuid().split('-')[0]}`,
        objectId: `contact_${uuid()}`
      };
    }

    if (toCreate && currentTab !== 0) {
      body = { ...body, objectIdentifier: `${process.env.REACT_APP_TENANT_ID}__contact` };
    }

    return save(body);
  };

  const onDelete = () => {
    const { tenantId, uuid, objectKey, objectId } = data;
    return save({ tenantId, uuid, objectKey, objectId }, ContactActions.DELETE);
  };

  const handleClose = () => {
    closeModal(true);
  };

  const onChange = useCallback(
    (fieldName: string, valueParser?: (_: any) => any): ChangeEventHandler<HTMLInputElement> =>
      (value: unknown) => {
        const newData = { ...data, [fieldName]: valueParser ? valueParser(value) : value };
        setData(newData);
      },
    [data]
  );

  const onBlur = (fieldName: string) => {
    const errors = validateData(fieldName, data[fieldName]);

    const updatedErrors = {
      ...validationErrors,
      ...errors
    };

    setValidationErrors(updatedErrors);
  };

  const onFocus = (fieldName: string) => {
    setValidationErrors({ ...validationErrors, [fieldName]: void 0 });
  };

  const { firstName, lastName, organization, phoneNumber, numberType } = data;

  const hasError = useMemo(() => {
    return (
      _isEmpty(phoneNumber) ||
      _isEmpty(numberType) ||
      !Object.values(validationErrors).every((value) => !Boolean(value))
    );
  }, [phoneNumber, numberType, validationErrors]);

  const { phoneNumber: phoneNumberError, numberType: numberTypeError } = validationErrors;

  if (!show) {
    return null;
  }

  return (
    <div className="upsert-contact">
      <h3 className="upsert-contact__header">
        <ClickableIcon icon={faArrowLeft} onClick={handleClose} />
        {`${_capitalise(contactAction.action)} ${getUserName({
          ...(contactAction.contact || {}),
          fallback: 'A Contact'
        })}`}
      </h3>
      <div className="upsert-contact__row__username">
        <TextField
          autoComplete="new-password"
          label="First Name"
          variant="outlined"
          name="firstName"
          id="firstName"
          value={firstName}
          type="text"
          onChange={onChange('firstName', inputValueParser)}
        />
        <TextField
          autoComplete="new-password"
          variant="outlined"
          label="Last Name"
          name="lastName"
          id="lastName"
          value={lastName}
          type="text"
          onChange={onChange('lastName', inputValueParser)}
        />
      </div>
      <div className="upsert-contact__row">
        <TextField
          autoComplete="new-password"
          variant="outlined"
          label="Organisation"
          name="organization"
          id="organization"
          value={organization}
          type="text"
          onChange={onChange('organization', inputValueParser)}
        />
      </div>
      <div className="upsert-contact__row">
        <PhoneInput
          initialValue={phoneNumber}
          onChange={onChange('phoneNumber', numberParser)}
          id="phone-number"
          helperText={phoneNumber && Boolean(phoneNumberError) ? phoneNumberError : void 0}
          onBlur={() => onBlur('phoneNumber')}
          onFocus={() => onFocus('phoneNumber')}
          error={phoneNumber ? Boolean(phoneNumberError) : void 0}
          testId="upsert-field-phone"
        />
      </div>
      <div className="upsert-contact__row" data-testid="directory-test">
        <Label text="Type" id="numberType">
          <ReactSelect
            name="numberType"
            isSearchable={false}
            options={NUMBER_TYPE_OPTIONS}
            className={cx('upsert-contact__number-type', {
              'upsert-contact__number-type__error': Boolean(numberTypeError)
            })}
            classNamePrefix="select"
            onChange={onChange('numberType', (selectedType) => selectedType.value) as any}
            onBlur={() => onBlur('numberType')}
            value={NUMBER_TYPE_OPTIONS.find((option) => option.value === numberType)}
            aria-label="number-type-select"
          />
        </Label>
        <FormHelperText className="upsert-contact__type-error">{numberTypeError || ''}</FormHelperText>
      </div>
      <div
        className={cx('upsert-contact__row__footer', {
          'upsert-contact__row__footer__single': toCreate
        })}
      >
        {!toCreate && (
          <Button icon="faTrash" onSuccess={handleClose} onClick={onDelete} styleType="DANGER" asyncAction />
        )}
        <Button
          disabled={hasError}
          onClick={onCreateOrUpdate}
          onSuccess={handleClose}
          asyncAction
          data-testid="upsert-contact-save"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UpsertContact;
