import { ReactNode, createContext, useContext, useEffect, useState, useRef } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useBeforeunload } from 'react-beforeunload';

import EventEmitter from 'lib/common/utils/EventEmitter';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import TUser from 'lib/common/types/User';
import connectGetter from 'lib/common/utils/connectGetter';
import { ConfigContext } from '../../config';
import LoginPage from './components/Login';
import SIGN_OUT_TYPES from './constants/signOutTypes';
import SigningOutOverlay from './components/SigningOutOverlay';
import SignedOutOverlay from './components/SignedOutOverlay';
import ConnectStreams from './components/ConnectStreams';
import AuthError from './components/AuthError';

const STREAMS_CLEANUP_TIMEOUT_MS = 2000;

type Props = {
  children: ReactNode;
};

type TAuthContext = {
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;

  // TODO define this. I've added a lot of types in here but this is a big one
  config: any;
  loaded?: boolean;

  // TODO define this. I've added a lot of types in here but this is a big one
  tokens?: any;
  email?: string;
  signOut: () => void;
  connectUserId?: string;
  user: TUser | null;
};

const USERNAME_SUFFIX = '@neon.com';

const defaultAuth = {
  fetch_: () => Promise.resolve(),
  signOut: () => {},
  config: {},
  user: null
};

export const AuthContext = createContext<TAuthContext>(defaultAuth);

function getChild({ children, loaded, user, error, signedOut, signingOut, signingOutRef }) {
  if (error) {
    return <AuthError />;
  }

  if (signedOut) {
    return <SignedOutOverlay />;
  }

  return (
    <>
      {signingOut && <SigningOutOverlay type={signingOutRef?.current} />}
      {!user && <LoginPage />}
      {loaded && children}
    </>
  );
}

function getConnectUserID(agent) {
  const agentQueues = connectGetter('getConfiguration', agent)?.routingProfile.queues;

  // By default, an agent is assigned a queue for transferring calls to the agent directly.
  const agentQueueArn = agentQueues?.find((q) => q.name === null)?.queueARN;

  // We get the connect user ID from the null queue ARN:
  // e.g. arn:aws:connect:ap-southeast-2:account:instance/instance-id/queue/agent/6ce04498-e7a3-44c2-a807-19a882bd6577"
  return agentQueueArn?.split('/')[4];
}

const AuthProvider = ({ children }: Props) => {
  const configContext = useContext(ConfigContext);
  const { config } = configContext;
  const { agent } = useAgentContext();
  const [tokens, setTokens] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [user, setUser] = useState<TUser | null>(null);
  const [signedOut, setSignedOut] = useState<boolean>(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);
  const [connectUserId, setConnectUserId] = useState<string | undefined>();
  const signingOutRef = useRef<null | ValueOf<typeof SIGN_OUT_TYPES>>(null);

  const tenantID = process.env.REACT_APP_TENANT_ID;
  const stage = process.env.REACT_APP_STAGE;

  const checkSession = async () => {
    try {
      if (loaded) {
        return;
      }

      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      if (mounted) {
        setTokens(JSON.parse(JSON.stringify(currentSession)));
      }

      const username = currentUser.username.split('@')[0].split('__')[1];

      EventEmitter.emit('initUserData', username);
    } catch (e) {
      if (e == null) {
        return;
      }
      console.error('Error checking user session', e);
    }
  };

  const signOut = async (type = SIGN_OUT_TYPES.MANUAL_SIGN_OUT) => {
    if (signingOutRef?.current) {
      return;
    }

    signingOutRef.current = type;
    setSigningOut(true);

    const connect = (window as any).getConnect();

    await fetch(`${config.CONNECT_HOST}/connect/logout`, {
      credentials: 'include',
      mode: 'no-cors'
    });

    connect.core.eventBus.trigger(connect.EventType.TERMINATE);

    navigator.sendBeacon(
      `${config.AGENT_SERVICE_HOST}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/cleanup/`,
      'close'
    );

    await Auth.signOut();

    sessionStorage.removeItem('currentPage');
    sessionStorage.removeItem('c_user');
    sessionStorage.removeItem('email');

    // Wait for streams cleanup
    setTimeout(() => {
      // Don't reload the page if it's a manual sign out
      if (type === SIGN_OUT_TYPES.MANUAL_SIGN_OUT) {
        return void setSignedOut(true);
      }

      window.location.href = window.location.origin;
    }, STREAMS_CLEANUP_TIMEOUT_MS);
  };

  EventEmitter.on('onInit', async (user_) => {
    try {
      checkSession();
      if (loaded) {
        return;
      }
      signIn(user_);
    } catch (e) {
      console.error('Error refreshing token', e);
    }
  });

  EventEmitter.on('onError', (val) => {
    setError(val);
  });

  const fetch_ = async (url, options = {}, tokens_?, throwError = true): Promise<Response> => {
    try {
      const tokensStore = tokens === null ? tokens_ : tokens;
      const headers = new Headers();
      const token = tokensStore?.idToken?.jwtToken;

      if (!token) {
        return Promise.reject('no token');
      }

      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');

      const result = await fetch(url, { ...options, headers });

      if (!result.ok) {
        const errorBody = await result.json();
        if (throwError) {
          throw new Error(errorBody.message || errorBody);
        }
      }

      return result;
    } catch (e) {
      console.error('Fetch error', e);
      throw e;
    }
  };

  const signIn = async (user) => {
    try {
      const username = `${tenantID}__${user.username}${!user.username.includes('@') ? USERNAME_SUFFIX : ''}`; // email address
      const challengeAnswer = user.routingProfile.queues.filter((q) => q.name === null)[0].queueARN.split('/')[4]; // connect user id
      const cognitoUser = await Auth.signIn(username);
      const currentSession = await Auth.sendCustomChallengeAnswer(cognitoUser, challengeAnswer);

      setTokens(JSON.parse(JSON.stringify(currentSession.signInUserSession)));
    } catch (e) {
      setError(true);
      console.error('Error signing in', e);
    }
  };

  if (configContext.configLoaded) {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });
  }

  useEffect(() => {
    EventEmitter.on('userData', (user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (!agent || connectUserId) {
      return;
    }

    setConnectUserId(getConnectUserID(agent));
  }, [agent]);

  useEffect(() => {
    const getSecureConfig = async () => {
      const res = await fetch_(`${config.AGENT_SERVICE_HOST}/cfg/secure/${tenantID}/${stage}`);
      const data = await res.json();

      config.ELASTICSEARCH_HOST = data.ELASTICSEARCH_HOST;
      config.ELASTICSEARCH_INDEX = data.ELASTICSEARCH_INDEX;

      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_HOST}/connect/${tenantID}/describe/user/?objectId=${connectUserId}`
      );
      const userInfo = await userInfoJson.json();

      // Making userInfo optional as IsolatedAuthProvider user object can be empty
      setEmail(userInfo?.User?.IdentityInfo?.Email);

      EventEmitter.emit('initUserData', sessionStorage.getItem('c_user'));
    };

    const initializeApp = async () => {
      setFetching(true);

      try {
        await getSecureConfig();

        // Logging users out after session invalid
        // @ts-ignore This exists and works but isn't in the type
        connect.core.getEventBus().subscribe(connect.EventType.AUTH_FAIL, () => {
          signOut(SIGN_OUT_TYPES.AUTH_FAIL);
        });

        // // Logging users out after session expiry
        // // @ts-ignore This exists and works but isn't in the type
        // connect.core.getEventBus().subscribe(connect.EventType.ACK_TIMEOUT, () => {
        //   signOut(SIGN_OUT_TYPES.ACK_TIMEOUT);
        // });
        setLoaded(true);
      } catch (e) {
        // Better to console log all errors rather than failing silently
        console.log(e);
        setError(true);
      }
    };

    if (tokens !== null && !fetching) {
      if (process.env.REACT_APP_IS_EXISTING === 'NO') {
        initializeApp();
      } else {
        setLoaded(true);
        EventEmitter.emit('initUserData', sessionStorage.getItem('c_user'));
      }
    }

    return () => {
      setMounted(false);
    };
  }, [tokens]);

  useBeforeunload((event) => {
    const contacts = connectGetter('getContacts', agent) || [];

    // If there are no contacts, or we are signing out, don't block reload
    if (!contacts.length || signingOutRef.current) {
      return;
    }

    // Show dialog to prevent users from refreshing the page when they have tasks
    event.preventDefault();
  });

  return (
    <AuthContext.Provider
      value={{
        config,
        fetch_,
        loaded,
        tokens,
        email,
        signOut,
        connectUserId,
        user
      }}
    >
      <ConnectStreams user={user} />
      {getChild({ error, user, signedOut, signingOut, signingOutRef, children, loaded })}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

export default AuthProvider;
