import 'what-input';
import { useContext, useEffect, useState } from 'react';

// components
import { Error } from 'lib/common/components';

// contexts
import PermissionsContext from 'lib/common/contexts/PermissionsContext';

import EventEmitter from 'lib/common/utils/EventEmitter';
import getQueryParam from 'lib/common/utils/getQueryParam';
import { AuthContext } from '../core/context/AuthProvider';

import RouteSwitcher from './components/RouteSwitcher';

const REVERSED_APP_LAYOUT_QUERY_PARAM = 'reversed';

function Routes() {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(false);

  const { fetch_, config } = useContext(AuthContext);

  // Define this in here, so we can capture it before any routes changes or any redirects occur
  const isReversedLayout = Boolean(getQueryParam({ param: REVERSED_APP_LAYOUT_QUERY_PARAM }));

  const initUser = async (user) => {
    const userId = user.username || user;
    const objectKey = `${process.env.REACT_APP_TENANT_ID}__${userId}`;
    const agentUrl = `${config.AGENT_SERVICE_HOST}/agent/${objectKey}`;
    const uiConfigUrl = `${config.AGENT_SERVICE_HOST}/config/${objectKey}/ui-config`;

    try {
      const responses = await Promise.all([fetch_(agentUrl), fetch_(uiConfigUrl)]);
      const data = await Promise.all(responses.map((res) => res.json()));

      if (!data[1].configBlob) {
        setError(true);

        return void EventEmitter.emit('onError', true);
      }

      const userToStore = { ...data[0], ...data[1].configBlob, username: userId };

      setUser(userToStore);

      EventEmitter.emit('userData', userToStore);

      sessionStorage.setItem('email', userToStore.email || '');
    } catch (error) {
      setError(true);

      console.error('Error fetching user: ', error);
    }
  };

  useEffect(() => {
    EventEmitter.on('initUserData', (user) => {
      initUser(user);
    });

    EventEmitter.on('onError', (val) => {
      setError(val);
    });

    if (!sessionStorage.getItem('c_user')) {
      return;
    }

    initUser(sessionStorage.getItem('c_user'));
  }, []);

  if (error) {
    return <Error />;
  }

  if (!user) {
    return null;
  }

  return (
    <PermissionsContext user={user}>
      <RouteSwitcher isReversedLayout={isReversedLayout} user={user} />
    </PermissionsContext>
  );
}

export default Routes;
