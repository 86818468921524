import cx from 'classnames';
import Loader from '../../Loader';
import '../styles/tab.scss';

interface TabProps {
  title: string;
  isSelected: boolean;
  onClick: () => void;
  showSelectedTabLoader?: boolean;
}

export default function Tab({ title, isSelected, onClick, showSelectedTabLoader }: TabProps) {
  return (
    <button
      data-testid={title}
      data-tour={title}
      type="button"
      className={cx('tab', { 'tab--selected': isSelected })}
      onClick={!isSelected ? onClick : void 0}
    >
      <div className="tab__wrapper">
        <p
          className={cx('tab__text', {
            'tab__text--selected': isSelected
          })}
        >
          {title}
        </p>
        <Loader
          size={20}
          minimised
          className={cx('tab__text__loader', {
            'tab__text__loader--visible': showSelectedTabLoader
          })}
        />
      </div>
    </button>
  );
}
