import { useEffect, useState } from 'react';
import cx from 'classnames';
import sizeMe from 'react-sizeme';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import { Icon, Popover } from 'lib/common/components';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import connectGetter from 'lib/common/utils/connectGetter';
import styles from './agent-status-timer.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const PRESENCE_DURATION_TIMEOUT_MS = 100;
const SMALL_TIMER_WIDTH = 100;
const WITH_HOUR_TEXT_WIDTH = '58px';
const WITHOUT_HOUR_TEXT_WIDTH = '40px';

interface IAgentStatusTimerProps {
  className?: string;
  size: { width: number };
}

const AgentStatusTimer = ({ className, size: { width } }: IAgentStatusTimerProps) => {
  const [startTime, setStartTime] = useState<Date>();
  const [show, setShow] = useState<boolean>(false);

  const timer = useTaskTimer(startTime);

  const { agent } = useAgentContext();
  const { agentPresence, isRemotelyBusy } = usePresenceContext();

  useEffect(() => {
    setShow(false);

    if (Object.values(PRESENCE_STATES).includes(agentPresence)) {
      return;
    }

    // We need a timeout here to wait for connect to reset duration of a status
    // before we pull it out
    setTimeout(() => {
      const duration = connectGetter('getStateDuration', agent);
      const nextState = connectGetter('getNextState', agent);

      if (!duration || nextState) {
        return;
      }

      const now = new Date().getTime();
      const startDate = new Date(now - duration);

      setShow(true);
      setStartTime(startDate);
    }, PRESENCE_DURATION_TIMEOUT_MS);
  }, [agentPresence]);

  if (isRemotelyBusy) {
    return null;
  }

  /**
   * 00:00 -> minutes:seconds
   * 00:00:00 -> hours:minutes:seconds
   */
  const timerWidth = timer.split(':').length > 2 ? WITH_HOUR_TEXT_WIDTH : WITHOUT_HOUR_TEXT_WIDTH;
  const isSmallTimer = width < SMALL_TIMER_WIDTH;

  const timerEl = (
    <p className={styles['agent-status-timer__container__text']} style={{ width: timerWidth }}>
      {timer}
    </p>
  );
  const iconEl = <Icon icon={faClock} size={isSmallTimer ? 15 : 17} />;

  return (
    <div className={cx(styles['agent-status-timer'], className)}>
      <CSSTransition
        in={show}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles['agent-status-timer-enter'],
          enterActive: styles['agent-status-timer-enter-active'],
          exit: styles['agent-status-timer-enter'],
          exitActive: styles['agent-status-timer-exit-active']
        }}
      >
        {!isSmallTimer ? (
          <div className={cx(styles['agent-status-timer__container'])} data-testid="agent-status-timer">
            {iconEl}
            {timerEl}
          </div>
        ) : (
          <Popover
            anchor={
              <div
                className={cx(styles['agent-status-timer__container'], styles['agent-status-timer__container-popover'])}
                data-testid="agent-status-timer"
              >
                {iconEl}
              </div>
            }
          >
            <div className={styles['agent-status-timer__container__popover-text']}>{timerEl}</div>
          </Popover>
        )}
      </CSSTransition>
    </div>
  );
};

export default sizeMeHOC(AgentStatusTimer);
