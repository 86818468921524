export default {
  actions: {
    setSelectedTaskId: () => {},
    setLastDialedNumber: () => {},
    matchExistingCustomerProfile: () => {},
    deleteExistingCustomerProfile: () => {},
    rejectTask: () => Promise.resolve(),
    acceptTask: () => Promise.resolve(),
    endTask: () => Promise.resolve(),
    removeTask: () => Promise.resolve(),
    makeOutboundCall: () => Promise.resolve(),
    onTransfer: () => Promise.resolve(),
    onTransferToQueueOrAgent: () => Promise.resolve(),
    holdCall: () => Promise.resolve(),
    resumeCall: () => Promise.resolve(),
    hangupAgent: () => Promise.resolve(),
    onJoinConference: () => Promise.resolve(),
    sendMessage: () => {},
    sendTypingEvent: () => {},
    getActiveCallTask: () => undefined,
    onHoldAll: () => Promise.resolve(),
    onSwapConferenceCall: () => Promise.resolve(),
    endThirdPartyConnection: () => Promise.resolve(),
    holdOutboundCall: () => Promise.resolve(),
    resumeOutboundCall: () => Promise.resolve(),
    mute: () => {},
    unmute: () => {}
  },
  state: {
    tasks: [],
    selectedTaskId: void 0,
    onActiveCall: false,
    lastDialedNumber: '',
    hasIncomingTask: false
  }
};
