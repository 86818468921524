import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import Landing from './Landing';
import Frame from './Frame';

const PAGE_TYPE = 'PAGE';

interface PageModuleProps {
  pageModuleProps: ConnectorModuleType;
  user: any;
}

export default function PageModule(props: PageModuleProps) {
  const isFrame = props.pageModuleProps.type === PAGE_TYPE;
  const Component = isFrame ? Frame : Landing;

  return <Component {...props.pageModuleProps} />;
}
