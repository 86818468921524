import { useState, MouseEvent } from 'react';
import _orderBy from 'lodash.orderby';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { faCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MAX_CONNECT_METRICS_QUEUES = 100;

export default function QueueSelect({ onSelect, queueSelect: queues }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClick = (value) => () => {
    onSelect(value);

    onClose();
  };

  const menuOptions = _orderBy(queues, 'Name', 'asc').map(({ Id, Name }) => (
    <MenuItem onClick={onClick(Id)} key={Id} data-my-value={Name}>
      {Name}
    </MenuItem>
  ));

  return (
    <>
      <div className="wallboard__stats-title__queue-select">
        <IconButton onClick={handleClick}>
          <FontAwesomeIcon icon={faCaretDown} size="sm" />
        </IconButton>
      </div>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={onClose}>
        {queues.length <= MAX_CONNECT_METRICS_QUEUES ? (
          <MenuItem onClick={onClick('all')} key="all" data-my-value="All">
            All Queues
          </MenuItem>
        ) : null}
        {menuOptions}
      </Menu>
    </>
  );
}
