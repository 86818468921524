import React from 'react';
import { formatDistanceToNow } from 'date-fns';

export default function UpdateTime({ updateTime, show }: { updateTime: number; show: boolean }) {
  return (
    <div className="update-time">
      {show && (
        <>
          Last updated <strong>{formatDistanceToNow(updateTime)}</strong> ago
        </>
      )}
    </div>
  );
}
