import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import Task from 'lib/common/types/Task';
import TUser from 'lib/common/types/User';
import TASK_TITLES from 'lib/common/constants/tasks/taskTitles';
import getTaskContentType from 'lib/common/utils/getTaskContentType';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';
import TChatTask from 'lib/common/types/ChatTask';
import { ReactComponent as NoSelectionCall } from '../assets/no-selection-call.svg';
import { ReactComponent as NoSelectionChat } from '../assets/no-selection-chat.svg';
import { ReactComponent as NoSelectionTask } from '../assets/no-selection-task.svg';
import TaskDetailHeader from './TaskDetailHeader';
import AfterContactWork from './AfterContactWork';
import TimedTask from './TimedTask';
import AgentTask from './AgentTask';
import NeglectedTask from './NeglectedTask';
import ChatTask from './Chat';

import '../styles/task-detail.scss';

const PLACEHOLDER_IMAGES = {
  [CONTACT_TYPES.CALL]: NoSelectionCall,
  [CONTACT_TYPES.QUEUE_CALLBACK]: NoSelectionCall,
  [CONTACT_TYPES.CHAT]: NoSelectionChat,
  [CONTACT_TYPES.TASK]: NoSelectionTask
};

export default function TaskDetail({
  selectedTask,
  setCreateAgentTaskOpen,
  setCreateTaskDefaults,
  user
}: {
  selectedTask?: Task;
  setCreateAgentTaskOpen: (boolean) => void;
  setCreateTaskDefaults: (any) => void;
  user: TUser;
}) {
  if (!selectedTask) {
    return null;
  }

  const { status, type } = selectedTask;
  const title = TASK_TITLES[type]?.[status];

  if (!title) {
    const Placeholder = PLACEHOLDER_IMAGES[type];

    return (
      <div className="task-detail--placeholder">
        <Placeholder />
      </div>
    );
  }

  const getTaskContent = () => {
    const taskContentType = getTaskContentType({ selectedTask });

    if (taskContentType === TASK_CONTENT_TYPES.AFTER_CONTACT_WORK) {
      return (
        <AfterContactWork
          {...selectedTask}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setCreateTaskDefaults={setCreateTaskDefaults}
          user={user}
        />
      );
    }

    if (taskContentType === TASK_CONTENT_TYPES.AGENT_TASK) {
      return <AgentTask {...selectedTask} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.CHAT_TASK) {
      return <ChatTask {...(selectedTask as TChatTask)} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.NEGLECTED_TASK) {
      return <NeglectedTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.TIMED_TASK) {
      return <TimedTask {...selectedTask} user={user} />;
    }
  };

  return (
    <div className="task-detail">
      {(type !== CONTACT_TYPES.TASK || status !== CONTACT_STATES.CONNECTED) && (
        <TaskDetailHeader>{title}</TaskDetailHeader>
      )}
      {getTaskContent()}
    </div>
  );
}
