import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import sizeMe from 'react-sizeme';
import { faCircleInfo, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import getQueryParam from 'lib/common/utils/getQueryParam';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import WORKSPACE_LAYOUTS from 'lib/common/constants/workspaceLayouts';
import TUser from 'lib/common/types/User';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import getContactAttributes from 'lib/common/utils/getContactAttributes';
import { Profile } from 'lib/common/components';
import { FusionData } from 'lib/common/components/atoms';
import NoTaskView from '../TaskDetail/components/NoTaskView';
import Column from '../Column/Column';
import TaskList from '../TaskList';
import ResizableGroup from '../ResizableGroup/ResizableGroup';
import CustomerProfile from '../CustomerProfile';
import TaskDetail from '../TaskDetail';
import { ReactComponent as ProfileUnsupported } from './assets/profile-unsupported.svg';
import TASK_FILTER_OPTIONS from './constants/taskFilterOptions';
import NO_TASK_PLACEHOLDERS from './constants/noTaskPlaceholders';
import COLUMN_IDS from './constants/columnIds';
import get2ColLayoutId from './utils/get2ColLayoutId';
import LAYOUTS from './constants/layouts';
import TasksAndControls from './components/TasksAndControls';
import ChooseLayoutModal from './components/ChooseLayoutModal/ChooseLayoutModal';
import CreateAgentTaskModal from './components/CreateAgentTaskModal';

import './styles/agent-workspace.scss';

type TAgentWorkspace = {
  user: TUser;
  size: { height?: number; width?: number };
};

const MINIMUM_TASK_LENGTH = 1;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const WORKSPACE_SMALL_BREAKPOINT = 1000;

function AgentWorkspace({ user, size: { width } }: TAgentWorkspace) {
  const history = useHistory();

  const urlTaskId = getQueryParam({ param: 'taskId' });

  const {
    actions: { setSelectedTaskId },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  const {
    state: { defaultWorkspaceLayout }
  } = usePreferencesContext();

  const tasksWithoutIncoming = tasks.filter(
    (task) =>
      task.status !== CONTACT_STATES.CONNECTING ||
      (task.status === CONTACT_STATES.CONNECTING && task.type === CONTACT_TYPES.CONFERENCE_CALL)
  );
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const [filter, setFilter] = useState<{ value: string | null; label: string }>(TASK_FILTER_OPTIONS[0]);
  const [filteredTasks, setFilteredTasks] = useState(tasksWithoutIncoming);
  const [createTaskModalOpen, setCreateAgentTaskOpen] = useState(false);
  const [createTaskDefaults, setCreateTaskDefaults] = useState({});

  const isTwoColumnLayout =
    (width && width <= WORKSPACE_SMALL_BREAKPOINT) || defaultWorkspaceLayout === WORKSPACE_LAYOUTS.TWO_COLUMNS;

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.ADD_TASK, () => setCreateAgentTaskOpen(true), [setCreateAgentTaskOpen]);

  const onFilterTasks = ({ value, label }) => {
    setFilteredTasks(
      value
        ? tasksWithoutIncoming.filter(({ status, taskId }) => status === value || taskId === selectedTaskId)
        : tasksWithoutIncoming
    );

    setFilter({ value, label });
  };

  useEffect(() => {
    if (createTaskModalOpen) {
      return;
    }

    setCreateTaskDefaults({});
  }, [createTaskModalOpen]);

  useEffect(() => {
    if (!urlTaskId) {
      return;
    }

    if (tasks.find((task) => task.taskId === urlTaskId)) {
      setSelectedTaskId(urlTaskId);
    }

    history.replace(PAGE_ROUTES.WORKSPACE);
  }, [urlTaskId]);

  useEffect(() => {
    onFilterTasks(filter);

    if (selectedTaskId && tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  useEffect(() => {
    onFilterTasks(filter);
  }, [selectedTaskId]);

  if (
    !tasks.length ||
    (tasks.length === MINIMUM_TASK_LENGTH &&
      tasks[0].status === CONTACT_STATES.CONNECTING &&
      tasks[0].type !== CONTACT_TYPES.CONFERENCE_CALL)
  ) {
    return (
      <div className="agent-workspace">
        <ChooseLayoutModal />
        <CreateAgentTaskModal
          {...createTaskDefaults}
          open={createTaskModalOpen}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        />
        <NoTaskView user={user} setCreateAgentTaskOpen={setCreateAgentTaskOpen} width={width} />
      </div>
    );
  }

  return (
    <div className="agent-workspace">
      <ChooseLayoutModal />
      <CreateAgentTaskModal
        {...createTaskDefaults}
        setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        open={createTaskModalOpen}
      />
      <ResizableGroup
        containerClassName="agent-workspace"
        layoutOptions={isTwoColumnLayout ? LAYOUTS.TWO_COLUMNS : LAYOUTS.THREE_COLUMNS}
      >
        {isTwoColumnLayout && (
          <TasksAndControls
            tasks={filteredTasks}
            onFilterTasks={onFilterTasks}
            setCreateAgentTaskOpen={setCreateAgentTaskOpen}
            setCreateTaskDefaults={setCreateTaskDefaults}
            user={user}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            filter={filter}
            selectedTask={selectedTask}
          />
        )}
        {!isTwoColumnLayout && (
          <Column minWidth={250} key={COLUMN_IDS.TASKS}>
            <TaskList
              tasks={filteredTasks}
              selectedFilter={filter}
              selectedTaskId={selectedTaskId}
              setSelectedTaskId={setSelectedTaskId}
              onFilter={onFilterTasks}
              setCreateAgentTaskOpen={setCreateAgentTaskOpen}
              filterOptions={TASK_FILTER_OPTIONS}
              noTasksPlaceholder={NO_TASK_PLACEHOLDERS[filter.value || 'default']}
            />
          </Column>
        )}
        {!isTwoColumnLayout && (
          <Column key={COLUMN_IDS.CONTROLS} minimisedIcon={faCircleInfo} minWidth={150} isResizable>
            <TaskDetail
              selectedTask={selectedTask}
              setCreateAgentTaskOpen={setCreateAgentTaskOpen}
              setCreateTaskDefaults={setCreateTaskDefaults}
              user={user}
            />
          </Column>
        )}
        <Column
          minimisedIcon={faUserCircle}
          minWidth={250}
          isResizable
          key={isTwoColumnLayout ? get2ColLayoutId(COLUMN_IDS.PROFILE) : COLUMN_IDS.PROFILE}
        >
          {selectedTask?.type === CONTACT_TYPES.TASK && !Boolean(getContactAttributes(selectedTask?.contact)?.length) && (
            <div className="agent-workspace__profile-unsupported">
              <ProfileUnsupported />
            </div>
          )}

          {selectedTask?.type === CONTACT_TYPES.TASK && Boolean(getContactAttributes(selectedTask?.contact)?.length) && (
            <Profile.ContentPanel className="agent-workspace__attached-data" title="Attached Data">
              <FusionData task={selectedTask} />
            </Profile.ContentPanel>
          )}

          {selectedTask?.type !== CONTACT_TYPES.TASK && (
            <CustomerProfile profileId={selectedTask?.profile?.profileId} task={selectedTask} />
          )}
        </Column>
      </ResizableGroup>
    </div>
  );
}

export default sizeMeHOC(AgentWorkspace);
