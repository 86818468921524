import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONNECTION_TYPE_MAP from 'lib/common/constants/connectionType';
import connectGetter from 'lib/common/utils/connectGetter';
import CONNECT_REJECTED_STATUS from 'lib/common/constants/connectRejectedState';
import CONNECT_TYPE_MAP from '../constants/connectTypeMap';
import getContactConnection from '../../../utils/getContactConnection';

const CONNECT_CONTACT_STATE_MAP = {
  [connect.ContactStateType.INIT]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.INCOMING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.PENDING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.CONNECTING]: CONTACT_STATES.CONNECTING,
  [connect.ContactStateType.CONNECTED]: CONTACT_STATES.CONNECTED,
  [connect.ContactStateType.MISSED]: CONTACT_STATES.MISSED,
  [connect.ContactStateType.ERROR]: CONTACT_STATES.MISSED,
  [connect.ContactStateType.ENDED]: CONTACT_STATES.ACW,
  [CONNECT_REJECTED_STATUS]: CONTACT_STATES.REJECTED
};

const CONNECT_CONNECTION_STATE_MAP = {
  [connect.ConnectionStateType.HOLD]: CONNECTION_STATES.HOLD
};

const CUSTOMER_CONNECTION_VALUE = 'Customer';

export default function getTaskFromContact(contact) {
  const connection = getContactConnection(contact);
  const connectionType = connectGetter('isInbound', contact)
    ? CONNECTION_TYPE_MAP.INBOUND
    : CONNECTION_TYPE_MAP.OUTBOUND;
  const status = CONNECT_CONTACT_STATE_MAP[connectGetter('getState', contact)?.type];
  const queueName = connectGetter('getQueue', contact)?.name;

  return {
    queueName,
    contact,
    status,
    type: CONNECT_TYPE_MAP[connectGetter('getType', contact)],
    connectionValue:
      connectGetter('getEndpoint', connection)?.phoneNumber ||
      connectGetter('getName', contact) ||
      CUSTOMER_CONNECTION_VALUE,
    connectionState:
      (connection && CONNECT_CONNECTION_STATE_MAP[connectGetter('getState', connection)?.type]) ||
      CONNECTION_STATES.LIVE,
    connectionType,
    taskId: contact.contactId,
    time:
      (connection && connectGetter('getState', connection)?.timestamp) || connectGetter('getState', contact)?.timestamp,
    description: connectGetter('getDescription', contact),
    connectedAtTime:
      status === CONTACT_STATES.CONNECTED ? connectGetter('getState', connection)?.timestamp ?? new Date() : new Date()
  };
}
