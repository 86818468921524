import { useVisibilityState } from 'webrix/hooks';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import Collapse from '@kunukn/react-collapse';
import { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';
import { Icon } from 'lib/common/components';
import './accordion.scss';

export interface AccordionProps {
  title: string | ReactNode;
  children: ReactNode;
  className?: string;
  childClassName?: string;
  small?: boolean;
  icon?: string | IconProp;
  inverse?: boolean;
  defaultOpen?: boolean;
  forceOpen?: boolean;
  onToggle?: () => void;
}

export default function Accordion({
  title,
  children,
  className,
  childClassName,
  small,
  icon,
  inverse = false,
  defaultOpen = false,
  forceOpen = undefined,
  onToggle
}: AccordionProps) {
  const { visible: open, toggle } = useVisibilityState(defaultOpen);
  const TitleEl = !small ? 'h3' : 'p';

  const isAccordingOpen = forceOpen === undefined ? open : forceOpen;

  return (
    <div className={cx('accordion', { 'accordion--inverse': inverse, 'accordion--open': isAccordingOpen }, className)}>
      <button
        className="accordion__header"
        onClick={() => {
          toggle();
          onToggle?.();
        }}
        data-testid="accordion"
      >
        <div className="accordion__title next-to">
          {icon && <Icon size={small ? 20 : void 0} icon={icon} className="mr-10" />}
          <TitleEl>{title}</TitleEl>
        </div>

        <div className={cx('accordion__header__icon', { 'accordion__header__icon--open': isAccordingOpen })}>
          <Icon size={17} icon={isAccordingOpen ? faChevronUp : faChevronDown} color="primary" />
        </div>
      </button>
      <Collapse transition={`height 300ms cubic-bezier(.4, 0, .2, 1)`} isOpen={isAccordingOpen}>
        <div className={cx('accordion__child', childClassName)}>{children}</div>
      </Collapse>
    </div>
  );
}
