import React from 'react';
import cx from 'classnames';
import './Badge.scss';

export interface BadgeProps {
  label: string;
  type?: 'NEUTRAL' | 'SUCCESS' | 'ERROR';
  className?: string;
  invert?: boolean; // This can be a color invert for any badge types
}

export default function Badge({ label, type = 'SUCCESS', className, invert = false }: BadgeProps) {
  return (
    <div
      className={cx('badge', `badge__${type.toLowerCase()}`, className, {
        [`badge__${type.toLowerCase()}--invert`]: invert
      })}
    >
      {label}
    </div>
  );
}
