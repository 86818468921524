import { roundToNearestMinutes, startOfToday } from 'date-fns';

import getUTCDate from 'lib/common/utils/getUTCDate';
import buildStats from '../utils/buildStats';

export default async function getStats({ queueSelected, serviceLevel, queueData, fetch, config }) {
  const startTime = getUTCDate(startOfToday()).toISOString();
  const nowInUTC = getUTCDate();
  const endTime = roundToNearestMinutes(nowInUTC, { nearestTo: 5 }).toISOString();
  const isSingleQueue = queueSelected.id !== 'all';

  const metricsRes = await fetch(`${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/metrics`, {
    method: 'POST',
    body: JSON.stringify({
      StartTime: startTime,
      EndTime: endTime,
      Queues: !isSingleQueue ? queueData.map(({ Id }) => Id) : [queueSelected.id],
      ServiceLevel: serviceLevel[0]
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const { status: metrics } = await metricsRes.json();

  return buildStats(metrics);
}
