import { useContext, useState } from 'react';
import { useBooleanState } from 'webrix/hooks';
import { useHistory } from 'react-router-dom';
import searchCustomerProfiles from 'lib/common/api/customerProfiles/searchCustomerProfiles';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Button, Loader, RequiresPermission } from 'lib/common/components';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import TProfile from 'lib/common/types/CustomerProfile';
import ProfilesTable from './ProfilesTable';
import Placeholder from './Placeholder';
import Search from './Search';

import '../styles/customer-profiles.scss';

const MAX_FETCHED_PROFILES = 30;

export default function CustomerProfiles() {
  const { value: searching, setTrue: setSearching, setFalse: setNotSearching } = useBooleanState();
  const { fetch_, config } = useContext(AuthContext);

  const [profiles, setProfiles] = useState<null | TProfile[]>(null);

  const history = useHistory();

  const onSearch = async (value: string, type: string) => {
    if (!value) {
      return void setProfiles(null);
    }

    setSearching();

    setProfiles(
      await searchCustomerProfiles({
        config,
        fetch_,
        type,
        value,
        maxResults: MAX_FETCHED_PROFILES
      })
    );

    setNotSearching();
  };

  const onClickCreateProfile = () => {
    history.push('/profiles/new');
  };

  return (
    <>
      <div className="flex mb-40">
        <Search onSearch={onSearch} searching={searching} />
        <RequiresPermission permission={PERMISSIONS.CUSTOMER_PROFILES_WRITE}>
          <Button className="ml-40" icon="faPlus" onClick={onClickCreateProfile} />
        </RequiresPermission>
      </div>
      <div className="panel customer-profiles__results">
        {!profiles && !searching && <Placeholder />}
        {profiles && !searching && <ProfilesTable profiles={profiles} />}
        {searching && <Loader relative />}
      </div>
    </>
  );
}
