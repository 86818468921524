import { useState, cloneElement, ReactElement, ReactNode } from 'react';
import { Popover as MuiPopover } from '@material-ui/core';

import styles from './popover.module.scss';

interface IPopoverProps {
  children: ReactNode;
  anchor: ReactNode;
  shiftBottom?: number;
  shiftLeft?: number;
}

const Popover = ({ children, anchor, shiftBottom = 10, shiftLeft = 0 }: IPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {anchor && cloneElement(anchor as ReactElement, { onClick: handleAnchorClick })}
      <MuiPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            marginTop: shiftBottom,
            marginLeft: shiftLeft
          }
        }}
      >
        <div className={styles['popover']}>{children}</div>
      </MuiPopover>
    </>
  );
};

export default Popover;
