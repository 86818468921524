import ReactSelect from 'lib/common/components/ReactSelect';
import SegmentedControls from 'lib/common/components/SegmentedControls';
import { Tab } from '../useCreateAgentTask';

import '../styles/create-agent-task.scss';

export default function TaskLocation({
  setSelectedTab,
  loading,
  selectedEntity,
  selectedTab,
  optionsList,
  setSelectedEntity
}) {
  return (
    <>
      <div className="create-agent-task__tabs">
        <SegmentedControls
          options={Object.values(Tab).map((tab) => ({
            label: tab,
            value: tab
          }))}
          onSelect={setSelectedTab}
        />
      </div>
      <ReactSelect
        id="create-task-assign-select"
        classNamePrefix="select"
        isLoading={loading}
        value={selectedEntity || ''}
        placeholder={`Select a${selectedTab === Tab.QUEUE ? ' queue' : 'n agent'}...`}
        options={optionsList}
        onChange={(newValue) => setSelectedEntity(newValue)}
      />
    </>
  );
}
