import { Icon } from 'lib/common/components/index';
import { faHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import styles from './agent-icon.module.scss';

export default function AgentIcon({ small }: { small?: boolean }) {
  return (
    <div className={styles['agent-icon']}>
      <Icon
        className={styles['agent-icon__icon']}
        icon={faHeadSideHeadphones}
        size={small ? 15 : 20}
        tooltip="Agents available / agents online"
      />
      <span
        className={cx(styles['agent-icon__dot'], {
          [styles['agent-icon__dot--small']]: small
        })}
      ></span>
    </div>
  );
}
