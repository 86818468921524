import { default as ConnectedCall } from './components/ConnectedCall';
import { default as ClearTask } from './components/ClearTask';
import { default as ACW } from './components/ACW';
import { default as Chat } from './components/Chat';
import { default as AgentTask } from './components/AgentTask';

export default {
  ConnectedCall,
  ClearTask,
  ACW,
  Chat,
  AgentTask
};
