import { format } from 'date-fns';
import cx from 'classnames';
import { faComment, faPhoneArrowDown, faPhoneArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import useInteractionHistory from 'lib/common/api/hooks/useInteractionHistory';
import usePagination from 'lib/common/hooks/usePagination';
import { Text, EmptyPlaceholder, Loader, Icon, ClickToCall, TourControl } from 'lib/common/components';
import useSort from 'lib/common/hooks/useSort';
import Header from './components/Header';

import styles from './interaction-history.module.scss';

const CALL_ICONS = {
  inbound: faPhoneArrowDown,
  outbound: faPhoneArrowUpRight
};

const UNKNOWN_NAME = 'Not Known';

function getName(historyItem) {
  const unknownName = historyItem.firstName === UNKNOWN_NAME && historyItem.lastName === UNKNOWN_NAME;

  if (unknownName) {
    return 'Customer';
  }

  const firstName = historyItem.firstName !== UNKNOWN_NAME ? historyItem.firstName : '';
  const lastName = historyItem.lastName !== UNKNOWN_NAME ? historyItem.lastName : '';

  return `${firstName}${firstName && lastName && ' '}${lastName}`;
}

export default function InteractionHistory() {
  const { onDateChange, loaded, error, historyData, startDate, endDate } = useInteractionHistory();
  const {
    data: sortedHistory,
    setSort,
    setSortDirection
  } = useSort({ data: historyData, defaultSortKey: 'timestamp' });
  const { onSelectPage, data: history, currentPage, pages } = usePagination({ data: sortedHistory });

  return (
    <>
      <TourControl isSoftphone />
      <div className={styles['softphone-interaction-history']} data-tour="page-interaction">
        <Header
          onDateChange={onDateChange}
          onSelectPage={onSelectPage}
          currentPage={currentPage}
          pages={pages}
          setSort={setSort}
          setSortDirection={setSortDirection}
          startDate={startDate}
          endDate={endDate}
        />
        {!loaded && <Loader className={cx(styles['softphone-interaction-history__loader'], 'relative small')} />}
        {(!history || !history.length) && loaded && (
          <EmptyPlaceholder subText="You'll see interactions in here when you accept some contacts." error={error} />
        )}
        {loaded &&
          history.map((historyItem: any) => (
            <div key={historyItem.contactId} className={styles['softphone-interaction-history__item']}>
              <div className={styles['softphone-interaction-history__item__title']}>
                <Text className="mr-auto" bold ellipsis>
                  {getName(historyItem)}
                </Text>
                <Text className={styles['softphone-interaction-history__item__title__date']} type="label">
                  {format(new Date(historyItem.timestamp), 'dd/MM/yy - HH:mm')}
                </Text>
              </div>
              <div className={styles['softphone-interaction-history__item__entry']}>
                <Icon
                  className="mr-10"
                  size={15}
                  icon={historyItem.phoneNumber ? CALL_ICONS[historyItem.type] : faComment}
                />
                <ClickToCall>{historyItem.phoneNumber || 'Chat'}</ClickToCall>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
