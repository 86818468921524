import { Grid } from '@material-ui/core';
import { ChartComponentProps, Doughnut } from 'react-chartjs-2';
import _capitalise from 'lodash.capitalize';
import cx from 'classnames';
import { SizeMeProps } from 'react-sizeme';
import { ReactElement } from 'react';

interface IWallboardItemProps {
  containerSize: SizeMeProps['size'];
  title: string;
  stat?: string;
  stats?: { [k: string]: string };
  statKey?: string;
  doughnut?: boolean;
  doughnutProps?: ChartComponentProps;
  titleSuffix?: ReactElement;
}

export default function WallboardItem({
  containerSize,
  title,
  stat,
  stats,
  statKey,
  doughnut,
  doughnutProps,
  titleSuffix
}: IWallboardItemProps) {
  return (
    <Grid item style={{ minWidth: (containerSize?.width ?? 0) > 350 ? 350 : '100%' }}>
      <div
        className={cx('panel', 'wallboard__stat-item', {
          'wallboard__stat-item--doughnut': doughnut
        })}
      >
        <p>
          {_capitalise(title)} {titleSuffix}
        </p>
        {doughnut && doughnutProps ? <Doughnut {...doughnutProps} /> : null}
        <h1>{stat || (stats && statKey && stats[statKey])}</h1>
      </div>
    </Grid>
  );
}
