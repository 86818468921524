import {
  faChartLine,
  faClockRotateLeft,
  faGauge,
  faGrid2,
  faNote,
  faObjectsColumn,
  faUserGroup
} from '@fortawesome/pro-regular-svg-icons';

import { SoftphoneNavOption } from '../types/SoftphoneNavOption';
import { SOFTPHONE_PAGE_ROUTES } from './softphonePageConfigurations';
import { PERMISSIONS } from './permissions';
import { PAGE_ROUTES } from './pageConfigurations';

export const SOFTPHONE_DEFAULT_NAV_OPTIONS: SoftphoneNavOption[] = [
  {
    link: SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY,
    icon: faClockRotateLeft,
    name: 'Interaction History',
    permission: PERMISSIONS.INTERACTION_HISTORY
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.AGENT_STATS,
    icon: faChartLine,
    name: 'Your Queues'
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.DIRECTORY,
    icon: faUserGroup,
    name: 'Directory'
  },
  {
    icon: faGrid2,
    name: 'NEON Desktop Shortcuts',
    childOptions: [
      {
        link: PAGE_ROUTES.DASHBOARDS,
        isolated: true,
        icon: faGauge,
        name: 'Dashboards'
      },
      {
        link: PAGE_ROUTES.WALLBOARD,
        isolated: true,
        icon: faObjectsColumn,
        name: 'Wallboard'
      }
    ]
  },
  {
    link: SOFTPHONE_PAGE_ROUTES.WIDGETS,
    icon: faNote,
    name: 'Widgets'
  }
];
