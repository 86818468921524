import { StepType } from '@reactour/tour';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import getStep from '../utils/getStep';

const stepsForLanding: Array<StepType> = [
  getStep({
    selector: '.softphone-add-agent-task-button',
    title: 'Creating Agent Tasks',
    text: `Use this button to create new agent tasks. These can be used for reminders, to pass information to other agents or to schedule callbacks.`,
    titleTag: 'h2',
    position: 'right'
  }),
  getStep({
    selector: '[data-tour="softphone-landing"]',
    title: 'Tasks',
    text: `Your contact tasks will appear in the sidebar. If we can’t fit all your tasks in the space available, we’ll move the extra ones to a menu.`,
    position: 'right',
    titleTag: 'h2',
    isPage: true
  }),
  getStep({
    selector: '.softphone-sidebar__nav-menu',
    title: 'NEON Pages',
    text: `Use these icons to access your interaction history, real time stats and the directory. The directory will contain your contacts and the dialpad.`,
    position: 'right',
    titleTag: 'h2'
  }),
  getStep({
    selector: '.user-profile__wrapper',
    title: 'User Profile',
    text: `Clicking the user profile will open a menu, allowing you to update your preferences (such as audio devices & ringtone), sign out, access help and more.`,
    position: 'right',
    titleTag: 'h2'
  })
];

const stepsForInteractionHistory: Array<StepType> = [
  getStep({
    selector: '[data-tour="page-interaction"]',
    title: 'History',
    text: `You'll see interaction history items appear here after you accept some calls. You'll be able to call the contact directly from the record.`,
    position: 'bottom',
    titleTag: 'h2',
    isPage: true
  }),
  getStep({
    selector: '.fa-filter-list',
    title: 'Filtering',
    text: 'Use the date filters to search through your interaction history.',
    titleTag: 'h2',
    position: 'bottom'
  })
];

const stepsForDirectory: Array<StepType> = [
  getStep({
    selector: '.directory--fill-container > .tabs',
    title: 'Directory',
    text: `Use these tabs to switch between directory pages.`,
    position: 'bottom',
    titleTag: 'h2'
  }),
  getStep({
    selector: '[data-tour="Personal"]',
    title: 'Personal Contacts',
    text: `Personal contacts are specific to you and no one else is able to see them. You can call them directly using the phone icon.`,
    position: 'bottom',
    titleTag: 'h2'
  }),
  getStep({
    selector: '[data-tour="Company"]',
    title: 'Company Contacts',
    text: `These are contacts that your administrator has added and you won’t be able to change them. Other agents will automatically appear there. You can see agents’ availability by looking at the dots next to their avatars.`,
    additionalText: [
      `If you just want to see agents in the list, you can search for “agent” using the search when in the Company tab.`
    ],
    position: 'bottom',
    titleTag: 'h2'
  }),
  getStep({
    selector: '[data-tour="Dialpad"]',
    title: 'Dialpad',
    text: `You can make outbound calls to phone numbers using this dialpad tab. If you need to press dialpad keys while you’re in a call, use the dialpad button in the call actions.`,
    position: 'bottom',
    titleTag: 'h2'
  })
];

export const softPhoneSteps = {
  [SOFTPHONE_PAGE_ROUTES.LANDING]: stepsForLanding,
  [SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY]: stepsForInteractionHistory,
  [SOFTPHONE_PAGE_ROUTES.DIRECTORY]: stepsForDirectory
};
