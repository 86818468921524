import CONTACT_STATES from 'lib/common/constants/contactStates';
import Task from 'lib/common/types/Task';
import { TaskReferences } from 'lib/common/components/atoms';
import { TaskFooter } from 'lib/common/components/molecules';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';
import TUser from 'lib/common/types/User';
import TaskDetailHeader from './TaskDetailHeader';

import '../styles/agent-task.scss';

interface IAgentTaskProps extends Task {
  user: TUser;
}

export default function AgentTask({
  status,
  description,
  taskId,
  connectionValue,
  contact,
  time,
  queueName,
  user
}: IAgentTaskProps) {
  if (status !== CONTACT_STATES.CONNECTED) {
    return null;
  }

  return (
    <>
      <div className="agent-task">
        <TaskDetailHeader>{connectionValue}</TaskDetailHeader>
        <QueueNameAndTimer queueName={queueName} time={time} />
        <p>{description || "This task doesn't have a description."}</p>
        <TaskReferences contact={contact} />
        <TaskFooter.AgentTask taskId={taskId} connectionValue={connectionValue} user={user} />
      </div>
    </>
  );
}
