import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Modal } from 'lib/common/components';

export default function PollingTimer(props: any) {
  const { closeFunc } = props;

  const valueChange = (event) => {
    setTimerVal(event.target.value);
  };

  const [timerVal, setTimerVal] = useState();

  return (
    <Modal
      open
      contentTopPadded
      title="Change the Refresh Rate"
      onSave={() => props.editTimer(timerVal)}
      onClose={closeFunc}
    >
      <div className="add-db-item">
        <TextField
          variant="outlined"
          id="name"
          label="Refresh interval (s)"
          onChange={valueChange}
          placeholder="Enter value in seconds"
          defaultValue={props.currTimer}
          type="number"
        />
      </div>
    </Modal>
  );
}
