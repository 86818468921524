import { useHistory } from 'react-router-dom';
import { Button } from 'lib/common/components';
import { ReactComponent as NotFoundImage } from 'assets/vectors/not-found.svg';
import '../styles/not-found.scss';

export default function NotFound({ onReload }: { onReload: () => Promise<void> }) {
  const history = useHistory();

  const onCreate = () => {
    history.push('/profiles/new');
  };

  return (
    <div className="contact-not-found">
      <NotFoundImage />
      <div className="contact-not-found__info">
        <h2>Hmm. We’ve gone searching and it seems this profile doesn't exist yet.</h2>
        <p>
          If you just created this profile, it might take a few seconds to show. You can try reloading the profile
          below.
        </p>
        <div className="contact-not-found__info__actions">
          <Button onClick={onCreate}>Create A New Profile</Button>
          <Button styleType="SECONDARY" onClick={onReload}>
            Reload
          </Button>
        </div>
      </div>
    </div>
  );
}
