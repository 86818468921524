import { faUsers, faClock } from '@fortawesome/pro-regular-svg-icons';
import _orderBy from 'lodash.orderby';

import Icon from 'lib/common/components/Icon';

import Queue from './components/Queue';
import SoftphoneQueue from './components/SoftphoneQueue';
import AgentIcon from './components/AgentIcon';

import styles from './queues.module.scss';

const Queues = ({ queues, isSoftphone }) => {
  const QueueComponent = isSoftphone ? SoftphoneQueue : Queue;

  return (
    <div className={styles['queues']}>
      {!isSoftphone && (
        <div className={styles['queues__header']}>
          <h3>Queue</h3>
          <div className={styles['queues__header__icons']}>
            <Icon className={styles['queues__header__icons__icon']} icon={faUsers} tooltip="Contacts in queue" />
            <Icon className={styles['queues__header__icons__icon']} icon={faClock} tooltip="Oldest contact in queue" />
            <AgentIcon />
          </div>
        </div>
      )}
      {_orderBy(queues, 'name').map((queue) => (
        <QueueComponent key={queue.Id} {...queue} />
      ))}
    </div>
  );
};

export default Queues;
