import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from 'lib/core/context/AuthProvider';
import queueEndpointTypes from 'lib/common/constants/queueEndpointTypes';
import getInstanceQueues from 'lib/common/api/getInstanceQueues';
import getInstanceQuickConnects from 'lib/common/api/getInstanceQuickConnects';
import IQuickConnect from 'lib/common/types/InstanceQuickConnect';
import IQueue from 'lib/common/types/InstanceQueue';
import connectGetter from 'lib/common/utils/connectGetter';
import filterDisabledQueues from 'lib/common/utils/filterDisabledQueues';
import getMaxContactsForQueues from '../../api/getMaxContactsForQueues';
import { useAgentContext } from '../AgentContext';

// TODO
type InstanceContextType = {
  instanceQueues: IQueue[];
  instanceQuickConnects: IQuickConnect[];
  instanceMaxContacts: Record<string, number>;
};

const Context = createContext<InstanceContextType>({
  instanceQueues: [],
  instanceQuickConnects: [],
  instanceMaxContacts: {}
});

const getQueueIdFromArn = (queue) => queue.queueId.split('/').pop();

export default function InstanceContext({ children }) {
  const { config, fetch_ } = useContext(AuthContext);
  const { agent } = useAgentContext();

  const [instanceQueues, setInstanceQueues] = useState([]);
  const [instanceQuickConnects, setInstanceQuickConnects] = useState([]);
  const [instanceMaxContacts, setInstanceMaxContacts] = useState({});

  const setMaxContacts = async (fetchedQueues) => {
    if (!Array.isArray(fetchedQueues)) {
      return;
    }

    const routingProfileQueues = connectGetter('getRoutingProfile', agent)?.queues || [];
    const defaultOutboundQueue = connectGetter('getRoutingProfile', agent)?.defaultOutboundQueue;
    if (defaultOutboundQueue && !routingProfileQueues.some((queue) => queue.queueId === defaultOutboundQueue.queueId)) {
      routingProfileQueues.push(defaultOutboundQueue);
    }
    const queueIds = routingProfileQueues?.map(getQueueIdFromArn);

    if (!queueIds) {
      return;
    }

    const filteredQueueIds = fetchedQueues
      .filter(({ Id, QueueType }) => queueIds.includes(Id) && QueueType === queueEndpointTypes.QUEUE)
      .map(({ Id }) => Id);

    const response = await getMaxContactsForQueues({ fetch_, config, queueIds: filteredQueueIds });

    setInstanceMaxContacts(response);
  };

  const setQueueInformation = async () => {
    const response = await getInstanceQueues({ fetch_, config });
    const filteredQueues = filterDisabledQueues({
      allQueues: response,
      disabledQueuePrefix: config.DISABLED_QUEUE_PREFIX
    });

    setInstanceQueues(filteredQueues);
    setMaxContacts(filteredQueues);
  };

  const setQuickConnects = async () => {
    const response = await getInstanceQuickConnects({ fetch_, config });
    setInstanceQuickConnects(response);
  };

  useEffect(() => {
    setQueueInformation();
    setQuickConnects();
  }, []);

  return (
    <Context.Provider
      value={{
        instanceQueues,
        instanceQuickConnects,
        instanceMaxContacts
      }}
    >
      {children}
    </Context.Provider>
  );
}

// Export the context as a HOC
export const { Consumer: InstanceConsumer, Provider: InstanceProvider } = Context;

// export the context hook
export const useInstanceContext = () => useContext(Context);
