import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClock, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';

import { Icon, Text } from 'lib/common/components/index';
import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';

import AgentIcon from '../AgentIcon';

import styles from './softphone-queue.module.scss';

function TextIcon({
  icon,
  text,
  customIcon,
  tooltip
}: {
  icon?: IconProp;
  text: string;
  customIcon?: ReactNode;
  tooltip?: string;
}) {
  return (
    <div className={styles['softphone-queue__container__details__detail']}>
      {customIcon || (icon ? <Icon size={14} icon={icon} tooltip={tooltip} /> : null)}
      <Text bold>{text}</Text>
    </div>
  );
}
export default function SoftphoneQueue({
  id,
  name,
  contactsInQueue,
  oldestContactInQueue,
  agentsAvailable,
  agentsOnline
}) {
  return (
    <div className={styles['softphone-queue']} key={id}>
      <div className={styles['softphone-queue__container']} key={id}>
        <Text ellipsis bold className={styles['softphone-queue__container__queue-name']}>
          {name}
        </Text>
        <div className={styles['softphone-queue__container__details']}>
          <TextIcon text={contactsInQueue} icon={faUsers} tooltip="Contacts in queue" />
          <TextIcon
            text={oldestContactInQueue ? convertMSToReadableTime(oldestContactInQueue * 1000) : '00:00'}
            icon={faClock}
            tooltip="Oldest contact in queue"
          />
          <TextIcon text={`${agentsAvailable}/${agentsOnline}`} customIcon={<AgentIcon small />} />
        </div>
      </div>
    </div>
  );
}
