import { useEffect } from 'react';
import connectGetter from 'lib/common/utils/connectGetter';
import TTask from 'lib/common/types/Task';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECT_REJECTED_STATUS from 'lib/common/constants/connectRejectedState';
import handleACWContact from './handleACWContact';
import handleConnectedContact from './handleConnectedContact';
import handleMissedContact from './handleMissedContact';

type TArgs = {
  tasks: TTask[];
  hasACWPermission: boolean;
  handleContactChange: (_: any) => void;
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
  config: any;
  tokens?: any;
  hasMissedCallPermission: boolean;
};

export default function useTaskRefresh({
  tasks,
  hasACWPermission,
  handleContactChange,
  fetch_,
  config,
  tokens,
  hasMissedCallPermission
}: TArgs) {
  const initAgentEvents = (agent: connect.Agent) => {
    agent.onRefresh(() => {
      const existingContacts = connectGetter('getContacts', agent) || [];

      tasks.forEach(({ contact: taskContact, taskId, status }) => {
        const existingContact = existingContacts.find((contact) => contact.contactId === taskId);

        if (!existingContact) {
          return handleContactChange({ contact: taskContact, isDestroy: true });
        }

        const existingContactState = connectGetter('getState', existingContact)?.type;

        if (status === CONTACT_STATES.CONNECTING && existingContactState === connect.ContactStateType.CONNECTED) {
          return handleConnectedContact({ contact: taskContact, handleContactChange, fetch_, config, tokens });
        }

        if (status === CONTACT_STATES.CONNECTING && existingContactState === connect.ContactStateType.REJECTED) {
          return handleContactChange({ contact: taskContact, status: CONTACT_STATES.REJECTED });
        }

        if (status === CONTACT_STATES.CONNECTING && existingContactState === connect.ContactStateType.MISSED) {
          return handleMissedContact({ contact: taskContact, handleContactChange, hasMissedCallPermission });
        }

        if (!(status === CONTACT_STATES.CONNECTED && existingContactState === connect.ContactStateType.ENDED)) {
          return;
        }

        handleACWContact({ contact: taskContact, handleContactChange, hasACWPermission });
      });
    });
  };

  useEffect(() => {
    if (!tasks?.length) {
      return;
    }

    const connect = (window as any).getConnect();
    connect.agent(initAgentEvents);
  }, [tasks]);
}
