import { FC, forwardRef } from 'react';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

export interface AppMenuItemComponentProps {
  className?: string;
  link?: string | null;
  activeClassName?: string;
  pageHasSubMenu?: boolean;
}

const NavLinkButton = (link) => (props, ref) => {
  return <NavLink exact {...props} to={link} innerRef={ref} />;
};

const SidebarMenuComponent: FC<AppMenuItemComponentProps> = (props) => {
  const { className, link, children, activeClassName } = props;

  return (
    <ListItem
      button
      className={cx(className, 'nav-item')}
      component={forwardRef(NavLinkButton(link))}
      activeClassName={activeClassName}
      isActive={(_, location) => location.pathname.indexOf(link) === 0}
    >
      {children}
    </ListItem>
  );
};

export default SidebarMenuComponent;
