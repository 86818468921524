import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import Task from 'lib/common/types/Task';
import cx from 'classnames';
import { Button, Icon } from '../';
import connectGetter from '../../utils/connectGetter';
import { useAgentContext } from '../../contexts/AgentContext';

import './task-actions.scss';

type TTaskActions = Pick<Task, 'status' | 'type' | 'taskId' | 'connectionType'> & {
  onEnd?: () => void;
  round?: boolean;
};

export default function TaskActions({ status, type, taskId, connectionType, onEnd, round }: TTaskActions) {
  const { agent } = useAgentContext();
  const {
    actions: { rejectTask, acceptTask, endTask }
  } = useContactContext();

  // When the agent is on a desk phone, they can only answer the call on it (not in NEON)
  // For queue callbacks, they need to be able to accept the task, even if they are on desk phone
  const willAcceptOnDeskPhone = !connectGetter('isSoftphoneEnabled', agent) && type === CONTACT_TYPES.CALL;

  const isOutbound = connectionType === connect.ConnectionType.OUTBOUND;

  const handleOnEnd = async () => {
    await endTask(taskId);
    onEnd?.();
  };

  const hasNoActions =
    (status === CONTACT_STATES.CONNECTED &&
      type !== CONTACT_TYPES.CALL &&
      type !== CONTACT_TYPES.CHAT &&
      type !== CONTACT_TYPES.QUEUE_CALLBACK) ||
    (status !== CONTACT_STATES.CONNECTED && status !== CONTACT_STATES.CONNECTING && status !== CONTACT_STATES.ACCEPTED);

  if (hasNoActions) {
    return null;
  }

  return (
    <div className={cx('task-actions', { 'task-actions__expanded': !round })}>
      {status === CONTACT_STATES.CONNECTING && type !== CONTACT_TYPES.CONFERENCE_CALL && (
        <>
          {!isOutbound && !willAcceptOnDeskPhone && (
            <Button
              small
              thin
              round={round}
              fullWidth
              onClick={() => {
                acceptTask(taskId);
              }}
              icon="faCheck"
              styleType="SUCCESS"
              asyncAction
              successTimeoutSeconds={0}
              preventDoubleClick
            >
              {round ? '' : 'Accept'}
            </Button>
          )}
          <Button
            small
            thin
            round={round}
            fullWidth
            onClick={() => rejectTask(taskId)}
            icon="faTimes"
            styleType="DANGER"
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          >
            {round ? '' : 'Decline'}
          </Button>
        </>
      )}
      {status === CONTACT_STATES.CONNECTED &&
        (type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.CHAT || type === CONTACT_TYPES.QUEUE_CALLBACK) && (
          <>
            <Button
              small
              round
              onClick={handleOnEnd}
              icon={type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK ? 'faPhoneHangup' : 'faTimes'}
              styleType="DANGER"
              asyncAction
              successTimeoutSeconds={0}
              preventDoubleClick
            />
          </>
        )}
      {status === CONTACT_STATES.ACCEPTED && (
        <>
          <Icon icon={faSpinnerThird} spin />
        </>
      )}
    </div>
  );
}
