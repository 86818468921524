import { OptionTypeBase, StylesConfig } from 'react-select';
import COLOURS from 'css/export-vars.module.scss';

const selectStyles: StylesConfig<OptionTypeBase, false> = {
  control: (styles, { isFocused }) => ({
    ...styles,
    ...(isFocused
      ? {
          outline: 'none',
          borderColor: ` ${COLOURS.midGrey} !important`,
          boxShadow: 'none !important'
        }
      : {}),
    height: '43px',
    border: 'none'
  }),
  menu: (styles) => ({
    ...styles,
    width: 200,
    minWidth: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%'
  }),
  valueContainer: (styles) => ({
    ...styles,
    alignItems: 'center',
    fontSize: '12px',
    justifyContent: 'center'
  }),
  singleValue: (styles) => ({
    ...styles,
    maxWidth: '100% !important',
    position: 'inherit',
    transform: 'unset !important',
    flexBasis: 0,
    flexGrow: 1
  }),
  placeholder: (styles) => ({
    ...styles,
    maxWidth: '100% !important',
    position: 'inherit',
    transform: 'unset !important',
    flexBasis: 0,
    flexGrow: 1,
    textAlign: 'center'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${COLOURS.black}`,
    padding: '0 4px'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: COLOURS.text,
    backgroundColor: COLOURS.white,
    fontSize: '12px',
    textAlign: 'start',
    fontWeight: isSelected ? 'bold' : 400,
    padding: '8px 0',
    width: '100%',

    '&:hover': {
      backgroundColor: COLOURS.lightGrey
    }
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
};

export default selectStyles;
