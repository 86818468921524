import TTask from 'lib/common/types/Task';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';

interface IGetTaskContentTypeProps {
  selectedTask: TTask;
  isSoftphone?: boolean;
}

export default function getTaskContentType({ selectedTask, isSoftphone = false }: IGetTaskContentTypeProps) {
  const { type, status } = selectedTask;

  if (
    isSoftphone &&
    selectedTask.status === CONTACT_STATES.CONNECTING &&
    selectedTask.type !== CONTACT_TYPES.CONFERENCE_CALL
  ) {
    return TASK_CONTENT_TYPES.INCOMING_TASK;
  }

  if (status === CONTACT_STATES.ACW) {
    return TASK_CONTENT_TYPES.AFTER_CONTACT_WORK;
  }

  if (status === CONTACT_STATES.CONNECTED && type === CONTACT_TYPES.TASK) {
    return TASK_CONTENT_TYPES.AGENT_TASK;
  }

  if (status === CONTACT_STATES.CONNECTED && type === CONTACT_TYPES.CHAT) {
    return TASK_CONTENT_TYPES.CHAT_TASK;
  }

  if (status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED) {
    return TASK_CONTENT_TYPES.NEGLECTED_TASK;
  }

  if (
    ((type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK) && status === CONTACT_STATES.CONNECTED) ||
    (type === CONTACT_TYPES.CONFERENCE_CALL && status === CONTACT_STATES.CONNECTED) ||
    (type === CONTACT_TYPES.CONFERENCE_CALL && status === CONTACT_STATES.CONNECTING)
  ) {
    return TASK_CONTENT_TYPES.TIMED_TASK;
  }

  return null;
}
