import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import { Error, Button } from 'lib/common/components';
import '../styles/landing.scss';

const BULLETS_SEPARATOR = ';';

function getSourceContent(source: string) {
  try {
    return JSON.parse(source);
  } catch {
    return null;
  }
}

export default function Landing(props: ConnectorModuleType) {
  const sourceContent = getSourceContent(props.source);

  if (!sourceContent) {
    return <Error />;
  }

  const { vectorUrl, bulletPoints, learnMoreLink, subtitle, launchLink, logoUrl } = sourceContent;

  return (
    <div className="panel landing">
      <div className="landing__left-column">
        <div className="landing__left-column__logos">
          <img alt="product-logo" src={logoUrl} />
        </div>
        <h1>{props.name}</h1>
        {subtitle ? <h2>{subtitle}</h2> : null}
        <div className="landing__left-column__checklist">
          {bulletPoints && bulletPoints.length
            ? bulletPoints.split(BULLETS_SEPARATOR).map((point) => (
                <div key={point} className="landing__left-column__checklist__item">
                  <FontAwesomeIcon icon={faCheckCircle} />
                  <span>{point}</span>
                </div>
              ))
            : null}
        </div>
        <div className="landing__left-column__footer">
          {learnMoreLink ? (
            <a href={learnMoreLink} target="_blank" rel="noreferrer">
              Learn More
            </a>
          ) : null}
          <Button
            icon="faExternalLinkAlt"
            onClick={() => {
              window.open(launchLink, '_blank');
            }}
          >
            Launch
          </Button>
        </div>
      </div>
      {vectorUrl ? <img alt="product-promo-image" src={vectorUrl} /> : null}
    </div>
  );
}
