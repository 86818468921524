import { faHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import taskActionWithCatch from 'lib/common/utils/taskActionWithCatch';
import { getConferenceUserName, isThirdPartyConnectionOnHold } from 'lib/common/utils/conferenceConnections';
import { ConferenceDisconnectButton } from 'lib/common/components/atoms';
import optional from 'lib/common/utils/optional';
import getConferenceActions from 'lib/common/utils/getConferenceActions';

import InfoPanel from '../../InfoPanel';

export default function ConferenceUserRow({
  thirdPartyConnectionsDisconnected,
  isConference,
  contact,
  isConferenceConnecting,
  status,
  taskId,
  allConnectionsConnected,
  canOnlyDisconnect
}) {
  const {
    actions: { holdOutboundCall, resumeOutboundCall, endThirdPartyConnection }
  } = useContactContext();

  const onDisconnectThirdParty = taskActionWithCatch(endThirdPartyConnection, taskId);

  if (!isConference || thirdPartyConnectionsDisconnected) {
    return null;
  }

  return (
    <InfoPanel.Row
      id="conference-third-party-user"
      text={getConferenceUserName(contact)}
      icon={faHeadSideHeadphones}
      badgeProps={{
        callIsOnHold: isThirdPartyConnectionOnHold(contact),
        isNeglected: false,
        isConferenceConnecting,
        status
      }}
      suffix={optional(
        !thirdPartyConnectionsDisconnected && canOnlyDisconnect,
        <ConferenceDisconnectButton onDisconnect={onDisconnectThirdParty} />
      )}
      actions={optional(
        !canOnlyDisconnect,
        getConferenceActions({
          onDisconnect: onDisconnectThirdParty,
          allConnectionsConnected,
          holdConnection: taskActionWithCatch(holdOutboundCall, taskId),
          resumeConnection: taskActionWithCatch(resumeOutboundCall, taskId)
        })
      )}
    />
  );
}
