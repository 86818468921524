import _omit from 'lodash.omit';
import _omitBy from 'lodash.omitby';
import {
  ContactAttributes,
  DYNAMIC_CW_NEON,
  DYNAMIC_CW_NEON_ACTIVE,
  DYNAMIC_CW_NEON_INCOMING
} from 'lib/common/constants/contactAttributes';
import connectGetter from './connectGetter';

export interface ContactAttributesValue {
  value: string | string[];
  isActive: boolean;
  isIncoming: boolean;
}

const valueParser = (value?: string, delimiter?: string) => {
  if (!value || !delimiter) {
    return [];
  }

  return value.split(delimiter);
};

const phoneNumberParser = (value?: string) => value;

const getIsActiveOrIsIncomingForDynamicAttributes = (attributeKey: string) => {
  if (attributeKey.startsWith(DYNAMIC_CW_NEON)) {
    return { isActive: true, isIncoming: true };
  }

  if (attributeKey.startsWith(DYNAMIC_CW_NEON_ACTIVE)) {
    return { isActive: true, isIncoming: false };
  }

  if (attributeKey.startsWith(DYNAMIC_CW_NEON_INCOMING)) {
    return { isActive: false, isIncoming: true };
  }

  return { isActive: false, isIncoming: false };
};

const CONTACT_STATIC_ATTRIBUTE_MAPPER = {
  [ContactAttributes.INTENTS]: { delimiter: '|', parser: valueParser, isActive: true, isIncoming: true },
  [ContactAttributes.TRANSCRIPT]: { delimiter: '|', parser: valueParser, isActive: true, isIncoming: true },
  [ContactAttributes.PHONE_NUMBER]: { parser: phoneNumberParser, isActive: true, isIncoming: false }
};

const normaliseAttributeNames = (attributes: Record<string, string>) =>
  Object.entries(attributes).reduce(
    (acc, [key, value]) => ({ ...acc, [key.replace(/[^a-z\d]/gi, '').toLowerCase()]: value }),
    {}
  );

export default function getMappedContactAttributes(
  contact?: connect.Contact
): Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue> | undefined {
  if (!contact) {
    return;
  }

  const contactAttributes = connectGetter('getAttributes', contact) || {};

  const staticContactAttributes = _omitBy(
    contactAttributes,
    (attribute) =>
      attribute?.name?.startsWith('CW-NEON_') ||
      attribute?.name?.startsWith('CW-NEON-ACTIVE_') ||
      attribute?.name?.startsWith('CW-NEON-INC_')
  );

  const normalisedStaticAttributes = normaliseAttributeNames(staticContactAttributes);

  const staticAttributes = Object.keys(CONTACT_STATIC_ATTRIBUTE_MAPPER).reduce((result, attribute) => {
    const { parser, delimiter, isActive, isIncoming } = CONTACT_STATIC_ATTRIBUTE_MAPPER[attribute];

    return {
      ...result,
      [attribute]: {
        value: parser(normalisedStaticAttributes[attribute]?.value, delimiter),
        isActive,
        isIncoming
      }
    };
  }, {}) as Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue>;

  const dynamicContactAttributes = _omit(contactAttributes, Object.keys(staticContactAttributes));

  const dynamicAttributes = Object.keys(dynamicContactAttributes).reduce((result, attribute) => {
    const { isActive, isIncoming } = getIsActiveOrIsIncomingForDynamicAttributes(attribute);

    if (!isActive && !isIncoming) {
      return result;
    }

    return {
      ...result,
      [attribute]: {
        value: contactAttributes[attribute]?.value,
        isActive,
        isIncoming
      }
    };
  }, {}) as Record<Extract<keyof typeof CONTACT_STATIC_ATTRIBUTE_MAPPER, string>, ContactAttributesValue>;

  return { ...staticAttributes, ...dynamicAttributes };
}
