import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SoftphoneModal from './components/SoftphoneModal';

interface ISoftphoneModalOptionsProps {
  title?: string;
  content: ReactNode;
  onSave?: () => void;
  saveDisabled?: boolean;
  primaryButtonText?: string;
  onClose?: () => void;
}

interface ISoftphoneModalContext {
  state: {
    isModalOpen: boolean;
    modalTitle: string;
  };
  actions: {
    openModal: (options?: ISoftphoneModalOptionsProps) => void;
    closeModal: () => void;
    updateModalOptions: (value) => void;
  };
}

interface ISoftphoneModalContextProps {
  children: ReactNode;
  isReversedLayout: boolean;
}

const Context = createContext<ISoftphoneModalContext>({
  state: {
    isModalOpen: false,
    modalTitle: ''
  },
  actions: {
    openModal: () => void 0,
    closeModal: () => void 0,
    updateModalOptions: () => void 0
  }
});

export default function SoftphoneModalContext({ children, isReversedLayout }: ISoftphoneModalContextProps) {
  const location = useLocation();

  const [modalOptions, setModalOptions] = useState<ISoftphoneModalOptionsProps | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (options) => {
    setIsModalOpen(true);
    setModalOptions(options);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    modalOptions?.onClose?.();
  };

  const handleClearContent = () => {
    setModalOptions(undefined);
  };

  const updateModalOptions = (options) => {
    setModalOptions((existingOptions) => ({ ...(existingOptions || {}), ...options }));
  };

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    closeModal();
  }, [location.pathname]);

  return (
    <Context.Provider
      value={{
        state: {
          isModalOpen,
          modalTitle: modalOptions?.title || ''
        },
        actions: {
          openModal,
          closeModal,
          updateModalOptions
        }
      }}
    >
      {children}
      {modalOptions && (
        <SoftphoneModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleClearContent={handleClearContent}
          onSave={modalOptions.onSave}
          saveDisabled={modalOptions.saveDisabled}
          primaryButtonText={modalOptions.primaryButtonText}
          isReversedLayout={isReversedLayout}
        >
          {modalOptions.content}
        </SoftphoneModal>
      )}
    </Context.Provider>
  );
}

// Export the context as a HOC
export const { Consumer: SoftphoneModalConsumer, Provider: SoftphoneModalProvider } = Context;

// export the context hook
export const useSoftphoneModalContext = () => useContext(Context);
