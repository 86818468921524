import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

export default {
  prefix: 'far' as IconPrefix,
  iconName: 'dialpad' as IconName,
  icon: [
    448,
    608,
    [],
    'f0e7',
    'M288,64A64,64,0,1,1,224,0,64,64,0,0,1,288,64ZM224,40a24,24,0,1,0,24,24A24,24,0,0,0,224,40ZM128,64A64,64,0,1,1,64,0,64,64,0,0,1,128,64ZM64,40A24,24,0,1,0,88,64,24,24,0,0,0,64,40Zm96,184a64,64,0,1,1,64,64A64,64,0,0,1,160,224Zm64,24a23.94,23.94,0,1,0-.12,0Zm64,136a64,64,0,1,1-64-64A64,64,0,0,1,288,384Zm-64-24a23.94,23.94,0,1,0,.12,0Zm64,184a64,64,0,1,1-64-64A64,64,0,0,1,288,544Zm-64-24a23.94,23.94,0,1,0,.12,0ZM448,64A64,64,0,1,1,384,0,64,64,0,0,1,448,64ZM384,40a24,24,0,1,0,24,24A24,24,0,0,0,384,40ZM320,224a64,64,0,1,1,64,64A64,64,0,0,1,320,224Zm64,24a23.94,23.94,0,1,0-.12,0Zm64,136a64,64,0,1,1-64-64A64,64,0,0,1,448,384Zm-64-24a23.94,23.94,0,1,0,.12,0ZM0,224a64,64,0,1,1,64,64A64,64,0,0,1,0,224Zm64,24a23.94,23.94,0,1,0-.12,0Zm64,136a64,64,0,1,1-64-64A64,64,0,0,1,128,384ZM64,360a23.94,23.94,0,1,0,.12,0Z'
  ]
} as IconDefinition;
