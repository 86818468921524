import React, { useContext } from 'react';
import { TextField, makeStyles, createStyles, MenuItem, FormHelperText, InputLabel } from '@material-ui/core';
import { Formik, Form, FormikProps, FieldProps, Field } from 'formik';
import * as Yup from 'yup';
import { OptionsType, ValueType } from 'react-select/src/types';
import { v4 as uuid } from 'uuid';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Toggle, Button, Label, Select as MUISelect, Modal, ReactSelect } from 'lib/common/components';

interface Option {
  label: number;
  value: number;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  defaultValue: OptionsType<Option>;
  className: string;
  classNamePrefix: string;
  id: string;
}

export const CustomSelect = ({
  field,
  form,
  options,
  defaultValue,
  className,
  classNamePrefix,
  isMulti = false,
  id
}: CustomSelectProps) => {
  const onChange = (option: ValueType<Option | Option[], boolean>) => {
    if ((option as Option[]) !== null) {
      form.setFieldValue(
        field.name,
        isMulti ? (option as Option[]).map((item: Option) => item) : (option as Option).value
      );
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) =>
              field.value
                .map(function (e) {
                  return e.value;
                })
                .indexOf(option.value) >= 0
          )
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ('' as any);
  };

  return (
    <ReactSelect
      defaultValue={defaultValue}
      className={className}
      classNamePrefix={classNamePrefix}
      id={id}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      maxMenuHeight={150}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginBottom: '40px'
    },
    selectMenu: {
      '& > *': {
        width: '100%'
      },
      marginBottom: '40px'
    },
    selectHelper: {
      marginBottom: '1vw'
    },
    selectLabel: {
      marginTop: '0.5vw'
    },
    progressLoader: {
      marginTop: '10px'
    },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 20,

      '&>.button:first-of-type': {
        marginRight: '20px'
      }
    }
  })
);

interface IMetricsConfigItem {
  id: string;
  name: string;
  type: string;
}

interface GroupConfig {
  value: string;
  label: string;
}

interface IMetricsGroupItem {
  tenantId: string;
  uuid: string;
  objectIdentifier: string;
  objectId: string;
  groupName: string;
  groupType: string;
  items: GroupConfig[];
  active: boolean;
  createdAt: string;
  updatedAt: string;
  accessedAt?: string;
}

interface IProps {
  getMetricGroups: (...args: any) => any;
  metricsConfigs: IMetricsConfigItem[];
  editMode: boolean;
  rowSelected: IMetricsGroupItem;
  toggleAddMetricsGroup: (...args: any) => any;
  show: boolean;
}

const ManageMetricsGroupPageInner: React.FunctionComponent<IProps> = (props_) => {
  const classes = useStyles();

  const { config } = useContext(AuthContext);
  const { fetch_ } = useContext(AuthContext);

  const upsertMetricsGroupItem = async (data: IMetricsGroupItem, { resetForm, setSubmitting }) => {
    let item = data;
    const currentDt = new Date().toISOString();
    setSubmitting(true);

    if (!props_.editMode) {
      item = Object.assign(item, {
        tenantId: process.env.REACT_APP_TENANT_ID,
        uuid: uuid(),
        objectIdentifier: `${process.env.REACT_APP_TENANT_ID}__metric`,
        objectId: uuid(),
        createdAt: currentDt,
        updatedAt: currentDt
      });
    } else {
      let isUpdated = false;
      if (props_.rowSelected.groupName !== item.groupName || props_.rowSelected.active !== item.active) {
        isUpdated = true;
      }
      const initValues = new Set(props_.rowSelected.items.map((i) => i.value));
      const newValues = new Set(item.items.map((i) => i.value));
      if (initValues.size !== newValues.size || ![...initValues].every((value) => newValues.has(value))) {
        isUpdated = true;
      }
      item = Object.assign(item, {
        updatedAt: isUpdated ? currentDt : item.updatedAt
      });
    }

    const url = `${config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/group`;
    fetch_(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(item)
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        } else {
          props_.toggleAddMetricsGroup();
          !props_.editMode && resetForm({});
          props_.getMetricGroups();
        }
      })
      .catch(() => {
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getFilteredMetricsConfigs = (groupType: string, metricsConfigs: IMetricsConfigItem[]) => {
    if (['queue', 'routing-profile'].includes(groupType)) {
      return metricsConfigs.filter((c: IMetricsConfigItem) => c.type === groupType);
    }
    return [];
  };

  const filterInitialItems = () => {
    if (Array.isArray(props_.metricsConfigs)) {
      return props_.rowSelected.items.filter((i) => props_.metricsConfigs.filter((c) => c.id === i.value).length > 0);
    }
    return props_.rowSelected.items;
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={
          !props_.editMode
            ? {
                tenantId: process.env.REACT_APP_TENANT_ID || '',
                uuid: '',
                objectIdentifier: '',
                objectId: '',
                groupName: '',
                groupType: '',
                items: [],
                active: false,
                createdAt: '',
                updatedAt: ''
              }
            : {
                tenantId: props_.rowSelected.tenantId,
                uuid: props_.rowSelected.uuid,
                objectIdentifier: props_.rowSelected.objectIdentifier,
                objectId: props_.rowSelected.objectId,
                groupName: props_.rowSelected.groupName,
                groupType: props_.rowSelected.groupType,
                items: filterInitialItems(),
                // items: props_.rowSelected.items,
                active: props_.rowSelected.active,
                createdAt: props_.rowSelected.createdAt,
                updatedAt: props_.rowSelected.updatedAt
              }
        }
        onSubmit={(data, actions) => {
          upsertMetricsGroupItem(data, actions);
        }}
        validationSchema={Yup.object().shape({
          groupName: Yup.string().required('Please enter a group name.'),
          groupType: Yup.string().required('Please select a group type.')
        })}
      >
        {(props: FormikProps<IMetricsGroupItem>) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting, setFieldValue } = props;
          return (
            <Modal
              hideFooter
              contentTopPadded
              open
              small
              onClose={props_.toggleAddMetricsGroup}
              title={`${props_.editMode ? 'Edit' : 'Add New'} Metrics Group`}
            >
              <Form translate="yes">
                <div className={classes.textField}>
                  <TextField
                    label="Group Name"
                    variant="outlined"
                    name="groupName"
                    id="groupName"
                    value={values.groupName}
                    type="text"
                    helperText={errors.groupName && touched.groupName ? errors.groupName : ''}
                    error={!!(errors.groupName && touched.groupName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.selectMenu}>
                  <MUISelect
                    label="Group Type"
                    name="groupType"
                    id="groupType"
                    disabled={props_.editMode}
                    value={values.groupType}
                    error={!!(errors.groupType && touched.groupType)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="queue">Queue</MenuItem>
                    <MenuItem value="routing-profile">Routing Profile</MenuItem>
                  </MUISelect>
                  <FormHelperText className={classes.selectHelper}>
                    {errors.groupType && touched.groupType ? errors.groupType : ''}
                  </FormHelperText>
                </div>
                {values.groupType && (
                  <div className={classes.textField}>
                    <InputLabel htmlFor="items">
                      {values.groupType === 'queue' ? 'Queue' : 'Routing Profile'}
                    </InputLabel>
                    <Field
                      name="items"
                      id="items"
                      component={CustomSelect}
                      defaultValue={values.items.map((item) => {
                        return {
                          value: item.value,
                          label: item.label
                        };
                      })}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={getFilteredMetricsConfigs(values.groupType, props_.metricsConfigs).map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        };
                      })}
                    />
                  </div>
                )}
                <Label id="active" text="Active">
                  <Toggle
                    id="active"
                    name="active"
                    checked={values.active}
                    onChange={(value) => setFieldValue('active', value)}
                  />
                </Label>
                <div className={classes.footer}>
                  <Button styleType="SECONDARY" disabled={isSubmitting} onClick={props_.toggleAddMetricsGroup}>
                    Cancel
                  </Button>
                  <Button busy={isSubmitting} type="submit" disabled={!values.groupName || !values.groupType}>
                    Save
                  </Button>
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};

const ManageMetricsGroupPage = (props) => {
  if (!props.show) {
    return null;
  }
  return <ManageMetricsGroupPageInner {...props} />;
};
export default ManageMetricsGroupPage;
