import { useEffect, useState } from 'react';
import { far, faPage } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { SOFTPHONE_DEFAULT_NAV_OPTIONS } from 'lib/common/constants/softphoneDefaultNavOptions';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { UserProfile } from 'lib/common/components';
import TUser from 'lib/common/types/User';

import SoftphoneAddAgentTaskButton from './components/SoftphoneAddAgentTaskButton';
import SoftphoneNavMenuItem from './components/SoftphoneNavMenuItem';
import SoftphoneTaskList from './components/SoftphoneTaskList';
import SoftphoneNavMenuFolder from './components/SoftphoneNavMenuFolder';

import './softphone-sidebar.scss';
import { SoftphoneNavOption } from '../../../../types/SoftphoneNavOption';

interface ISoftphoneSidebar {
  user: TUser;
  isReversedLayout: boolean;
}

export default function SoftphoneSidebar({ user, isReversedLayout }: ISoftphoneSidebar) {
  const {
    state: { pageModules }
  } = useModulesContext();
  const { hasPermission } = usePermissionsContext();
  const [navOptions, setNavOptions] = useState<any>(SOFTPHONE_DEFAULT_NAV_OPTIONS);
  const [navLoaded, setNavLoaded] = useState(false);

  useEffect(() => {
    if (navLoaded) {
      return;
    }

    setNavOptions([
      ...navOptions,
      ...pageModules.map((pageModule: ConnectorModuleType) => ({
        link: `/${pageModule.pathName}`,
        name: pageModule.name,
        icon: pageModule.icon ? far[pageModule.icon] || faPage : faPage
      }))
    ]);

    setNavLoaded(true);
  }, []);

  const isCreateTaskDisabled = hasPermission({ type: 'connect', permission: PERMISSIONS.CREATE_TASK_DISABLED });

  return (
    <div className={cx('softphone-sidebar', { 'softphone-sidebar--reversed': isReversedLayout })}>
      <div className="softphone-sidebar__top">
        {!isCreateTaskDisabled && <SoftphoneAddAgentTaskButton />}
        <SoftphoneTaskList isCreateTaskDisabled={isCreateTaskDisabled} isReversedLayout={isReversedLayout} />
      </div>
      <div className="softphone-sidebar__nav-menu">
        {navOptions.map((item: SoftphoneNavOption) =>
          item.childOptions ? (
            <SoftphoneNavMenuFolder key={item.link} {...item} isReversedLayout={isReversedLayout} />
          ) : (
            <SoftphoneNavMenuItem key={item.link} {...item} />
          )
        )}
      </div>
      <UserProfile user={user} isSoftphone isReversedPopover={isReversedLayout} />
    </div>
  );
}
