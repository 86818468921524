import React from 'react';
import { Line } from 'react-chartjs-2';
import Loader from 'lib/common/components/Loader';
import useData from '../../../../hooks/useData';
import Error from '../Error/Error';
import './lineGraph.scss';
import { Text } from '../../../../../../common/components';

interface IProps {
  block: any;
  refresh: number;
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
  small: boolean;
}

const GRAPH_OPTIONS = {
  legend: { display: false },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Time'
        }
      }
    ],
    yAxes: [
      {
        display: true,
        scaleLabel: {
          display: true,
          labelString: 'Value'
        },
        ticks: {
          suggestedMin: 0,
          precision: 0
        }
      }
    ]
  }
};

function getGraphData(data) {
  if (!data) {
    return null;
  }

  return {
    labels: data.dates,
    datasets: [
      {
        label: 'Value',
        fill: true,
        lineTension: 0.1,
        tension: 0.1,
        backgroundColor: 'rgba(0,0,0,0)',
        borderColor: '#11B5E7',
        borderDash: [],
        borderDashOffset: 0.0,
        borderWidth: 2,
        pointBorderColor: '#11B5E7',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 3,
        pointHoverBackgroundColor: '#ed3c80',
        pointHoverBorderColor: '#ed3c80',
        pointHoverBorderWidth: 5,
        pointRadius: 1,
        pointHitRadius: 10,
        data: data.values
      }
    ]
  };
}
export default function LineGraph(props: IProps) {
  const {
    block: { processor, element, queueid, dataModule, _uid, name },
    block: raw,
    refresh,
    small,
    config,
    fetch
  } = props;

  const { loading, data, error } = useData({
    dataModule,
    element,
    raw,
    processor,
    refresh,
    queueid,
    config,
    fetch
  });
  const graphData = getGraphData(data);

  return (
    <div className="line-graph" key={_uid}>
      <Text type={small ? void 0 : 'heading2'} color="darkBlue" bold={small}>
        {name}
      </Text>
      {loading && <Loader small />}
      {!loading && graphData && !error && (
        <div className="line-graph__line" data-testid="line-graph-widget">
          <Line data={graphData} options={GRAPH_OPTIONS} />
        </div>
      )}
      {!loading && error && <Error small={small} />}
    </div>
  );
}
