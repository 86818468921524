import { useEffect, useState } from 'react';
import elasticsearch from 'elasticsearch';
import { format } from 'date-fns';
import prettyMilliseconds from 'pretty-ms';
import queries from 'lib/core/config/queries.json';
import getUTCDate from 'lib/common/utils/getUTCDate';

interface IProps {
  dataModule: any;
  element: any;
  processor: any;
  queueid: any;
  raw: any;
  refresh: number;
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
}

const DATA_MODULES = {
  DATE_SEARCH: 'DATE-SEARCH',
  QUEUES_GENERAL: 'QUEUES-GENERAL'
};

type DateRangeUnit = 'days' | 'mins';

function getStartDate({
  dateRangeUnit,
  dateRangeValue
}: { dateRangeUnit?: DateRangeUnit; dateRangeValue?: number } = {}) {
  const date = new Date();

  if (!dateRangeUnit || !dateRangeValue) {
    // return now. Adding a day to get stats for today too.
    date.setUTCDate(date.getUTCDate() + 1);
    date.setUTCHours(0, 0, 0, 0);
    return date;
  }

  if (dateRangeUnit === 'days') {
    date.setUTCDate(date.getUTCDate() - dateRangeValue);
    date.setUTCHours(0, 0, 0, 0);
  }

  if (dateRangeUnit === 'mins') {
    date.setUTCMinutes(date.getUTCMinutes() - dateRangeValue);
  }

  // new Date(argument) with no timezone information returns a UTC date
  return getUTCDate(date).toISOString();
}

const processData = ({ data, token, type, options }) => {
  if (type === 'makeGraph') {
    const rawDateGroupResult = data.aggregations[2].buckets.reduce(
      ({ dates, values }, item) => ({
        dates: [
          ...dates,
          format(getUTCDate(new Date(item.key_as_string)), options.time === 'days' ? 'dd/MM/yyyy' : 'HH:mm')
        ],
        values: [...values, item[1].hits.hits[0]._source[token]]
      }),
      { dates: [], values: [] }
    );

    return rawDateGroupResult;
  }

  const dataResult = data.aggregations[1]?.hits?.hits[0]?._source?.[token] || 0;

  if (type === 'getTimeFmt' && !dataResult) {
    return '0s';
  }

  // Expects a milliseconds value
  if (type === 'getTimeFmt') {
    return dataResult < 1000 ? '1s' : prettyMilliseconds(dataResult, { secondsDecimalDigits: 0, unitCount: 2 });
  }

  return dataResult;
};

async function elasticSearchHandler({ processor, config, dataModule, query, element, queueid, raw }) {
  const client = elasticsearch.Client({ host: config.ELASTICSEARCH_HOST });

  const {
    dateRange: { active, dateRangeUnit }
  } = raw;

  if (dataModule === DATA_MODULES.QUEUES_GENERAL) {
    query.query.aggs[1].top_hits.docvalue_fields[0].field = element;
    query.query.query.bool.filter[0].bool.should[0].match_phrase['QueueID.keyword'] = queueid;
    query.query.query.bool.filter[1].bool.should[0].match_phrase['QueueID.keyword'] = queueid;
  }

  if (dataModule === DATA_MODULES.DATE_SEARCH) {
    query.query.aggs[2].aggs[1].top_hits.docvalue_fields[0].field = element;
    query.query.aggs[2].aggs[1].top_hits.sort[element] = { order: 'desc' };
    query.query.query.bool.filter[1].match_phrase.QueueID = queueid;
  }

  if (dataModule === DATA_MODULES.DATE_SEARCH && active) {
    query.query.aggs[2].date_histogram.calendar_interval = `1${dateRangeUnit === 'days' ? 'd' : 'm'}`;
    query.query.query.bool.filter[2].range.create_date.gte = getStartDate(raw.dateRange);
    query.query.query.bool.filter[2].range.create_date.lte = getStartDate();
  }

  const result = await client.search({ index: query.index, body: query.query });
  const options =
    dataModule === DATA_MODULES.DATE_SEARCH && active ? { time: dateRangeUnit === 'days' ? 'days' : 'mins' } : {};

  return processData({
    data: result,
    token: element,
    type: processor,
    options
  });
}

async function dynamoHandler({ raw, config, query, fetch }) {
  const { dateRange, filter } = raw;
  const startDate = getStartDate(dateRange);

  // Original code had a typo and has been corrected, however existing widgets have been stored with typo
  // TODO -- update the misspelled words in the database
  const queryString =
    raw.filterable || raw.fitlerable ? `?type=${filter}&tenantId=${config.TENANT_ID}&startDate=${startDate}` : '';
  const url = `${config.CALL_SERVICE_HOST}/${query.request}${queryString}`;

  const response = await fetch(url);
  const { data } = await response.json();

  return { labels: data.labels, values: data.values };
}

export default function useData({ dataModule, element, raw, processor, queueid, refresh, config, fetch }: IProps) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setError(false);
      setLoading(true);

      const query = { ...queries[dataModule], index: config.ELASTICSEARCH_INDEX };

      try {
        const data =
          query.source === 'elastic'
            ? await elasticSearchHandler({
                processor,
                config,
                dataModule,
                query,
                element,
                queueid,
                raw
              })
            : await dynamoHandler({ raw, config, fetch, query });

        setData(data);
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    })();
  }, [refresh]);

  return { data, loading, error };
}
