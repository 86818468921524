import connectGetter from 'lib/common/utils/connectGetter';

export default async function getContactChatSession(
  contact?: connect.Contact
): Promise<connect.AgentChatSession | null> {
  if (!contact) {
    return null;
  }

  const type = connectGetter('getType', contact);

  if (type !== connect.ContactType.CHAT) {
    return null;
  }

  const connection = connectGetter('getConnections', contact)?.find(
    (connection) => connectGetter('getType', connection) === connect.ConnectionType.AGENT
  ) as connect.ChatConnection | undefined;

  if (!connection) {
    return null;
  }

  const session: connect.AgentChatSession = await (connection as connect.ChatConnection).getMediaController();

  return session;
}
