export enum PERMISSIONS {
  CUSTOMER_PROFILES_WRITE = 'CUSTOMER_PROFILES_WRITE',
  CUSTOMER_PROFILES = 'CUSTOMER_PROFILES',
  INTERACTION_HISTORY = 'CALL_HISTORY',
  WALLBOARD = 'WALLBOARD',
  ADMIN = 'ADMIN',
  AGENT_ACW = 'AGENT_ACW',
  AGENT_ACW_CANT_SKIP = 'AGENT_ACW_CANT_SKIP',
  AGENT_MISSED_CALL = 'AGENT_MISSED_CALL',
  PERSONAL_CONTACTS = 'PERSONAL_CONTACTS',
  CREATE_TASK_DISABLED = 'restrictTaskCreation',
  SOFTPHONE_ONLY = 'SOFTPHONE_ONLY',

  // This is a legacy permission, so the format is different
  DASHBOARDS = 'tailoreddashboards'
}
