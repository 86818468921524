export enum SOFTPHONE_PAGE_ROUTES {
  BASE = '/',
  LANDING = '/landing',
  INTERACTION_HISTORY = '/interactions',
  AGENT_STATS = '/stats',
  DIRECTORY = '/directory',
  WIDGETS = '/widgets',
  PREFERENCES = '/preferences',
  TASK = '/task'
}

export const SOFTPHONE_PAGE_CONFIGURATIONS = {
  [SOFTPHONE_PAGE_ROUTES.LANDING]: {
    pageTitle: ''
  },
  [SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY]: {
    pageTitle: 'Interaction History'
  },
  [SOFTPHONE_PAGE_ROUTES.AGENT_STATS]: {
    pageTitle: 'Your Queues'
  },
  [SOFTPHONE_PAGE_ROUTES.DIRECTORY]: {
    pageTitle: 'Directory'
  },
  [SOFTPHONE_PAGE_ROUTES.WIDGETS]: {
    pageTitle: 'Widgets'
  },
  [SOFTPHONE_PAGE_ROUTES.PREFERENCES]: {
    pageTitle: 'Preferences'
  }
};
