import { SizeMe } from 'react-sizeme';
import Count from './Count';
import PieGraph from './PieGraph';
import LineGraph from './LineGraph';

import styles from './dashboard-module.module.scss';

const modules = {
  count: Count,
  pieGraph: PieGraph,
  linegraph: LineGraph
};

const SMALL_THRESHOLD = 250;

interface IModule {
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
  block: {
    visualisation: 'count' | 'pieGraph' | 'lineGraph';
  };
  refresh: number;
}

export default function Module({ block, refresh, config, fetch }: IModule) {
  if (!modules[block.visualisation]) {
    return <p>Unknown</p>;
  }

  const Element = modules[block.visualisation];

  return (
    <SizeMe monitorWidth monitorHeight={false} refreshRate={128}>
      {({ size }) => (
        <div className={styles['dashboard-module']}>
          <Element
            small={size?.width && size?.width < SMALL_THRESHOLD}
            block={block}
            refresh={refresh}
            config={config}
            fetch={fetch}
          />
        </div>
      )}
    </SizeMe>
  );
}
