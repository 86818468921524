import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { GithubPicker } from 'react-color';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';
import { Modal } from 'lib/common/components';
import DASHBOARD_COLOURS from '../constants/dashboardBgColours';
import '../styles/dashboard-action.scss';

export default function AddDB(props: any) {
  const [name, setName] = React.useState('');

  const [background, setBackground] = React.useState('#ffffff');
  const [description, setDescription] = React.useState<string>();

  const { addDB } = props;

  const values = {
    status: false,
    name: '',
    description: '',
    backgroundcolor: '',
    theme: 'light'
  };

  const valueChange = (event) => {
    setName(event.target.value);
  };

  const descriptionChange = (event) => {
    setDescription(event.target.value as string);
  };

  const handleChangeColor = (color) => {
    setBackground(color.hex);
  };

  const submitDB = () => {
    if (!name) {
      return;
    }

    const trimmedName = name?.trim();

    return addDB({
      ...values,
      status: true,
      name: trimmedName,
      description: description,
      backgroundcolor: background
    });
  };

  const cancelDB = () => {
    addDB(values);
  };

  return (
    <Modal
      open
      contentTopPadded
      title="Create Dashboard"
      onSave={submitDB}
      onSuccess={cancelDB}
      onClose={cancelDB}
      saveDisabled={!name?.trim()}
    >
      <div className="dashboard-action">
        <TextField
          id="name"
          label="Name"
          data-testid="new-dashboard-name"
          onChange={valueChange}
          onKeyPress={onEnterKeyPressed(submitDB)}
          variant="outlined"
          value={name}
        />
      </div>

      <div className="dashboard-action">
        <Typography className="edit-widget-label">Theme</Typography>
        <GithubPicker
          color={background}
          colors={DASHBOARD_COLOURS}
          onChangeComplete={handleChangeColor}
          width="100%"
          triangle="hide"
        />
      </div>

      <div className="dashboard-action">
        <TextField
          id="description"
          label="Description"
          onChange={descriptionChange}
          value={description}
          variant="outlined"
          data-testid="new-dashboard-description"
        />
      </div>
    </Modal>
  );
}
