import connectGetter from 'lib/common/utils/connectGetter';
import { setContext, setUser } from '@sentry/browser';
import { createContext, useContext, useEffect, useState } from 'react';
import EventEmitter from 'lib/common/utils/EventEmitter';

type AgentContextType = {
  agent: connect.Agent | null;
  states: connect.AgentStateDefinition[];
  routingProfile?: string;
  channelConcurrencyMap?: connect.AgentChannelConcurrencyMap;
};

const Context = createContext<AgentContextType>({
  agent: null,
  states: []
});

export default function AgentContext({ children }) {
  const [agent, setAgent] = useState<connect.Agent | null>(null);

  const handleNewAgent = (agent: connect.Agent) => {
    const config = connectGetter('getConfiguration', agent) || {};

    sessionStorage.setItem('c_user', config.username);

    EventEmitter.emit('onInit', config);

    setAgent(agent);

    // Set Sentry contexts for reporting
    setContext('user', config);
    setUser({ username: config.username });
  };

  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.agent(handleNewAgent);
  }, []);

  return (
    <Context.Provider
      value={{
        agent,
        routingProfile: connectGetter('getConfiguration', agent)?.routingProfile?.routingProfileId?.split('/')[3],
        states: connectGetter('getAgentStates', agent) || [],
        channelConcurrencyMap: connectGetter('getConfiguration', agent)?.routingProfile?.channelConcurrencyMap || {}
      }}
    >
      {children}
    </Context.Provider>
  );
}

// Export the context as a HOC
export const { Consumer: AgentConsumer, Provider: AgentProvider } = Context;

// export the context hook
export const useAgentContext = () => useContext(Context);
