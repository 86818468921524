import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import TimedTask from './TimedTask';

export default function NeglectedTask({ selectedTask }) {
  const { hasPermission } = usePermissionsContext();
  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_MISSED_CALL
  });

  if (!hasMissedCallPermission && selectedTask.status === CONTACT_STATES.MISSED) {
    return null;
  }

  return <TimedTask {...selectedTask} />;
}
