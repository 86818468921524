import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { Tooltip } from '@material-ui/core';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import COLOURS from 'css/export-vars.module.scss';
import './icon.scss';

interface IconProps extends Omit<FontAwesomeIconProps, 'size' | 'icon'> {
  className?: string;
  color?: string;
  size?: number;
  icon: IconProp | string;
  tooltip?: string;
  tooltipDelay?: number;
}

const Icon = ({ className, color, size = 20, tooltip, icon, tooltipDelay = 1000, ...iconProps }: IconProps) => {
  const sizePx = `${size}px`;

  const iconEl = (
    <FontAwesomeIcon
      className={cx('base-icon', className)}
      color={color ? COLOURS[color] : COLOURS.darkBlue}
      style={{ fontSize: sizePx, width: sizePx, height: sizePx }}
      icon={typeof icon === 'string' ? ['far', icon as IconName] : icon}
      {...iconProps}
    />
  );

  return tooltip ? (
    <Tooltip enterDelay={tooltipDelay} title={tooltip}>
      <div className="base-icon-container">{iconEl}</div>
    </Tooltip>
  ) : (
    iconEl
  );
};

export default Icon;
