import { faWaveformLines } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { TASK_TYPES } from 'lib/common/constants/taskStates';
import { Icon } from 'lib/common/components';
import '../styles/status-icon.scss';

export default function StatusIcon({ isNeglected, callIsOnHold, connectionType, status, type, size = 40 }) {
  return (
    <Icon
      className={cx('status-icon', {
        'status-icon--on-hold': callIsOnHold,
        'status-icon--neglected': isNeglected
      })}
      icon={isNeglected ? TASK_TYPES(connectionType)[status][type].icon : faWaveformLines}
      size={size}
    />
  );
}
