import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_CONFIGURATIONS } from 'lib/common/constants/pageConfigurations';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';

export default function usePageProps() {
  const { pathname } = useLocation();
  const {
    state: { pageModules }
  } = useModulesContext();

  const {
    pagename = '',
    className = '',
    pageTitle = ''
  } = useMemo(() => {
    const pageModulesNavOptions = pageModules
      ? pageModules?.reduce(
          (result, pageModule) => ({
            ...result,
            ...(pageModule?.pathName
              ? {
                  [pageModule.pathName]: {
                    pageName: pageModule.name || '',
                    pageTitle: pageModule.type === 'PAGE' ? pageModule.name : '',
                    className: ''
                  }
                }
              : {})
          }),
          {}
        )
      : {};

    return PAGE_CONFIGURATIONS[pathname] || pageModulesNavOptions[pathname] || {};
  }, [pathname]);

  return { pagename, className, pageTitle };
}
