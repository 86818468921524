import cx from 'classnames';

import { Button, HeadingLabel, AccordionGroup } from 'lib/common/components/index';
import { useCreateAgentTask } from 'lib/common/components/molecules';

import styles from './softphone-create-agent-task.module.scss';

export default function SoftphoneCreateAgentTask({
  name: defaultName = '',
  description: defaultDescription = '',
  onClose
}) {
  const { blocks, saveDisabled, createTask } = useCreateAgentTask({
    defaultName,
    defaultDescription,
    open: true,
    isSoftphone: true
  });

  return (
    <div className={styles['softphone-create-agent-task']}>
      <AccordionGroup defaultOpenIndex={0} onlyOneOpen>
        {blocks.map(({ content, className, ...block }, index) => (
          <AccordionGroup.Accordion
            key={index}
            className={cx(className, { 'mb-auto': index === blocks.length - 1 })}
            title={
              <HeadingLabel
                noMargin
                smallTitle
                primary={block.title}
                secondary={!block.optional && block.summaryAbbreviated}
                optional={block.optional}
              />
            }
          >
            {content}
          </AccordionGroup.Accordion>
        ))}
      </AccordionGroup>
      <div className="create-agent-task__buttons">
        <Button styleType="SECONDARY" onClick={onClose}>
          Cancel
        </Button>
        <Button icon="faSave" disabled={saveDisabled} onClick={createTask} onSuccess={onClose} asyncAction>
          Save
        </Button>
      </div>
    </div>
  );
}
