import { useState, useEffect, useContext } from 'react';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { ConfigContext } from 'lib/core/config';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { Label, ReactSelect } from 'lib/common/components';

import styles from './acw-outcome.module.scss';

type TOutcome = {
  code: number;
  name: string;
};

interface Campaign {
  name: string;
  type: string;
  callOutcomes: number[];
  routingProfile: string[];
}

const ACWOutcome = ({ campaign, outcome, setCampaign, setOutcome }) => {
  const [campaignTypes, setCampaignTypes] = useState<string[]>([]);
  const [outcomesList, setOutcomesList] = useState<TOutcome[]>([]);
  const [outcomesLoading, setOutcomesLoading] = useState(false);
  const [campaignsList, setCampaignsList] = useState<Campaign[]>([]);
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(false);

  const { routingProfile } = useAgentContext();
  const { hasPermission } = usePermissionsContext();

  const { config } = useContext(ConfigContext);
  const { fetch_ } = useContext(AuthContext);

  const tenantId = config.TENANT_ID;

  const hasACWPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_ACW
  });

  const getData = async (service) => {
    try {
      const response = await fetch_(`${config.CALL_SERVICE_HOST}/${service}/?tenantId=${tenantId}`);

      return (await response.json())?.data || [];
    } catch {
      return [];
    }
  };

  const handleSelectCampaign = async ({ value }: any) => {
    setOutcomesList([]);
    setOutcomesLoading(true);

    setOutcome(null);
    setCampaign(value);

    const outcomesUnfiltered = await getData('getoutcomes');
    const filteredCampaigns = campaignsList.filter((campaign: Campaign) => campaign.type === value);
    const campaignOutcomes = filteredCampaigns.map((campaign: Campaign) => campaign.callOutcomes).flat();
    const filteredOutcomes = outcomesUnfiltered.filter((outcome) => campaignOutcomes.includes(outcome.code));

    setOutcomesList(filteredOutcomes);
    setOutcomesLoading(false);
  };

  useEffect(() => {
    if (!hasACWPermission) {
      return;
    }

    (async () => {
      setCampaignsLoading(true);

      const campaignList = await getData('campaigns');
      const filteredCampaigns = campaignList.filter(
        (campaign: Campaign) => routingProfile && campaign.routingProfile.includes(routingProfile)
      );
      const campaignTypes = filteredCampaigns.map((campaign: Campaign) => campaign.type);
      const uniqueCampaignTypes = campaignTypes.filter(
        (campaignType, index) => campaignTypes.indexOf(campaignType) >= index
      );

      setCampaignsList(filteredCampaigns);
      setCampaignTypes(uniqueCampaignTypes);
      setCampaignsLoading(false);
    })();
  }, [routingProfile]);

  if (!hasACWPermission) {
    return null;
  }

  return (
    <div className={styles['acw-outcome']}>
      <h4 className="mb-15">Outcome</h4>
      <Label id="campaign-type-select" text="Campaign Type">
        <ReactSelect
          isSearchable
          name="campaign-type"
          options={campaignTypes.map((campaignType: any) => ({
            value: campaignType,
            label: campaignType
          }))}
          classNamePrefix="select"
          onChange={handleSelectCampaign}
          placeholder="Search/select campaigns"
          value={campaign ? { label: campaign, value: campaign } : void 0}
          aria-label="campaign-select"
          isLoading={campaignsLoading}
        />
      </Label>
      {campaign && (
        <Label id="outcome-select" text="Wrap Up Code" className="mt-20">
          <ReactSelect
            isSearchable
            name="outcomes"
            options={outcomesList.map((outcome: any) => ({
              value: outcome.name,
              label: outcome.name
            }))}
            classNamePrefix="select"
            onChange={({ value }: any) => setOutcome(value)}
            placeholder="Search/select wrap up codes"
            isLoading={outcomesLoading}
            value={outcome ? { label: outcome, value: outcome } : void 0}
            aria-label="outcome-select"
          />
        </Label>
      )}
    </div>
  );
};

export default ACWOutcome;
