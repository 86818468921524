import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';

export default function endThirdPartyConnectionHelper(contact) {
  const activeThirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', contact);

  if (!activeThirdPartyConnection) {
    return Promise.resolve();
  }

  return connectAction('destroy', activeThirdPartyConnection);
}
