import CHAT_EVENTS from 'lib/common/constants/chatEvents';

type TArgs = {
  session: connect.ChatSession | null;
  taskId: string;
  handleContactChange: (_: any) => void;
};

const MESSAGE_DATA_TYPE = 'MESSAGE';

const CUSTOMER_ROLE = 'CUSTOMER';

export default function registerChatHandlers({ session, taskId, handleContactChange }: TArgs) {
  if (!session) {
    return;
  }

  session.onMessage((event) => {
    const { data } = event;

    if (data.Type !== MESSAGE_DATA_TYPE || data.ContentType === CHAT_EVENTS.TYPING) {
      return;
    }

    return void handleContactChange({
      taskId,
      message: data,
      customerTyping: data.ParticipantRole === CUSTOMER_ROLE ? false : void 0
    });
  });

  session.onTyping((event) => {
    const { data } = event;

    if (data.ParticipantRole !== CUSTOMER_ROLE) {
      return;
    }

    handleContactChange({ taskId, customerTyping: true });
  });
}
