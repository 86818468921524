import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { TextField } from '@material-ui/core';
import Button from 'lib/common/components/Button';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import isURL from 'validator/lib/isURL';
import cx from 'classnames';

import '../styles/create-agent-task.scss';
import '../styles/task-references.scss';

export default function TaskReferences({ references, onUpdateReference, removeReference, addReference, isSoftphone }) {
  return (
    <div className="task-references">
      {references.map((reference, index) => (
        <div className="task-references__reference" key={reference.id}>
          <div>
            <div className="create-agent-task__text-field">
              <TextField
                variant="outlined"
                label="Name"
                fullWidth
                value={reference.name}
                type="url"
                onChange={onUpdateReference(index, 'name')}
                data-testid="create-task-reference-name-input"
              />
            </div>
            <div className="create-agent-task__text-field">
              <TextField
                variant="outlined"
                label="Link"
                fullWidth
                value={reference.url}
                error={Boolean(reference.url && !isURL(reference.url))}
                helperText={Boolean(reference.url && !isURL(reference.url)) && 'Reference link should be a valid URL'}
                type="url"
                onChange={onUpdateReference(index, 'url')}
                data-testid="create-task-reference-url-input"
              />
            </div>
          </div>
          <ClickableIcon icon={faTrash} onClick={removeReference(index)} />
        </div>
      ))}
      {isSoftphone && (
        <Button
          className={cx('task-references__button', { 'task-references__button--only-child': !references.length })}
          onClick={addReference}
          round={false}
          styleType="SECONDARY"
        >
          Add Reference
        </Button>
      )}
    </div>
  );
}
