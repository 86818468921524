import { Redirect } from 'react-router-dom';
import userIsAdmin from '../utils/userIsAdmin';
import { usePermissionsContext } from '../contexts/PermissionsContext';
import { PERMISSIONS } from '../constants/permissions';
import { PAGE_ROUTES } from '../constants/pageConfigurations';

export default function RoleRedirect({ user, ...props }) {
  const { hasPermission } = usePermissionsContext();

  return (
    <Redirect
      {...props}
      to={
        userIsAdmin(user) && hasPermission({ type: 'user', permission: PERMISSIONS.DASHBOARDS })
          ? PAGE_ROUTES.DASHBOARDS
          : PAGE_ROUTES.WORKSPACE
      }
    />
  );
}
