import cx from 'classnames';
import { format } from 'date-fns';
import _startCase from 'lodash.startcase';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components';
import Loader from './Loader';

import styles from './chat-message.module.scss';

interface IMessage {
  message?: connect.ChatTranscriptItem;
  customerName: string;
  lastMessageOfRole: boolean;
  isSmall?: boolean;
  invert?: boolean;
}

export default function Message({ message, customerName, lastMessageOfRole, isSmall, invert = false }: IMessage) {
  const isAgent = message?.ParticipantRole === 'AGENT';
  const isSystem = !isAgent && message?.ParticipantRole !== 'CUSTOMER';
  const displayName = message?.DisplayName || customerName;

  return (
    <div
      className={cx(styles['chat-message'], {
        [styles['chat-message--agent']]: message && (isAgent || isSystem),
        [styles['chat-message--customer']]: !message || (!isAgent && !isSystem),
        [styles['chat-message--customer--invert']]: (!message || (!isAgent && !isSystem)) && invert,
        [styles['chat-message--end']]: lastMessageOfRole,
        [styles['chat-message--small']]: isSmall
      })}
      data-testid={message ? 'chat-message' : 'typing-message'}
    >
      {message && (
        <div className={styles['chat-message__header']}>
          <p>{isAgent ? 'You' : _startCase(displayName.toLowerCase())}</p>
          <div className={styles['chat-message__header__time']}>
            <Icon size={10} icon={faCheck} />
            <p>{format(new Date(message?.AbsoluteTime), 'HH:mm')}</p>
          </div>
        </div>
      )}
      {message ? message.Content : <Loader />}
    </div>
  );
}
