import cx from 'classnames';

import { DIALS } from '../../constants/dials';

import './number-pad.scss';

interface INumberPad {
  onDialPress: (digit: string) => void;
  isSmallWidth: boolean;
}

const NumberPad = ({ onDialPress, isSmallWidth }: INumberPad) => {
  return (
    <div className="number-pad__container">
      {DIALS.map((dial, key) => (
        <div key={key.toString()} className="number-pad__row">
          {dial.map(({ digit, sub }) => (
            <div
              data-testid={`number-pad-digit-${digit}`}
              className={cx('number-pad__row__digit', { 'number-pad__row__digit--small': isSmallWidth })}
              key={digit}
              onClick={() => onDialPress(digit)}
            >
              {digit}
              <div className="sub">{sub}</div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default NumberPad;
