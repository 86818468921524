import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import cx from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { Icon } from '../';

import './clickable-icon.scss';

interface TClickableIcon {
  className?: string;
  onClick?: (_: any) => void;
  color?: string;
  icon: IconDefinition;
  withHover?: boolean;
  size?: number;
  tooltip?: string;
  setRef?: (ref: HTMLButtonElement | null) => void;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

// Intersection types. Cannot create a union type as it is not allowed
interface TClickableIconProps extends TClickableIcon, FontAwesomeIconProps {
  size?: any;
  icon: any;
  onClick?: any;
}

export default function ClickableIcon({
  className,
  onClick,
  icon,
  withHover = true,
  size,
  tooltip,
  setRef,
  tooltipPlacement = 'bottom',
  ...iconProps
}: TClickableIconProps) {
  const iconButton = (
    <button
      ref={(node) => (setRef ? setRef(node) : void 0)}
      className={cx('clickable-icon', { 'clickable-icon--with-hover': withHover }, className)}
      style={{ fontSize: size && `${size}px` }}
      onClick={onClick}
      data-testid={`clickable-icon-${typeof icon === 'string' ? icon : icon.iconName}`}
    >
      <Icon icon={icon} {...iconProps} size={size} />
    </button>
  );

  return tooltip ? (
    <Tooltip enterDelay={1000} title={tooltip} placement={tooltipPlacement}>
      {iconButton}
    </Tooltip>
  ) : (
    iconButton
  );
}
