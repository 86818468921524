import { CONTACT_NOT_FOUND_EXCEPTION, DEFAULT_ERROR_MSG } from '../constants/connectExceptions';
import parseJSON from './parseJSON';
import toast from './toast';

const OUTBOUND_CALL_NOT_SUPPORTED_MSG = 'NEON does not support dialing to this country yet.';
const BAD_EXCEPTION = 'BadEndpointException';
const UNSUPPORTED_COUNTRIES_EXCEPTION =
  'Cannot dial third party destination: Phone number is not in dialable countries.';

const HIDE_TOAST_FOR_EXCEPTIONS = [CONTACT_NOT_FOUND_EXCEPTION];

export default async function connectAction(
  action: string,
  entity?: connect.Contact | connect.BaseConnection | connect.Agent | null,
  payload?: any,
  retries = 3,
  errorMsg?: string,
  ...optionalPayloads: any
): Promise<any> {
  if (!entity) {
    return Promise.resolve();
  }

  try {
    const result = await new Promise<void>((resolve, reject) => {
      const callbacks = {
        success: resolve,
        failure: (e) => {
          console.error(action, e);
          reject(e);
        }
      };

      const firstArgument = payload || callbacks;
      const secondArgument = payload ? callbacks : void 0;

      entity[action](firstArgument, secondArgument, ...optionalPayloads);
    });

    return result;
  } catch (e: any) {
    if (retries === 0) {
      const { type, message = '' } = parseJSON(e) || {};

      const connectErrorMsg =
        type === BAD_EXCEPTION && message === UNSUPPORTED_COUNTRIES_EXCEPTION
          ? OUTBOUND_CALL_NOT_SUPPORTED_MSG
          : DEFAULT_ERROR_MSG;

      if (!HIDE_TOAST_FOR_EXCEPTIONS.includes(type)) {
        toast('error', errorMsg || connectErrorMsg);
      }

      return Promise.reject(e);
    }

    return connectAction(action, entity, payload, retries - 1, errorMsg);
  }
}
