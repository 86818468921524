import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { TextField } from '@material-ui/core';

// Only use this instead of a MUI select component. react-select should be used for new select components
export default function Select({ children, ...props }) {
  return (
    <TextField
      SelectProps={{ IconComponent: () => <FontAwesomeIcon icon={faChevronDown} /> }}
      select
      variant="outlined"
      placeholder="Select..."
      {...props}
    >
      {children}
    </TextField>
  );
}
