import { StepType } from '@reactour/tour';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import getStep from '../utils/getStep';

const stepsForInteractionHistory: Array<StepType> = [
  getStep({
    selector: '.interaction-history__date-selector',
    title: 'Filtering',
    text: 'Use the date filters to search through your interaction history.',
    position: 'bottom'
  }),
  getStep({
    selector: '.interaction-history__container',
    title: 'History',
    text: `You'll see interaction history items appear here after you accept some calls. You'll be able to call the contact directly from the record.`,
    position: 'bottom'
  })
];

const stepsForDashboards: Array<StepType> = [
  getStep({
    selector: '.dashboard-select',
    title: 'Your Dashboard',
    text: 'Choose the dashboard you want to view.',
    position: 'bottom'
  }),
  getStep({
    selector: '.action-menu__trigger',
    title: 'Dashboard actions',
    text: 'Create a new dashboard, add widgets or customise the current dashboard.',
    position: 'bottom'
  }),
  getStep({
    selector: '.dashboards__fullscreen-toggle',
    title: 'Go Fullscreen',
    text: 'Too many distractions? Go full screen and view uninterrupted stats and metrics.',
    position: 'bottom'
  }),
  getStep({
    selector: '.update-time',
    title: 'Last Updated',
    text: "We'll let you know the last time your dashboard was updated. You can change how often it refreshes in the settings.",
    position: 'bottom'
  })
];

const stepsForWallboard: Array<StepType> = [
  getStep({
    selector: 'span.wallboard__stats-title',
    title: 'Queue Selection',
    text: `You can choose the contact queue you want to view stats for. If you want to see all stats across all queues, select 'All'.`,
    position: 'bottom'
  }),
  getStep({
    selector: '[data-tour="wallboard-widgets"]',
    title: 'Widgets',
    text: `These widgets update in close to real time. You'll see the numbers change as agents interact with contacts.`,
    position: 'top'
  })
];

const stepsForUserSettings: Array<StepType> = [
  getStep({
    selector: 'a[href="/settings/users"]',
    title: 'Users',
    text: 'Manage users for the current NEON instance. This is where you give Amazon Connect users access to NEON.',
    additionalText: ['These users are not the same users as the ones managed in CloudWave Admin.']
  }),
  getStep({
    selector: 'a[href="/settings/metrics-config"]',
    title: 'Metrics Configuration',
    text: 'Manage metric groups and configure polling intervals.',
    additionalText: ['These are used for stats on dashboards.']
  }),
  getStep({
    selector: 'a[href="/settings/outcomes"]',
    title: 'Interaction Outcomes',
    text: 'Set up after call work codes and outcomes for interactions.',
    additionalText: ['Agents will be able to select these in after call work after each interaction.']
  }),
  getStep({
    selector: 'a[href="/settings/campaigns"]',
    title: 'Campaigns',
    text: 'Create and manage campaigns.',
    additionalText: [
      'Campaigns are used to group interaction outcomes and provide you the ability to assign after call work codes to routing profiles and queues.'
    ]
  })
];

const stepsForMetricsSettings: Array<StepType> = [
  getStep({
    selector: '[data-tour="metricsconfig-updateinterval"]',
    title: 'Update Interval',
    text: 'Choose how often you want metrics to update when used on Dashboards. Increasing the frequency of updates may result in a higher bill.'
  }),
  getStep({
    selector: 'div.relative.min-height-300',
    title: 'Groups',
    text: 'Assign queues or routing profiles to metrics groups. This will group stats and metrics together when viewing widgets on the dashboard.'
  })
];

const stepsForCallOutcomes: Array<StepType> = [
  getStep({
    selector: '[data-tour="outcomes"]',
    title: 'Interaction Outcomes',
    text: 'Specify wrap up codes and interaction outcomes for agents to use during after call work. These must be assigned to a campaign before an agent can select them.'
  })
];

const stepsForCampaignSettings: Array<StepType> = [
  getStep({
    selector: '[data-tour="campaign-settings"]',
    title: 'Campaigns',
    text: 'Add campaigns to specify agent behaviour during ACW.',
    additionalText: [
      'A campaign is assigned to a routing profile, where any agent using the profile will be able to select from interaction outcomes added to the campaign during ACW.'
    ]
  })
];

export const desktopSteps = {
  [PAGE_ROUTES.INTERACTION_HISTORY]: stepsForInteractionHistory,
  [PAGE_ROUTES.DASHBOARDS]: stepsForDashboards,
  [PAGE_ROUTES.WALLBOARD]: stepsForWallboard,
  [PAGE_ROUTES.USER_SETTINGS]: stepsForUserSettings,
  [PAGE_ROUTES.METRICS_SETTINGS]: stepsForMetricsSettings,
  [PAGE_ROUTES.OUTCOME_SETTINGS]: stepsForCallOutcomes,
  [PAGE_ROUTES.CAMPAIGN_SETTINGS]: stepsForCampaignSettings
};
