export default async function getMaxContactsForQueues({ fetch_, config, queueIds }) {
  try {
    const params = new URLSearchParams({ queueIds: queueIds.join(',') });
    const maxContactsResponse = await fetch_(
      `${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/max-contacts?${params}`,
      { method: 'GET' }
    );

    return maxContactsResponse.json();
  } catch (e) {
    return {};
  }
}
