import useTaskTimer from 'lib/common/hooks/useTaskTimer';

interface ITimerProps {
  time: Date;
  prefix?: string;
}

export default function ConnectionTimer({ time, prefix }: ITimerProps) {
  const taskTime = useTaskTimer(time);

  return (
    <div className="mb-20">
      {prefix ? `${prefix} ` : ''}
      {taskTime}
    </div>
  );
}
