import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import TUser from 'lib/common/types/User';
import { Icon, UserProfile } from 'lib/common/components';

import './no-tasks-placeholder.scss';

type TNoTasksPlaceholder = {
  user: TUser;
};

function getPlaceholderText({ isBusyFromConnect, agentPresence }) {
  if (agentPresence === PRESENCE_STATES.AVAILABLE || isBusyFromConnect) {
    return 'You are ready to start receiving tasks.';
  }

  return 'Change your user status to start receiving tasks.';
}

export default function NoTasksPlaceholder({ user }: TNoTasksPlaceholder) {
  const { agentPresence, isBusyFromConnect } = usePresenceContext();

  return (
    <div className="no-tasks-placeholder">
      <Icon icon={faLayerGroup} size={60} />
      <div className="no-tasks-placeholder__text">
        <h2>No Active Tasks</h2>
        <p>{getPlaceholderText({ agentPresence, isBusyFromConnect })}</p>
      </div>
      <UserProfile user={user} />
    </div>
  );
}
