import { useEffect } from 'react';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import connectGetter from 'lib/common/utils/connectGetter';
import getTaskFromContact from '../utils/getTaskFromContact';
import getContactChatSession from '../utils/getContactChatSession';
import registerChatHandlers from '../utils/registerChatHandlers';
import ChatTask from '../../../types/ChatTask';
import Task from '../../../types/Task';
import getContactConnection from '../../../utils/getContactConnection';
import endTaskHelper from '../utils/endTaskHelper';
import endThirdPartyConnectionHelper from '../utils/endThirdPartyConnectionHelper';
import getInitialChatMessages from '../utils/getInitialChatMessages';

export default function useInitialSetup({ setTasks, searchForProfile, handleContactChange }) {
  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.agent(async (agent) => {
      const existingContacts: connect.Contact[] = connectGetter('getContacts', agent) || [];

      if (!existingContacts?.length) {
        setTasks([]);
        return;
      }

      const tasks = await Promise.all(
        existingContacts.map(async (contact): Promise<Task | ChatTask | undefined> => {
          searchForProfile(contact);

          const task = getTaskFromContact(contact);

          const chatSession = await getContactChatSession(contact);

          /**
           * If call is connected, on page refresh set to a disconnected connection state
           */
          if (
            (task.type === CONTACT_TYPES.CALL || task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
            task.status === CONTACT_STATES.CONNECTED
          ) {
            try {
              const contactConnection = getContactConnection(contact);
              if (!contactConnection) {
                return;
              }

              await endTaskHelper(contact, handleContactChange);

              // Scenario where a third party/agent connection is active but initial connection is disconnected
              await endThirdPartyConnectionHelper(contact);

              return {
                ...task,
                status: CONTACT_STATES.ACW,
                connectionState: CONNECTION_STATES.DISCONNECTED
              };
            } catch {
              console.log('Failed to kill initial call');
              return;
            }
          }

          if (!chatSession) {
            return task as Task;
          }

          registerChatHandlers({ session: chatSession, taskId: task.taskId, handleContactChange });

          return {
            ...task,
            chatSession,
            messages: await getInitialChatMessages(chatSession),
            customerTyping: false
          } as ChatTask;
        })
      );

      setTasks(tasks.filter(Boolean));
    });
  }, []);
}
