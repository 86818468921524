import { ValueType } from 'react-select';

import QUEUE_ENDPOINT_TYPES from 'lib/common/constants/queueEndpointTypes';
import { Label, ReactSelect } from 'lib/common/components';

import './transfer-to-queue.scss';

interface ITransferQueue {
  connectionValue: string;
  endpoints: any;
  selectedEndpoint: any;
  setSelectedEndpoint: any;
}

interface Queue {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: ValueOf<typeof QUEUE_ENDPOINT_TYPES>;
}

const TransferToQueue = ({ connectionValue, endpoints, selectedEndpoint, setSelectedEndpoint }: ITransferQueue) => (
  <>
    <p className="transfer-to-queue__agent-transfer-info">
      <span>We'll transfer</span>
      <strong>{connectionValue}</strong>
      <span>to the new queue for another agent to accept</span>
    </p>
    <Label id="transfer-queue" text="Queue">
      <ReactSelect
        id="transfer-queue-select"
        name="transfer-queue"
        isSearchable={false}
        placeholder="Select a queue..."
        options={endpoints}
        className="select__control__presence-states"
        classNamePrefix="select"
        onChange={(selected) => setSelectedEndpoint(selected as ValueType<{ value: Queue; label: string }, false>)}
        value={selectedEndpoint}
        aria-label="transfer-queue-select"
      />
    </Label>
  </>
);

export default TransferToQueue;
