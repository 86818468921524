import { ChangeEvent, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Button, ClickableIcon, Icon, Select } from 'lib/common/components';
import { Label, Toggle } from 'lib/common/components';
import '../styles/editWidget.scss';

function buildWidgetThreshold(type, widget, componentJson) {
  return {
    ...componentJson.threshold[type],
    enabled: widget[`${type}ThresholdState`],
    value: widget[`${type}Threshold`],
    calc: widget[`${type}ThresholdCalculation`]
  };
}

function buildComponentJson(widget, componentJson, queues) {
  return {
    ...componentJson,
    queueid: widget.queueSelect,
    dateRange: {
      active: componentJson.dateRange.active,
      dateRangeValue: widget.dateRangeValue,
      dateRangeUnit: widget.dateRange
    },
    threshold: {
      active: componentJson.threshold.active,
      warning: buildWidgetThreshold('warning', widget, componentJson),
      alert: buildWidgetThreshold('alert', widget, componentJson)
    },
    filter: widget.filterValue,
    queueName: queues.find((queue) => queue.objectId === widget.queueSelect)?.name
  };
}

export default function EditWidget(props: any) {
  const {
    widgetIndex,
    componentjson,
    componentjson: { filterable, filterValues, dateRange, filter, queueid, threshold },
    changeWidget,
    queues,
    onClose
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [widget, setWidget] = useState({
    dateRangeValue: dateRange.dateRangeValue,
    dateRange: dateRange.dateRangeUnit,
    queueSelect: queueid,
    warningThreshold: threshold.warning.value,
    alertThreshold: threshold.alert.value,
    warningThresholdState: threshold.warning.enabled,
    alertThresholdState: threshold.alert.enabled,
    warningThresholdCalculation: threshold.warning.calc,
    alertThresholdCalculation: threshold.alert.calc,
    filterValue: filter
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    onClose();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : void 0;

  const updateWidgetValue = (widgetKey) => (event: ChangeEvent<HTMLInputElement | { value: unknown | string }>) => {
    setWidget({ ...widget, [widgetKey]: event.target.value });
  };

  const onToggleThreshold = (widgetKey) => (checked) => {
    setWidget({ ...widget, [widgetKey]: checked });
  };

  const handlePress = (e) => {
    e.stopPropagation();
  };

  const submitDB = () => {
    changeWidget(buildComponentJson(widget, componentjson, queues));

    handleClose();
  };

  const deleteWidget = () => {
    changeWidget({ delete: true, index: widgetIndex });
    setAnchorEl(null);
  };

  return (
    <div onMouseDown={handlePress}>
      <ClickableIcon
        icon={faEdit}
        color="primary"
        className="widget-menu-icon"
        onClick={handleClick}
        data-testid="widget-menu-btn"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className="edit-widget"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: { minWidth: 300 }
        }}
      >
        <h2 className="edit-widget__title mb-30">
          Edit Widget{' '}
          <ClickableIcon data-testid="delete-widget-btn" onClick={deleteWidget} color="danger" icon={faTrash} />
        </h2>
        {!filterable && (
          <div className="edit-widget-item">
            <FormControl>
              <TextField
                select
                variant="outlined"
                label="Metrics Group"
                id="queue-select"
                value={!queues.length ? 'empty' : widget.queueSelect}
                onChange={updateWidgetValue('queueSelect')}
                disabled={!queues.length}
              >
                {!queues.length && (
                  <MenuItem key="empty" value="empty">
                    No other groups available
                  </MenuItem>
                )}
                {queues.map(({ name, objectId }) => (
                  <MenuItem key={objectId} value={objectId}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </div>
        )}
        {filterable && (
          <div className="edit-widget-item">
            <FormControl>
              <Select
                label="Show"
                id="queue-select"
                value={widget.filterValue}
                onChange={updateWidgetValue('filterValue')}
              >
                {filterValues.map((filter, index) => (
                  <MenuItem key={filterValues[index]} value={filterValues[index]}>
                    {filterValues[index]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        {dateRange.active && (
          <div className="edit-widget-item">
            <div className="edit-widget-item__row edit-widget-item__date-range-row">
              <TextField
                variant="outlined"
                label="Range"
                onChange={updateWidgetValue('dateRangeValue')}
                value={widget.dateRangeValue}
                inputProps={{ 'data-testid': 'edit-widget-item__row-range' }}
              />
              <Select id="date-range-selector" value={widget.dateRange} onChange={updateWidgetValue('dateRange')}>
                <MenuItem key="mins" value="mins">
                  Minutes
                </MenuItem>
                <MenuItem key="days" value="days">
                  Previous Days
                </MenuItem>
              </Select>
            </div>
          </div>
        )}
        {threshold.active && (
          <div className="edit-widget-item">
            <Label id="edit-widget-label" text="Warning Threshold">
              <div className="edit-widget-item__row edit-widget-item__threshold-row">
                <Toggle checked={widget.warningThresholdState} onChange={onToggleThreshold('warningThresholdState')} />
                <Select
                  id="warning-calc-selector"
                  disabled={!widget.warningThresholdState}
                  value={widget.warningThresholdCalculation}
                  onChange={updateWidgetValue('warningThresholdCalculation')}
                >
                  <MenuItem key="gte" value="gte">
                    Greater than
                  </MenuItem>
                  <MenuItem key="lte" value="lte">
                    Less than
                  </MenuItem>
                </Select>
                <TextField
                  variant="outlined"
                  id="the-warning-threshold"
                  onChange={updateWidgetValue('warningThreshold')}
                  disabled={!widget.warningThresholdState}
                  value={widget.warningThreshold}
                />
              </div>
            </Label>
            <Label id="edit-widget-label" text="Alert Threshold" className="mt-20">
              <div className="edit-widget-item__row edit-widget-item__threshold-row">
                <Toggle checked={widget.alertThresholdState} onChange={onToggleThreshold('alertThresholdState')} />
                <Select
                  id="alert-calc-selector"
                  value={widget.alertThresholdCalculation}
                  disabled={!widget.alertThresholdState}
                  onChange={updateWidgetValue('alertThresholdCalculation')}
                >
                  <MenuItem key="gte" value="gte">
                    Greater than
                  </MenuItem>
                  <MenuItem key="lte" value="lte">
                    Less than
                  </MenuItem>
                </Select>
                <TextField
                  variant="outlined"
                  id="the-alert-threshold"
                  onChange={updateWidgetValue('alertThreshold')}
                  value={widget.alertThreshold}
                  disabled={!widget.alertThresholdState}
                />
              </div>
            </Label>
          </div>
        )}
        <div className="edit-widget-button-array">
          <Button small styleType="SECONDARY" onClick={handleClose}>
            Cancel
          </Button>
          <Button small onClick={submitDB} disabled={!widget.queueSelect}>
            Save
          </Button>
        </div>
      </Popover>
    </div>
  );
}
