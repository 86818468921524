import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import _sortBy from 'lodash.sortby';
import { faChartLine, faChartPie, faHashtag, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ListItemIcon } from '@material-ui/core';
import widgets from 'lib/core/config/widgets.json';
import '../styles/select-widget.scss';
import '../styles/searchTextField.scss';

interface IProps {
  addWidget: any;
  closeModals: any;
  classes: any;
}

interface IStates {
  displayedWidgets: any;
  menuOpen: boolean;
}

interface ISelectWidget {
  [key: string]: {
    id: number;
    name: string;
    visualisations: string[];
    query: string;
    element: string;
    processor: string;
    threshold: boolean;
    dateRange: boolean;
    filterable: boolean;
    filterValues?: string[];
    filter?: string;
    Description: string;
    navigation?: string;
  };
}

const VISUALISATION_ICON_MAP = {
  count: faHashtag,
  linegraph: faChartLine,
  pieGraph: faChartPie
};

const StyledMenu = withStyles({})((props: MenuProps) => (
  <Menu
    anchorEl={document.querySelector('#action-menu')}
    elevation={0}
    PaperProps={{
      style: {
        height: 400,
        maxHeight: 400,
        minWidth: 350,
        width: 350
      }
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    getContentAnchorEl={null}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {}
}))(MenuItem);

const Search = withStyles(() => ({ root: {} }))(TextField);

const styles = {};

function convertToArray(object: ISelectWidget) {
  return Object.values(object).map((value) => value);
}

class SelectWidget extends React.Component<IProps, IStates> {
  constructor(props) {
    super(props);

    this.state = {
      displayedWidgets: _sortBy(convertToArray(widgets as ISelectWidget), 'name'),
      menuOpen: true
    };
  }

  searchFilter = (event) => {
    event.preventDefault();

    const query = event.target.value.toLowerCase();
    const widgetArray = convertToArray(widgets);

    this.setState({
      displayedWidgets: _sortBy(
        widgetArray.filter(({ name }) => name.toLowerCase().includes(query)),
        'name'
      )
    });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const { myValue } = event.currentTarget.dataset;

    this.props.addWidget(myValue);

    this.setState({ menuOpen: false });
  };

  handleClose = () => {
    this.setState({ menuOpen: false });

    this.props.closeModals();
  };

  handleSearchFocus = (inputEl: null | HTMLInputElement) => {
    if (!inputEl) {
      return;
    }

    setTimeout(() => {
      inputEl.focus();
    }, 100);
  };

  stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="action-menu select-widget">
        <StyledMenu
          data-testid="select-widget-menu"
          id="customized-menu"
          keepMounted
          open={this.state.menuOpen}
          onClose={this.handleClose}
        >
          <MenuItem onClickCapture={this.stopImmediatePropagation} onKeyDown={(e) => e.stopPropagation()} key="search">
            <Search
              variant="outlined"
              inputRef={this.handleSearchFocus}
              className="input-search-box"
              onChange={this.searchFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                ),
                className: classes.input
              }}
              id="standard-full-width"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="Search widgets"
            />
          </MenuItem>
          {!this.state.displayedWidgets.length && (
            <p className="select-widget__empty">There aren't any widgets matching that search.</p>
          )}
          {Object.keys(this.state.displayedWidgets).map((widgetId) => (
            <Tooltip title={this.state.displayedWidgets[widgetId].Description} placement="right" key={widgetId}>
              <StyledMenuItem
                onClick={this.handleClick}
                key={this.state.displayedWidgets[widgetId].id}
                data-my-value={this.state.displayedWidgets[widgetId].id}
              >
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={VISUALISATION_ICON_MAP[this.state.displayedWidgets[widgetId].visualisations[0]]}
                  />
                </ListItemIcon>
                <ListItemText primary={this.state.displayedWidgets[widgetId].name} />
              </StyledMenuItem>
            </Tooltip>
          ))}
        </StyledMenu>
      </div>
    );
  }
}

export default withStyles(styles)(SelectWidget);
