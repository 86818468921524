import { Backdrop, Fade, Modal as MUIModal } from '@material-ui/core';

import { Loader, Text } from 'lib/common/components';
import SIGN_OUT_TYPES from '../../constants/signOutTypes';

import './signing-out-overlay.scss';

interface ISigningOutOverlay {
  type: ValueOf<typeof SIGN_OUT_TYPES>;
}

const SIGN_OUT_CODES = {
  [SIGN_OUT_TYPES.MANUAL_SIGN_OUT]: 'C1',
  [SIGN_OUT_TYPES.ACK_TIMEOUT]: 'C2',
  [SIGN_OUT_TYPES.AUTH_FAIL]: 'C3'
};

const SIGN_OUT_TITLES = {
  [SIGN_OUT_TYPES.MANUAL_SIGN_OUT]: "We're signing you out.",
  [SIGN_OUT_TYPES.ACK_TIMEOUT]: "Your session has expired. We'll get you to sign in again.",
  [SIGN_OUT_TYPES.AUTH_FAIL]: "Your session has expired. We'll get you to sign in again."
};

const SIGN_OUT_SUBTITLES = {
  [SIGN_OUT_TYPES.ACK_TIMEOUT]: "We recommend logging out or closing NEON whenever you're finished for the day.",
  [SIGN_OUT_TYPES.AUTH_FAIL]: "We recommend logging out or closing NEON whenever you're finished for the day."
};

export default function SigningOutOverlay({ type }: ISigningOutOverlay) {
  const title = SIGN_OUT_TITLES[type];
  const subtitle = SIGN_OUT_SUBTITLES[type];

  return (
    <MUIModal open className="cw-modal" BackdropProps={{ timeout: 500 }} BackdropComponent={Backdrop}>
      <Fade in={Boolean(open)}>
        <div className="signing-out">
          <Text className="signing-out__type-code">{SIGN_OUT_CODES[type]}</Text>
          <Text type="heading1">{title}</Text>
          <Text className="mt-20">
            {subtitle || ''} Keep seeing this? Try manually{' '}
            <strong>
              <a href={window.location.origin}>reloading the page</a>
            </strong>
            .
          </Text>
          <Loader relative />
        </div>
      </Fade>
    </MUIModal>
  );
}
