import getUserName from 'lib/common/utils/getUserName';
import { TaskFooter } from 'lib/common/components/molecules';
import TTask from 'lib/common/types/Task';
import { Accordion } from 'lib/common/components';
import { TaskReferences } from 'lib/common/components/atoms';
import TUser from 'lib/common/types/User';

import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';

interface IAgentTaskProps extends TTask {
  user: TUser;
}

const AgentTask = (props: IAgentTaskProps) => {
  const { profile, queueName, time, connectionValue, taskId, description, contact, user } = props;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={name} id={name} />
      </InfoPanel>
      <Accordion title="Description" className="mt-10">
        <p>{description || "This task doesn't have a description."}</p>
      </Accordion>
      <TaskReferences contact={contact} isSoftphone />
      <AttachedData task={props} />
      <TaskFooter.AgentTask taskId={taskId} connectionValue={connectionValue} user={user} isSoftphone />
    </>
  );
};

export default AgentTask;
