import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { GithubPicker } from 'react-color';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';
import { Modal } from 'lib/common/components';
import DASHBOARD_COLOURS from '../constants/dashboardBgColours';
import '../styles/dashboard-action.scss';

export default function AddDB(props: any) {
  const { editDB, curData } = props;
  const { theme } = curData;
  const [title, setTitle] = React.useState(curData.name);
  const [backgroundColor, setbackgroundColor] = React.useState(curData.backgroundcolor);
  const [description, setDescription] = React.useState<string>();

  const titleChange = (event) => {
    setTitle(event.target.value as string);
  };

  const descriptionChange = (event) => {
    setDescription(event.target.value as string);
  };

  const handleChangeColor = (color) => {
    setbackgroundColor(color.hex);
  };

  const submitDB = () => {
    if (!title) {
      return;
    }
    const trimmedTitle = title?.trim();

    curData.name = trimmedTitle;
    curData.theme = theme;
    curData.backgroundcolor = backgroundColor;
    curData.description = description;

    const tempData = { status: true, data: curData };

    return editDB(tempData);
  };

  const cancelDB = () => {
    editDB(curData);
  };

  return (
    <Modal
      open
      contentTopPadded
      title="Edit Dashboard"
      onSave={submitDB}
      onClose={cancelDB}
      saveDisabled={!title?.trim()}
    >
      <div className="dashboard-action ">
        <TextField
          id="name"
          label="Name"
          onChange={titleChange}
          value={title}
          onKeyPress={onEnterKeyPressed(submitDB)}
          variant="outlined"
          data-testid="edit-dashboard-name"
        />
      </div>

      <Typography className="edit-widget-label">Theme</Typography>

      <div className="dashboard-action ">
        <GithubPicker
          colors={DASHBOARD_COLOURS}
          color={backgroundColor}
          onChangeComplete={handleChangeColor}
          width="100%"
          triangle="hide"
        />
      </div>
      <div className="dashboard-action ">
        <TextField
          id="description"
          label="Description"
          onChange={descriptionChange}
          value={description}
          variant="outlined"
          data-testid="edit-dashboard-description"
        />
      </div>
    </Modal>
  );
}
