import { useState, ReactElement, cloneElement } from 'react';
import cx from 'classnames';
import './segmented-controls.scss';

type TSegmentedControlsOption = {
  label: string | ReactElement;
  value?: string;
};

type TSegmentedControls = {
  options: TSegmentedControlsOption[];
  initialIndex?: number;
  onSelect?: (option) => void;
  square?: boolean;
  selectedIndex?: number;
};

function SegmentedControlsOption({
  label,
  selected,
  onClick
}: TSegmentedControlsOption & { selected: boolean; onClick?: (option) => void }) {
  return (
    <button
      className={cx('segmented-controls__option', { 'segmented-controls__option--selected': selected })}
      onClick={selected ? void 0 : onClick}
      data-testid={`segmented-control-option-${label}`}
    >
      {typeof label === 'string' ? label : cloneElement(label, { selected })}
    </button>
  );
}

export default function SegmentedControls({
  options,
  onSelect,
  initialIndex = 0,
  square,
  selectedIndex
}: TSegmentedControls) {
  const [_selectedIndex, setSelectedIndex] = useState(initialIndex);

  const handleSelect = (index) => () => {
    setSelectedIndex(index);

    onSelect?.(options[index].value ?? options[index].label);
  };

  return (
    <div className={cx('segmented-controls', { 'segmented-controls--square': square })}>
      {options.map((option, index) => (
        <SegmentedControlsOption
          key={option.value}
          selected={selectedIndex !== void 0 ? selectedIndex === index : _selectedIndex === index}
          onClick={handleSelect(index)}
          {...option}
        />
      ))}
    </div>
  );
}
