import { useEffect } from 'react';
import { format } from 'date-fns';
import sizeMe from 'react-sizeme';

import { faComments } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components';
import { ChatMessage } from 'lib/common/components/atoms';

import styles from './chat-messages.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_WIDTH_PX = 400;
const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy';

type TChatMessages = {
  customerName: string;
  messages: connect.ChatTranscriptItem[];
  size: { width: number };
  customerTyping?: boolean;
  showEmptyPlaceholder?: boolean;
  scrollContainerRef?: React.RefObject<HTMLDivElement> | null;
};

const ChatMessages = ({
  customerName,
  messages,
  showEmptyPlaceholder,
  size: { width },
  customerTyping,
  scrollContainerRef
}: TChatMessages) => {
  const isSmall = width <= SMALL_WIDTH_PX;
  const today = format(new Date(), DISPLAY_DATE_FORMAT);

  const sortedMessages = messages.reduce((sorted, message) => {
    const date = format(new Date(message.AbsoluteTime), DISPLAY_DATE_FORMAT);

    if (!sorted[date]) {
      return { ...sorted, [date]: [message] };
    }

    return { ...sorted, [date]: [...sorted[date], message] };
  }, {});

  const scrollToBottom = () => {
    scrollContainerRef?.current?.scrollTo({
      top: scrollContainerRef?.current?.scrollHeight
    });
  };

  useEffect(scrollToBottom, [messages, customerTyping]);

  return (
    <div className={styles['chat-messages__container']} ref={scrollContainerRef}>
      <div className={styles['chat-messages__messages']}>
        <div className={styles['chat-messages__start']}>
          <Icon icon={faComments} size={25} />
          <p>
            {showEmptyPlaceholder && !messages.length
              ? `There weren't any chat messages exchanged.`
              : `Conversation started with ${customerName}`}
          </p>
        </div>
        {Object.entries(sortedMessages).map(([date, messagesForDate]: any) => (
          <div className={styles['chat-messages__messages__block']} key={date}>
            <div className={styles['chat-messages__messages__block__date']}>
              <span />
              <p>{today === date ? 'TODAY' : date}</p>
              <span />
            </div>
            {messagesForDate.map((message, index) => (
              <ChatMessage
                key={message.Id}
                message={message}
                customerName={customerName}
                lastMessageOfRole={
                  (!messagesForDate[index + 1] ||
                    messagesForDate[index + 1].ParticipantRole !== message.ParticipantRole) &&
                  (!customerTyping || message.ParticipantRole === 'AGENT')
                }
                isSmall={isSmall}
                invert
              />
            ))}
            {customerTyping && <ChatMessage key="typing" customerName={customerName} lastMessageOfRole invert />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default sizeMeHOC(ChatMessages);
