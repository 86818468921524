const touchtone = require('touchtone');
const webaudio = require('webaudio');

const touchTone = touchtone({
  duration: 0.25,
  volume: 0.1
});

const webAudio = webaudio(touchTone.play());

export function onKeyPress(digit: string) {
  touchTone.press(digit);
  webAudio.play();
}

export function onDial(digits: string) {
  touchTone.dial(digits);
  webAudio.play();
}
