import { createElement } from 'react';
import { Position } from '@reactour/popover';
import { MaskStylesObj } from '@reactour/mask';

type TStepParams = {
  selector: string;
  title: string;
  text: string;
  additionalText?: string[];
  titleTag?: 'h3' | 'h2';
  position?: Position;
  styles?: MaskStylesObj;
  isPage?: boolean;
};

export default function getStep({
  selector,
  title,
  text,
  additionalText = [],
  position,
  styles,
  titleTag = 'h3',
  isPage = false
}: TStepParams) {
  return {
    selector,
    content: createElement(
      'div',
      { className: 'reactour__content-wrapper' },
      createElement(titleTag, null, title),
      createElement('p', { className: 'reactour__paragraph' }, text),
      ...additionalText.map((additionalTextItem) =>
        createElement('p', { className: 'reactour__paragraph--additional' }, additionalTextItem)
      )
    ),
    position: position || 'right',
    styles: {
      ...styles,
      maskWrapper: (base) => ({
        ...base,
        ...styles?.maskWrapper,
        ...(isPage ? { color: 'transparent' } : {})
      })
    }
  };
}
