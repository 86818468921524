type TSearchOptions = {
  config: any;
  fetch_: (url, options) => Promise<any>;
  profileId: string;
};

export default async function deleteCustomerProfile({ config, fetch_, profileId }: TSearchOptions): Promise<void> {
  return fetch_(`${config['AGENT_SERVICE_HOST']}/connect/${process.env.REACT_APP_TENANT_ID}/contacts/${profileId}`, {
    method: 'DELETE'
  });
}
