import cx from 'classnames';
import withCharacterLimit from 'lib/common/utils/withCharacterLimit';
import { TextField } from '@material-ui/core';

import '../styles/create-agent-task.scss';

export default function TaskInformation({ name, setName, description, setDescription, isSoftphone }) {
  return (
    <div
      className={cx('create-agent-task', {
        'create-agent-task--padding': isSoftphone
      })}
    >
      <div className="create-agent-task__text-field">
        <TextField
          variant="outlined"
          label="Name"
          required
          fullWidth
          value={name}
          data-testid="create-task-name-input"
          onChange={(e) => setName(withCharacterLimit(150, e.target.value))}
        />
      </div>
      <div className="create-agent-task__text-field">
        <TextField
          variant="outlined"
          label="Description"
          fullWidth
          value={description}
          multiline
          maxRows={3}
          onChange={(e) => setDescription(withCharacterLimit(4096, e.target.value))}
        />
      </div>
    </div>
  );
}
