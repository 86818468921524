import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { Loader } from 'lib/common/components';
import getTaskContentType from 'lib/common/utils/getTaskContentType';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';
import TChatTask from 'lib/common/types/ChatTask';
import NeglectedTask from './components/NeglectedTask';
import ChatTask from './components/ChatTask';
import TimedTask from './components/TimedTask';
import AgentTask from './components/AgentTask';
import AfterContactWork from './components/AfterContactWork';

import styles from './task.module.scss';
import IncomingTask from './components/IncomingTask';

export default function Task({ user }) {
  const {
    actions: { setSelectedTaskId },
    state: { selectedTaskId, tasks }
  } = useContactContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (selectedTaskId && tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  useEffect(() => {
    // Only show the landing page when we're on the last task and it's removed
    if (tasks.length > 0 || location.pathname !== SOFTPHONE_PAGE_ROUTES.TASK) {
      return;
    }

    history.replace(SOFTPHONE_PAGE_ROUTES.LANDING);
  }, [tasks?.length]);

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  if (!selectedTask) {
    return null;
  }

  const getTaskContent = () => {
    const taskContentType = getTaskContentType({ selectedTask, isSoftphone: true });

    if (taskContentType === TASK_CONTENT_TYPES.AFTER_CONTACT_WORK) {
      return <AfterContactWork {...selectedTask} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.INCOMING_TASK) {
      return <IncomingTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.AGENT_TASK) {
      return <AgentTask {...selectedTask} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.CHAT_TASK) {
      return <ChatTask {...(selectedTask as TChatTask)} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.NEGLECTED_TASK) {
      return <NeglectedTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.TIMED_TASK) {
      return <TimedTask {...selectedTask} user={user} />;
    }
  };

  return (
    <div className={styles['softphone-task']}>
      {getTaskContent()}
      {selectedTask.status === CONTACT_STATES.ACCEPTED && <Loader className="mt-auto mb-auto" relative />}
    </div>
  );
}
