import React, { createContext, useState, useEffect } from 'react';
import DEFAULT_CONFIG from './defaultConfig';

type Props = {
  children: React.ReactNode;
};

const configObj: any = {
  AGENT_SERVICE_HOST: '',
  CALL_SERVICE_HOST: '',
  COGNITO_USER_POOL_ARN: '',
  COGNITO_CLIENT_ID: '',
  CONNECT_HOST: '',
  ELASTICSEARCH_HOST: '',
  ELASTICSEARCH_INDEX: '',
  MODULES_HOST: DEFAULT_CONFIG.MODULES_HOST,
  MODULES_S3DOMAIN: DEFAULT_CONFIG.MODULES_S3DOMAIN,
  PRESENCE_URL: DEFAULT_CONFIG.PRESENCE_URL,
  STAGE: DEFAULT_CONFIG.STAGE,
  TENANT_ID: DEFAULT_CONFIG.TENANT_ID
};

const getOverrides = (baseConfig: { [key: string]: string }) => ({
  AGENT_SERVICE_HOST: process.env.REACT_APP_AGENT_SERVICE_HOST || baseConfig.AGENT_SERVICE_HOST
});

export const ConfigContext = createContext(configObj);

const isDevEnv = process.env.REACT_APP_STAGE === 'dev';

const ConfigProvider = ({ children }: Props) => {
  const [config, setConfig] = useState(configObj);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    const initConfig = async () => {
      try {
        const cfgJson = await fetch(`${DEFAULT_CONFIG.CONFIG_API}/cfg/unsecure/${config.TENANT_ID}/${config.STAGE}`);
        const cfg = await cfgJson.json();
        const baseConfig = { ...config, ...cfg };
        const overrides = getOverrides(baseConfig);

        setConfig({
          ...baseConfig,
          ...(isDevEnv ? overrides : {})
        });
        setConfigLoaded(true);
      } catch (e) {
        console.log('Error fetching config: ', e);
        setConfigLoaded(false);
      }
    };
    initConfig();
  }, []);

  return configLoaded ? (
    <ConfigContext.Provider value={{ config, configLoaded }}>{children}</ConfigContext.Provider>
  ) : null;
};

export default ConfigProvider;
