import { faUser } from '@fortawesome/pro-regular-svg-icons';

import getUserName from 'lib/common/utils/getUserName';
import TTask from 'lib/common/types/Task';
import { TaskFooter } from 'lib/common/components/molecules';
import connectGetter from 'lib/common/utils/connectGetter';
import useTaskConnections from 'lib/common/hooks/useTaskConnections';
import isActionableCallTask from 'lib/common/utils/tasks/isActionableCallContact';
import { useSoftphoneLayout } from 'lib/common/contexts/SoftphoneLayoutContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import taskActionWithCatch from 'lib/common/utils/taskActionWithCatch';
import optional from 'lib/common/utils/optional';
import getConferenceActions from 'lib/common/utils/getConferenceActions';
import { ConferenceDisconnectButton } from 'lib/common/components/atoms';

import AttachedData from '../AttachedData';
import InfoPanel from '../InfoPanel';
import ConferenceUserRow from './components/ConferenceUserRow';

interface ITimedTaskProps extends TTask {
  user: any;
}

const SMALL_WIDTH_PX = 250;

export default function TimedTask(props: ITimedTaskProps) {
  const { contentWidth } = useSoftphoneLayout();
  const {
    isNeglected,
    isConference,
    isConferenceConnecting,
    allConnectionsOnHold,
    allConnectionsConnected,
    initialConnectionDisconnected,
    thirdPartyConnectionsDisconnected,
    callIsOnHold
  } = useTaskConnections(props);
  const {
    actions: { holdCall, resumeCall, endTask }
  } = useContactContext();
  const { profile, queueName, time, connectionValue, type, taskId, connectionState, contact, status, user } = props;

  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const isSmallWidth = contentWidth < SMALL_WIDTH_PX;
  const canOnlyDisconnect = isConference && !allConnectionsConnected && !allConnectionsOnHold;

  const onDisconnectMainTask = () => endTask(taskId);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row
          text={name}
          id={name}
          icon={optional(isConference, faUser)}
          badgeProps={{
            callIsOnHold,
            isNeglected,
            isConferenceConnecting,
            status
          }}
          suffix={optional(canOnlyDisconnect, <ConferenceDisconnectButton onDisconnect={onDisconnectMainTask} />)}
          actions={optional(
            isConference && !canOnlyDisconnect,
            getConferenceActions({
              onDisconnect: onDisconnectMainTask,
              allConnectionsConnected,
              holdConnection: taskActionWithCatch(holdCall, taskId),
              resumeConnection: taskActionWithCatch(resumeCall, taskId)
            })
          )}
        />
        <ConferenceUserRow
          isConference={isConference}
          taskId={taskId}
          isConferenceConnecting={isConferenceConnecting}
          thirdPartyConnectionsDisconnected={thirdPartyConnectionsDisconnected}
          allConnectionsConnected={allConnectionsConnected}
          contact={contact}
          status={status}
          canOnlyDisconnect={canOnlyDisconnect}
        />
      </InfoPanel>
      <AttachedData task={props} />
      {optional(
        isActionableCallTask({ type, status }),
        <TaskFooter.ConnectedCall
          inConference={isConference}
          connectionState={connectionState}
          taskId={taskId}
          connectionValue={connectionValue}
          thirdPartyConnection={connectGetter('getSingleActiveThirdPartyConnection', contact)}
          isConferenceConnecting={isConferenceConnecting}
          allConnectionsOnHold={allConnectionsOnHold}
          allConnectionsConnected={allConnectionsConnected}
          thirdPartyConnectionsDisconnected={thirdPartyConnectionsDisconnected}
          initialConnectionDisconnected={initialConnectionDisconnected}
          type={type}
          isSmallWidth={isSmallWidth}
          user={user}
          isSoftphone
        />
      )}
      {optional(isNeglected, <TaskFooter.ClearTask taskId={taskId} />)}
    </>
  );
}
