import { useMemo, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { LayoutProvider } from 'lib/common/contexts/SoftphoneLayoutContext';
import { SOFTPHONE_PAGE_CONFIGURATIONS } from 'lib/common/constants/softphonePageConfigurations';

import TUser from 'lib/common/types/User';

import NotificationBanner from './components/NotificationBanner';
import SoftphoneHeader from './components/SoftphoneHeader';
import SoftphoneSidebar from './components/SoftphoneSidebar';

import './softphone-layout.scss';

interface ISoftphoneLayoutProps {
  children: ReactNode;
  user: TUser;
  isReversedLayout: boolean;
}

export default function SoftphoneLayout({ children, user, isReversedLayout }: ISoftphoneLayoutProps) {
  const { pathname } = useLocation();
  const {
    state: { pageModules }
  } = useModulesContext();
  const [headerLoading, setHeaderLoading] = useState(false);

  const { pageTitle = '' } = useMemo(() => {
    const pageModulesNavOptions = pageModules
      ? pageModules?.reduce(
          (result, pageModule) => ({
            ...result,
            ...(pageModule?.pathName
              ? {
                  [pageModule.pathName]: {
                    pageName: pageModule.name || '',
                    pageTitle: pageModule.type === 'PAGE' ? pageModule.name : ''
                  }
                }
              : {})
          }),
          {}
        )
      : {};

    return SOFTPHONE_PAGE_CONFIGURATIONS[pathname] || pageModulesNavOptions[pathname] || {};
  }, [pathname]);

  return (
    <div className="softphone-layout">
      <SoftphoneHeader hasLoader={headerLoading} pageTitle={pageTitle} isReversedLayout={isReversedLayout} />
      <div className={cx('softphone-layout__content', { 'softphone-layout__content--reversed': isReversedLayout })}>
        <SoftphoneSidebar user={user} isReversedLayout={isReversedLayout} />
        <LayoutProvider setHeaderLoading={setHeaderLoading}>
          <div className="softphone-layout__content__container">
            <NotificationBanner />
            <div className="softphone-layout__content__container__children">{children}</div>
          </div>
        </LayoutProvider>
      </div>
    </div>
  );
}
