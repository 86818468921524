import {
  areThirdPartyConnectionsDisconnected,
  isInitialConnectionDisconnected
} from 'lib/common/utils/conferenceConnections';

import CONTACT_TYPE from 'lib/common/constants/contactTypes';

export default function getCallContactType(contact: connect.Contact) {
  if (isInitialConnectionDisconnected(contact) || areThirdPartyConnectionsDisconnected(contact)) {
    return CONTACT_TYPE.CALL;
  }

  return CONTACT_TYPE.CONFERENCE_CALL;
}
