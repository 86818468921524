import { useState } from 'react';
import { Button } from 'lib/common/components/index';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useHotkeys } from 'react-hotkeys-hook';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';

export default function MuteButton() {
  const {
    actions: { mute, unmute }
  } = useContactContext();
  const [muted, setMuted] = useState(false);

  const onToggleMute = () => {
    setMuted(!muted);

    if (!muted) {
      return void mute();
    }

    unmute();
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_MUTE, onToggleMute, [mute, unmute, muted, setMuted, onToggleMute]);

  return (
    <Button
      icon={muted ? 'faMicrophoneSlash' : 'faMicrophone'}
      onClick={onToggleMute}
      tooltip={muted ? 'Unmute microphone' : 'Mute microphone'}
      styleType={muted ? 'WARNING' : 'NEUTRAL'}
      round
    />
  );
}
