import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { Icon, Text } from 'lib/common/components';

import './error.scss';

export default function Error({ small }: { small: boolean }) {
  return (
    <div className={cx('module-error', { 'module-error--small': small })}>
      <div className="module-error__header">
        {!small && <Icon className="mr-10" icon={faCircleExclamation} color="danger" />}
        <Text bold>We couldn't load this widget</Text>
      </div>
      <Text>You can either wait for the dashboard to refresh, or try and reload the page.</Text>
    </div>
  );
}
