import { roundToNearestMinutes, startOfToday } from 'date-fns';
import getUTCDate from 'lib/common/utils/getUTCDate';
import { buildQueueStats, buildAllQueuesStats } from '../utils/getAgentStats';

export default async function getAgentMetrics({ fetch_, config, agentQueues }) {
  try {
    const startTime = getUTCDate(startOfToday()).toISOString();
    const nowInUTC = getUTCDate();
    const endTime = roundToNearestMinutes(nowInUTC, { nearestTo: 5 }).toISOString();

    const metricsRes = await fetch_(`${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/metrics`, {
      method: 'POST',
      body: JSON.stringify({
        StartTime: startTime,
        EndTime: endTime,
        Queues: agentQueues?.map(({ Id }) => Id),
        ServiceLevel: 60,
        Groupings: ['QUEUE']
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const { status: metrics } = await metricsRes.json();

    return {
      allQueuesStats: buildAllQueuesStats({ metrics }),
      queuesStats: buildQueueStats({ metrics, agentQueues })
    };
  } catch {
    return {};
  }
}
