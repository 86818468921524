import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';

import connectGetter from 'lib/common/utils/connectGetter';
import connectAction from 'lib/common/utils/connectAction';
import parseJSON from 'lib/common/utils/parseJSON';

import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { CONTACT_NOT_FOUND_EXCEPTION } from 'lib/common/constants/connectExceptions';

import {
  areThirdPartyConnectionsDisconnected,
  isConference,
  isInitialConnectionDisconnected
} from 'lib/common/utils/conferenceConnections';
import ChatTask from '../../../types/ChatTask';
import TTask from '../../../types/Task';

const getCallContact = (tasks: TTask[]) => {
  return tasks.find(
    (task) =>
      (task.type === CONTACT_TYPES.CALL ||
        task.type === CONTACT_TYPES.CONFERENCE_CALL ||
        task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
      task.status === CONTACT_STATES.CONNECTED
  )?.contact;
};

const useAgentActions = ({
  tasks,
  handleContactChange
}: {
  tasks: (TTask | ChatTask)[];
  handleContactChange: (any) => void;
}) => {
  const { agent } = useAgentContext();

  const destroyInitialConnection = async (contact) => {
    const activeInitialConnection = connectGetter('getActiveInitialConnection', contact);

    if (!activeInitialConnection) {
      throw new Error('Active initial connection not found');
    }

    await connectAction('destroy', activeInitialConnection);
  };

  const destroyAgentConnection = async (contact) => {
    const agentConnection = connectGetter('getAgentConnection', contact);

    if (!agentConnection) {
      throw new Error('Cannot find agent connection');
    }

    await connectAction('destroy', agentConnection);
  };

  const destroyThirdPartyConnection = async (connection) => {
    const thirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', connection);

    if (!thirdPartyConnection) {
      throw new Error('Cannot find third connection');
    }

    await connectAction('destroy', thirdPartyConnection);
  };

  const hangupActiveContact = async (contact) => {
    try {
      const inConference = isConference(contact);

      const initialConnectionDisconnected = isInitialConnectionDisconnected(contact);
      const thirdPartyConnectionsDisconnected = areThirdPartyConnectionsDisconnected(contact);
      const thirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', contact);

      // Not in conference or agent "was" in conference but third party connection was disconnected
      if (!inConference || (inConference && thirdPartyConnectionsDisconnected)) {
        await destroyInitialConnection(contact);
      }

      // Active conference where all parties are connected
      if (inConference && !initialConnectionDisconnected && !thirdPartyConnectionsDisconnected) {
        await destroyAgentConnection(contact);
      }

      // Agent was in conference but initial connection was disconnected
      if (inConference && initialConnectionDisconnected && thirdPartyConnection) {
        await destroyThirdPartyConnection(thirdPartyConnection);
      }

      await connectAction('clear', contact);
    } catch (e: any) {
      const { type } = parseJSON(e) || {};

      if (type === CONTACT_NOT_FOUND_EXCEPTION) {
        handleContactChange({ contact, isDestroy: true });
      }

      throw new Error(e);
    }
  };

  const makeOutboundCall = async (phoneNumber: string) => {
    const activeContact = getCallContact(tasks);

    if (!agent) {
      return Promise.resolve();
    }

    if (!activeContact) {
      return connectAction('connect', agent, connect.Endpoint.byPhoneNumber(phoneNumber));
    }

    try {
      await hangupActiveContact(activeContact);
      return connectAction('connect', agent, connect.Endpoint.byPhoneNumber(phoneNumber));
    } catch (e) {
      return;
    }
  };

  const onTransfer = async (phoneNumber: string) => {
    const activeContact = getCallContact(tasks);

    if (!activeContact) {
      return Promise.resolve();
    }

    handleContactChange({ contact: activeContact, connectionState: CONNECTION_STATES.HOLD });

    return connectAction('addConnection', activeContact, connect.Endpoint.byPhoneNumber(phoneNumber));
  };

  const mute = () => agent?.mute();

  const unmute = () => agent?.unmute();

  return { makeOutboundCall, onTransfer, mute, unmute };
};

export default useAgentActions;
