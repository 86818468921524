import { Component, ContextType } from 'react';
import {
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from 'lib/core/context/AuthProvider';
import TUser from 'lib/common/types/User';

import { Alert, DataTable, Badge, Loader, EmptyPlaceholder, Button, TourControl } from 'lib/common/components';
import ManageUsersModal from './ManageUsers';

type TProps = {
  hasDashboards: boolean;
  user: TUser;
};

interface TStates {
  loading: boolean;
  showManageUsers: boolean;
  users: any[];
  metricsGroups: any[];
  rowSelected: any;
  editMode: boolean;
  modalStatus: boolean;
  showAlert: boolean;
  alertSeverity: 'error' | 'info' | 'success' | 'warning' | undefined;
  alertMsg: string;
}

export default class Users extends Component<TProps, TStates> {
  static contextType = AuthContext;

  context!: ContextType<typeof AuthContext>;

  config: any;

  fetch_: any;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showManageUsers: false,
      users: [],
      metricsGroups: [],
      rowSelected: null,
      editMode: false,
      modalStatus: false,
      alertSeverity: 'success',
      alertMsg: '',
      showAlert: false
    };
  }

  toProperCase = (s: string) => {
    return s
      .split(' ')
      .map((elem) => {
        return elem
          .toLocaleLowerCase()
          .split('')
          .map((x, i) => {
            return i === 0 ? x.toUpperCase() : x;
          })
          .join('');
      })
      .join(' ');
  };

  updateGetUsersResponse = (result: any) => {
    return result.items.map((item) => {
      return Object.assign(item, {
        name: this.toProperCase(`${item.firstName || ''} ${item.lastName || ''}`),
        isNeonUser: Boolean(item.tenantId) && Boolean(item.uuid),
        role: item.role || '',
        metricsGroups: item.configBlob
          ? item.configBlob.metricsGroups.map((m) => {
              return { label: m.groupName, value: m.uuid };
            })
          : []
      });
    });
  };

  getRowSelected = (selected: any) => {
    const [objectId, objectKey] = selected.data;

    return this.state.users.filter((u) => u.objectKey === objectKey && u.objectId === objectId)[0];
  };

  UNSAFE_componentWillMount() {
    this.config = this.context.config;
    this.fetch_ = this.context.fetch_;
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    if (this.props.hasDashboards) {
      this.loadData();
    } else {
      this.getAllUsers();
    }
  }

  toggleAddUser = () => {
    this.setState({
      showManageUsers: !this.state.showManageUsers,
      rowSelected: null,
      editMode: false
    });
  };

  loadData = async () => {
    const getUsersUrl = `${this.config.AGENT_SERVICE_HOST}/agents?tenantId=${process.env.REACT_APP_TENANT_ID}&includeConnect=true&includeConfig=true`;
    const metricsGroupsUrl = `${this.config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/groups`;

    try {
      const responses = await Promise.all([this.fetch_(getUsersUrl), this.fetch_(metricsGroupsUrl)]);
      const results = await Promise.all(responses.map((r) => r.json()));

      this.setState(
        {
          users: this.updateGetUsersResponse(results[0]),
          metricsGroups: results[1].items || [],
          loading: false
        },
        () => {
          if (this.state.metricsGroups.length > 0) {
            const metricsGroups = this.state.metricsGroups.sort((a, b) => a.groupName.localeCompare(b.groupName));
            this.setState({
              metricsGroups
            });
          }
        }
      );
    } catch (e) {
      this.setState({
        loading: false,
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getAllUsers = async () => {
    const url = `${this.config.AGENT_SERVICE_HOST}/agents?tenantId=${process.env.REACT_APP_TENANT_ID}&includeConnect=true&includeConfig=true`;

    try {
      const res = await this.fetch_(url);
      const users = await res.json();

      this.setState({
        users: this.updateGetUsersResponse(users),
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false,
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getOptions() {
    return {
      filterType: 'checkbox',
      selectableRows: 'single',
      responsive: 'vertical',
      sortOrder: {
        name: 'objectKey',
        direction: 'asc'
      },
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: 10,
      rowsSelected: this.state.rowSelected,
      customToolbarSelect: (selectedRows, displayData) => {
        const selected = displayData[selectedRows.data[0].index];
        const rowSelected = this.getRowSelected(selected);
        return (
          <Grid container direction="column" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
              {!rowSelected.isNeonUser && (
                <Tooltip title="Add User">
                  <IconButton onClick={() => this.addUser(rowSelected)}>
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </IconButton>
                </Tooltip>
              )}
              {rowSelected.isNeonUser && (
                <Tooltip title="Edit User">
                  <IconButton onClick={() => this.editUser(rowSelected)}>
                    <FontAwesomeIcon icon={faEdit} size="sm" />
                  </IconButton>
                </Tooltip>
              )}
              {rowSelected.isNeonUser && (
                <Tooltip title="Delete User">
                  <IconButton onClick={() => this.deleteUser(rowSelected)}>
                    <FontAwesomeIcon icon={faTrash} color="red" size="sm" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
      downloadOptions: {
        filename: 'Neon Users.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: false
        }
      }
    };
  }

  handleClose(toDelete) {
    if (toDelete) {
      this.setState({ alertSeverity: undefined });

      const item = {
        tenantId: this.state.rowSelected.tenantId,
        uuid: this.state.rowSelected.uuid,
        objectKey: this.state.rowSelected.objectKey,
        objectId: this.state.rowSelected.objectId
      };
      const url = `${this.config.AGENT_SERVICE_HOST}/agent?type=agent`;

      this.fetch_(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify(item)
      })
        .then((res) => {
          if (res.status !== 200) {
            this.setState({
              alertMsg: 'There was an error deleting the user. Please try again.',
              alertSeverity: 'error',
              showAlert: true
            });
          } else {
            this.setState({
              alertMsg: 'User has been deleted successfully.',
              alertSeverity: 'success',
              showAlert: true
            });
          }
        })
        .catch(() => {
          const errMsg = 'There was an error deleting the user. Please try again.';
          this.setState({
            alertMsg: errMsg,
            alertSeverity: 'error',
            showAlert: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            if (this.state.alertSeverity === 'success') {
              this.getAllUsers();
            }
          }, 2000);
          setTimeout(() => {
            this.setState({
              alertSeverity: undefined,
              showAlert: false
            });
          }, 4000);
        });
    }
    this.setState({
      modalStatus: false
    });
  }

  deleteUser(rowSelected) {
    this.setState({
      modalStatus: true,
      rowSelected
    });
  }

  addUser(rowSelected) {
    this.setState({
      editMode: false,
      rowSelected,
      showManageUsers: true
    });
  }

  editUser(rowSelected) {
    this.setState({
      editMode: true,
      rowSelected,
      showManageUsers: true
    });
  }

  getColumns() {
    const columns: any = [
      {
        name: 'objectId',
        label: 'Object Id',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'objectKey',
        label: 'Object Key',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'objectKey',
        label: 'Login Name',
        options: {
          customBodyRender: (value) => <span>{value.split('__')[value.split('__').length - 1]}</span>,
          filterType: 'dropdown'
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          customBodyRender: (value) => <span>{value ? this.toProperCase(value) : ''}</span>,
          filterType: 'dropdown'
        }
      },
      {
        name: 'isNeonUser',
        label: 'Neon User',
        options: {
          customBodyRender: (value) => (
            <div>
              <Badge label={value ? 'Yes' : 'No'} type={value ? 'SUCCESS' : 'ERROR'} />
            </div>
          ),
          filterType: 'dropdown'
        }
      }
    ];

    if (this.props.hasDashboards) {
      columns.splice(columns.length - 1, 0, {
        name: 'uMetricsGroups',
        label: 'Metrics Groups',
        options: {
          filter: false
        }
      });

      columns.splice(columns.length - 1, 0, {
        name: 'metricsGroups',
        label: 'Metrics Groups',
        options: {
          customBodyRender: () => <></>,
          display: 'excluded',
          filterType: 'dropdown',
          filterOptions: {
            names: this.state.metricsGroups.map((c) => c.groupName),
            logic: (item, filters) => {
              if (item.length > 0) {
                return !item.map((i) => i.label).includes(filters[0]);
              }
              return true;
            }
          },
          filter: true
        }
      });
    }
    return columns;
  }

  render() {
    const {
      loading,
      showManageUsers,
      users,
      metricsGroups,
      rowSelected,
      editMode,
      modalStatus,
      showAlert,
      alertSeverity,
      alertMsg
    } = this.state;

    if (loading) {
      return <Loader />;
    }

    if (!users.length) {
      return (
        <EmptyPlaceholder
          subText="You need to add some users in Amazon Connect before they appear here."
          error={showAlert && alertSeverity === 'error'}
        />
      );
    }

    return (
      <>
        <TourControl />
        <div className="panel">
          <DataTable
            data={users.map((u) => ({
              ...u,
              uMetricsGroups: u.metricsGroups ? u.metricsGroups.map((i) => i.label).join(', ') : ''
            }))}
            columns={this.getColumns()}
            options={this.getOptions()}
            title={<Alert show={showAlert} severity={alertSeverity} message={alertMsg} />}
          />
        </div>
        <ManageUsersModal
          show={showManageUsers}
          getAllUsers={this.getAllUsers}
          editMode={editMode}
          metricsGroups={metricsGroups}
          rowSelected={rowSelected}
          toggleAddUser={this.toggleAddUser}
          showMetricsGroup={this.props.hasDashboards}
        />
        <Dialog
          open={modalStatus}
          keepMounted
          onClose={() => this.handleClose(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Delete User</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this user?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} styleType="SECONDARY">
              Cancel
            </Button>
            <Button onClick={() => this.handleClose(true)} styleType="DANGER">
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
