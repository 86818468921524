export default function buildReportableTasks(tasks) {
  try {
    const reportableTasks = tasks.map((task) => ({
      ...task,
      messages: void 0,
      chatSession: void 0,
      contact: task.contact?.toSnapshot()
    }));

    return { value: JSON.stringify(reportableTasks) };
  } catch (e) {
    return {};
  }
}
