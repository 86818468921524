import { cloneElement, forwardRef, ReactNode } from 'react';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

type AuthorisedRouteProps = {
  permission?: string | string[];
  permissionType?: string;
  fallback?: ReactNode;
  children?: ReactNode;
};

function getChild(children, ref) {
  if (!ref) {
    return children;
  }

  return cloneElement(children, { ref });
}

function RequiresPermission(
  { permission, permissionType, children = null, fallback = null }: AuthorisedRouteProps,
  ref
) {
  const { hasPermission } = usePermissionsContext();

  const renderChild = Array.isArray(permission)
    ? permission.every((permission) => hasPermission({ type: permissionType, permission }))
    : hasPermission({ type: permissionType, permission });

  if (!renderChild) {
    return fallback;
  }

  return getChild(children, ref);
}

export default forwardRef(RequiresPermission);
