import React, { Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';
import { faCompress, faExpand, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { Loader, EmptyPlaceholder, TourControl, Icon, ClickableIcon } from 'lib/common/components';
import widgets from 'lib/core/config/widgets.json';
import { GridLayout } from 'lib/common/layouts';
import { ReactComponent as NoAccessImage } from '../assets/no-access.svg';
import DASHBOARD_COLOURS, {
  LIGHTEST as LIGHTEST_DASH_COLOUR,
  DEFAULT as DEFAULT_DASH_COLOUR
} from '../constants/dashboardBgColours';
import { COMPONENT_VISUALISATION_SIZE_MAP, MAX_GRID_COLUMNS } from '../constants/dashboardGrid';
import getFirstEmptyGridSpace from '../utils/getFirstEmptyGridSpace';
import DashboardSelect from './DashboardSelect';
import ActionMenu from './ActionMenu';
import AddDB from './AddDB';
import EditDB from './EditDB';
import SelectWidget from './SelectWidget';
import UpdateTime from './UpdateTime';
import Copy from './CopyDB';
import PollingTimer from './PollingTimer';
import Widget from './Widget';

import '../styles/dashboards.scss';

interface IProps {
  user: any;
  maximised?: boolean;
  toggleFullScreen: Dispatch<SetStateAction<boolean>>;
  config: any;
  fetch: (url: string, options?: any) => Promise<any>;
}

interface IStates {
  loaded: boolean;
  users: any;
  error: any;
  profile: any;
  dashboards: any;
  layout: any;
  currentDashboardIndex: number;
  newDB: boolean;
  editDB: boolean;
  newWidget: boolean;
  copy: boolean;
  editTimer: boolean;
  windowHeight: any;
  isDraggable: boolean;
  isResizable: boolean;
  updateTime: number;
  timerBar: any;
  pollingTimer: number;
  uuid: string;
  objectId: string;
  anchorEl: any;
  fullscreen: boolean;
  description: boolean;
  refresh: number;
}

function injectMinDimensions(layout, components) {
  if (!layout || !components) {
    return [];
  }

  return layout.map((item) => {
    const { i: id } = item;
    const { visualisation: defaultVisualisation = 'count', processor } = components[id] || {};
    const visualisation = processor === 'getTimeFmt' ? 'time' : defaultVisualisation;

    return { ...item, ...COMPONENT_VISUALISATION_SIZE_MAP[visualisation] };
  });
}

function getDashboardBackgroundColour({ loaded, dashboard }) {
  if (!loaded || !dashboard) {
    return 'rgba(0, 0, 0, 0)';
  }

  const colour = dashboard.backgroundcolor?.toUpperCase();
  const supportedColour = DASHBOARD_COLOURS.includes(colour);

  return supportedColour ? colour : DEFAULT_DASH_COLOUR;
}

export default class Dashboards extends React.Component<IProps, IStates> {
  layout: any;

  width = 0;

  rows = 0;

  _refreshInterval?: ReturnType<typeof setTimeout>;

  divRef = React.createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);
    this.state = {
      users: [],
      error: false,
      profile: { userId: -1, theme: 'empty' },
      dashboards: [],
      layout: [],
      currentDashboardIndex: 0,
      loaded: false,
      newDB: false,
      editDB: false,
      newWidget: false,
      copy: false,
      editTimer: false,
      windowHeight: 1000,
      isDraggable: true,
      isResizable: true,
      updateTime: Date.now(),
      timerBar: { width: '0%' },
      pollingTimer: 0,
      uuid: '',
      objectId: '',
      anchorEl: null,
      fullscreen: false,
      description: false,
      refresh: Date.now()
    };
  }

  addDB = async ({ status, ...data }) => {
    const { dashboards } = this.state;

    if (!status) {
      this.setState({ newDB: false });
      this.closeModals();

      return;
    }

    const newDashboards = [
      ...dashboards,
      {
        ...data,
        id: `d${Date.now()}`,
        components: {},
        layout: []
      }
    ];

    await this.writeDB(newDashboards);

    localStorage.setItem('selected-dashboard-id', newDashboards[newDashboards.length - 1].id);

    this.setState({
      dashboards: newDashboards,
      currentDashboardIndex: newDashboards.length - 1
    });

    this.closeModals();
  };

  editDB = async (data) => {
    if (data.status) {
      const { dashboards } = this.state;
      const newDashboards = [...dashboards];

      newDashboards[this.state.currentDashboardIndex] = data.data;

      await this.writeDB(newDashboards);

      this.setState({
        dashboards: newDashboards
      });
    } else {
      this.setState({ editDB: false });
    }
    this.closeModals();
  };

  toggleFullscreen = () => {
    this.setState(
      ({ fullscreen }) => ({ fullscreen: !fullscreen }),
      () => {
        this.props.toggleFullScreen(this.state.fullscreen);
      }
    );
  };

  copyDB = async (data) => {
    if (data.status) {
      this.state.dashboards.push(data.data);
      this.setState({
        copy: false,
        currentDashboardIndex: this.state.dashboards.length - 1
      });

      await this.writeDB(this.state.dashboards);
    } else {
      this.setState({ copy: false });
    }
    this.closeModals();
  };

  closeTimer = () => {
    this.setState({ editTimer: false });
    this.closeModals();
  };

  manageSel = (data) => {
    const { dashboards } = this.state;

    localStorage.setItem('selected-dashboard-id', dashboards[data].id);

    this.setState({ currentDashboardIndex: data });
    this.closeModals();
  };

  editTimer = (data) => {
    this.setState(
      {
        editTimer: false,
        pollingTimer: parseInt(data)
      },
      () => {
        this.writeDB(this.state.dashboards);
      }
    );
  };

  // if delete, data is an object eg. { delete: true, index: 0 }
  // otherwise data is the updated component object containing a _uid
  changeWidget = (data) => {
    const { dashboards, currentDashboardIndex } = this.state;
    const { components } = dashboards[currentDashboardIndex];

    const newDashboards = [...dashboards];

    const changedWidgetKey = data.delete ? data.index : data._uid;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [changedWidgetKey]: _, ...componentsWithoutWidget } = components;

    const newComponents = data.delete ? componentsWithoutWidget : { ...componentsWithoutWidget, [data._uid]: data };

    const newDashboard = { ...dashboards[currentDashboardIndex], components: newComponents };

    newDashboards.splice(currentDashboardIndex, 1, newDashboard);

    this.setState({ dashboards: newDashboards, updateTime: Date.now() });

    this.writeDB(newDashboards);

    this.closeModals();
  };

  addWidget = (data) => {
    const { currentDashboardIndex } = this.state;
    const {
      user: { metricsGroups, colors }
    } = this.props;

    this.setState({ newWidget: false });

    if (data.hide) {
      return void this.closeModals();

      return;
    }

    const widget = widgets[data];

    const newId = `a${Date.now()}`;

    const templateJson = {
      _uid: newId,
      name: widget.name,
      type: data,
      visualisation: widget.visualisations[0],
      queueid: metricsGroups[0].objectId,
      queueName: metricsGroups[0].name,
      dataModule: widget.query,
      element: widget.element,
      processor: widget.processor,
      backgroundColor: '',
      threshold: {
        active: Boolean(widget.threshold),
        warning: {
          enabled: false,
          value: '0',
          calc: 'gte'
        },
        alert: {
          enabled: false,
          value: '5',
          calc: 'gte'
        }
      },
      dateRange: {
        active: Boolean(widget.dateRange),
        dateRangeValue: '5',
        dateRangeUnit: 'days'
      },
      colors,
      filterable: Boolean(widget.filterable),
      filterValues: widget.filterValues || [],
      filter: widget.filter || '',
      navigation: widget.navigation || void 0,
      extraClass: widget.extraClass || void 0
    };

    const existingDB = JSON.parse(JSON.stringify(this.state.dashboards));

    const sizeMap = COMPONENT_VISUALISATION_SIZE_MAP[widget.visualisations[0]];
    const { layout } = existingDB[currentDashboardIndex];

    const { x, y } = getFirstEmptyGridSpace({ layout, ...sizeMap });

    const newLayout = {
      i: newId,
      moved: false,
      static: false,
      h: sizeMap.minH,
      w: sizeMap.minW,
      x,
      y
    };

    existingDB[currentDashboardIndex].components[newId] = templateJson;

    existingDB[currentDashboardIndex].layout.push(newLayout);

    this.setState({ dashboards: existingDB });
    this.writeDB(existingDB);
    this.closeModals();
  };

  actionMenuSelect = (data) => {
    this.setState({
      anchorEl: data.currentTarget
    });
    if (data === '1') {
      this.setState({ newDB: true });
    }
    if (data === '4') {
      this.setState({ copy: true });
    }
    if (data === '5') {
      this.setState({ editDB: true });
    }
    if (data === '6') {
      this.setState({ newWidget: true });
    }

    // Delete dashboard
    if (data === '7') {
      if (this.state.dashboards.length === 1) {
        return;
      }

      const { dashboards, currentDashboardIndex } = this.state;

      const newDashboards = [...dashboards];

      newDashboards.splice(currentDashboardIndex, 1);

      this.writeDB(newDashboards);

      const newIndex = currentDashboardIndex === 0 ? 0 : currentDashboardIndex - 1;

      this.setState(
        {
          dashboards: newDashboards,
          currentDashboardIndex: newIndex
        },
        () => {
          localStorage.setItem('selected-dashboard-id', newDashboards[newIndex].id);
        }
      );

      this.closeModals();
    }
    if (data === '8') {
      this.setState({ editTimer: true });
    }
  };

  onLayoutChange = (layout) => {
    const { dashboards, currentDashboardIndex } = this.state;

    const newDashboards = [...dashboards];

    newDashboards.splice(currentDashboardIndex, 1, {
      ...newDashboards[currentDashboardIndex],
      layout
    });

    this.setState({ dashboards: newDashboards });

    this.writeDB(newDashboards);
  };

  resizeWindows = () => {
    this.setState({
      windowHeight: window.innerHeight
    });
  };

  setRefreshInterval = () => {
    const { pollingTimer } = this.state;

    if (this._refreshInterval) {
      clearInterval(this._refreshInterval);
    }

    this._refreshInterval = setInterval(() => {
      this.setState({ refresh: Date.now() });
    }, pollingTimer * 1000);
  };

  componentDidMount() {
    const { user, config } = this.props;

    this.setState({
      pollingTimer: (user.metrics && user.metrics.refreshRate) || config.POLLING_TIMER || 60
    });

    this.getData();
    this.resizeWindows();

    this.setRefreshInterval();

    window.addEventListener('resize', this.resizeWindows);
  }

  componentDidUpdate(_, prevState) {
    const { pollingTimer } = prevState;
    const { pollingTimer: currentPollingTimer } = this.state;

    if (pollingTimer === currentPollingTimer) {
      return;
    }

    this.setRefreshInterval();
  }

  componentWillUnmount() {
    if (this._refreshInterval) {
      clearInterval(this._refreshInterval);
    }

    window.removeEventListener('resize', this.resizeWindows);
  }

  getData = async () => {
    const { pollingTimer } = this.state;
    const { config, fetch } = this.props;

    const selectedDashboardId = localStorage.getItem('selected-dashboard-id');

    const objectKey = `${process.env.REACT_APP_TENANT_ID}__${sessionStorage.getItem('c_user')}`;
    const url = `${config.AGENT_SERVICE_HOST}/config/${objectKey}/dashboard-config`;

    try {
      const result = await fetch(url);
      const data = await result.json();
      const selectedDashboardIndex = selectedDashboardId
        ? data.dashboardBlob.findIndex(({ id }) => id === selectedDashboardId)
        : 0;

      this.setState({
        updateTime: Date.now(),
        dashboards: data.dashboardBlob,
        isDraggable: true,
        isResizable: true,
        uuid: data.uuid,
        objectId: data.objectId,
        pollingTimer: data.pollingTimer || pollingTimer || 60,
        loaded: true,
        currentDashboardIndex: selectedDashboardIndex >= 0 ? selectedDashboardIndex : 0
      });
    } catch (e) {
      this.setState({ loaded: true, error: true });
    }
  };

  writeDB = (dbs) => {
    const { config, fetch } = this.props;

    const dashboardConfig = {
      tenantId: process.env.REACT_APP_TENANT_ID,
      uuid: this.state.uuid,
      objectKey: `${process.env.REACT_APP_TENANT_ID}__${sessionStorage.getItem('c_user')}`,
      objectId: this.state.objectId,
      objectIdentifier: `${process.env.REACT_APP_TENANT_ID}__dashboard-config`,
      dashboardBlob: dbs,
      pollingTimer: this.state.pollingTimer
    };

    const url = `${config.AGENT_SERVICE_HOST}/config`;

    return fetch(url, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dashboardConfig)
    });
  };

  closeModals = () => {
    this.setState({
      newDB: false,
      editDB: false,
      copy: false,
      editTimer: false,
      newWidget: false
    });
  };

  render() {
    const {
      loaded,
      newDB,
      editDB,
      copy,
      editTimer,
      pollingTimer,
      newWidget,
      windowHeight,
      dashboards,
      currentDashboardIndex,
      isDraggable,
      isResizable,
      updateTime,
      error,
      fullscreen
    } = this.state;

    const {
      user,
      user: { metricsGroups },
      config,
      fetch
    } = this.props;

    const hasNoMetricsGroups = loaded && !metricsGroups?.length;

    const rowHeight = windowHeight / 4;
    const dashboardBackground = getDashboardBackgroundColour({
      loaded,
      dashboard: dashboards?.[currentDashboardIndex]
    });

    if (error) {
      return (
        <>
          <div className="component-title dashboards__error-title">Dashboards</div>
          <div className="panel">
            <EmptyPlaceholder error />
          </div>
        </>
      );
    }

    const currentDashboard = dashboards[currentDashboardIndex] || {};

    return (
      <>
        {loaded && <TourControl />}
        <div className="component-title dashboards__actions">
          {loaded && (
            <DashboardSelect manageSel={this.manageSel} dashboards={dashboards} curDashboard={currentDashboardIndex} />
          )}

          {loaded && currentDashboard?.description && (
            <Icon className="ml-10" icon={faCircleInfo} tooltip={currentDashboard?.description} tooltipDelay={0} />
          )}

          {loaded && (
            <ActionMenu
              actionMenuSelect={this.actionMenuSelect}
              isLastDashboard={dashboards?.length && dashboards?.length === 1}
              hasNoMetricsGroups={hasNoMetricsGroups}
            />
          )}

          {loaded && (
            <ClickableIcon
              className="dashboards__actions__action"
              onClick={this.toggleFullscreen}
              icon={fullscreen ? faCompress : faExpand}
              tooltip={fullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
            />
          )}
        </div>
        <UpdateTime show={loaded} updateTime={updateTime} />
        {newDB && <AddDB addDB={this.addDB} colors={user.colors} />}

        {editDB && loaded && <EditDB curData={currentDashboard} colors={user.colors} editDB={this.editDB} />}

        {newWidget && <SelectWidget addWidget={this.addWidget} closeModals={this.closeModals} />}

        {copy && loaded && <Copy curData={currentDashboard} copyDB={this.copyDB} />}

        {editTimer && loaded && (
          <PollingTimer currTimer={pollingTimer} editTimer={this.editTimer} closeFunc={this.closeTimer} />
        )}

        {document.querySelector('#root') &&
          createPortal(
            <div className="dashboards__background" style={{ background: dashboardBackground }} />,
            document.querySelector('#root') as HTMLElement
          )}

        {!loaded && <Loader elevated />}

        {loaded && !Object.keys(currentDashboard?.components || {}).length && (
          <div className={`panel${dashboardBackground === LIGHTEST_DASH_COLOUR ? '--elevated' : ''}`}>
            {hasNoMetricsGroups ? (
              <EmptyPlaceholder
                Image={NoAccessImage}
                text="You haven't got access to any metrics yet, so you won't be able to add any widgets. Contact your Administrator for help."
              />
            ) : (
              <EmptyPlaceholder subText="You can add a new widget through the settings menu." />
            )}
          </div>
        )}

        {loaded && (
          <div className="grid-wrapper content-wrapper-td" id="grid-canvas" ref={this.divRef}>
            <GridLayout
              rowHeight={rowHeight}
              cols={MAX_GRID_COLUMNS}
              layout={injectMinDimensions(currentDashboard?.layout, currentDashboard?.components)}
              onLayoutChange={this.onLayoutChange}
              margin={[20, 20]}
              containerPadding={[0, 0]}
              preventCollision={false}
              className="grid-tailored"
              isDraggable={isDraggable}
              isResizable={isResizable}
              key={updateTime}
              useCSSTransforms={false}
            >
              {Object.keys(currentDashboard?.components || {}).map((widget) => (
                <Widget
                  key={currentDashboard?.components?.[widget]?._uid?.toString()}
                  widget={widget}
                  dashboard={currentDashboard}
                  metricsGroups={user.metricsGroups}
                  updateTime={updateTime}
                  refresh={this.state.refresh}
                  changeWidget={this.changeWidget}
                  config={config}
                  fetch={fetch}
                />
              ))}
            </GridLayout>
          </div>
        )}
      </>
    );
  }
}
