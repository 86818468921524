import cx from 'classnames';
import getUserName from 'lib/common/utils/getUserName';
import TTask from 'lib/common/types/Task';
import COLOURS from 'css/export-vars.module.scss';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { Icon, TaskActions } from 'lib/common/components';
import { TASK_COLOURS, TASK_TYPES } from 'lib/common/constants/taskStates';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';

import styles from './task.module.scss';

interface TTaskListTask extends TTask {
  selected: boolean;
  onSelect: (contactId: string) => void;
  listIndex: number;
  selectedTaskIndex: number;
}

const BASE_TOP_PX = 165;
const TASK_HEIGHT = 60;
const SELECTED_TASK_TOP_PX = 40;

const SELECTED_COLOUR = 'blue';

function SubtitleSuffix({ time }: { time?: Date }) {
  const taskTime = useTaskTimer(time);

  return <span> | {taskTime}</span>;
}

export default function Task({
  status,
  contact,
  selected,
  type,
  profile,
  time,
  taskId,
  connectionValue,
  connectionType,
  onSelect,
  listIndex,
  selectedTaskIndex
}: TTaskListTask) {
  const taskProps = TASK_TYPES(connectionType)[status][type];
  const title = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const subtitleToDisplay = taskProps?.subtitle || '';

  const onSelectTask = () => {
    onSelect(contact.contactId);
  };

  const isIncoming = status === CONTACT_STATES.CONNECTING;
  const selectedNotIncoming = selected && !isIncoming;

  const actualIndex = listIndex < selectedTaskIndex ? listIndex : listIndex - 1;
  const gapPx = actualIndex ? 10 : 0;
  const totalGapPx = gapPx * actualIndex;

  return (
    <div
      className={cx(styles['task__content'], {
        [styles['task__content--incoming']]: isIncoming,
        [styles['task__content--selected']]: selectedNotIncoming
      })}
      style={{
        position: 'absolute',
        top: selected ? `${SELECTED_TASK_TOP_PX}px` : `${BASE_TOP_PX + totalGapPx + actualIndex * TASK_HEIGHT}px`
      }}
      onClick={!selected && !isIncoming ? onSelectTask : void 0}
    >
      <Icon icon={taskProps?.icon} color={selectedNotIncoming ? SELECTED_COLOUR : TASK_COLOURS[status]} />
      <div className={styles['task__content__text']} data-testid={title}>
        <p style={{ color: selectedNotIncoming ? COLOURS[SELECTED_COLOUR] : 'inherit' }}>
          <strong>{title}</strong>
        </p>
        <p>
          <span style={{ color: selectedNotIncoming ? COLOURS[SELECTED_COLOUR] : COLOURS[TASK_COLOURS[status]] }}>
            {subtitleToDisplay}
          </span>
          {(!selected || isIncoming) && <SubtitleSuffix time={time} />}
        </p>
      </div>
      <TaskActions taskId={taskId} status={status} type={type} connectionType={connectionType} round />
    </div>
  );
}
