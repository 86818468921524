import { useCallback, useContext, useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { Text, Loader, Icon } from 'lib/common/components';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useInstanceContext } from 'lib/common/contexts/InstanceContext';
import connectGetter from 'lib/common/utils/connectGetter';
import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';
import getAgentMetrics from './api/getAgentMetrics';
import { IAgentStatsProps, TAllQueuesStats, TQueuesStats, TAgentQueues } from './types/AgentStats';
import Queues from './components/Queues';

import './agent-stats.scss';

const POLL_TIMEOUT_MS = 5000;

function getAgentQueues(agent, queues) {
  const routingProfileQueues = connectGetter('getRoutingProfile', agent)?.queues || [];
  const agentQueuesIds = routingProfileQueues.map((queue) => queue.queueId.split('/').pop());

  if (!agentQueuesIds) {
    return;
  }

  return queues.filter(({ Id }) => agentQueuesIds.includes(Id)).map(({ Id, Name }) => ({ Id, name: Name }));
}

const AgentStats = ({ setLoaderLoading, setLoaderNotLoading, isSoftphone }: IAgentStatsProps) => {
  const [allQueuesStats, setAllQueuesStats] = useState<TAllQueuesStats>({});
  const [queuesStats, setQueuesStats] = useState<TQueuesStats[]>([]);
  const [agentQueues, setAgentQueues] = useState<TAgentQueues[]>([]);
  const [triggerLoad, setTriggerLoad] = useState(Date.now());
  const [firstLoad, setFirstLoad] = useState(true);

  const { config, fetch_ } = useContext(AuthContext);
  const { agent } = useAgentContext();
  const { instanceQueues } = useInstanceContext();

  const loadData = useCallback(async () => {
    if (!sessionStorage.getItem('c_user') || !agentQueues.length) {
      return void setTimeout(() => setTriggerLoad(Date.now()), POLL_TIMEOUT_MS);
    }

    !firstLoad && setLoaderLoading?.();

    const { allQueuesStats, queuesStats } = await getAgentMetrics({ fetch_, config, agentQueues });

    if (firstLoad) {
      setFirstLoad(false);
    }

    !firstLoad && setLoaderNotLoading?.();

    setAllQueuesStats(allQueuesStats || {});
    setQueuesStats(queuesStats || []);

    setTimeout(() => setTriggerLoad(Date.now()), POLL_TIMEOUT_MS);
  }, [agentQueues, firstLoad]);

  useEffect(() => {
    if (!instanceQueues.length) {
      return;
    }

    setAgentQueues(getAgentQueues(agent, instanceQueues));
  }, [instanceQueues]);

  useEffect(() => {
    loadData();
  }, [triggerLoad]);

  const { contactsInQueues, oldestContactInQueues } = allQueuesStats;

  return (
    <div className="agent-stats">
      {firstLoad && <Loader relative small />}
      {!firstLoad && (
        <div className="agent-stats__container" data-testid="agent-stats">
          <div className="agent-stats__container__header">
            <h3 className="agent-stats__container__header__title">Total (All Your Queues)</h3>
            <div className="agent-stats__container__header__stats">
              <Text>
                In Queue:{' '}
                <Text className="agent-stats__container__header__stats__stat" type="heading1">
                  {contactsInQueues}
                </Text>
              </Text>
              <Text>
                Oldest:{' '}
                <Text className="agent-stats__container__header__stats__stat" type="heading1">
                  {oldestContactInQueues ? convertMSToReadableTime(oldestContactInQueues * 1000) : `00:00`}
                </Text>
              </Text>
            </div>
          </div>
          {Boolean(queuesStats.length) && (
            <div className="agent-stats__queues">
              <Queues queues={queuesStats} isSoftphone={isSoftphone} />
            </div>
          )}
          <div className={cx('agent-stats__container__warning', { 'mt-30': !isSoftphone })}>
            <Icon icon={faInfoCircle} size={15} />
            <Text>
              Queues can take <strong>15{isSoftphone ? 's' : ' seconds'}</strong> to update
            </Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentStats;
