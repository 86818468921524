import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import React, { useState, useCallback, useEffect } from 'react';
import { Loader } from 'lib/common/components';

import './widget.scss';

const S3_SOURCE = 'S3';
const IFRAME_LOAD_TIMEOUT = 2000;

interface WidgetProps {
  widgetDetails: Partial<ConnectorModuleType>;
  iframeRef: React.RefObject<HTMLIFrameElement>;
  modulesUrl: string;
  fetchAgentService: (url: string, options) => Promise<void>;
}

function Widget(props: WidgetProps) {
  const { widgetDetails, iframeRef, modulesUrl, fetchAgentService } = props;
  const { name, source, tenantId, moduleId, rootFile, external } = widgetDetails;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (external) {
      return;
    }

    /**
     * Child iframe widgets would post a message to a parent iframe with fetchId
     * for the generic "message" event handler
     */
    window.addEventListener(
      'message',
      async (event) => {
        const { fetchId, url, options } = event.data || {};

        if (!fetchId || fetchId !== moduleId) {
          return;
        }

        try {
          await fetchAgentService(url, options);

          iframeRef.current?.contentWindow?.postMessage({ msg: 'fetchSuccess', fetchId: moduleId }, '*');
        } catch {
          iframeRef.current?.contentWindow?.postMessage({ msg: 'fetchError', fetchId: moduleId }, '*');
        }
      },
      false
    );

    return () => {};
  }, []);

  const onLoad = useCallback(() => {
    setTimeout(() => {
      if (external) {
        return;
      }

      if (iframeRef) {
        iframeRef.current?.contentWindow?.postMessage(
          {
            data: moduleId,
            msg: 'fetchId'
          },
          '*'
        );
      }

      setLoading(false);
    }, IFRAME_LOAD_TIMEOUT);
  }, [iframeRef]);

  return (
    <div className="widget">
      <h3 data-testid="widget-title">{name}</h3>
      {loading && <Loader />}
      <div className="widget__iframe-container" data-testid="widget-iframe-container">
        <iframe
          frameBorder="0"
          src={source === S3_SOURCE ? `${modulesUrl}/${tenantId}-${moduleId}/${rootFile || 'index.html'}` : source}
          width="100%"
          height="100%"
          ref={iframeRef}
          onLoad={onLoad}
          name={name}
        />
      </div>
    </div>
  );
}

export default Widget;
