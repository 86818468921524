import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { AuthContext } from 'lib/core/context/AuthProvider';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';

interface IModulesContext {
  state: {
    pageModules: Array<ConnectorModuleType>;
    widgetModules: Partial<ConnectorModuleType>[];
    externalLibraryModules: Partial<ConnectorModuleType>[];
  };
}

interface IModulesContextProps {
  children: ReactNode;
}

const Context = createContext<IModulesContext>({
  state: {
    pageModules: [],
    widgetModules: [],
    externalLibraryModules: []
  }
});

const ModulesContext = ({ children }: IModulesContextProps) => {
  const { fetch_, config } = useContext(AuthContext);

  const [pageModules, setPageModules] = useState<Array<ConnectorModuleType>>([]);
  const [widgetModules, setWidgetModules] = useState<Partial<ConnectorModuleType>[]>([]);
  const [externalLibraryModules, setExternalLibraryModules] = useState<Partial<ConnectorModuleType>[]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const modulesJson = await fetch_(`${config.MODULES_HOST}?tenantId=${config.TENANT_ID}`);
      const modules = await modulesJson.json();

      setPageModules(modules.data?.filter((module) => ['LANDING', 'PAGE'].includes(module?.type)) || []);
      setWidgetModules(modules.data?.filter((module) => module?.type === 'WIDGET') || []);
      setExternalLibraryModules(modules.data?.filter((module) => module?.type === 'EXTERNAL_LIBRARY') || []);
      setLoaded(true);
    })();
  }, []);

  return (
    <Context.Provider
      value={{
        state: {
          pageModules,
          widgetModules,
          externalLibraryModules
        }
      }}
    >
      {loaded && children}
      {externalLibraryModules && (
        <Helmet>
          {externalLibraryModules.map((module, index) => (
            <script key={index} src={module.source || ''} type="text/javascript" />
          ))}
        </Helmet>
      )}
    </Context.Provider>
  );
};

export default ModulesContext;

// Export the context as a HOC
export const { Consumer: ModulesConsumer, Provider: ModulesProvider } = Context;

// export the context hook
export const useModulesContext = () => useContext(Context);
