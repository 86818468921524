import { faClock, faInboxIn } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode } from 'react';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import { Icon, Text } from 'lib/common/components';
import Row from './components/Row';

import styles from './info-panel.module.scss';

interface InfoPanelProps {
  timestamp: Date;
  queue?: string;
  children?: ReactNode;
}

function InfoPanel({ timestamp, queue, children }: InfoPanelProps) {
  const time = useTaskTimer(timestamp);

  return (
    <div className={styles['softphone-task-info-panel']}>
      <div className={styles['softphone-task-info-panel__header']}>
        {timestamp && (
          <div className={styles['softphone-task-info-panel__header__section']}>
            <Icon color="darkGrey" icon={faClock} size={15} />
            <Text bold>{time}</Text>
          </div>
        )}
        {queue && (
          <div className={styles['softphone-task-info-panel__header__section']}>
            <Icon color="darkGrey" icon={faInboxIn} size={15} />
            <Text bold>{queue}</Text>
          </div>
        )}
      </div>
      <div className={styles['softphone-task-info-panel__content']}>{children}</div>
    </div>
  );
}

InfoPanel.Row = Row;

export default InfoPanel;
