import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';

export const LAYOUT_SWITCH_REDIRECTION_ROUTES = [
  { from: SOFTPHONE_PAGE_ROUTES.LANDING, to: PAGE_ROUTES.WORKSPACE },
  { from: SOFTPHONE_PAGE_ROUTES.TASK, to: PAGE_ROUTES.WORKSPACE },
  { from: SOFTPHONE_PAGE_ROUTES.WIDGETS, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.AGENT_STATS, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.DIRECTORY, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.PREFERENCES, to: '/' }
];
