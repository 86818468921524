import { useContext, useState, useRef, useEffect } from 'react';
import { Tooltip } from '@material-ui/core';
import Fuse from 'fuse.js';
import { faCircleInfo, faEdit, faPhone, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import TTask from 'lib/common/types/Task';
import getTransferEndpoint from 'lib/common/api/getTransferEndpoint';
import { AuthContext } from 'lib/core/context/AuthProvider';
import quickConnectTypes from 'lib/common/constants/quickConnectTypes';
import { PRESENCE_STATES, STATUS_TO_COLOUR_MAP } from 'lib/common/constants/presenceStates';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import { Avatar, ClickableIcon, Icon } from 'lib/common/components';
import COLOURS from 'css/export-vars.module.scss';
import getUserName from 'lib/common/utils/getUserName';

import HangupModal from '../HangupModal';
import { ICON_MAP } from '../../constants/numberTypes';
import { ContactActions, IContact, SourceType } from '../../types';

interface PhonebookData extends IContact {
  source: SourceType;
}

interface IRowRenderer {
  rowKey: string;
  row: PhonebookData;
  matches?: readonly Fuse.FuseResultMatch[];
  style: { [x: string]: string };
  presence: PRESENCE_STATES;
  showEdit: boolean;
  onActiveCall: boolean;
  canTransfer: boolean;
  connectedTask?: TTask;
  isDirectoryTransfer?: boolean;
  updateContactAdminRecord: ({ action, contact }) => void;
  closeModal?: () => void;
  pushToTask?: (url: string) => void;
  contentWidth?: number;
}

const MAX_USERNAME_WIDTH = 170;

const SMALL_CONTACT_WIDTH_PX = 330;

function FieldLetter({ char, highlighted }) {
  return highlighted ? <strong style={{ color: `${COLOURS.blue}` }}>{char}</strong> : char;
}

function MatchableField({ fieldToMatch, matches, value, className }) {
  const match = matches?.find((match) => match.key === fieldToMatch);

  return (
    <span className={className}>
      {match
        ? value
            .split('')
            .map((char, charIndex) => (
              <FieldLetter
                key={`${char}-${charIndex}`}
                char={char}
                highlighted={match.indices.some(
                  ([matchStartIndex, matchEndIndex]) => charIndex >= matchStartIndex && charIndex <= matchEndIndex
                )}
              />
            ))
        : value?.trim() || 'Agent'}
    </span>
  );
}

const Contact = ({
  rowKey,
  row,
  style,
  matches,
  presence,
  showEdit,
  updateContactAdminRecord,
  onActiveCall,
  canTransfer,
  connectedTask,
  closeModal,
  isDirectoryTransfer,
  pushToTask,
  contentWidth
}: IRowRenderer) => {
  const userNameRef = useRef<HTMLSpanElement>(null);
  const [transferring, setTransferring] = useState(false);
  const [openHangupModal, setOpenHangupModal] = useState(false);
  const [outboundCallInProgress, setOutboundCallInProgress] = useState(false);
  const [usernameWidth, setUserNameWidth] = useState(0);

  const isSmall = Boolean(contentWidth && contentWidth < SMALL_CONTACT_WIDTH_PX);

  const {
    actions: { makeOutboundCall, onTransfer, onTransferToQueueOrAgent }
  } = useContactContext();
  const { fetch_, config } = useContext(AuthContext);

  useEffect(() => {
    if (!userNameRef?.current) {
      return;
    }

    setUserNameWidth(userNameRef?.current.getBoundingClientRect().width);
  }, [userNameRef]);

  const onHangUpAndCall = async (phoneNumber) => {
    setOutboundCallInProgress(true);
    await makeOutboundCall(phoneNumber);
    setOutboundCallInProgress(false);

    closeModal?.();
    pushToTask?.(PAGE_ROUTES.WORKSPACE);
  };

  const onContactTransfer = (phoneNumber) => {
    onTransfer(phoneNumber);
    closeModal?.();
    pushToTask?.(`${PAGE_ROUTES.WORKSPACE}${connectedTask ? `?taskId=${connectedTask.taskId}` : ''}`);
  };

  const onAgentTransfer = async (objectId: string) => {
    if (!connectedTask?.taskId) {
      return;
    }

    setTransferring(true);

    const identifier = objectId.split('_')[1];

    const endpointARN = await getTransferEndpoint({
      fetch_,
      config,
      type: quickConnectTypes.AGENT,
      identifier: identifier
    });

    if (!endpointARN) {
      return setTransferring(false);
    }

    const endPoint = {
      agentLogin: '',
      endpointARN,
      endpointId: endpointARN,
      name: identifier,
      phoneNumber: '',
      queue: '',
      type: connect.EndpointType.AGENT
    };

    try {
      await onTransferToQueueOrAgent(endPoint, connectedTask?.taskId);

      closeModal?.();

      pushToTask?.(`${PAGE_ROUTES.WORKSPACE}${connectedTask ? `?taskId=${connectedTask.taskId}` : ''}`);
    } finally {
      setTransferring(false);
    }

    return;
  };

  const onSelectRowForTransfer = () => {
    if (row.source !== SourceType.CONNECT) {
      return void onContactTransfer(row.phoneNumber);
    }

    onAgentTransfer(row.objectId);
  };

  return (
    <div
      key={rowKey}
      className={cx('directory__phone-row', {
        'directory__phone-row--hoverable': canTransfer && isDirectoryTransfer
      })}
      style={style}
      onClick={canTransfer && isDirectoryTransfer ? onSelectRowForTransfer : void 0}
    >
      <div className="directory__phone-user-profile">
        <Avatar
          name={getUserName({ firstName: row.firstName, lastName: row.lastName })}
          size={isSmall ? 30 : 40}
          statusProps={{
            statusColor: STATUS_TO_COLOUR_MAP[presence] || COLOURS.yellow
          }}
        />
        <div
          className={cx('directory__phone-user-profile__user-details', {
            'directory__phone-user-profile__user-details--small': isSmall
          })}
          title={`${row.firstName} ${row.lastName}`}
        >
          <span ref={userNameRef}>
            <MatchableField
              value={getUserName({ firstName: row.firstName })}
              matches={matches}
              fieldToMatch="firstName"
              className="directory__phone-user-profile__user-details__firstName"
            />
            <MatchableField
              value={getUserName({ firstName: row.lastName })}
              matches={matches}
              fieldToMatch="lastName"
              className={cx('directory__phone-user-profile__user-details__lastName', {
                'directory__phone-user-profile__user-details__lastName--ellipsis': usernameWidth > MAX_USERNAME_WIDTH
              })}
            />
          </span>
          {row.organization && (
            <Tooltip
              title={
                <>
                  <p>{row.organization}</p>
                  <p>Organisation</p>
                </>
              }
              arrow
              placement="top"
              classes={{
                popper: 'directory__phone-user-profile__user-details__organisation'
              }}
            >
              <span className="directory__phone-user-profile__user-details__info">
                <Icon icon={faCircleInfo} size={12} />
              </span>
            </Tooltip>
          )}
          <div className="directory__phone-user-profile__user-details__phoneNumber-wrapper">
            {ICON_MAP[row.numberType] && (
              <span className="directory__phone-user-profile__user-details__phoneNumber">
                <Icon icon={ICON_MAP[row.numberType]} size={10} color="darkGrey" />
              </span>
            )}
            <MatchableField
              value={row.phoneNumber}
              matches={matches}
              fieldToMatch="phoneNumber"
              className="directory__phone-user-profile__user-details__phoneNumber"
            />
          </div>
        </div>
      </div>
      <div className="directory__contact-actions">
        {transferring ? (
          <div className="directory__contact-actions__in-progress">
            <Icon icon={faSpinnerThird} spin size={15} />
          </div>
        ) : (
          <>
            {showEdit && row.source !== SourceType.CONNECT && (
              <ClickableIcon
                icon={faEdit}
                onClick={() => updateContactAdminRecord({ action: ContactActions.EDIT, contact: row })}
                size={15}
              />
            )}
            {row.source !== SourceType.CONNECT && !isDirectoryTransfer && (
              <>
                {outboundCallInProgress ? (
                  <div className="directory__contact-actions__in-progress">
                    <Icon icon={faSpinnerThird} spin size={15} />
                  </div>
                ) : (
                  <ClickableIcon
                    icon={faPhone}
                    onClick={() => (onActiveCall ? setOpenHangupModal(true) : onHangUpAndCall(row.phoneNumber))}
                    size={15}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>
      {openHangupModal && (
        <HangupModal
          open={openHangupModal}
          onClose={() => setOpenHangupModal(false)}
          onSave={() => onHangUpAndCall(row.phoneNumber)}
          primaryText={`Dialling this contact will hang up the current active call with ${
            getUserName({ firstName: row.firstName, lastName: row.lastName }) || row.phoneNumber
          }`}
          secondaryText="Use the transfer-to-agent call control button if you want to transfer or create a conference call."
        />
      )}
    </div>
  );
};

export default Contact;
