import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns3, faDownload, faFilter, faPrint, faSearch } from '@fortawesome/pro-regular-svg-icons';
import MUIDataTable from 'mui-datatables';
import './data-table.scss';

export default function DataTable(props) {
  return (
    <MUIDataTable
      className="mui-table"
      components={{
        ...(props.components || {}),
        icons: {
          SearchIcon: () => <FontAwesomeIcon className="data-table__icon" size="sm" icon={faSearch} />,
          DownloadIcon: () => <FontAwesomeIcon className="data-table__icon" size="sm" icon={faDownload} />,
          PrintIcon: () => <FontAwesomeIcon className="data-table__icon" size="sm" icon={faPrint} />,
          ViewColumnIcon: () => <FontAwesomeIcon className="data-table__icon" size="sm" icon={faColumns3} />,
          FilterIcon: () => <FontAwesomeIcon className="data-table__icon" size="sm" icon={faFilter} />
        }
      }}
      {...props}
    />
  );
}
