import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import requestNotificationPermission from 'lib/common/utils/requestNotificationPermission';

import packageJson from '../package.json';
import { unregister as unregisterServiceWorker } from './serviceWorker';

// These have to be imported before the app, otherwise the styles don't seem to get loaded/prioritised properly
import 'css/global.scss';
import 'css/app.scss';

import App from './App';

const isDevEnvironment = process.env.REACT_APP_STAGE === 'dev';

function overrideLog(type) {
  const originalLog = window.console[type];

  window.console[type] = (message, ...args) => {
    // message logged from aws connect streams contains [ERROR] string
    if (type === 'error' && typeof message === 'string' && message.includes('[ERROR]')) {
      return originalLog('[CONNECT-ERROR]', message);
    }
    originalLog('[NEON]', message, ...args);
  };
}

if (!isDevEnvironment) {
  Sentry.init({
    environment: process.env.REACT_APP_STAGE,
    dsn: 'https://a84b9050457b4b62b18b05cf7e8f5dc1@o1243134.ingest.sentry.io/6398215',
    integrations: [new CaptureConsole({ levels: ['error'] }), new BrowserTracing()],
    tracesSampleRate: 0.5,
    release: `${packageJson.name}@${packageJson.version}`,
    normalizeDepth: 5
  });
}

if (isDevEnvironment) {
  ['log', 'info', 'warn', 'error'].forEach(overrideLog);
}

requestNotificationPermission();

ReactDOM.render(<App />, document.getElementById('root'));

unregisterServiceWorker();
