import appConfigUrl from './appConfigUrl';
import modulesHostUrl from './modulesHostUrl';
import teamsPresenceUrl from './teamsPresenceUrl';

const getConfigApi = () => {
  if (process.env.REACT_APP_IS_EXISTING === 'YES') {
    return 'https://ec5yikz6r1.execute-api.us-east-1.amazonaws.com/dev';
  }

  if (process.env.REACT_APP_CONFIG_API) {
    return process.env.REACT_APP_CONFIG_API;
  }

  return process.env.REACT_APP_STAGE ? appConfigUrl[process.env.REACT_APP_STAGE] : appConfigUrl['dev'];
};

const getPresenceUrl = () => {
  if (process.env.PRESENCE_URL) {
    return process.env.PRESENCE_URL;
  }

  return process.env.REACT_APP_STAGE ? teamsPresenceUrl[process.env.REACT_APP_STAGE] : teamsPresenceUrl['dev'];
};

const getModulesHost = () => {
  if (process.env.REACT_APP_MODULES_HOST) {
    return process.env.REACT_APP_MODULES_HOST;
  }

  return process.env.REACT_APP_STAGE ? modulesHostUrl[process.env.REACT_APP_STAGE] : modulesHostUrl['dev'];
};

export default {
  STAGE: process.env.REACT_APP_STAGE || 'dev',
  TENANT_ID: process.env.REACT_APP_TENANT_ID || 'demo',
  CONFIG_API: getConfigApi(),
  PRESENCE_URL: getPresenceUrl(),
  MODULES_HOST: getModulesHost(),
  MODULES_S3DOMAIN: process.env.REACT_APP_MODULES_S3DOMAIN || 'https://modules.dev.cw-neon.com'
};
