import { Accordion } from 'lib/common/components';
import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';
import _omit from 'lodash.omit';
import _isEmpty from 'lodash.isempty';
import isURL from 'validator/lib/isURL';
import normaliseAttributeKey from 'lib/common/utils/normaliseAttributeKey';

import './contact-attributes.scss';

const Intents = ({ intents }) => {
  if (!intents?.length) {
    return null;
  }

  return (
    <div className="intents__container" data-testid="intents-container-testId">
      {intents.map((intent) => (
        <div className="intents__content" key={intent}>
          {intent?.trim()}
        </div>
      ))}
    </div>
  );
};

const Transcripts = ({ transcript }) => {
  if (!transcript?.length) {
    return null;
  }

  return (
    <div className="transcript__container" data-testid="transcript-container-testId">
      <Accordion title="Transcript" small defaultOpen inverse>
        {transcript.map((transcript) => (
          <div className="transcript__content" key={transcript}>
            {transcript?.trim()}
          </div>
        ))}
      </Accordion>
    </div>
  );
};

const DynamicAttributes = ({ dynamicAttributes }: { dynamicAttributes: Record<string, ContactAttributesValue> }) => {
  if (_isEmpty(dynamicAttributes)) {
    return null;
  }

  return (
    <div className="dynamic-attributes__container">
      {Object.entries(dynamicAttributes).map(([label, attribute]) => (
        <div key={label}>
          <span className="dynamic-attributes__label">
            {normaliseAttributeKey(label.replace(/CW-NEON_|CW-NEON-ACTIVE_|CW-NEON-INC_/gi, ''))}:
          </span>
          {attribute?.value && isURL(attribute?.value as string) ? (
            <a
              className="dynamic-attributes__value__link"
              rel="noreferrer"
              target="_blank"
              href={attribute?.value as string}
            >
              {attribute?.value}
            </a>
          ) : (
            <span className="dynamic-attributes__value">{attribute?.value}</span>
          )}
        </div>
      ))}
    </div>
  );
};

interface IContactAttributes {
  attributes: Record<string, ContactAttributesValue> | undefined;
}

export default function ContactAttributes({ attributes }: IContactAttributes) {
  return (
    <>
      <DynamicAttributes dynamicAttributes={attributes ? _omit(attributes, ['intents', 'transcript']) : {}} />
      <Intents intents={attributes?.intents?.value} />
      <Transcripts transcript={attributes?.transcript?.value} />
    </>
  );
}
