import EventEmitter from 'lib/common/utils/EventEmitter';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import addInteractionHistoryItem from '../utils/addInteractionHistoryItem';

export default async function handleConnectedContact({ contact, handleContactChange, fetch_, config, tokens }) {
  handleContactChange({ contact, status: CONTACT_STATES.CONNECTED });

  addInteractionHistoryItem({ contact: contact, fetch_, config, tokens });

  EventEmitter.emit('onContactConnected', contact);
}
