import { useHotkeys } from 'react-hotkeys-hook';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { Button } from 'lib/common/components/index';
import connectGetter from 'lib/common/utils/connectGetter';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import TUser from 'lib/common/types/User';
import MuteButton from '../MuteButton';
import TaskFooterRow from '../../../TaskFooterRow';
import TransferFooterButtons from '../../../TransferFooterButtons';

interface ICallProps {
  taskId: string;
  connectionValue: string;
  connectionState: string;
  type: string;
  isSmallWidth: boolean;
  isSoftphone?: boolean;
  user: TUser;
}

export default function Call({
  taskId,
  connectionValue,
  connectionState,
  type,
  isSmallWidth,
  isSoftphone,
  user
}: ICallProps) {
  const { agent } = useAgentContext();
  const {
    actions: { resumeCall, holdCall, endTask }
  } = useContactContext();

  // When the agent is on a desk phone, they can only mute/unmute the call on it (not in NEON)
  const isOnDeskPhone =
    !connectGetter('isSoftphoneEnabled', agent) &&
    (type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK);
  const callIsOnHold = connectionState === CONNECTION_STATES.HOLD;

  const toggleHoldStatus = async () => {
    try {
      callIsOnHold ? await resumeCall(taskId) : await holdCall(taskId);
    } catch {}
  };

  useHotkeys(
    AGENT_WORKSPACE_HOTKEYS.IN_CALL_HOLD,
    () => {
      (async () => {
        await toggleHoldStatus();
      })();
    },
    [callIsOnHold, taskId, resumeCall, holdCall]
  );

  return (
    <>
      <TaskFooterRow isSmallWidth={isSmallWidth}>
        <TransferFooterButtons
          taskId={taskId}
          connectionValue={connectionValue}
          isSoftphone={isSoftphone}
          user={user}
          showDialpadTransfer
        />
      </TaskFooterRow>
      <TaskFooterRow primaryButtonIndex={1} isSmallWidth={isSmallWidth}>
        <Button
          icon={callIsOnHold ? 'faPlay' : 'faPause'}
          onClick={toggleHoldStatus}
          tooltip={callIsOnHold ? 'Resume call' : 'Put call on hold'}
          styleType="NEUTRAL"
          round
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        <Button
          onClick={() => endTask(taskId)}
          icon="faPhoneHangup"
          styleType="DANGER"
          tooltip="Hang up"
          round
          large
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        {!isOnDeskPhone && <MuteButton />}
      </TaskFooterRow>
    </>
  );
}
