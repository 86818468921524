import getContactConnection from 'lib/common/utils/getContactConnection';

const TENANT_ID = process.env.REACT_APP_TENANT_ID;

export default async function addInteractionHistoryItem({
  contact,
  fetch_,
  config,
  tokens
}: {
  contact: connect.Contact;
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
  config: any;
  tokens: any;
}) {
  const contactConnection = getContactConnection(contact);
  const agentConnection = contact.getAgentConnection();

  if (!contactConnection || !agentConnection) {
    return;
  }

  const interactionHistory = {
    tenantId: TENANT_ID,
    contactId: contact.contactId,
    historyId: `${TENANT_ID}__${sessionStorage.getItem('c_user')}`,
    calledAt: agentConnection.getStatus().timestamp,
    connectionType: contactConnection.getType(),
    phoneNumber: contactConnection.getEndpoint().phoneNumber
  };

  try {
    await fetch_(
      `${config.CALL_SERVICE_HOST}/callhistory`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(interactionHistory)
      },
      tokens
    );
  } catch (e) {
    console.log(e);
  }
}
