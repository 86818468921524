import CONTACT_STATES from '../constants/contactStates';
import CONTACT_TYPES from '../constants/contactTypes';
import {
  areAllConnectionsConnected,
  areAllConnectionsOnHold,
  areThirdPartyConnectionsDisconnected,
  isConference as isConferenceContact,
  isInitialConnectionDisconnected,
  isThirdPartyConnectionOnHold
} from '../utils/conferenceConnections';
import CONNECTION_STATES from '../constants/connectionStates';

export default function useTaskConnections(task) {
  const { type, contact, connectionState, status } = task;

  const isNeglected = status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED;

  /**
   * Explicitly checking for contact connections rather than contact type
   * as there might be multiple connections (inbound/outbound) which are disconnected but there is
   * still a single active inbound/outbound call which corresponds to type CALL and not CONFERENCE_CALL
   */
  const isConference = !isNeglected && isConferenceContact(contact);
  const isConferenceConnecting = type === CONTACT_TYPES.CONFERENCE_CALL && status === CONTACT_STATES.CONNECTING;
  const allConnectionsOnHold = isConference && areAllConnectionsOnHold(contact);
  const allConnectionsConnected = isConference && areAllConnectionsConnected(contact);
  const initialConnectionDisconnected = isConference && isInitialConnectionDisconnected(contact);
  const thirdPartyConnectionsDisconnected = isConference && areThirdPartyConnectionsDisconnected(contact);
  const connectionsDisconnected = initialConnectionDisconnected || thirdPartyConnectionsDisconnected;
  const callIsOnHold =
    isConference && initialConnectionDisconnected
      ? isThirdPartyConnectionOnHold(contact)
      : connectionState === CONNECTION_STATES.HOLD;

  return {
    isNeglected,
    isConference,
    isConferenceConnecting,
    allConnectionsOnHold,
    allConnectionsConnected,
    callIsOnHold,
    initialConnectionDisconnected,
    thirdPartyConnectionsDisconnected,
    connectionsDisconnected
  };
}
