import React, { FunctionComponent, ReactNode, SVGProps } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as ErrorImage } from 'assets/vectors/error.svg';
import { Button, Text } from 'lib/common/components';

import './error.scss';

type ErrorProps = {
  text?: string | ReactNode;
  title?: string;
  Image?: FunctionComponent<SVGProps<SVGSVGElement>>;
  relative?: boolean;
  hidePrimaryAction?: boolean;
};

function Error({ text, Image, title, relative, hidePrimaryAction }: ErrorProps) {
  const history = useHistory();
  const useDefaults = !text;

  const onClick = () => {
    if (useDefaults) {
      return void window.location.reload();
    }

    history.push('/');
  };

  return (
    <div className={cx('error', { 'error--relative': relative })} data-testid="error-page">
      <div className="error__page">
        <div className="error__content">
          <Text type="heading1">{title || 'Oops'}</Text>
          <Text className="error__content__text mt-10">{text || 'Something went wrong. Try reloading the page.'}</Text>
          {!hidePrimaryAction && <Button onClick={onClick}>{useDefaults ? 'Reload' : 'Take Me Home'}</Button>}
        </div>
        {Image ? <Image /> : <ErrorImage />}
      </div>
    </div>
  );
}

export default Error;
