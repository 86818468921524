import { Button } from 'lib/common/components/index';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import TUser from 'lib/common/types/User';
import TaskFooterRow from '../TaskFooterRow';
import TaskFooter from '../TaskFooter';
import TransferFooterButtons from '../TransferFooterButtons';
import './chat-footer.scss';

export default function Chat({
  taskId,
  connectionValue,
  user,
  isSoftphone
}: {
  taskId: string;
  connectionValue: string;
  user: TUser;
  isSoftphone?: boolean;
}) {
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <TaskFooter>
      <TaskFooterRow className="chat-footer__row" isSingleRow>
        <TransferFooterButtons
          taskId={taskId}
          connectionValue={connectionValue}
          user={user}
          isSoftphone={isSoftphone}
        />
        <Button
          icon="faTimes"
          styleType="DANGER"
          round
          onClick={() => hangupAgent(taskId)}
          large
          preventDoubleClick
          tooltip="End the chat"
          asyncAction
          successTimeoutSeconds={0}
        />
      </TaskFooterRow>
    </TaskFooter>
  );
}
