import { TypeOptions } from 'react-toastify/dist/types';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components';
import styles from './toast-alert.module.scss';

export interface IToastAlertProps {
  title?: string;
  message: string;
  type?: TypeOptions;
}

const TOAST_ICONS = {
  info: faInfoCircle,
  success: faCheckCircle,
  error: faExclamationCircle,
  warning: faExclamationTriangle
};

const ToastAlert = ({ title, message, type = 'info' }: IToastAlertProps) => (
  <div className={styles['toast-alert']}>
    <Icon size={17} icon={TOAST_ICONS[type]} />
    <div className={styles['toast-alert__text']}>
      {title && <h3>{title}</h3>}
      <p>{message}</p>
    </div>
  </div>
);

export default ToastAlert;
