import { useState } from 'react';
import { format, formatDistanceStrict } from 'date-fns';
import { faClock, faComments, faPlug } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import getContactConnection from 'lib/common/utils/getContactConnection';
import connectGetter from 'lib/common/utils/connectGetter';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import getUserName from 'lib/common/utils/getUserName';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { Accordion, Icon } from 'lib/common/components';
import { TaskFooter, ACWOutcome, ChatMessages, SoftphoneCreateAgentTask } from 'lib/common/components/molecules';
import TTask from 'lib/common/types/Task';
import TChatTask from 'lib/common/types/ChatTask';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';

import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';

import styles from './after-contact-work.module.scss';

const AfterContactWork = (props: TTask & { user: any }) => {
  const [campaign, setCampaign] = useState<string | null>(null);
  const [outcome, setOutcome] = useState<string | null>(null);
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const { profile, queueName, time, connectionValue, contact, connectedAtTime = new Date(), type, user } = props;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const endedAt = getContactConnection(contact)
    ? connectGetter('getState', getContactConnection(contact))?.timestamp ?? new Date()
    : new Date();

  const handleOpenChatHistoryModal = () => {
    openModal({
      title: 'Chat History',
      content: (
        <ChatMessages showEmptyPlaceholder messages={(props as unknown as TChatTask).messages} customerName={name} />
      )
    });
  };

  const scheduleCallback = () => {
    openModal({
      title: 'Add A New Agent Task',
      content: (
        <SoftphoneCreateAgentTask
          name="Call A Customer Back"
          description={
            type === CONTACT_TYPES.TASK
              ? ''
              : getScheduleCallbackDescription(connectionValue, profile, connectedAtTime || new Date(), user)
          }
          onClose={closeModal}
        />
      )
    });
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={name} id={name} />
      </InfoPanel>
      <Accordion title="Work" className="mt-10">
        <div className={styles['after-contact-work__info']}>
          <div className={styles['after-contact-work__info__row']}>
            <Icon icon={faPlug} tooltip="Time interaction started" />
            <span>{format(connectedAtTime || new Date(), 'h:mm aa')}</span>
          </div>
          <div className={styles['after-contact-work__info__row']}>
            <Icon icon={faClock} tooltip="Interaction duration" />
            <span>{formatDistanceStrict(connectedAtTime || new Date(), endedAt)}</span>
          </div>
          {type === CONTACT_TYPES.CHAT && (
            <div className={cx(styles['after-contact-work__info__row'], styles['after-contact-work__info__row-chat'])}>
              <Icon icon={faComments} />
              <a onClick={handleOpenChatHistoryModal}>View Chat History</a>
            </div>
          )}
        </div>
        <ACWOutcome campaign={campaign} outcome={outcome} setCampaign={setCampaign} setOutcome={setOutcome} />
      </Accordion>
      <AttachedData task={props} />
      <TaskFooter.ACW outcome={outcome} campaign={campaign} scheduleCallback={scheduleCallback} />
    </>
  );
};

export default AfterContactWork;
