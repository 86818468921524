import { AgentStats as PureAgentStats } from 'lib/common/components/molecules';
import { useSoftphoneLayout } from 'lib/common/contexts/SoftphoneLayoutContext';

export default function AgentStats() {
  const { setHeaderLoading } = useSoftphoneLayout();

  const setLoading = () => {
    setHeaderLoading(true);
  };

  const setNotLoading = () => {
    setHeaderLoading(false);
  };

  return <PureAgentStats isSoftphone setLoaderLoading={setLoading} setLoaderNotLoading={setNotLoading} />;
}
