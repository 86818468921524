import { faSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import { Icon, ClickableIcon } from 'lib/common/components';
import { TASK_TYPES, TASK_COLOURS } from 'lib/common/constants/taskStates';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import getUserName from 'lib/common/utils/getUserName';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import TTask from 'lib/common/types/Task';
import TChatTask from 'lib/common/types/ChatTask';

import styles from './header-task-banner.module.scss';

export default function HeaderTaskBanner({ task, hideSubtitle }: { task: TTask | TChatTask; hideSubtitle?: boolean }) {
  const time = useTaskTimer(task?.time);
  const history = useHistory();

  const pushToTask = () => {
    history.push(`${PAGE_ROUTES.WORKSPACE}?taskId=${task.taskId}`);
  };

  if (!task) {
    return null;
  }

  const userName = task.profile?.firstName ? getUserName(task?.profile) : task?.connectionValue;
  const taskProps = TASK_TYPES(task.connectionType)?.[task.status]?.[task.type] || {};

  return (
    <div className={styles['header-task-banner']}>
      <Icon color={TASK_COLOURS[task.status]} icon={taskProps.icon} size={20} />
      <div className={styles['header-task-banner__text']}>
        {!hideSubtitle && <h2>{userName}</h2>}
        <div className={styles['header-task-banner__text__connection']}>
          <span>
            <strong>{hideSubtitle ? userName : taskProps.subtitle}</strong>
          </span>
          <span>| {time}</span>
        </div>
      </div>
      <ClickableIcon
        className={styles['header-task-banner__action']}
        icon={faSquareArrowUpRight}
        onClick={pushToTask}
      />
    </div>
  );
}
