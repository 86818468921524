import { useState } from 'react';
import { LayoutColumnsIcon, Modal } from 'lib/common/components';
import WORKSPACE_LAYOUT from 'lib/common/constants/workspaceLayouts';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import './choose-layout-modal.scss';

const LAYOUT_SET_KEY = 'first-layout-set';

export default function ChooseLayoutModal() {
  const {
    state: { defaultWorkspaceLayout },
    actions: { setDefaultWorkspaceLayout }
  } = usePreferencesContext();

  const [layoutChosen, setLayoutChosen] = useState(Boolean(localStorage.getItem(LAYOUT_SET_KEY)));

  const onClose = () => {
    localStorage.setItem(LAYOUT_SET_KEY, 'true');
    setLayoutChosen(true);
  };

  return (
    <Modal
      open={!layoutChosen}
      hideSecondaryButton
      primaryButtonText="Close"
      title="Default Workspace Layout"
      onClose={onClose}
      onSave={onClose}
    >
      <div className="choose-layout-modal">
        <p className="mb-30">
          Choose a default layout to use on <strong>larger screens</strong>. The two column layout will always be used
          on smaller screens. Columns can be resized.
        </p>
        <div className="choose-layout-modal__options">
          <button
            data-testid="choose-layout-modal-option-three-column"
            className="choose-layout-modal__options__option"
            onClick={() => setDefaultWorkspaceLayout(WORKSPACE_LAYOUT.THREE_COLUMNS)}
          >
            <LayoutColumnsIcon
              large
              inverseColours
              selected={defaultWorkspaceLayout === WORKSPACE_LAYOUT.THREE_COLUMNS}
            />
          </button>
          <button
            data-testid="choose-layout-modal-option-two-column"
            className="choose-layout-modal__options__option"
            onClick={() => setDefaultWorkspaceLayout(WORKSPACE_LAYOUT.TWO_COLUMNS)}
          >
            <LayoutColumnsIcon
              large
              inverseColours
              twoColumns
              selected={defaultWorkspaceLayout === WORKSPACE_LAYOUT.TWO_COLUMNS}
            />
          </button>
        </div>
        <p className="mt-30">You can change the default layout at any time in the preferences window.</p>
      </div>
    </Modal>
  );
}
