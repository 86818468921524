import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { ClickableIcon } from 'lib/common/components';

import './softphone-nav-menu-item.scss';
import { SoftphoneNavOption } from '../../../../../../types/SoftphoneNavOption';

export default function SoftphoneNavMenuItem({
  permissionType,
  permission,
  link,
  icon,
  name
}: SoftphoneNavOption & { link: string }) {
  const location = useLocation();
  const history = useHistory();
  const { hasPermission } = usePermissionsContext();

  return (
    <>
      {hasPermission({ type: permissionType || 'tenant', permission }) && (
        <div
          className={cx('softphone-nav-menu-item', {
            'softphone-nav-menu-item--active': location.pathname === link
          })}
        >
          <ClickableIcon
            icon={icon}
            withHover={false}
            tooltip={name}
            onClick={() => history.push(link)}
            tooltipPlacement="right"
            size={18}
          />
        </div>
      )}
    </>
  );
}
