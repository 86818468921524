import cx from 'classnames';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon } from 'lib/common/components';
import { TaskStatusBadge } from 'lib/common/components/atoms';
import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import StatusIcon from './StatusIcon';
import ConferenceActions from './ConferenceActions';
import '../styles/user-information.scss';

interface IUserInformation {
  isRow: boolean;
  icon: IconProp | string;
  name: string;
  callIsOnHold: boolean;
  isNeglected: boolean;
  time: Date;
  connectionType: ValueOf<typeof CONNECTION_TYPES>;
  type: ValueOf<typeof CONTACT_TYPES>;
  isConferenceConnecting: boolean;
  allConnectionsOnHold: boolean;
  allConnectionsConnected: boolean;
  onDisconnect: () => void;
  holdConnection: () => void;
  resumeConnection: () => void;
  status: ValueOf<typeof CONTACT_STATES>;
  isSmallWidth: boolean;
}

export default function UserInformation({
  isRow,
  icon,
  name,
  callIsOnHold,
  isNeglected,
  time,
  connectionType,
  type,
  status,
  isConferenceConnecting,
  allConnectionsOnHold,
  allConnectionsConnected,
  onDisconnect,
  holdConnection,
  resumeConnection,
  isSmallWidth
}: IUserInformation) {
  const taskTime = useTaskTimer(time);

  return (
    <>
      {!isRow && <h2>{name}</h2>}
      <div
        className={cx('user-information', {
          'user-information--on-hold': callIsOnHold,
          'user-information--neglected': isNeglected,
          'user-information--row': isRow
        })}
      >
        {isRow && !isSmallWidth && (
          <StatusIcon
            size={30}
            callIsOnHold={callIsOnHold}
            isNeglected={isNeglected}
            connectionType={connectionType}
            type={type}
            status={status}
          />
        )}
        {isRow && <Icon icon={icon} />}
        {isRow && <h2>{name}</h2>}
        <TaskStatusBadge
          isSmallWidth={isSmallWidth}
          isRow={isRow}
          isNeglected={isNeglected}
          callIsOnHold={callIsOnHold}
          isConferenceConnecting={isConferenceConnecting}
          status={status}
        />
        {isRow && (
          <ConferenceActions
            onDisconnect={onDisconnect}
            allConnectionsConnected={allConnectionsConnected}
            allConnectionsOnHold={allConnectionsOnHold}
            holdConnection={holdConnection}
            resumeConnection={resumeConnection}
          >
            <button className="user-information__action-button">
              <Icon size={15} icon={faEllipsisVertical} />
            </button>
          </ConferenceActions>
        )}
        {!isRow && <p>{taskTime}</p>}
      </div>
    </>
  );
}
