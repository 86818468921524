import { ReactNode, useEffect, useRef, useState, cloneElement, ReactElement } from 'react';
import { useBooleanState } from 'webrix/hooks';
import cx from 'classnames';
import Draggable from 'react-draggable';
import { faTimes, faWindowRestore } from '@fortawesome/pro-regular-svg-icons';
import VARS from 'css/export-vars.module.scss';
import parseJSON from '../../utils/parseJSON';
import ClickableIcon from '../ClickableIcon';
import Loader from '../Loader';
import './styles/draggable-overlay.scss';

const ASSUMED_MIN_HEIGHT = 300;

export enum DraggableOverlaySize {
  SMALL = 'sm',
  LARGE = 'lg'
}

interface DraggableOverlayProps {
  id: string;
  open: boolean;
  title?: string;
  size?: DraggableOverlaySize;
  height?: string;
  className?: string;
  onClose: () => void;
  triggerSelector: string;
  children: ReactNode;
  canShowLoading?: boolean;
}

function getInitialPosition({ id, triggerSelector, size = DraggableOverlaySize.SMALL, ignoreStored = false }) {
  const storedPosition = parseJSON(localStorage.getItem(id) || '');

  const width =
    size === DraggableOverlaySize.SMALL ? parseInt(VARS.overlayWidthSmall, 10) : parseInt(VARS.overlayWidthLarge, 10);

  const fallback = { x: document.body.getBoundingClientRect().width / 2 - width / 2, y: 75 };

  if (
    !ignoreStored &&
    storedPosition &&
    storedPosition.x &&
    storedPosition.y &&
    storedPosition.x + width < document.body.getBoundingClientRect().width &&
    storedPosition.y + ASSUMED_MIN_HEIGHT < document.body.getBoundingClientRect().height
  ) {
    return storedPosition;
  }

  const trigger = document.querySelector(triggerSelector);

  if (!trigger) {
    return fallback;
  }

  const { x: triggerX, width: triggerWidth } = trigger.getBoundingClientRect();
  const xPos = triggerX + triggerWidth / 2;

  return { x: xPos - width / 2, y: 75 };
}

export default function DraggableOverlay({
  id,
  triggerSelector,
  size = DraggableOverlaySize.SMALL,
  className,
  onClose,
  title,
  children,
  open,
  canShowLoading = false
}: DraggableOverlayProps) {
  const draggableRef = useRef(null);

  const [position, setPosition] = useState(getInitialPosition({ id, triggerSelector, size }));
  const [hasMaxHeight, setHasMaxHeight] = useState(false);
  const { value: isLoaderLoading, setTrue: setLoaderLoading, setFalse: setLoaderNotLoading } = useBooleanState();

  const onFinishMove = (_: any, { x, y }) => {
    localStorage.setItem(id, JSON.stringify({ x, y }));
  };

  const onMove = (_: any, coords) => {
    setPosition(coords);
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  const onResetPosition = () => {
    const initialPosition = getInitialPosition({ id, triggerSelector, size, ignoreStored: true });

    setPosition(initialPosition);

    onFinishMove(null, initialPosition);
  };

  useEffect(() => {
    setPosition(getInitialPosition({ id, triggerSelector, size }));

    const { height } = document.body.getBoundingClientRect();

    // 600 is the smallest height for desktops
    if (height <= 600) {
      return void setHasMaxHeight(true);
    }

    setHasMaxHeight(false);
  }, [open]);

  return (
    <div data-testid="draggable-overlay-testid">
      <Draggable
        ref={draggableRef}
        handle=".draggable-overlay__header"
        position={position}
        bounds="body"
        onStop={onFinishMove}
        onDrag={onMove}
      >
        <div
          className={cx('draggable-overlay', className, {
            'draggable-overlay--hidden': !open,
            'draggable-overlay--large': size !== DraggableOverlaySize.SMALL
          })}
          data-testid="draggable-overlay-container"
        >
          <div className="draggable-overlay__header" data-testid="draggable-overlay-drag-handle">
            <ClickableIcon
              onClick={onResetPosition}
              icon={faWindowRestore}
              className="draggable-overlay__header__reset"
              tooltip="Reset window position"
            />
            <div className="draggable-overlay__header__title">
              <h2 data-testid="draggable-overlay-drag-title">{title}</h2>
              <Loader
                size={20}
                minimised
                className={cx('draggable-overlay__header__title__loader', {
                  'draggable-overlay__header__title__loader--visible': isLoaderLoading
                })}
              />
            </div>
            <ClickableIcon onClick={onCloseClick} icon={faTimes} data-testid="draggable-overlay-drag-close" size={25} />
          </div>
          <hr />
          <div
            className={cx('draggable-overlay__content', {
              'draggable-overlay__content--large': size == DraggableOverlaySize.LARGE,
              'draggable-overlay__content--max-height': hasMaxHeight
            })}
          >
            {canShowLoading
              ? cloneElement(children as ReactElement, { setLoaderLoading, setLoaderNotLoading })
              : children}
          </div>
        </div>
      </Draggable>
    </div>
  );
}
