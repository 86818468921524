import { useState, useRef, ReactNode, Children, useEffect } from 'react';
import _get from 'lodash.get';
import cx from 'classnames';

import Tab from './Tab';

import '../styles/tabs.scss';

interface TabsProps {
  children: ReactNode;
  updateSelectedTabIndex: (selectedTabIndex: number) => void;
  selectedIndex?: number;
  showSelectedTabLoader?: boolean;
  className?: string;
}

function getSelectedLineStyles(numberOfTabs, selectedTabIndex) {
  const calcTabWidth = 100 / numberOfTabs;

  return { left: `${calcTabWidth * selectedTabIndex}%`, width: `${calcTabWidth}%` };
}

export default function Tabs(props: TabsProps) {
  const { selectedIndex, children, updateSelectedTabIndex, showSelectedTabLoader = false, className } = props;
  const tabContentRef = useRef<HTMLDivElement>(null);

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(selectedIndex || 0);

  useEffect(() => {
    setSelectedTabIndex(selectedIndex || 0);
  }, [selectedIndex]);

  const onSelectTab = (selectedTabIndex: number) => {
    setSelectedTabIndex(selectedTabIndex);
    updateSelectedTabIndex(selectedTabIndex);

    if (!tabContentRef.current) {
      return;
    }

    tabContentRef.current.scrollTop = 0;
  };

  const tabs = Children.toArray(children);

  return (
    <div className={cx('tabs', className)}>
      <ol className="tabs__list">
        {tabs.map((tab, index) => (
          <Tab
            key={_get(tab, 'props.title')}
            isSelected={index === selectedTabIndex}
            title={_get(tab, 'props.title')}
            onClick={() => onSelectTab(index)}
            showSelectedTabLoader={index === selectedTabIndex && showSelectedTabLoader}
          />
        ))}
      </ol>
      <div className="tabs__selected-line" style={getSelectedLineStyles(tabs.length, selectedTabIndex)} />
      <div className="tabs__tab-content" ref={tabContentRef}>
        {tabs[selectedTabIndex]}
      </div>
    </div>
  );
}
