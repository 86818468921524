import { Component } from 'react';
import {
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Loader, EmptyPlaceholder, Button, Alert, Badge, DataTable, TourControl } from 'lib/common/components';
import ManageCampaignsModal from './ManageCampaigns';
import './campaigns.scss';

const tenantId = process.env.REACT_APP_TENANT_ID;

type IProps = Record<string, unknown>;

interface IStates {
  loading: boolean;
  showCampaignsPage: boolean;
  showManageModal: boolean;
  campaigns: any[];
  rowSelected: any;
  editMode: boolean;
  modalStatus: boolean;
  outcomes: any;
  routingProfiles: any[];
  showAlert: boolean;
  alertSeverity: 'error' | 'info' | 'success' | 'warning' | undefined;
  alertMsg: string;
}

export default class Campaigns extends Component<IProps, IStates> {
  static contextType = AuthContext;

  context!: React.ContextType<typeof AuthContext>;

  config: any;

  fetch_: any;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showCampaignsPage: false,
      showManageModal: false,
      campaigns: [],
      rowSelected: null,
      editMode: false,
      modalStatus: false,
      outcomes: [],
      routingProfiles: [],
      alertSeverity: 'success',
      alertMsg: '',
      showAlert: false
    };
  }

  UNSAFE_componentWillMount() {
    this.config = this.context.config;
    this.fetch_ = this.context.fetch_;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getOutcomes();
  }

  toggleAddCampaign = () => {
    this.setState({
      showManageModal: !this.state.showManageModal,
      rowSelected: null,
      editMode: false
    });
  };

  getCampaigns = async () => {
    try {
      const res = await this.fetch_(`${this.config.CALL_SERVICE_HOST}/campaigns/?tenantId=${tenantId}`);
      const result = await res.json();

      this.setState({
        campaigns: result.data.map((c) => {
          if (c.hasOwnProperty('callOutcomes') && c.callOutcomes.length > 0 && typeof c.callOutcomes[0] !== 'string') {
            c.callOutcomesName = c.callOutcomes.map((code) => {
              const outcomeName = this.state.outcomes.filter((o) => o.code === code)[0];
              return outcomeName !== undefined ? outcomeName.name : 'N/A';
            });
          } else {
            c.callOutcomesName = ['No call outcomes assigned'];
          }
          c.routingProfile = `${this.state.routingProfiles.filter((r) => r.Id === c.routingProfile[0])[0].Name}-${
            c.routingProfile[0]
          }`;
          return c;
        }),
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false,
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getOutcomes = async () => {
    try {
      const res = await this.fetch_(`${this.config.CALL_SERVICE_HOST}/getoutcomes/?tenantId=${tenantId}`);
      const result = await res.json();

      this.setState(
        {
          outcomes: result.data
        },
        () => {
          this.getRoutingProfiles();
        }
      );
    } catch (e) {
      this.setState({
        alertSeverity: 'error',
        showAlert: true,
        loading: false,
        alertMsg: 'There was an error fetching this data. Please try refreshing the page.'
      });
    }
  };

  getOptions() {
    return {
      filterType: 'checkbox',
      selectableRows: 'single',
      responsive: 'vertical',
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      sortOrder: {
        name: 'campaignId',
        direction: 'asc'
      },
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: 10,
      rowsSelected: this.state.rowSelected,
      customToolbarSelect: (selectedRows, displayData) => {
        return (
          <Grid container direction="column" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
              <Tooltip title="Edit Campaign">
                <IconButton onClick={() => this.editCampaign(displayData[selectedRows.data[0].index])}>
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Campaign">
                <IconButton onClick={() => this.deleteCampaign(displayData[selectedRows.data[0].index])}>
                  <FontAwesomeIcon icon={faTrash} color="red" size="sm" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
      downloadOptions: {
        filename: 'Neon Campaigns.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: false
        }
      }
    };
  }

  deleteCampaign(campaign) {
    this.setState({
      modalStatus: true,
      rowSelected: campaign
    });
  }

  handleClose(deleteCampaign) {
    if (deleteCampaign) {
      this.fetch_(
        `${this.config.CALL_SERVICE_HOST}/deletecampaign/${this.state.rowSelected.data[0]}/?tenantId=${tenantId}`,
        {
          method: 'DELETE'
        }
      )
        .then((res) => res.json())
        .then((msg) => {
          if (msg.statusCode === 500) {
            // failed to delete
            this.setState({
              alertMsg: msg.response,
              alertSeverity: 'error',
              showAlert: true
            });
          } else {
            this.setState({
              alertMsg: msg.response,
              alertSeverity: 'success',
              showAlert: true
            });
          }
          this.getCampaigns();
        })
        .catch((e) => {
          this.setState({
            alertMsg: `Error deleting campaign: ${e}`,
            alertSeverity: 'error',
            showAlert: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({
              showAlert: false
            });
          }, 10000);
        });
    }
    this.setState({
      modalStatus: false
    });
  }

  editCampaign(campaign) {
    let outcomesArr;
    if (campaign.data[3].length > 0) {
      outcomesArr = campaign.data[3].map((c) => {
        return {
          value: c,
          label: c
        };
      });
    } else {
      outcomesArr = [];
    }
    this.setState({
      editMode: true,
      rowSelected: {
        objectId: campaign.data[0],
        name: campaign.data[1],
        type: campaign.data[2],
        callOutcomes: outcomesArr,
        routingProfile: {
          Id: campaign.data[5].props.children[1].props.children,
          Name: campaign.data[5].props.children[0].props.children
        },
        isOutcomeCompulsary: campaign.data[6].props.children.props.id,
        isActive: campaign.data[7].props.children.props.id
      },
      showManageModal: true
    });
  }

  getRoutingProfiles() {
    this.fetch_(`${this.config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/list/routing-profiles`, {
      method: 'GET'
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState(
          {
            routingProfiles: result
          },
          () => {
            this.getCampaigns();
          }
        );
      })
      .catch((e) => {
        console.error('Error getting list of routing profiles', e);
      });
  }

  getColumns() {
    return [
      {
        name: 'objectId',
        label: 'Campaign ID',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          filterType: 'dropdown'
        }
      },
      {
        name: 'type',
        label: 'Type',
        options: {
          filterType: 'dropdown'
        }
      },
      {
        name: 'callOutcomes',
        label: 'Interaction Outcomes',
        options: {
          filterType: 'dropdown',
          display: false
        }
      },
      {
        name: 'callOutcomesName',
        label: 'Interaction Outcomes',
        options: {
          customBodyRender: (value) => (
            <Typography className="outcomes-txt">
              {value.map((v, i) => {
                return (
                  <span id={v} key={v}>
                    {(i > 0 ? ', ' : '') + v}
                  </span>
                );
              })}
            </Typography>
          ),
          filterType: 'dropdown'
        }
      },
      {
        name: 'routingProfile',
        label: 'Routing Profile',
        options: {
          customBodyRender: (value) => (
            <div>
              <p>{value == null ? 'N/A' : value.split('-')[0]}</p>
              <p style={{ display: 'none' }}>{value.substr(value.split('-')[0].length + 1, value.length)}</p>
            </div>
          ),
          filterType: 'dropdown'
        }
      },
      {
        name: 'isOutcomeCompulsary',
        label: 'Call Outcome Required',
        options: {
          customBodyRender: (value) => (
            <p style={{ width: 10 }}>
              <p>
                <Badge label={value ? 'Yes' : 'No'} type={value ? 'SUCCESS' : 'ERROR'} />
              </p>
            </p>
          ),
          filterType: 'dropdown'
        }
      },
      {
        name: 'isActive',
        label: 'Active',
        options: {
          customBodyRender: (value) => (
            <p>
              <Badge label={value ? 'Yes' : 'No'} type={value ? 'SUCCESS' : 'ERROR'} />
            </p>
          ),
          filterType: 'dropdown'
        }
      }
    ];
  }

  render() {
    const {
      loading,
      showManageModal,
      campaigns,
      rowSelected,
      editMode,
      modalStatus,
      outcomes,
      routingProfiles,
      showAlert,
      alertSeverity,
      alertMsg
    } = this.state;

    return (
      <div className="campaigns">
        {loading && <Loader />}
        {!loading && !campaigns.length ? <EmptyPlaceholder error={showAlert && alertSeverity === 'error'} /> : null}
        {!loading && (
          <Button
            data-testid="add-campaign-button"
            className="campaigns__add"
            onClick={this.toggleAddCampaign}
            icon="faPlus"
          />
        )}
        {!loading && campaigns.length ? (
          <div data-tour="campaign-settings" className="panel">
            <TourControl />
            <DataTable
              data={campaigns}
              columns={this.getColumns()}
              options={this.getOptions()}
              title={<Alert show={showAlert} severity={alertSeverity} message={alertMsg} />}
            />
          </div>
        ) : null}
        <ManageCampaignsModal
          getCampaigns={this.getCampaigns}
          editMode={editMode}
          rowSelected={rowSelected}
          outcomes={outcomes}
          campaigns={campaigns}
          routingProfiles={routingProfiles}
          toggleAddCampaign={this.toggleAddCampaign}
          show={showManageModal}
        />
        <Dialog
          open={modalStatus}
          keepMounted
          onClose={() => this.handleClose(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Delete campaign</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this campaign?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} styleType="SECONDARY">
              Cancel
            </Button>
            <Button onClick={() => this.handleClose(true)}>Continue</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
