import { createContext, useContext, useState } from 'react';
import WORKSPACE_LAYOUTS from 'lib/common/constants/workspaceLayouts';
import RINGTONE_OPTIONS from 'lib/common/constants/ringtoneOptions';
import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import { isWindows } from 'lib/common/utils/isWindows';

const DEFAULT_INCOMING_TASK_PLACEMENT = isWindows() ? INCOMING_TASK_PLACEMENTS.TOP : INCOMING_TASK_PLACEMENTS.BOTTOM;

type TPreferencesContext = {
  state: {
    defaultWorkspaceLayout: ValueOf<typeof WORKSPACE_LAYOUTS>;
    incomingTaskPlacement: ValueOf<typeof INCOMING_TASK_PLACEMENTS>;
    ringtone: string;
  };
  actions: {
    setDefaultWorkspaceLayout: (layout: ValueOf<typeof WORKSPACE_LAYOUTS>) => void;
    setIncomingTaskPlacement: (incomingTaskPlacement: ValueOf<typeof INCOMING_TASK_PLACEMENTS>) => void;
    setRingtone: (ringtone: string) => void;
  };
};

const PREFERENCE_IDS = {
  DEFAULT_WORKSPACE_LAYOUT: 'default-workspace-layout',
  RINGTONE: 'ringtone',
  INCOMING_TASK_PLACEMENT: 'incoming-task-placement'
};

function getInitialPreferenceValue(id, defaultValue) {
  return localStorage.getItem(`${id}-preference`) || defaultValue;
}

function setPreference(id, value) {
  return localStorage.setItem(`${id}-preference`, value);
}

const Context = createContext<TPreferencesContext>({
  state: {
    incomingTaskPlacement: DEFAULT_INCOMING_TASK_PLACEMENT,
    defaultWorkspaceLayout: WORKSPACE_LAYOUTS.THREE_COLUMNS,
    ringtone: RINGTONE_OPTIONS[0].value
  },
  actions: {
    setDefaultWorkspaceLayout: () => {},
    setIncomingTaskPlacement: () => {},
    setRingtone: () => {}
  }
});

/** This context is only for global preferences that are needed by multiple components.
 * Other agent preferences can be found in the preferences overlay **/
export default function PreferencesContext({ children }) {
  const [defaultWorkspaceLayout, setDefaultWorkspaceLayout] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.DEFAULT_WORKSPACE_LAYOUT, WORKSPACE_LAYOUTS.THREE_COLUMNS)
  );

  const [incomingTaskPlacement, setIncomingTaskPlacement] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.INCOMING_TASK_PLACEMENT, DEFAULT_INCOMING_TASK_PLACEMENT)
  );

  const [ringtone, setRingtone] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.RINGTONE, RINGTONE_OPTIONS[0].value)
  );

  const onChangePreference = (id, setter) => (value) => {
    setter(value);
    setPreference(id, value);
  };

  return (
    <Context.Provider
      value={{
        state: { defaultWorkspaceLayout, ringtone, incomingTaskPlacement },
        actions: {
          setDefaultWorkspaceLayout: onChangePreference(
            PREFERENCE_IDS.DEFAULT_WORKSPACE_LAYOUT,
            setDefaultWorkspaceLayout
          ),
          setIncomingTaskPlacement: onChangePreference(
            PREFERENCE_IDS.INCOMING_TASK_PLACEMENT,
            setIncomingTaskPlacement
          ),
          setRingtone: onChangePreference(PREFERENCE_IDS.RINGTONE, setRingtone)
        }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const { Consumer: PreferencesConsumer, Provider: PreferencesProvider } = Context;

export const usePreferencesContext = () => useContext(Context);
