import { useContext, useEffect, useState } from 'react';
import { formatISO } from 'date-fns';
import { ConfigContext } from '../../../core/config';
import { AuthContext } from '../../../core/context/AuthProvider';

const ONE_DAY_IN_MS = 86400000;

function getDefaultStartDate() {
  const startOfToday = new Date();

  startOfToday.setHours(0, 0, 0, 0);

  const threeDaysAgo = new Date();

  // Set the date to 3 days ago at midnight
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
  threeDaysAgo.setHours(0, 0, 0, 0);

  return formatISO(threeDaysAgo);
}

export default function useInteractionHistory() {
  const [history, setHistory] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(formatISO(new Date(new Date().getTime() + ONE_DAY_IN_MS)));
  const [error, setError] = useState(false);

  const { config } = useContext(ConfigContext);
  const { fetch_ } = useContext(AuthContext);

  useEffect(() => {
    loadHistory();
  }, [startDate, endDate]);

  const loadHistory = async () => {
    setError(false);
    setLoaded(false);

    try {
      const historyId = `${process.env.REACT_APP_TENANT_ID}__${sessionStorage.getItem('c_user')}`;

      const start = `start=${startDate}`;
      const end = `end=${endDate}`;
      const join = start && end ? '&' : '';

      const res = await fetch_(`${config.CALL_SERVICE_HOST}/callhistory/${historyId}/?${start}${join}${end}`);
      const data = await res.json();

      setHistory(
        data.items.map((user) => {
          return {
            firstName: user.firstName,
            lastName: user.lastName,
            phoneNumber: user.phoneNumber,
            type: user.connectionType,
            timestamp: user.calledAt,
            contactId: user.contactId
          };
        })
      );
    } catch {
      setError(true);
    } finally {
      setLoaded(true);
    }
  };

  const onDateChange = (date) => {
    if (!date?.start?.getTime() || !date?.end?.getTime()) {
      return;
    }

    date.start.setHours(0, 0, 0, 0);
    date.end.setHours(0, 0, 0, 0);

    // end date should be the start of the next day
    const startOfTomorrowDate = new Date(date.end.getTime() + ONE_DAY_IN_MS);

    setStartDate(formatISO(date.start));
    setEndDate(formatISO(startOfTomorrowDate));
  };

  return {
    startDate,
    endDate,
    onDateChange,
    error,
    historyData: history,
    loaded
  };
}
