import { TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import getUserName from 'lib/common/utils/getUserName';
import { Button } from 'lib/common/components';
import ChatTask from 'lib/common/types/ChatTask';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';
import { TaskFooter, ChatMessages } from 'lib/common/components/molecules';
import TUser from 'lib/common/types/User';

import '../styles/chat.scss';

type TChat = Pick<ChatTask, 'messages' | 'profile' | 'connectionValue' | 'taskId' | 'time' | 'customerTyping'> & {
  minimised?: boolean;
  showEmptyPlaceholder?: boolean;
  queueName?: string;
  user: TUser;
};

function Chat({
  messages,
  connectionValue,
  profile,
  time,
  customerTyping,
  taskId,
  minimised,
  showEmptyPlaceholder,
  queueName,
  user
}: TChat) {
  const [message, setMessage] = useState('');
  const [typing, setTyping] = useState(false);
  const [sending, setSending] = useState(false);
  const customerName = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<any>(null);

  const {
    actions: { sendMessage, sendTypingEvent }
  } = useContactContext();

  const scrollToBottom = () => {
    scrollContainerRef?.current?.scrollTo({
      top: scrollContainerRef?.current?.scrollHeight
    });
  };

  const onChangeInputMessage = ({ target: { value } }) => {
    setMessage(value);

    if (typing) {
      return;
    }

    sendTypingEvent(taskId);
    setTyping(true);
  };

  const onSendMessage = async () => {
    if (!message) {
      return;
    }

    setSending(true);
    setTyping(false);

    await sendMessage(taskId, message);

    setMessage('');
  };

  useEffect(scrollToBottom, [messages, customerTyping]);

  return (
    <div className="chat">
      {!minimised && <QueueNameAndTimer queueName={queueName} time={time} />}
      <div className="chat__box-container">
        <ChatMessages
          customerName={customerName}
          messages={messages}
          customerTyping={customerTyping}
          showEmptyPlaceholder={showEmptyPlaceholder}
          scrollContainerRef={scrollContainerRef}
        />
        {!minimised && (
          <>
            <div className="chat__send">
              <TextField
                variant="outlined"
                placeholder="Enter a message"
                value={message}
                onChange={onChangeInputMessage}
                inputRef={messageInputRef}
                disabled={sending}
                // We use the ref click here to get the full button action states
                onKeyDown={onEnterKeyPressed(() => submitButtonRef?.current?.click())}
                data-testid="chat-message-input"
              />
              <Button
                ref={submitButtonRef}
                icon="faPaperPlane"
                disabled={!message}
                onClick={onSendMessage}
                asyncAction
                onFinally={() => {
                  setSending(false);
                  messageInputRef?.current?.focus();
                }}
                successTimeoutSeconds={0}
                data-testid="send-chat-message-button"
              />
            </div>
            <TaskFooter.Chat taskId={taskId} connectionValue={connectionValue} user={user} />
          </>
        )}
      </div>
    </div>
  );
}

export default Chat;
