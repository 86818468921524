import { Button } from 'lib/common/components/index';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useDirectoryContext } from 'lib/common/contexts/DirectoryContext';
import connectGetter from 'lib/common/utils/connectGetter';

import TaskFooterRow from '../../../TaskFooterRow';
import MuteButton from '../MuteButton';

export default function Conference({
  taskId,
  thirdPartyConnection,
  isConferenceConnecting,
  allConnectionsOnHold,
  allConnectionsConnected,
  initialConnectionDisconnected,
  thirdPartyConnectionsDisconnected,
  isSmallWidth
}) {
  const {
    actions: {
      onJoinConference,
      onHoldAll,
      onSwapConferenceCall,
      holdOutboundCall,
      resumeOutboundCall,
      hangupAgent,
      endThirdPartyConnection
    }
  } = useContactContext();
  const { openDirectory } = useDirectoryContext();

  const onClickDialpad = () => openDirectory({ selectedTabIndex: 0, isDialPadTransfer: true });

  const thirdPartyConnectionOnHold =
    connectGetter('getState', thirdPartyConnection)?.type === connect.ConnectionStateType.HOLD;

  const conferenceLive = !isConferenceConnecting && !(allConnectionsOnHold || allConnectionsConnected);

  return (
    <>
      <TaskFooterRow isSmallWidth={isSmallWidth}>
        {conferenceLive && (
          <Button
            onClick={() => onJoinConference(taskId)}
            icon="faArrowRightToBracket"
            tooltip="Join everyone on the call"
            styleType="NEUTRAL"
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        {conferenceLive && (
          <Button
            onClick={() => onSwapConferenceCall(taskId)}
            tooltip="Swap the person on hold"
            icon="faExchange"
            styleType="NEUTRAL"
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        <Button icon="dialpad" onClick={onClickDialpad} tooltip="Dialpad" styleType="NEUTRAL" round />
      </TaskFooterRow>
      <TaskFooterRow primaryButtonIndex={1} isSmallWidth={isSmallWidth}>
        {!isConferenceConnecting && !thirdPartyConnectionsDisconnected && !initialConnectionDisconnected && (
          <Button
            onClick={() => (allConnectionsOnHold ? onJoinConference(taskId) : onHoldAll(taskId))}
            tooltip={allConnectionsOnHold ? 'Resume All' : 'Hold All'}
            icon={allConnectionsOnHold ? 'faPlay' : 'faPause'}
            styleType="NEUTRAL"
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        {initialConnectionDisconnected && thirdPartyConnection && (
          <Button
            onClick={() => (thirdPartyConnectionOnHold ? resumeOutboundCall(taskId) : holdOutboundCall(taskId))}
            tooltip={thirdPartyConnectionOnHold ? 'Resume Agent Call' : 'Put Agent On Hold'}
            icon={thirdPartyConnectionOnHold ? 'faPlay' : 'faPause'}
            styleType="NEUTRAL"
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        {initialConnectionDisconnected && thirdPartyConnection && (
          <Button
            onClick={() => endThirdPartyConnection(taskId)}
            icon="faPhoneHangup"
            styleType="DANGER"
            tooltip="Hang up"
            round
            large
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        {!thirdPartyConnectionsDisconnected && !initialConnectionDisconnected && (
          <Button
            icon="faRightFromBracket"
            tooltip="Leave the conference"
            styleType="DANGER"
            round
            onClick={() => hangupAgent(taskId)}
            large
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
        <MuteButton />
      </TaskFooterRow>
    </>
  );
}
