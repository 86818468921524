import { faFilterList } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { Text, ReactSelect, ClickableIcon, PopoverMenu } from 'lib/common/components';
import { DateSelector } from 'lib/common/components/atoms';

import styles from './header.module.scss';

const SORT_TYPE_OPTIONS = [
  { label: 'Time', value: 'timestamp' },
  { label: 'Type', value: 'phoneNumber' },
  { label: 'Contact', value: 'firstName' }
];

const SORT_DIRECTION_OPTIONS = [
  { label: 'Descending', value: 'desc' },
  { label: 'Ascending', value: 'asc' }
];

export default function Header({
  onDateChange,
  currentPage,
  onSelectPage,
  pages,
  setSort,
  setSortDirection,
  startDate,
  endDate
}) {
  return (
    <div className={styles['softphone-interaction-history-header']}>
      {Boolean(pages.length) && (
        <div className={styles['softphone-interaction-history-header__page']}>
          <Text>Page</Text>
          <ReactSelect
            className={styles['softphone-interaction-history-header__page__options']}
            options={pages.map((pageNumber) => ({ label: pageNumber, value: pageNumber }))}
            value={{ label: currentPage, value: currentPage }}
            onChange={({ value }) => onSelectPage(value)}
            isSearchable={false}
            small
          />
        </div>
      )}
      <PopoverMenu anchor={<ClickableIcon className="ml-auto" icon={faFilterList} />}>
        <div className={styles['softphone-interaction-history-header__options-popover']}>
          <Text bold className="mb-20">
            Filter
          </Text>
          <div className={styles['softphone-interaction-history-header__options-popover__date-selector']}>
            <DateSelector
              onDateChange={onDateChange}
              justifyDirection="flex-start"
              defaultStartDate={startDate}
              defaultEndDate={endDate}
            />
          </div>
          <Text bold className="mt-30 mb-20">
            Sort By
          </Text>
          <ReactSelect
            className={cx(styles['softphone-interaction-history-header__options-popover__sort'], 'mb-15')}
            options={SORT_TYPE_OPTIONS}
            onChange={({ value }) => setSort(value)}
            defaultValue={SORT_TYPE_OPTIONS[0]}
            isSearchable={false}
          />
          <ReactSelect
            className={styles['softphone-interaction-history-header__options-popover__sort-direction']}
            options={SORT_DIRECTION_OPTIONS}
            defaultValue={SORT_DIRECTION_OPTIONS[0]}
            onChange={({ value }) => setSortDirection(value)}
            isSearchable={false}
          />
        </div>
      </PopoverMenu>
    </div>
  );
}
