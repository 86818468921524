import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import TTask from 'lib/common/types/Task';
import parseJSON from 'lib/common/utils/parseJSON';
import connectGetter from 'lib/common/utils/connectGetter';
import { CONTACT_NOT_FOUND_EXCEPTION } from 'lib/common/constants/connectExceptions';
import connectAction from '../../../utils/connectAction';
import endThirdPartyConnectionHelper from '../utils/endThirdPartyConnectionHelper';
import endTaskHelper from '../utils/endTaskHelper';

function getTask(tasks, taskId): TTask {
  return tasks.find((task) => task.taskId === taskId);
}

function getTaskContact(tasks, taskId): connect.Contact | undefined {
  return getTask(tasks, taskId)?.contact;
}

function holdThirdPartyConnection(contact) {
  const thirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', contact);

  if (
    !thirdPartyConnection ||
    connectGetter('getState', thirdPartyConnection)?.type !== connect.ConnectionStateType.CONNECTED
  ) {
    return Promise.resolve();
  }

  return connectAction('hold', thirdPartyConnection);
}

async function holdInitialConnection(contact) {
  const initialConnection = connectGetter('getActiveInitialConnection', contact);

  if (connectGetter('getState', initialConnection)?.type !== connect.ConnectionStateType.CONNECTED) {
    return Promise.resolve();
  }

  return connectAction('hold', initialConnection);
}

export default function useTaskActions({ tasks, handleContactChange, setSelectedTaskId }) {
  const rejectTask = (taskId) => {
    return connectAction('reject', getTaskContact(tasks, taskId));
  };

  const acceptTask = (taskId) => {
    setSelectedTaskId(taskId);

    return connectAction('accept', getTaskContact(tasks, taskId));
  };

  const endThirdPartyConnection = (taskId) => endThirdPartyConnectionHelper(getTaskContact(tasks, taskId));

  const endTask = (taskId) => endTaskHelper(getTaskContact(tasks, taskId), handleContactChange);

  const hangupAgent = (taskId) => {
    const agentConnection = connectGetter('getAgentConnection', getTaskContact(tasks, taskId));

    if (!agentConnection) {
      return Promise.resolve();
    }

    return connectAction('destroy', agentConnection);
  };

  const removeTask = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (getTask(tasks, taskId)?.connectionState === CONNECTION_STATES.DISCONNECTED) {
      handleContactChange({ contact, isDestroy: true });
    }

    try {
      await connectAction('clear', contact);
    } catch (e: any) {
      const { type } = parseJSON(e) || {};
      // If the error thrown corresponds to connect's contact not found, remove it from task list
      if (type === CONTACT_NOT_FOUND_EXCEPTION) {
        handleContactChange({ contact, isDestroy: true });
      }
    }
  };

  const onJoinConference = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      await connectAction('conferenceConnections', contact);
      handleContactChange({ contact, connectionState: CONNECTION_STATES.LIVE });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  /**
   * https://github.com/amazon-connect/amazon-connect-streams/issues/307#issuecomment-933321559
   * Need to make sure we hold the third party/outbound connection first and then hold the inbound connection
   * Else the APIs behave inconsistently. Sometimes the inbound hold fails or the outbound hold.
   */
  const onHoldAll = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    await holdThirdPartyConnection(contact);

    await holdInitialConnection(contact);

    handleContactChange({ contact, connectionState: CONNECTION_STATES.HOLD });
  };

  const onSwapConferenceCall = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    const initialConnection = connectGetter('getActiveInitialConnection', contact);

    try {
      await connectAction('toggleActiveConnections', contact);
      handleContactChange({
        contact,
        connectionState:
          connectGetter('getState', initialConnection)?.type === connect.ConnectionStateType.CONNECTED
            ? CONNECTION_STATES.HOLD
            : CONNECTION_STATES.LIVE
      });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return {
    rejectTask,
    acceptTask,
    endTask,
    removeTask,
    hangupAgent,
    onJoinConference,
    onHoldAll,
    onSwapConferenceCall,
    endThirdPartyConnection
  };
}
