import { faPause, faPlug, faWaveformLines } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { Icon } from 'lib/common/components';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import BADGE_LABEL from 'lib/common/constants/taskStatusBadgeLabels';

import styles from './task-status-badge.module.scss';

interface TaskStatusBadgeProps {
  callIsOnHold?: boolean;
  isNeglected?: boolean;
  isConferenceConnecting?: boolean;
  isSmallWidth?: boolean;
  isRow?: boolean;
  status: ValueOf<typeof CONTACT_STATES>;
  className?: string;
}

function getBadgeProps({ callIsOnHold, isNeglected, isConferenceConnecting, status }) {
  if (callIsOnHold) {
    return { text: BADGE_LABEL.ON_HOLD, icon: faPause, classNameSuffix: '--on-hold' };
  }

  if (isConferenceConnecting) {
    return { text: BADGE_LABEL.CONNECTING, icon: faPlug };
  }

  if (isNeglected) {
    return { text: BADGE_LABEL[status], classNameSuffix: '--neglected' };
  }

  return { text: BADGE_LABEL.LIVE, icon: faWaveformLines };
}

export default function TaskStatusBadge({
  callIsOnHold,
  isNeglected,
  isConferenceConnecting,
  isSmallWidth,
  isRow,
  status,
  className
}: TaskStatusBadgeProps) {
  const badgeProps = getBadgeProps({ callIsOnHold, isNeglected, isConferenceConnecting, status });

  return (
    <span
      className={cx(
        styles['task-status-badge'],
        badgeProps.classNameSuffix ? styles[`task-status-badge${badgeProps.classNameSuffix}`] : '',
        className
      )}
    >
      {isSmallWidth && badgeProps.icon && isRow ? (
        <Icon icon={badgeProps.icon} color="white" size={15} />
      ) : (
        badgeProps.text
      )}
    </span>
  );
}
