import { useContext } from 'react';
import { Button } from 'lib/common/components/index';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { ConfigContext } from 'lib/core/config';
import { AuthContext } from 'lib/core/context/AuthProvider';
import TaskFooterRow from './TaskFooterRow';
import TaskFooter from './TaskFooter';

type TFooter = {
  outcome: string | null;
  campaign: string | null;
  scheduleCallback: () => void;
};

export default function ACW({ outcome, campaign, scheduleCallback }: TFooter) {
  const {
    state: { tasks, selectedTaskId },
    actions: { removeTask }
  } = useContactContext();

  const { hasPermission } = usePermissionsContext();
  const { config } = useContext(ConfigContext);
  const { fetch_ } = useContext(AuthContext);

  const tenantId = config.TENANT_ID;
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  const hasACWCantSkipPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_ACW_CANT_SKIP
  });

  const clearTask = () => {
    if (!selectedTask) {
      return;
    }

    removeTask(selectedTask.taskId);
  };

  const onSave = () => {
    if (!selectedTask || !selectedTask?.contact) {
      return Promise.resolve();
    }

    const outcomePayload = {
      tenantId,
      contactId: selectedTask.contact.contactId,
      calledAt: selectedTask.connectedAtTime?.toISOString() || new Date().toISOString(),
      callOutcome: outcome,
      connectUsername: sessionStorage.getItem('c_user'),
      campaignType: campaign
    };

    return fetch_(`${config.CALL_SERVICE_HOST}/addoutcomerec`, {
      method: 'POST',
      body: JSON.stringify(outcomePayload)
    });
  };

  return (
    <TaskFooter>
      <TaskFooterRow isSingleRow>
        <Button
          data-testid="after-call-work-create-task-btn"
          icon="faCalendarClock"
          tooltip="Create a callback agent task"
          onClick={scheduleCallback}
          styleType="NEUTRAL"
          round
        />
        <Button
          data-testid="after-call-work-save-btn"
          icon="faCheck"
          tooltip="Complete"
          onClick={onSave}
          asyncAction
          onSuccess={clearTask}
          round
          disabled={!outcome || !campaign}
          large
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        {!hasACWCantSkipPermission && (
          <Button
            icon="faForwardFast"
            tooltip="Skip"
            styleType="NEUTRAL"
            onClick={clearTask}
            round
            asyncAction
            successTimeoutSeconds={0}
            preventDoubleClick
          />
        )}
      </TaskFooterRow>
    </TaskFooter>
  );
}
