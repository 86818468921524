import get2ColLayoutId from '../utils/get2ColLayoutId';
import COLUMN_IDS from './columnIds';

export default {
  THREE_COLUMNS: [
    { defaultSizePercent: 20, id: COLUMN_IDS.TASKS, minSize: 250 },
    { defaultSizePercent: 30, id: COLUMN_IDS.CONTROLS, minSize: 100 },
    {
      defaultSizePercent: 50,
      id: COLUMN_IDS.PROFILE,
      minSize: 100
    }
  ],
  TWO_COLUMNS: [
    { defaultSizePercent: 30, id: COLUMN_IDS.TASKS_AND_CONTROLS, minSize: 250 },
    {
      defaultSizePercent: 70,
      id: get2ColLayoutId(COLUMN_IDS.PROFILE),
      minSize: 100
    }
  ],
  TASKS_AND_CONTROLS: [
    { defaultSizePercent: 50, id: get2ColLayoutId(COLUMN_IDS.TASKS), minSize: 220 },
    {
      defaultSizePercent: 50,
      id: get2ColLayoutId(COLUMN_IDS.CONTROLS),
      minSize: 335
    }
  ]
};
