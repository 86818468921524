const HISTORICAL_TYPE = 'ht';
const REAL_TIME_TYPE = 'rt';

// -- Utils -- //

function getType(historical: boolean) {
  return historical ? HISTORICAL_TYPE : REAL_TIME_TYPE;
}

function withMax(value, max) {
  return value > max ? max : value;
}

function divide(value, secondValue) {
  if (!secondValue) {
    return 0;
  }

  return value / secondValue;
}

function getPercent(value) {
  return parseInt((value * 100).toFixed(0));
}

function getMinMaxArray(value) {
  if (isNaN(value)) {
    return [0, 100];
  }

  // Prevent decimal percentages
  const rounded = parseInt(value.toFixed(0), 10);

  return [rounded, 100 - rounded];
}

// -- Get Metrics -- //

function getMetric(
  metrics,
  name,
  { historical = false, fallback = 0 }: { historical?: boolean; fallback?: number | null } = {}
) {
  const collections = metrics[getType(historical)].MetricResults?.[0]?.Collections;
  const metric = collections?.find((collection) => collection.Metric.Name === name);

  if (!metric) {
    return fallback;
  }

  return metric.Value;
}

export default function buildStats(metrics) {
  const contactsQueued = getMetric(metrics, 'CONTACTS_QUEUED', { historical: true });
  const handleRate = contactsQueued
    ? withMax(divide(getMetric(metrics, 'CONTACTS_HANDLED', { historical: true }), contactsQueued), 1)
    : 1;
  const abandonRate = withMax(
    divide(getMetric(metrics, 'CONTACTS_ABANDONED', { historical: true }), contactsQueued),
    1
  );
  const serviceLevel = getMetric(metrics, 'SERVICE_LEVEL', { historical: true, fallback: null });

  return {
    contactsHandledMidnight: getMetric(metrics, 'CONTACTS_HANDLED', { historical: true }),
    contactsAbandonedMidnight: getMetric(metrics, 'CONTACTS_ABANDONED', { historical: true }),
    handleRate: getMinMaxArray(getPercent(handleRate)),
    abandonRate: getMinMaxArray(getPercent(abandonRate)),

    // Default this to 100 so it doesn't display a negative service level when there are no contacts handled
    serviceLevel: getMinMaxArray(serviceLevel === null ? 100 : serviceLevel),
    contactsInQueue: getMetric(metrics, 'CONTACTS_IN_QUEUE'),
    oldestContactInQueue: getMetric(metrics, 'OLDEST_CONTACT_AGE'),
    agentsOnline: getMetric(metrics, 'AGENTS_ONLINE'),
    agentsAvailable: getMetric(metrics, 'AGENTS_AVAILABLE')
  };
}
