import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import sizeMe from 'react-sizeme';
import { memo } from 'react';

import { Icon, PopoverMenu, Text } from 'lib/common/components';
import { TaskStatusBadge } from 'lib/common/components/atoms';
import { InfoPanelRow } from '../../types/InfoPanelRow';

import styles from './row.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const ROW_SMALL_WIDTH_PX = 300;

function Row({ id, icon, text, badgeProps, suffix, actions, size: { width } }: InfoPanelRow) {
  return (
    <div className={styles['info-panel-row']} key={id}>
      {icon && <Icon className="mr-5" icon={icon} size={15} />}
      <Text>{text}</Text>
      {badgeProps && (
        <TaskStatusBadge {...badgeProps} className="ml-10" isSmallWidth={width < ROW_SMALL_WIDTH_PX} isRow />
      )}
      {actions && (
        <PopoverMenu anchor={<Icon className={cx('ml-auto', { 'ml-10': badgeProps })} icon={faEllipsisVertical} />}>
          {actions}
        </PopoverMenu>
      )}
      {suffix && (
        <div className={cx(styles['info-panel-row__suffix'], { 'ml-10': badgeProps || actions })}>{suffix}</div>
      )}
    </div>
  );
}

export default memo(sizeMeHOC(Row));
