import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';

import EventEmitter from 'lib/common/utils/EventEmitter';
import { Error as ErrorPage, Loader } from 'lib/common/components';
import getQueryParam from 'lib/common/utils/getQueryParam';
import TUser from 'lib/common/types/User';

import { ConfigContext } from '../../config';

type Props = {
  children: ReactNode;
};

type TAuthContext = {
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
  loaded?: boolean;
  tokens?: any;
  email?: string;
  userData?: TUser;
};

const USERNAME_SUFFIX = '@neon.com';

const defaultAuth = {
  fetch_: () => Promise.resolve(),
  signOut: () => {},
  config: {}
};

export const IsolatedAuthContext = createContext<TAuthContext>(defaultAuth);

function getChild({ children, user, error, connectToken, userName }) {
  if (error) {
    return <ErrorPage />;
  }

  if (!connectToken || !userName) {
    return (
      <ErrorPage
        title="You'll Need To Open The Page From NEON"
        text="You can only use this page by opening it from NEON and won't be able to reload or load it directly. Try clicking the original link."
        hidePrimaryAction
      />
    );
  }

  return (
    <>
      {!user ? <Loader /> : null}
      {user && children}
    </>
  );
}

/**
 * This is the auth provider for when the app is in isolated mode. Isolated mode will not mount connect streams
 * and requires query params with token & user ID. A limited sub set of pages are supported in isolated mode.
 */
const IsolatedAuthProvider = ({ children }: Props) => {
  const { config, configLoaded } = useContext(ConfigContext);
  const history = useHistory();
  const location = useLocation();
  const [tokens, setTokens] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [userData, setUserData] = useState<any>(null);
  const [connectToken] = useState<undefined | string>(getQueryParam({ param: 'token' }));
  const [userName] = useState<undefined | string>(getQueryParam({ param: 'user' }));

  useEffect(() => {
    if (!connectToken && !userName) {
      return;
    }

    history.replace(`${location.pathname}?standalone=true`);
  }, []);

  const tenantID = process.env.REACT_APP_TENANT_ID;
  const stage = process.env.REACT_APP_STAGE;

  const initUser = async (user, tokens) => {
    const userId = user.username || user;

    const objectKey = `${process.env.REACT_APP_TENANT_ID}__${userId}`;
    const agentUrl = `${config.AGENT_SERVICE_HOST}/agent/${objectKey}`;
    const uiConfigUrl = `${config.AGENT_SERVICE_HOST}/config/${objectKey}/ui-config`;

    try {
      const responses = await Promise.all([fetch_(agentUrl, void 0, tokens), fetch_(uiConfigUrl, void 0, tokens)]);
      const data = await Promise.all(responses.map((res) => res.json()));

      if (!data[1].configBlob) {
        setError(true);

        return void EventEmitter.emit('onError', true);
      }

      const userToStore = { ...data[0], ...data[1].configBlob, username: userId };

      setUserData(userToStore);

      EventEmitter.emit('userData', userToStore);

      sessionStorage.setItem('email', userToStore.email || '');
    } catch (e) {
      setError(true);

      console.error('Error fetching user (isolated)', e);
    }
  };

  const checkSession = async () => {
    try {
      if (loaded) {
        return;
      }

      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      if (mounted) {
        setTokens(JSON.parse(JSON.stringify(currentSession)));
      }

      const username = currentUser.username.split('@')[0].split('__')[1];

      initUser(username, JSON.parse(JSON.stringify(currentSession)));
    } catch (e) {
      if (e == null) {
        return;
      }
      console.error('Error checking session (isolated)', e);
    }
  };

  useEffect(() => {
    if (!connectToken || !userName) {
      return;
    }

    try {
      checkSession();
      if (loaded) {
        return;
      }
      signIn();
    } catch (e) {
      console.error('Error refreshing token (isolated)', e);
    }
  }, []);

  const fetch_ = async (url, options = {}, tokens_?, throwError = true): Promise<Response> => {
    try {
      const tokensStore = tokens === null ? tokens_ : tokens;
      const headers = new Headers();
      const token = tokensStore?.idToken?.jwtToken;

      if (!token) {
        return Promise.reject('no token');
      }

      headers.append('Authorization', `Bearer ${token}`);
      headers.append('Accept', 'application/json');
      headers.append('Content-Type', 'application/json');

      const result = await fetch(url, { ...options, headers });

      if (!result.ok) {
        const errorBody = await result.json();

        if (throwError) {
          throw new Error(errorBody.message || errorBody);
        }
      }

      return result;
    } catch (e) {
      console.error('Fetch error (isolated)', e);
      throw e;
    }
  };

  const signIn = async () => {
    if (!connectToken || !userName) {
      return;
    }

    try {
      const authUserName = `${tenantID}__${userName}${!userName.includes('@') ? USERNAME_SUFFIX : ''}`; // email address
      const cognitoUser = await Auth.signIn(authUserName);
      const currentSession = await Auth.sendCustomChallengeAnswer(cognitoUser, connectToken);

      setTokens(JSON.parse(JSON.stringify(currentSession.signInUserSession)));
    } catch (e) {
      setError(true);
      console.error('Error signing in: ', e);
    }
  };

  if (configLoaded) {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });
  }

  useEffect(() => {
    const getSecureConfig = async () => {
      const res = await fetch_(`${config.AGENT_SERVICE_HOST}/cfg/secure/${tenantID}/${stage}`);
      const data = await res.json();

      config.ELASTICSEARCH_HOST = data.ELASTICSEARCH_HOST;
      config.ELASTICSEARCH_INDEX = data.ELASTICSEARCH_INDEX;

      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_HOST}/connect/${tenantID}/describe/user/?objectId=6907d21b-c203-4bc1-82c7-8f6835b2d75c`
      );
      const userInfo = await userInfoJson.json();

      // Making userInfo optional as IsolatedAuthProvider user object can be empty
      setEmail(userInfo?.User?.IdentityInfo?.Email);
    };

    const initializeApp = async () => {
      setFetching(true);

      try {
        await getSecureConfig();

        setLoaded(true);
      } catch (e) {
        // Better to console log all errors rather than failing silently
        console.log(e);
        setError(true);
      }
    };

    if (tokens !== null && !fetching) {
      initializeApp();
    }

    return () => {
      setMounted(false);
    };
  }, [tokens]);

  return (
    <IsolatedAuthContext.Provider
      value={{
        fetch_,
        loaded,
        tokens,
        email,
        userData
      }}
    >
      {getChild({ children, connectToken, userName, user: userData, error })}
    </IsolatedAuthContext.Provider>
  );
};

export const useIsolatedAuthContext = () => useContext(IsolatedAuthContext);

export default IsolatedAuthProvider;
