import { useEffect } from 'react';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import EventEmitter from 'lib/common/utils/EventEmitter';
import connectGetter from 'lib/common/utils/connectGetter';
import CONNECT_REJECTED_STATUS from 'lib/common/constants/connectRejectedState';
import { isConference as isConferenceContact } from 'lib/common/utils/conferenceConnections';
import handleACWContact from '../utils/handleACWContact';
import handleConnectedContact from '../utils/handleConnectedContact';
import handleMissedContact from '../utils/handleMissedContact';

export default function useContactHandlers({
  matchNewContact,
  handleContactChange,
  fetch_,
  config,
  tokens,
  hasMissedCallPermission,
  hasACWPermission
}) {
  const handleContactDestroy = (contact) => {
    handleContactChange({ contact, isDestroy: true });

    EventEmitter.emit('onContactEnded', contact);
  };

  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.contact((contact) => {
      contact.onConnecting(matchNewContact);

      contact.onIncoming(matchNewContact);

      contact.onAccepted(() => {
        handleContactChange({ contact, status: CONTACT_STATES.ACCEPTED });
      });

      contact.onConnected(async (latestContact) =>
        handleConnectedContact({ contact: latestContact, handleContactChange, fetch_, config, tokens })
      );

      contact.onACW((latestContact) =>
        handleACWContact({ contact: latestContact, hasACWPermission, handleContactChange })
      );

      contact.onMissed((latestContact) =>
        handleMissedContact({ contact: latestContact, handleContactChange, hasMissedCallPermission })
      );

      contact.onDestroy(handleContactDestroy);

      contact.onRefresh((latestContact: connect.Contact) => {
        // We can't use the provided type from connect here because it's undefined at runtime
        const isRejected = connectGetter('getStatus', latestContact)?.type === CONNECT_REJECTED_STATUS;

        const isConference = isConferenceContact(latestContact);

        if (!isConference && !isRejected) {
          return;
        }

        if (isRejected) {
          return handleContactChange({ contact: latestContact, status: CONTACT_STATES.REJECTED });
        }

        // Conference/transfer
        if (isConference && connectGetter('getState', latestContact)?.type === connect.ContactStateType.ENDED) {
          return handleContactChange({ contact: latestContact, status: CONTACT_STATES.ACW });
        }

        const thirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', latestContact);

        handleContactChange({
          contact: latestContact,
          status:
            connectGetter('getState', thirdPartyConnection)?.type === connect.ContactStateType.CONNECTING
              ? CONTACT_STATES.CONNECTING
              : CONTACT_STATES.CONNECTED
        });
      });
    });
  }, []);
}
