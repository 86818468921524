import { useState, useContext, useEffect } from 'react';
import { TextField, makeStyles, createStyles, MenuItem, FormHelperText } from '@material-ui/core';
import { Formik, Form, FormikProps, FieldProps, Field } from 'formik';
import * as Yup from 'yup';
import { useBooleanState } from 'webrix/hooks';
import { OptionsType, ValueType } from 'react-select';
import { v4 as uuid } from 'uuid';

import { AuthContext } from 'lib/core/context/AuthProvider';

import EventEmitter from 'lib/common/utils/EventEmitter';
import { Loader, Label, Button, Select as MUISelect, Modal, ReactSelect } from 'lib/common/components';
import getUserName from 'lib/common/utils/getUserName';
import { useAgentContext } from 'lib/common/contexts/AgentContext';

interface Option {
  label: number;
  value: number;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  defaultValue: OptionsType<Option>;
  className: string;
  classNamePrefix: string;
  id: string;
}

export const CustomSelect = ({
  field,
  form,
  options,
  defaultValue,
  className,
  classNamePrefix,
  isMulti = false,
  id
}: CustomSelectProps) => {
  const onChange = (option: ValueType<Option[] | Option, boolean>) => {
    const multiOption = (option as Option[] | null) || [];

    form.setFieldValue(
      field.name,
      multiOption.map((item: Option) => item)
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) =>
              field.value
                .map(function (e) {
                  return e.value;
                })
                .indexOf(option.value) >= 0
          )
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ('' as any);
  };

  return (
    <ReactSelect
      defaultValue={defaultValue}
      className={className}
      classNamePrefix={classNamePrefix}
      id={id}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      maxMenuHeight={150}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    modalRoot: {
      display: 'flex',
      margin: 'auto',
      height: '100%'
    },
    textField: {
      marginBottom: '40px',
      width: '100%'
    },
    selectMenu: {
      '& > *': {
        width: '100%'
      },
      marginBottom: '40px'
    },
    selectHelper: {
      marginBottom: '1vw'
    },
    selectLabel: {
      marginTop: '0.5vw'
    },
    progressLoader: {
      marginTop: '10px'
    },
    title: { textAlign: 'center', marginBottom: '2vw' },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
    paper: {
      padding: '40px',
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      borderRadius: 16,
      margin: 'auto',
      height: 'fit-content',
      width: 'calc(100% - 20px)',
      maxWidth: '500px'
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',

      '&>.button:first-of-type': {
        marginRight: '20px'
      }
    }
  })
);

interface IProps {
  getAllUsers: (...args: any) => any;
  editMode: boolean;
  metricsGroups: any[];
  rowSelected: any;
  toggleAddUser: (...args: any) => any;
  showMetricsGroup: boolean;
  show: boolean;
}

export interface IUserForm {
  tenantId?: string;
  uuid?: string;
  objectKey: string;
  objectId: string;
  objectIdentifier: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  email?: string;
  phoneNumber?: string;
  routingProfileId?: string;
  routingProfileName?: string;
  securityProfileIds?: string[];
  status?: string;
  role?: string;
  configBlob?: any;
  metricsGroups: any[];
  name: string;
  isNeonUser: boolean;
}

type IFormStatus = null | 'ERROR' | 'SUCCESS';

const ManageUsersPageInner: React.FunctionComponent<IProps> = (props_) => {
  const { agent } = useAgentContext();

  const classes = useStyles();

  const [formStatus, setFormStatus] = useState<IFormStatus>();
  const [user, setUser] = useState(props_.rowSelected);

  const { config, fetch_ } = useContext(AuthContext);
  const { value: loadingUser, toggle: toggleLoadingUser } = useBooleanState(!props_.editMode);

  useEffect(() => {
    if (!props_.editMode) {
      const objectId = user.objectId.split('_')[user.objectId.split('_').length - 1];
      const url = `${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/user/${objectId}`;
      fetch_(url)
        .then((res) => res.json())
        .then((result) => {
          setUser(Object.assign(result, { metricsGroups: [] }));
          toggleLoadingUser();
        })
        .catch(() => {
          toggleLoadingUser();
        });
    }
  }, [config, props_.editMode, user.objectId, fetch_]);

  const upsertUser = async (data: IUserForm, { setSubmitting }) => {
    // eslint-disable-next-line prefer-const, @typescript-eslint/no-unused-vars
    let { name, isNeonUser, configBlob, ...item } = data;

    if (!props_.editMode) {
      item = Object.assign(item, {
        tenantId: process.env.REACT_APP_TENANT_ID,
        uuid: uuid()
      });
    }

    // item.metricsGroups - array of value (uuid) and label (groupName)
    item.metricsGroups = item.metricsGroups
      .filter((i) => (props_.metricsGroups || []).filter((g) => g.uuid === i.value).length > 0)
      .map((i) => {
        const filtered = (props_.metricsGroups || []).filter((g) => g.uuid === i.value)[0];
        return {
          tenantId: filtered.tenantId,
          uuid: filtered.uuid,
          groupName: filtered.groupName,
          groupType: filtered.groupType,
          objectId: filtered.objectId,
          name: filtered.groupName
        };
      });

    setSubmitting(true);

    const action = !props_.editMode ? 'add' : 'update';
    const url = `${config.AGENT_SERVICE_HOST}/agent?type=agent&action=${action}`;

    fetch_(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(item)
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }

        setFormStatus('SUCCESS');

        props_.getAllUsers();
        props_.toggleAddUser();

        const currentUsername = agent?.getConfiguration()?.username;
        const updatedUsername = props_.rowSelected.objectKey.replace(`${props_.rowSelected.organization}__`, '');

        if (currentUsername === updatedUsername) {
          EventEmitter.emit('initUserData', currentUsername);
        }
      })
      .catch(() => {
        setFormStatus('ERROR');
        setSubmitting(false);
        setFormStatus(null);
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={user}
      onSubmit={(data, actions) => {
        upsertUser(data, actions);
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('Please enter a first name.'),
        lastName: Yup.string().required('Please enter a last name.'),
        role: Yup.string().required('Please select a user role.')
      })}
    >
      {(props: FormikProps<IUserForm>) => {
        const { values, touched, errors, handleBlur, handleChange, isSubmitting } = props;

        return (
          <Modal
            open
            onClose={props_.toggleAddUser}
            small
            hideFooter
            contentTopPadded
            title={
              props_.editMode
                ? `Edit ${getUserName({ ...props_.rowSelected, fallback: 'A User' })}`
                : 'Add A New User To NEON'
            }
          >
            {loadingUser ? (
              <Loader relative />
            ) : (
              <Form translate="yes">
                <div className={classes.textField}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    type="text"
                    helperText={errors.firstName && touched.firstName ? errors.firstName : void 0}
                    error={!!(errors.firstName && touched.firstName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.textField}>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    type="text"
                    helperText={errors.lastName && touched.lastName ? errors.lastName : void 0}
                    error={!!(errors.lastName && touched.lastName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.selectMenu}>
                  <MUISelect
                    label="Role"
                    name="role"
                    id="role"
                    value={values.role}
                    error={!!(errors.role && touched.role)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="agent">Agent</MenuItem>
                  </MUISelect>
                  <FormHelperText className={classes.selectHelper}>
                    {errors.role && touched.role ? errors.role : void 0}
                  </FormHelperText>
                </div>
                {props_.showMetricsGroup && (
                  <div className={classes.textField}>
                    <Label text="Metrics Groups" id="metricsGroups">
                      <Field
                        name="metricsGroups"
                        id="metricsGroups"
                        component={CustomSelect}
                        defaultValue={values.metricsGroups.map((item) => {
                          return {
                            value: item.uuid,
                            label: item.groupName
                          };
                        })}
                        isMulti
                        className="basic-multi-select"
                        classNamePrefix="select"
                        options={(props_.metricsGroups || []).map((item) => {
                          return {
                            value: item.uuid,
                            label: item.groupName
                          };
                        })}
                      />
                    </Label>
                  </div>
                )}
                <div className={classes.footer}>
                  <Button styleType="SECONDARY" disabled={isSubmitting} onClick={props_.toggleAddUser}>
                    Cancel
                  </Button>
                  <Button disabled={isSubmitting || formStatus === 'ERROR'} type="submit" busy={isSubmitting}>
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};

const ManageUsersPage: React.FC<IProps> = (props_) => {
  if (!props_.show) {
    return null;
  }
  return <ManageUsersPageInner {...props_} />;
};

export default ManageUsersPage;
