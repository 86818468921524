import { useState, useRef, useMemo, useEffect } from 'react';
import { parsePhoneNumber } from 'awesome-phonenumber';
import _isEmpty from 'lodash.isempty';
import cx from 'classnames';

import { Button, ClickableIcon } from 'lib/common/components/index';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import connectGetter from 'lib/common/utils/connectGetter';
import { onDial, onKeyPress } from 'lib/common/utils/dtmfTone';
import { isInitialConnectionDisconnected } from 'lib/common/utils/conferenceConnections';
import { faDeleteLeft } from '@fortawesome/pro-regular-svg-icons';
import VALIDATION_TEXT from 'lib/common/constants/validationText';
import connectAction from 'lib/common/utils/connectAction';

import { PhoneInput } from 'lib/common/components/index';
import HangupModal from '../HangupModal';
import NumberPad from '../NumberPad';

import './dialpad.scss';

interface IDialPadProps {
  onActiveCall: boolean;
  activeCallContact?: connect.Contact;
  isDialPadTransfer?: boolean;
  closeModal?: () => void;
  pushToTask?: (url: string) => void;
  contentWidth?: number;
}

const ERROR_MSG = VALIDATION_TEXT.PHONE_NUMBER;
const CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR = 'The maximum amount of 3 conference attendees has been reached.';
const CONFERENCE_TRANSFER_CONNECTION_ERROR = 'Cannot transfer as the initial connection has been disconnected.';
const SMALL_DIALPAD_WIDTH_PX = 300;

const sendDigitsOverConnection = async (connection: connect.BaseConnection, digit: string) => {
  try {
    await connectAction('sendDigits', connection, digit);
  } catch {
    throw new Error('Failed to send digits to connection');
  }
};

const DialPad = ({
  onActiveCall,
  activeCallContact,
  isDialPadTransfer,
  closeModal,
  pushToTask,
  contentWidth
}: IDialPadProps) => {
  const [dialNumber, setDialNumber] = useState('');
  const [toDialError, setToDialError] = useState(true);
  const {
    actions: { makeOutboundCall, onTransfer, setLastDialedNumber },
    state: { lastDialedNumber }
  } = useContactContext();
  const { agent } = useAgentContext();
  const [openHangupTransferModal, setOpenHangupTransferModal] = useState(false);
  const [openHangupConferenceModal, setOpenHangupConferenceModal] = useState(false);

  const phoneInputRef = useRef(null);
  const phoneInputInnerRef = useRef<HTMLInputElement>(null);
  const sendDigitsInFlight = useRef<boolean>(false);
  const digitsInFlight = useRef<string>('');

  const inConference =
    Boolean(connectGetter('getActiveInitialConnection', activeCallContact)) &&
    Boolean(connectGetter('getSingleActiveThirdPartyConnection', activeCallContact));

  const initialConnectionDisconnected =
    inConference && activeCallContact && isInitialConnectionDisconnected(activeCallContact);

  const isSmallWidth = Boolean(contentWidth && contentWidth <= SMALL_DIALPAD_WIDTH_PX);

  useEffect(() => {
    if (!lastDialedNumber || isDialPadTransfer) {
      return;
    }

    // @ts-ignore
    phoneInputRef?.current?.handleValueChange(lastDialedNumber);
    setToDialError(false);
  }, []);

  // Maintain a queue of digits to be sent while in a call and recursively send each digit to the connection
  const sendDigit = async (connection: connect.BaseConnection, digit: string) => {
    if (!connection) {
      return;
    }

    if (sendDigitsInFlight.current) {
      digitsInFlight.current += digit;
      return;
    }

    sendDigitsInFlight.current = true;
    digitsInFlight.current = '';

    try {
      await sendDigitsOverConnection(connection, digit);
      sendDigitsInFlight.current = false;

      if (!digitsInFlight.current) {
        return;
      }

      await sendDigit(connection, digit);
    } catch (e) {
      console.error('Error sending dialpad digits to the connection', e);

      sendDigitsInFlight.current = false;
    }
  };

  const sendDtmf = (digit: string) => {
    const isCallConnected = connectGetter('isConnected', activeCallContact);

    if (!(activeCallContact && isCallConnected)) {
      return;
    }

    const connection = connectGetter('getAgentConnection', activeCallContact);
    sendDigit(connection, digit);
  };

  const onDialPress = (digit: string) => {
    onKeyPress(digit);

    // @ts-ignore
    phoneInputRef?.current?.handleValueChange(`${phoneInputRef?.current?.state.value}${digit}`);
    phoneInputInnerRef?.current?.focus();
    sendDtmf(digit);
  };

  const onHandleChange = (number) => {
    setDialNumber(number);
    setToDialError(!parsePhoneNumber(number).isValid());
  };

  const isNumberValid = () => {
    if (!dialNumber) {
      return false;
    }

    return parsePhoneNumber(dialNumber).isValid();
  };

  const onBlur = () => setToDialError(!isNumberValid());

  const handleBackspace = () => {
    const {
      //@ts-ignore
      state: { value },
      //@ts-ignore
      handleValueChange
    } = phoneInputRef?.current || {};

    const updatedNumber = value?.length > 1 ? value.slice(0, -1) : '';

    handleValueChange(updatedNumber);
  };

  const onDialPadTransfer = async () => {
    onDial(dialNumber);
    await onTransfer(dialNumber);
  };

  const onDialPadOutboundCall = async () => {
    onDial(dialNumber);

    setLastDialedNumber(dialNumber);

    await makeOutboundCall(dialNumber);

    closeModal?.();

    pushToTask?.(PAGE_ROUTES.WORKSPACE);
  };

  const onDialActionSuccess = () => {
    setOpenHangupTransferModal(false);

    closeModal?.();

    pushToTask?.(PAGE_ROUTES.WORKSPACE);
  };

  const onDialPadCall = () => {
    if (inConference) {
      return setOpenHangupConferenceModal(true);
    }

    if (onActiveCall) {
      return setOpenHangupTransferModal(true);
    }

    return onDialPadOutboundCall();
  };

  const errorMsg = useMemo(() => {
    if (_isEmpty(dialNumber) || toDialError) {
      return ERROR_MSG;
    }

    if (initialConnectionDisconnected) {
      return CONFERENCE_TRANSFER_CONNECTION_ERROR;
    }

    if (inConference) {
      return CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR;
    }

    return '';
  }, [dialNumber, toDialError, inConference]);

  const handleEnterKeyPress = async () => {
    if (!isNumberValid() || errorMsg) {
      return;
    }

    if (isDialPadTransfer) {
      await onDialPadTransfer();
      return onDialActionSuccess();
    }

    onDialPadCall();
  };

  return (
    <div className="dialpad">
      <div className="dialpad__number-input">
        <PhoneInput
          ref={phoneInputRef}
          inputRef={phoneInputInnerRef}
          initialValue={dialNumber}
          onChange={onHandleChange}
          onBlur={onBlur}
          onEnterKeyPress={handleEnterKeyPress}
          testId="dialpad-phone-input"
          countries={connectGetter('getDialableCountries', agent) || []}
          autoFocus
        />
      </div>

      <NumberPad onDialPress={onDialPress} isSmallWidth={isSmallWidth} />

      <div className={cx('dialpad__actions', { 'dialpad__actions--small': isSmallWidth })}>
        <div
          className={cx('dialpad__actions__phone', {
            dialpad__actions__transfer: isDialPadTransfer
          })}
        >
          {isDialPadTransfer ? (
            <Button
              asyncAction
              icon="faPeopleArrowsLeftRight"
              onClick={onDialPadTransfer}
              onSuccess={onDialActionSuccess}
              disabled={_isEmpty(dialNumber) || toDialError || inConference || initialConnectionDisconnected}
              tooltip={errorMsg || (isSmallWidth ? 'Transfer' : void 0)}
              successTimeoutSeconds={0}
            >
              {isSmallWidth ? null : 'Transfer'}
            </Button>
          ) : (
            <Button
              asyncAction
              icon="faPhone"
              styleType="SUCCESS"
              onClick={onDialPadCall}
              disabled={_isEmpty(dialNumber) || toDialError}
              tooltip={_isEmpty(dialNumber) || toDialError ? ERROR_MSG : ''}
              successTimeoutSeconds={0}
            />
          )}
        </div>
        <div className="dialpad__actions__backspace">
          <ClickableIcon icon={faDeleteLeft} onClick={handleBackspace} />
        </div>
      </div>
      {openHangupTransferModal && (
        <HangupModal
          open={openHangupTransferModal}
          onClose={() => setOpenHangupTransferModal(false)}
          onSave={onDialPadOutboundCall}
          onSuccess={onDialActionSuccess}
          primaryText={`Dialling this contact will hang up the current active call with ${dialNumber}`}
          secondaryText="Use the dialpad call control button if you want to transfer to an external number or dial numbers in your current call."
        />
      )}
      {openHangupConferenceModal && (
        <HangupModal
          open={openHangupConferenceModal}
          onClose={() => setOpenHangupConferenceModal(false)}
          onSave={onDialPadOutboundCall}
          onSuccess={onDialActionSuccess}
          primaryText="Dialling this number will remove you from the currently active conference call."
        />
      )}
    </div>
  );
};

export default DialPad;
