import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(1)
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 550
    },
    tableWrapper: {
      overflowX: 'auto',
      padding: theme.spacing(1)
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    grid: {
      marginBottom: 10
    },
    datePicker: {
      width: 200,
      paddingLeft: 5,
      paddingRight: 5
    },
    searchBtn: {
      fontSize: 20,
      marginTop: 5
    }
  })
);
