import { faPlay, faStop } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import PhoneNumber from 'awesome-phonenumber';
import { useBooleanState } from 'webrix/hooks';
import 'react-phone-input-2/lib/style.css';
import AUSTRALIA_PHONE_EXTENSION from 'lib/common/constants/defaultPhoneNumberExtension';
import WORKSPACE_LAYOUT from 'lib/common/constants/workspaceLayouts';
import { LayoutColumnsIcon, SegmentedControls, Accordion, PhoneInput } from 'lib/common/components';
import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import RINGTONE_OPTIONS, { NONE as NO_RINGTONE } from 'lib/common/constants/ringtoneOptions';
import { Label, Button, ClickableIcon, ReactSelect } from 'lib/common/components';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import connectGetter from 'lib/common/utils/connectGetter';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import IncomingTaskPlacementIcon from './components/IncomingTaskPlacementIcon';
import { AGENT_PREFERENCES, DEFAULT_DEVICE, PHONE_OPTIONS } from './constants';
import { getDeviceOptionsByType } from './helpers';

import './styles/preferences.scss';

function getDefaultDevice(agentConfig, key) {
  return agentConfig ? localStorage.getItem(`${agentConfig?.username}:${key}`) || DEFAULT_DEVICE : DEFAULT_DEVICE;
}

const Preferences = ({ isSoftphone = false }) => {
  const {
    state: { onActiveCall, hasIncomingTask }
  } = useContactContext();
  const { agent } = useAgentContext();
  const {
    state: { defaultWorkspaceLayout, ringtone, incomingTaskPlacement },
    actions: { setDefaultWorkspaceLayout, setRingtone, setIncomingTaskPlacement }
  } = usePreferencesContext();

  const agentConfig = connectGetter('getConfiguration', agent);

  const connect = (window as any).getConnect();

  const [isSoftphoneEnabled, setIsSoftphoneEnabled] = useState(connectGetter('isSoftphoneEnabled', agent) || false);
  const [phoneNumber, setPhoneNumber] = useState(connectGetter('getExtension', agent) || AUSTRALIA_PHONE_EXTENSION);

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const [ringtoneAudio] = useState<HTMLAudioElement>(new Audio(ringtone));

  const {
    value: ringtonePlaying,
    toggle: toggleRingtonePlaying,
    setFalse: stopRingtonePlaying
  } = useBooleanState(false);

  const [ringtoneChanged, setRingtoneChanged] = useState(false);

  const [devices, setDevices] = useState([]);

  const [speakerDevice, setSpeakerDevice] = useState(getDefaultDevice(agentConfig, 'speakerDevice'));
  const [microphoneDevice, setMicrophoneDevice] = useState(getDefaultDevice(agentConfig, 'microphoneDevice'));
  const [ringerDevice, setRingerDevice] = useState(getDefaultDevice(agentConfig, 'ringerDevice'));

  useEffect(() => {
    if (!hasIncomingTask) {
      return;
    }

    agent?.setRingerDevice(ringerDevice);
  }, [hasIncomingTask]);

  useEffect(() => {
    if (!onActiveCall) {
      return;
    }

    agent?.setSpeakerDevice(speakerDevice);
    agent?.setMicrophoneDevice(microphoneDevice);
  }, [onActiveCall]);

  useEffect(() => {
    (async () => {
      try {
        const framedMediaDevices = await connect.core.getFrameMediaDevices(30000);
        setDevices(framedMediaDevices);
      } catch (e) {
        console.error('Error getting media devices', e);
      }
    })();

    ringtoneAudio?.addEventListener('ended', stopRingtonePlaying);

    return () => {
      ringtoneAudio?.removeEventListener('ended', stopRingtonePlaying);
    };
  }, []);

  const handleSpeakerChange = ({ value }) => {
    setSpeakerDevice(value);
    agent?.setSpeakerDevice(value);
    localStorage.setItem(`${agentConfig?.username}:speakerDevice`, value);
  };

  const handleMicrophoneChange = ({ value }) => {
    setMicrophoneDevice(value);
    agent?.setMicrophoneDevice(value);
    localStorage.setItem(`${agentConfig?.username}:microphoneDevice`, value);
  };

  const handleRingerChange = ({ value }) => {
    setRingerDevice(value);
    agent?.setRingerDevice(value);
    localStorage.setItem(`${agentConfig?.username}:ringerDevice`, value);
  };

  const outputDevicesOptions = getDeviceOptionsByType(devices, 'audiooutput');
  const inputDevicesOptions = getDeviceOptionsByType(devices, 'audioinput');

  const handleSoftPhoneChange = (newValue) => {
    const isSoftPhoneEnabled = newValue?.value === PHONE_OPTIONS[0].value;

    if (!agentConfig) {
      return;
    }

    if (isSoftPhoneEnabled) {
      agent?.setConfiguration({
        ...agentConfig,
        softphoneEnabled: true,
        agentPreferences: AGENT_PREFERENCES
      });
    }

    const savedPhoneNumber = connectGetter('getExtension', agent) || AUSTRALIA_PHONE_EXTENSION;
    setPhoneNumber(savedPhoneNumber);

    if (!isSoftPhoneEnabled && PhoneNumber(savedPhoneNumber).isValid()) {
      agent?.setConfiguration({
        ...agentConfig,
        softphoneEnabled: false,
        extension: savedPhoneNumber,
        agentPreferences: AGENT_PREFERENCES
      });
      setButtonDisabled(true);
    }

    setIsSoftphoneEnabled(isSoftPhoneEnabled);
  };

  const handleDeskPhoneSet = () => {
    if (!agentConfig) {
      return;
    }

    agent?.setConfiguration({
      ...agentConfig,
      softphoneEnabled: false,
      extension: phoneNumber,
      agentPreferences: AGENT_PREFERENCES
    });

    setIsSoftphoneEnabled(false);
    setButtonDisabled(true);
  };

  const phoneValue = isSoftphoneEnabled ? PHONE_OPTIONS[0] : PHONE_OPTIONS[1];

  return (
    <div className="preferences">
      <Accordion title="Phone" defaultOpen={isSoftphone}>
        <ReactSelect
          classNamePrefix="react-select"
          isSearchable={false}
          id="Phone"
          options={PHONE_OPTIONS}
          value={phoneValue}
          onChange={handleSoftPhoneChange}
        />
        {!isSoftphoneEnabled && (
          <div className="preferences__phone-number__container">
            <div className="preferences__phone-number__container__input">
              <PhoneInput
                initialValue={phoneNumber}
                onChange={(number) => {
                  setButtonDisabled(false);
                  setPhoneNumber(number);
                }}
              />
            </div>
            <Button
              onClick={handleDeskPhoneSet}
              disabled={buttonDisabled || !PhoneNumber(phoneNumber).isValid()}
              icon="faFloppyDisk"
            />
          </div>
        )}
        {isSoftphoneEnabled && (
          <Label text="Call / Chat Ringtone" id="Ringtone" className="mt-20">
            <>
              <div className="preferences__ringtone">
                <ReactSelect
                  classNamePrefix="react-select"
                  isSearchable={false}
                  id="Ringtone"
                  options={RINGTONE_OPTIONS}
                  value={RINGTONE_OPTIONS.find((ringtoneOption) => ringtoneOption.value === ringtone)}
                  onChange={(newValue) => {
                    if (!newValue) {
                      return;
                    }

                    setRingtoneChanged(true);
                    setRingtone(newValue.value);
                    stopRingtonePlaying();

                    ringtoneAudio.src = newValue.value;
                  }}
                />
                {ringtone !== NO_RINGTONE && (
                  <ClickableIcon
                    icon={ringtonePlaying ? faStop : faPlay}
                    className="preferences__play-icon"
                    onClick={async () => {
                      const isPause = !ringtoneAudio.paused;
                      const action = isPause ? 'pause' : 'play';

                      try {
                        await ringtoneAudio?.[action]();

                        toggleRingtonePlaying();

                        if (!isPause) {
                          return;
                        }

                        ringtoneAudio.currentTime = 0;
                      } catch {
                        /** Silently fail https://goo.gl/LdLk22 */
                      }
                    }}
                  />
                )}
              </div>
              {ringtoneChanged && (
                <>
                  <div className="preferences__warning-text">
                    Please reload the page for the ringtone changes to take effect.
                  </div>
                </>
              )}
            </>
          </Label>
        )}
      </Accordion>
      {inputDevicesOptions.some((device) => device.label) && (
        <Accordion title="Audio Devices" className="mt-20">
          <Label text="Speaker" id="Speaker" className="mb-20">
            <ReactSelect
              classNamePrefix="react-select"
              isSearchable={false}
              id="Speaker"
              options={outputDevicesOptions}
              value={outputDevicesOptions.find((device) => device.value === speakerDevice)}
              onChange={handleSpeakerChange}
            />
          </Label>
          <Label text="Microphone" id="Microphone" className="mb-20">
            <ReactSelect
              classNamePrefix="react-select"
              isSearchable={false}
              id="Microphone"
              options={inputDevicesOptions}
              value={inputDevicesOptions.find((device) => device.value === microphoneDevice)}
              onChange={handleMicrophoneChange}
            />
          </Label>
          <Label text="Ringer" id="Ringer">
            <ReactSelect
              classNamePrefix="react-select"
              isSearchable={false}
              id="Ringer"
              options={outputDevicesOptions}
              value={outputDevicesOptions.find((device) => device.value === ringerDevice)}
              onChange={handleRingerChange}
            />
          </Label>
        </Accordion>
      )}
      {!isSoftphone && (
        <>
          <h3 data-testid="widget-item-title" className="mb-10 mt-40">
            Default Workspace Layout
          </h3>
          <SegmentedControls
            square
            options={[
              {
                label: <LayoutColumnsIcon />,
                value: WORKSPACE_LAYOUT.THREE_COLUMNS
              },
              {
                label: <LayoutColumnsIcon twoColumns />,
                value: WORKSPACE_LAYOUT.TWO_COLUMNS
              }
            ]}
            selectedIndex={defaultWorkspaceLayout === WORKSPACE_LAYOUT.TWO_COLUMNS ? 1 : 0}
            onSelect={setDefaultWorkspaceLayout}
          />
          <h3 data-testid="widget-item-title" className="mb-10 mt-40">
            Incoming Task Placement
          </h3>
          <SegmentedControls
            square
            options={[
              {
                label: <IncomingTaskPlacementIcon />,
                value: INCOMING_TASK_PLACEMENTS.BOTTOM
              },
              {
                label: <IncomingTaskPlacementIcon top />,
                value: INCOMING_TASK_PLACEMENTS.TOP
              }
            ]}
            selectedIndex={incomingTaskPlacement === INCOMING_TASK_PLACEMENTS.TOP ? 1 : 0}
            onSelect={setIncomingTaskPlacement}
          />
        </>
      )}
    </div>
  );
};

export default Preferences;
