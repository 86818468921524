import { useContext, useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import _orderBy from 'lodash.orderby';

import isURL from 'validator/lib/isURL';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { useInstanceContext } from 'lib/common/contexts/InstanceContext';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import TaskInformation from './components/TaskInformation';
import TaskReferences from './components/TaskReferences';
import TaskLocation from './components/TaskLocation';
import TaskDateTime from './components/TaskDateTime';
import createTask from './api/createTask';

export enum Tab {
  QUEUE = 'Queue',
  AGENT = 'Agent'
}

interface IUseCreateAgentTaskProps {
  defaultName: string;
  defaultDescription: string;
  open: boolean;
  isSoftphone?: boolean;
}

export default function useCreateAgentTask({
  defaultName,
  defaultDescription,
  open,
  isSoftphone
}: IUseCreateAgentTaskProps) {
  const { agent } = useAgentContext();
  const { fetch_, config }: any = useContext(AuthContext);
  const { instanceQueues } = useInstanceContext();
  const [name, setName] = useState(defaultName);
  const [references, setReferences] = useState<{ id: string; name: string; url: string }[]>([]);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.QUEUE);
  const [description, setDescription] = useState(defaultDescription);
  const [selectedEntity, setSelectedEntity] = useState<any>(null);
  const [scheduledDateTime, setScheduledDateTime] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [optionsList, setOptionsList] = useState<Array<{ value: any; label: string }>>([]);
  const [invalidDateTimeError, setInvalidDateTimeError] = useState<string>('');

  useEffect(() => {
    setOptionsList([]);
    setSelectedEntity(null);

    if (selectedTab === Tab.QUEUE) {
      return void setQueueList();
    }

    setAgentList();
  }, [selectedTab, agent, instanceQueues]);

  useEffect(() => {
    if (open) {
      setName(defaultName);
      setDescription(defaultDescription);

      return;
    }

    setName('');
    setDescription('');
    setReferences([]);
    setScheduledDateTime(null);
    setSelectedEntity(null);
    setLoading(false);
    setInvalidDateTimeError('');
  }, [open]);

  const setAgentList = async () => {
    setLoading(true);

    const AGENT_URL = `${config.AGENT_SERVICE_HOST}/contacts?tenantId=${process.env.REACT_APP_TENANT_ID}&includeAgents=true`;
    const { items: agents } = await (await fetch_(AGENT_URL)).json();

    setOptionsList(
      _orderBy(
        agents.filter((agent) => agent?.objectIdentifier?.endsWith?.('__agent')),
        'firstName',
        'asc'
      ).map((agent) => ({
        label: `${agent.firstName} ${agent.lastName}`,
        value: agent.objectId.split('_')[1]
      }))
    );

    setLoading(false);
  };

  const setQueueList = async () => {
    if (!agent) {
      return;
    }

    setLoading(true);

    try {
      setOptionsList(
        _orderBy(
          instanceQueues.map((queue) => ({
            label: queue.Name,
            value: queue.Name
          })),
          'label',
          'asc'
        )
      );
    } catch (e) {
      setOptionsList([]);
    } finally {
      setLoading(false);
    }
  };

  const addReference = () => {
    setReferences([...references, { id: `${Date.now()}`, name: '', url: '' }]);
  };

  const removeReference = (index) => () => {
    const newReferences = [...references];

    newReferences.splice(index, 1);

    setReferences(newReferences);
  };

  const onUpdateReference =
    (index, field) =>
    ({ target: { value } }) => {
      const newReferences = [...references];

      newReferences.splice(index, 1, { ...newReferences[index], [field]: value });

      setReferences(newReferences);
    };

  const handleDateTimeChange = (value, field) => {
    if (!value) {
      return;
    }

    const scheduled = scheduledDateTime ? new Date(scheduledDateTime) : new Date();

    const newValue =
      field === 'date'
        ? new Date(value?.setMinutes(new Date().getMinutes() + 5))
        : new Date(new Date(scheduled.setMinutes(value.getMinutes())).setHours(value.getHours()));

    if (newValue?.toString() === 'Invalid Date') {
      return setInvalidDateTimeError('Date should be in the format dd/mm/yyyy hh:mm AM/PM');
    }

    if (newValue < new Date()) {
      return setInvalidDateTimeError('Date has to be in the future');
    }

    setInvalidDateTimeError('');
    setScheduledDateTime(newValue);
  };

  const areValidReferences = references.every(
    (reference) => (!reference.name && !reference.url) || (reference.name && isURL(reference.url))
  );

  const blocks = [
    {
      title: 'Basics',
      summary: 'Information about the task',
      summaryAbbreviated: 'Basic Information',
      content: (
        <TaskInformation
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          isSoftphone={isSoftphone}
        />
      )
    },
    {
      title: 'References',
      optional: true,
      actionIcon: faPlus,
      className: 'create-agent-task__block',
      actionIconOnClick: addReference,
      content: (
        <TaskReferences
          references={references}
          onUpdateReference={onUpdateReference}
          removeReference={removeReference}
          addReference={addReference}
          isSoftphone={isSoftphone}
        />
      )
    },
    {
      title: 'Where',
      summary: 'Where will the task go?',
      summaryAbbreviated: 'Where will it go?',
      className: 'create-agent-task__block',
      content: (
        <TaskLocation
          setSelectedTab={setSelectedTab}
          loading={loading}
          selectedEntity={selectedEntity}
          selectedTab={selectedTab}
          optionsList={optionsList}
          setSelectedEntity={setSelectedEntity}
        />
      )
    },
    {
      title: 'When',
      optional: true,
      summary: 'What time will the task arrive?',
      summaryAbbreviated: '',
      className: 'create-agent-task__block',
      content: (
        <TaskDateTime
          scheduledDateTime={scheduledDateTime}
          handleDateTimeChange={handleDateTimeChange}
          invalidDateTimeError={invalidDateTimeError}
          isSoftphone={isSoftphone}
        />
      )
    }
  ];

  return {
    blocks,
    saveDisabled: !name || !selectedEntity || !areValidReferences || Boolean(invalidDateTimeError),
    createTask: () =>
      createTask({
        fetch_,
        config,
        selectedTab,
        selectedEntity,
        references,
        agent,
        name,
        description,
        scheduledDateTime
      })
  };
}
