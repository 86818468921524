import { ReactComponent as SearchImage } from '../assets/search.svg';
import '../styles/placeholder.scss';

export default function Placeholder() {
  return (
    <div className="customer-profiles-placeholder" data-testid="customer-profiles-placeholder">
      <SearchImage />
      <h2>Search for a profile by using their full name, phone number, account number or email address.</h2>
    </div>
  );
}
