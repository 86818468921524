import { AuthContext } from 'lib/core/context/AuthProvider/index';
import ConnectStreamsComponent from '../components/ConnectStreams';

export default function ConnectStreams(props) {
  return (
    <AuthContext.Consumer>
      {({ config, fetch_, tokens }) => (
        <ConnectStreamsComponent {...props} config={config} fetch_={fetch_} tokens={tokens} />
      )}
    </AuthContext.Consumer>
  );
}
