import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { Avatar, Profile } from 'lib/common/components';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';
// import CONTACT_STATES from 'lib/common/constants/contactStates';
import getUserName from 'lib/common/utils/getUserName';
import TProfile from 'lib/common/types/CustomerProfile';

type TSummary = {
  profile?: TProfile | null;
  currentContactIsViewed: boolean;
};

export default function Summary({ profile }: TSummary) {
  if (!profile) {
    return null;
  }

  const name = getUserName({ ...profile, fallback: 'Customer' });

  return (
    <>
      <div className="customer-profile__content__profile__summary">
        <Avatar
          size={75}
          name={name}
          fallbackIcon={profile?.partyType === PROFILE_TYPES.INDIVIDUAL ? void 0 : faBuilding}
        />
        <h1>{name}</h1>
        {/*{currentContactIsViewed && contactState ? (*/}
        {/*  <span*/}
        {/*    className="customer-profile__content__profile__summary__dot"*/}
        {/*    style={{ background: contactState === CONTACT_STATES.CONNECTING ? '#11b5e7' : '#34c659' }}*/}
        {/*  />*/}
        {/*) : null}*/}
      </div>
      <div className="customer-profile__content__profile__info">
        <Profile.Information profile={profile} />
      </div>
    </>
  );
}
