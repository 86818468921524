import { STATUS_TO_COLOUR_MAP } from 'lib/common/constants/presenceStates';

export interface IContact {
  tenantId: string;
  uuid: string;
  objectKey: string;
  objectId: string;
  objectIdentifier?: string;
  firstName: string;
  lastName: string;
  organization: string;
  phoneNumber: string;
  numberType: string;
  status?: ValueOf<typeof STATUS_TO_COLOUR_MAP>;
  presence?: ValueOf<typeof STATUS_TO_COLOUR_MAP>;
}

export enum ContactActions {
  CREATE = 'creating',
  EDIT = 'editing',
  DELETE = 'deleting'
}

export interface IContactAction {
  action?: ContactActions;
  contact: IContact;
}

export enum SourceType {
  CUSTOM = 'CUSTOM',
  CONNECT = 'CONNECT'
}
