import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { createPortal } from 'react-dom';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import SoftphoneModalContext from 'lib/common/contexts/SoftphoneModalContext';
import ContactContext from 'lib/common/contexts/ContactContext';
import PresenceContext from 'lib/common/contexts/PresenceContext';
import DirectoryContext from 'lib/common/contexts/DirectoryContext';
import { PERMISSIONS } from 'lib/common/constants/permissions';

import VARS from 'css/export-vars.module.scss';
import CoreLayoutRoutes from './CoreLayoutRoutes';
import SoftphoneLayoutRoutes from './SoftphoneLayoutRoutes';

export default function RouteSwitcher({ user, isReversedLayout }) {
  const { hasPermission } = usePermissionsContext();

  const softphoneEl = (
    <SoftphoneModalContext isReversedLayout={isReversedLayout}>
      <SoftphoneLayoutRoutes user={user} isReversedLayout={isReversedLayout} />
    </SoftphoneModalContext>
  );

  const onlyShowSoftphone = hasPermission({ permission: PERMISSIONS.SOFTPHONE_ONLY });

  return (
    <div className={cx('page', { 'softphone-only': onlyShowSoftphone })}>
      {onlyShowSoftphone && createPortal(<div className="softphone-only__background" />, document.body)}
      <ContactContext>
        <PresenceContext>
          {onlyShowSoftphone ? (
            softphoneEl
          ) : (
            <>
              <MediaQuery minWidth={Number(VARS.softphoneWidthNumber) + 1}>
                <DirectoryContext user={user}>
                  <CoreLayoutRoutes user={user} />
                </DirectoryContext>
              </MediaQuery>
              <MediaQuery maxWidth={VARS.softphoneWidth}>{softphoneEl}</MediaQuery>
            </>
          )}
        </PresenceContext>
      </ContactContext>
    </div>
  );
}
