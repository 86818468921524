import { useContext, useEffect, useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import Fuse from 'fuse.js';
import cx from 'classnames';
import _debounce from 'lodash.debounce';
import { AutoSizer, List } from 'react-virtualized';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Loader, ClickableIcon, Tabs } from 'lib/common/components/index';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import useInterval from 'lib/common/hooks/useInterval';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { PERMISSIONS } from 'lib/common/constants/permissions';

import DialPad from './components/DialPad/DialPad';
import Searchbar from './components/Contacts/Searchbar';
import UpsertContact from './components/Contacts/UpsertContact';
import Contact from './components/Contacts/Contact';

import { ContactActions, IContact, IContactAction, SourceType } from './types';
import { defaultContact } from './constants/defaultContact';
import { DIRECTORY_TAB, DIRECTORY_TABS } from './constants/directoryTabs';

import './directory.scss';

interface PhonebookData extends IContact {
  source: SourceType;
}

interface DirectoryProps {
  user: any;
  initialSelectedTab?: number;
  isDirectoryTransfer?: boolean;
  isDialPadTransfer?: boolean;
  onClose?: () => void;
  pushToTask?: (url: string) => void;
  contentHeight?: number;
  contentWidth?: number;
  fillContainer?: boolean;
}

const DEFAULT_LIST_HEIGHT = 470;

const { REACT_APP_TENANT_ID } = process.env;

const AGENT_SEARCH_TERM = 'agent';

const LOAD_INTERVAL_S = 5;

// 80px for search, 24px for tabs
const TABS_AND_SEARCH_HEIGHT = 104;

const Directory = ({
  user,
  initialSelectedTab = 0,
  isDirectoryTransfer,
  onClose,
  pushToTask,
  isDialPadTransfer,
  contentHeight = DEFAULT_LIST_HEIGHT,
  contentWidth,
  fillContainer
}: DirectoryProps) => {
  const [selectedIndex, setSelectedIndex] = useState(initialSelectedTab || 0);
  const [currentTab, setCurrentTab] = useState(initialSelectedTab || 0);
  const [data, setData] = useState<PhonebookData[]>([]);
  const [dataStore, setDataStore] = useState<PhonebookData[]>([]);
  const [loading, setLoading] = useState(true);
  const [polling, setPolling] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchMatches, setSearchMatches] = useState<Fuse.FuseResult<any>[]>([]);
  const [contactAction, setContactAction] = useState<IContactAction>({
    action: void 0,
    contact: defaultContact
  });
  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const { config, fetch_ } = useContext(AuthContext);
  const {
    state: { tasks, selectedTaskId, onActiveCall },
    actions: { getActiveCallTask }
  } = useContactContext();
  const { hasPermission } = usePermissionsContext();

  // Any selected task of type call/chat/task and it is connected
  const connectedTask = tasks.find(
    (task) => task.taskId === selectedTaskId && task.status === CONTACT_STATES.CONNECTED
  );
  const canTransfer = Boolean(connectedTask);

  const isAdmin = useMemo(() => user.role.search('admin') >= 0, [user]);

  const hasPersonalContactPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.PERSONAL_CONTACTS
  });

  const directoryTabs = useMemo(() => {
    let tabsToShow = DIRECTORY_TABS;

    if (!hasPersonalContactPermission) {
      tabsToShow = DIRECTORY_TABS.filter((directoryTab) => directoryTab !== DIRECTORY_TAB.PERSONAL);
    }

    if (isDirectoryTransfer) {
      tabsToShow = tabsToShow.filter((tab) => tab !== DIRECTORY_TAB.DIALPAD);
    }

    return tabsToShow;
  }, [hasPersonalContactPermission, isDirectoryTransfer]);

  const currentTabTitle = directoryTabs[currentTab];
  const showContactTabs =
    !isDialPadTransfer && [DIRECTORY_TAB.PERSONAL, DIRECTORY_TAB.COMPANY].includes(currentTabTitle);
  const showDialPad = currentTabTitle === DIRECTORY_TAB.DIALPAD || isDialPadTransfer;

  const updateByFuse = (pattern, updatedDataStore?: PhonebookData[]) => {
    const dataToSearch = updatedDataStore || dataStore;

    if (!pattern.trim()) {
      setData(dataToSearch);
      setSearchMatches([]);
      return;
    }

    if (pattern.toLowerCase() === AGENT_SEARCH_TERM) {
      setData(dataToSearch.filter((contact) => contact.source === SourceType.CONNECT));

      return void setSearchMatches([]);
    }

    const fuse = new Fuse(dataToSearch, {
      isCaseSensitive: false,
      threshold: 0.3,
      minMatchCharLength: 1,
      includeMatches: true,
      keys: ['firstName', 'lastName', 'phoneNumber']
    });

    const matches = fuse.search(pattern);

    setSearchMatches(matches);
    setData(matches.map(({ item }) => item));
  };

  const searchByKeyword = _debounce((toSearch: string) => {
    setSearchTerm(toSearch);
    updateByFuse(toSearch);
  }, 300);

  const toggleForm = () => {
    setShowActionModal(!showActionModal);
  };

  const updateContactAdminRecord = (contactAction: IContactAction) => {
    setContactAction(contactAction);
    toggleForm();
  };

  const loadData = async (withPolling = false) => {
    const isPersonal = currentTabTitle === DIRECTORY_TAB.PERSONAL;

    if (!sessionStorage.getItem('c_user')) {
      return;
    }

    const setLoadingFn = withPolling ? setPolling : setLoading;
    setLoadingFn(true);

    const objectKey = `${REACT_APP_TENANT_ID}__${sessionStorage.getItem('c_user')}`;
    const url = isPersonal
      ? `${config.AGENT_SERVICE_HOST}/agent/${objectKey}/contacts`
      : `${config.AGENT_SERVICE_HOST}/contacts?tenantId=${REACT_APP_TENANT_ID}&includeAgents=true`;

    try {
      const res = await fetch_(url);
      const data = await res.json();

      const phonebookItems = isPersonal
        ? data.items
        : data.items.map((i) => ({
            ...i,
            source: i.objectIdentifier.endsWith('__contact') ? SourceType.CUSTOM : SourceType.CONNECT
          }));

      if (!searchTerm.trim()) {
        setSearchMatches([]);
      }

      setDataStore(phonebookItems);
      setData(polling && searchMatches.length ? searchMatches.map(({ item }) => item) : phonebookItems);
      setLoading(false);
      setPolling(false);

      if (searchTerm.trim()) {
        updateByFuse(searchTerm, phonebookItems);
      }
    } catch (e) {
      setLoading(false);
      setPolling(false);
    }
  };

  const closeModal = (shouldLoadData?: boolean) => {
    setShowActionModal(false);
    setSelectedIndex(currentTab);

    if (!shouldLoadData) {
      return;
    }

    loadData();
  };

  useEffect(() => {
    loadData();
  }, []);

  useInterval(() => {
    loadData(true);
  }, LOAD_INTERVAL_S * 1000);

  useEffect(() => {
    if (!showContactTabs) {
      return;
    }

    loadData();
  }, [currentTabTitle, showContactTabs]);

  useEffect(() => {
    setCurrentTab(initialSelectedTab);
    setSelectedIndex(initialSelectedTab);
  }, [initialSelectedTab]);

  const handleTabChange = (selectedTabIndex: number) => {
    setCurrentTab(selectedTabIndex);
    setSelectedIndex(selectedTabIndex);
    setData([]);
  };

  const rowRenderer = ({ index, key, style }) => {
    const row = data[index];

    const { matches } = searchMatches?.find((match) => match.item.objectKey === row.objectKey) || {};
    const presence = (row.status || row.presence || PRESENCE_STATES.OFFLINE) as PRESENCE_STATES;
    const showEdit = (currentTabTitle === DIRECTORY_TAB.PERSONAL || isAdmin) && !canTransfer;

    return (
      <Contact
        key={key}
        row={row}
        rowKey={key}
        style={style}
        showEdit={showEdit}
        presence={presence}
        matches={matches}
        updateContactAdminRecord={updateContactAdminRecord}
        onActiveCall={onActiveCall}
        canTransfer={canTransfer}
        connectedTask={connectedTask}
        closeModal={onClose}
        isDirectoryTransfer={isDirectoryTransfer}
        pushToTask={pushToTask}
        contentWidth={contentWidth}
      />
    );
  };

  if (showActionModal) {
    return (
      <UpsertContact
        show={showActionModal}
        closeModal={closeModal}
        contactAction={contactAction}
        currentTab={currentTab}
      />
    );
  }

  return (
    <div className={cx('directory', { 'directory--fill-container': fillContainer })}>
      {!isDialPadTransfer && directoryTabs.length > 1 && (
        <Tabs
          className="directory__tabs"
          selectedIndex={selectedIndex}
          updateSelectedTabIndex={handleTabChange}
          showSelectedTabLoader={showContactTabs && polling}
        >
          {directoryTabs.map((tab) => (
            <div key={tab} title={tab} />
          ))}
        </Tabs>
      )}
      {showContactTabs && (
        <>
          <div className="directory__phone-search" data-testid="directory-test">
            <Searchbar onChange={searchByKeyword} currentTab={currentTab} />
            {(currentTabTitle === DIRECTORY_TAB.PERSONAL || user.role.search('admin') >= 0) && (
              <ClickableIcon
                icon={faPlus}
                data-testid="create-contact"
                onClick={() =>
                  updateContactAdminRecord({
                    action: ContactActions.CREATE,
                    contact: defaultContact
                  })
                }
              />
            )}
          </div>
          {!loading && searchTerm && !data.length ? <p>We couldn't find any matches for that search.</p> : null}
          {loading && <Loader relative small />}
          {!loading && Boolean(data.length) && (
            <div className="directory__phone-container">
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={contentHeight - TABS_AND_SEARCH_HEIGHT}
                    overscanRowCount={3}
                    rowCount={data.length}
                    rowHeight={58}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </div>
          )}
          {!loading && !data.length && !searchTerm && <p>There is nothing in here yet.</p>}
        </>
      )}
      {showDialPad && (
        <DialPad
          onActiveCall={onActiveCall}
          activeCallContact={getActiveCallTask()?.contact}
          isDialPadTransfer={isDialPadTransfer}
          closeModal={onClose}
          pushToTask={pushToTask}
          contentWidth={contentWidth}
        />
      )}
    </div>
  );
};

export default Directory;
