import { TaskOverlayBanner } from 'lib/common/components/index';
import _pickBy from 'lodash.pickby';
import getMappedContactAttributes from 'lib/common/utils/getMappedContactAttributes';
import TTask from 'lib/common/types/Task';
import hasIncomingContactAttributes from 'lib/common/contexts/ContactContext/components/IncomingTaskOverlay/hasIncomingContactAttributes';
import { useSoftphoneLayout } from 'lib/common/contexts/SoftphoneLayoutContext';

import './incoming-task.scss';

const CONTENT_OFFSET_FROM_TOP = 48;

export default function IncomingTask({ selectedTask }: { selectedTask: TTask }) {
  const { contentHeight } = useSoftphoneLayout();

  if (!selectedTask) {
    return null;
  }

  const attributes = getMappedContactAttributes(selectedTask?.contact);
  const contactAttributes = attributes ? _pickBy(attributes, (attribute) => Boolean(attribute.isIncoming)) : void 0;

  return (
    <>
      <div className="incoming-task" style={{ maxHeight: contentHeight - CONTENT_OFFSET_FROM_TOP }}>
        <TaskOverlayBanner
          task={selectedTask}
          overlayOpen
          showQueueDetails
          showContactAttributes={hasIncomingContactAttributes(contactAttributes)}
          attributes={contactAttributes}
          isSoftphone
        />
      </div>
    </>
  );
}
