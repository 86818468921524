import React, { useState } from 'react';
import Iframe from 'react-iframe';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import { Loader } from '../..';
import '../styles/frame.scss';

export default function Frame(props: ConnectorModuleType) {
  const [loading, setLoading] = useState(true);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="frame__iframe-container" data-testid="frame__iframe-container">
        <Iframe url={props.source} id={props.moduleId} height="100%" frameBorder={0} onLoad={onLoad} />
      </div>
    </>
  );
}
