import { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _debounce from 'lodash.debounce';
import cx from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { ReactSelect } from 'lib/common/components';
import customerProfileSearchTypes from 'lib/common/constants/customerProfileSearchTypes';
import '../styles/search.scss';

const SEARCH_TYPES = {
  NUMBER: {
    value: customerProfileSearchTypes.phoneNumber,
    label: 'Phone Number',
    placeholder: 'Search using a phone number, international or with no prefix'
  },
  NAME: {
    value: customerProfileSearchTypes.name,
    label: 'Name',
    placeholder: 'Search using a full name, such as Bob Smith'
  },
  ACCOUNT_NUMBER: {
    value: customerProfileSearchTypes.accountNumber,
    label: 'Account Number',
    placeholder: 'Search using an account or membership number'
  },
  EMAIL: {
    value: customerProfileSearchTypes.emailAddress,
    label: 'Email Address',
    placeholder: 'Search using an email address'
  }
};

type TSearchType = { label: string; value: string; placeholder: string };

type TSearchProps = {
  searching: boolean;
  onSearch: (value: string, type: string) => Promise<void>;
};

export default function Search({ onSearch, searching }: TSearchProps) {
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState<TSearchType>(SEARCH_TYPES.NAME);

  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    _debounce((value) => onSearch(value, searchType.value), 1000),
    [searchType.value]
  );

  const onChangeSearch = ({ target: { value } }) => {
    setSearchValue(value);

    handleSearch(value);
  };

  useEffect(() => {
    if (!searchInput) {
      return;
    }

    searchInput?.current?.[searching ? 'blur' : 'focus']();
  }, [searching]);

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchType]);

  return (
    <div
      className={cx('panel--small', 'customer-profiles-search', {
        'customer-profiles-search--disabled': searching
      })}
    >
      <FontAwesomeIcon icon={faSearch} />
      <ReactSelect
        className="customer-profiles-search__dropdown"
        options={Object.values(SEARCH_TYPES)}
        onChange={setSearchType as any}
        defaultValue={searchType}
        value={searchType}
      />
      <input
        ref={searchInput}
        data-testid="customer-profile-search"
        onChange={onChangeSearch}
        value={searchValue}
        placeholder={searchType.placeholder}
      />
    </div>
  );
}
