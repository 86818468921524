import cx from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import COLORS from 'css/export-vars.module.scss';

interface IStyles {
  color?: string;
  ellipsis?: boolean;
  bold?: boolean;
}

interface ITextProps {
  type?: keyof typeof textType;
  color?: keyof typeof COLOR_MAP;
  className?: string;
  children: string | ReactNode;
  ellipsis?: boolean;
  bold?: boolean;
  onClick?: () => any;
  href?: string;
}

interface TTextType {
  [key: string]: {
    element: string;
    className?: string;
  };
}

const COLOR_MAP = {
  primary: COLORS.primary,
  danger: COLORS.danger,
  text: COLORS.text,
  darkBlue: COLORS.darkBlue
};

const optionalLinkProps = ({ onClick, href }) => ({
  onClick,
  href,
  target: href ? '_blank' : void 0,
  rel: href ? 'noreferrer' : void 0
});

const styles = ({ color, ellipsis, bold }: IStyles): CSSProperties => ({
  color: color && COLOR_MAP[color],
  textOverflow: ellipsis ? 'ellipsis' : void 0,
  overflow: ellipsis ? 'hidden' : void 0,
  whiteSpace: ellipsis ? 'nowrap' : void 0,
  fontWeight: bold ? 800 : void 0
});

const textType: TTextType = {
  heading1: { element: 'h1' },
  heading2: { element: 'h2' },
  body: { element: 'p' },
  label: { element: 'label' },
  // text classes are defined in typography scss file until we move all text to this component
  helper: { element: 'p', className: 'helper-text' },
  link: { element: 'a' }
};

const Text = ({ type = 'body', color, className, ellipsis, bold, children, onClick, href }: ITextProps) => {
  const TextElement = textType[onClick ? 'link' : type].element as keyof JSX.IntrinsicElements;
  const computedClassName = cx(textType[type]?.className, className);

  return (
    <TextElement
      {...optionalLinkProps({ onClick, href })}
      style={styles({ color, ellipsis, bold })}
      className={computedClassName}
    >
      {children}
    </TextElement>
  );
};

export default Text;
