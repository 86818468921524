export default function getDoughnutGraphOptions({ statsData, loaded }) {
  const backgroundColour = !loaded ? '#F7F8FA' : ['#34C759', '#D5554A'];
  const reversedBackgroundColor = !loaded ? '#F7F8FA' : ['#D5554A', '#34C759'];

  return {
    sls: {
      datasets: [
        {
          data: statsData.serviceLevel,
          backgroundColor: backgroundColour,
          hoverBackgroundColor: backgroundColour,
          hoverBorderColor: 'white',
          borderColor: 'white'
        }
      ]
    },
    handleRate: {
      datasets: [
        {
          data: statsData.handleRate,
          backgroundColor: backgroundColour,
          hoverBackgroundColor: backgroundColour,
          hoverBorderColor: 'white',
          borderColor: 'white'
        }
      ]
    },
    abandonRate: {
      datasets: [
        {
          data: statsData.abandonRate,
          backgroundColor: reversedBackgroundColor,
          hoverBackgroundColor: reversedBackgroundColor,
          hoverBorderColor: 'white',
          borderColor: 'white'
        }
      ]
    }
  };
}
