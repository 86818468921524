import { toast as toastify } from 'react-toastify';
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from 'lib/common/components';
import ToastAlert, { IToastAlertProps } from './ToastAlert';

import './toast.scss';

const getToastContent = (content: string | IToastAlertProps, type: TypeOptions) => {
  if (typeof content === 'string') {
    return <ToastAlert message={content} type={type} />;
  }

  return <ToastAlert {...content} type={type} />;
};

export default function toast(type: TypeOptions, content: string | IToastAlertProps, options: ToastOptions = {}) {
  toastify[type](getToastContent(content, type), {
    ...options,
    icon: () => null,
    closeButton: ({ closeToast }) => <Icon size={17} onClick={closeToast} icon={faTimes} />
  });
}
