import React, { useContext } from 'react';
import { TextField, makeStyles, createStyles, MenuItem, FormHelperText } from '@material-ui/core';
import { Formik, Form, FormikProps } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Select, Button, Modal } from 'lib/common/components';

const lower = (str) => (str ? str.toLowerCase() : '');

function outcomeExists(outcomes, { newName, existingName }) {
  const newLower = lower(newName);
  const existingLower = lower(existingName);

  return outcomes.some(
    ({ name }) =>
      (!existingName && lower(name) === newLower) ||
      (existingName && newLower !== existingLower && lower(name) !== existingLower && newLower === lower(name))
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    modalRoot: {
      maxWidth: '500px',
      display: 'flex',
      margin: 'auto',
      height: '100%',
      width: 'calc(100% - 20px)'
    },
    textField: {
      marginBottom: '40px'
    },
    selectMenu: {
      '& > *': {
        width: '100%'
      },
      marginBottom: '40px'
    },
    selectHelper: {
      marginBottom: '1vw'
    },
    selectLabel: {
      marginTop: '0.5vw'
    },
    progressLoader: {
      marginTop: '10px'
    },
    title: { textAlign: 'center', marginBottom: '2vw' },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
    paper: {
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
      borderRadius: 16,
      margin: 'auto',
      height: 'fit-content',
      width: '100%',
      padding: 40
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '40px',

      '&>.button:first-of-type': {
        marginRight: '20px'
      }
    }
  })
);

interface IProps {
  getOutcomes: (...args: any) => any;
  editMode: boolean;
  rowSelected: any;
  toggleAddOutcome: (...args: any) => any;
  outcomes: Record<string, any>[];
  show: boolean;
}

interface IOutcomeForm {
  tenantId: string;
  objectType: string;
  objectId: string;
  code: number;
  name: string;
  type: string;
  description: string;
}

const ManageOutcomesPageInner: React.FunctionComponent<IProps> = (props_) => {
  const classes = useStyles();

  const { config } = useContext(AuthContext);
  const { fetch_ } = useContext(AuthContext);
  const tenantID = process.env.REACT_APP_TENANT_ID || '';
  const createNewOutcome = async (data: IOutcomeForm, { resetForm, setSubmitting }) => {
    try {
      fetch_(`${config.CALL_SERVICE_HOST}/addoutcome`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
      }).then((res) => {
        if (res.status !== 200) {
          throw new Error();
        }
        props_.toggleAddOutcome();
        !props_.editMode && resetForm({});
        props_.getOutcomes();
      });
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={
          !props_.editMode
            ? {
                tenantId: tenantID,
                objectType: 'outcome',
                objectId: '',
                code: -1,
                name: '',
                type: '',
                description: ''
              }
            : {
                tenantId: tenantID,
                objectType: 'outcome',
                objectId: props_.rowSelected.objectId,
                code: props_.rowSelected.code,
                name: props_.rowSelected.name,
                type: props_.rowSelected.type,
                description: props_.rowSelected.description
              }
        }
        onSubmit={(data, actions) => {
          createNewOutcome(data, actions);
        }}
        validationSchema={YupObject().shape({
          name: YupString().required('Please enter an outcome name'),
          type: YupString().required('Please select an outcome type'),
          description: YupString().required('Please enter a description for this outcome')
        })}
      >
        {(props: FormikProps<IOutcomeForm>) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting } = props;

          const nameExists = outcomeExists(props_.outcomes, {
            newName: values.name,
            existingName: props_.rowSelected && props_.rowSelected.name
          });

          return (
            <Modal
              open
              title={`${props_.editMode ? 'Edit A' : 'Add A New'} Call Outcome`}
              onClose={props_.toggleAddOutcome}
              small
              hideFooter
              contentTopPadded
            >
              <Form translate="yes">
                {props_.editMode && (
                  <div className={classes.textField}>
                    <TextField
                      label="Code"
                      variant="outlined"
                      name="code"
                      id="code"
                      value={`000${values.code}`.substr(-3, 3)}
                      helperText={errors.code && touched.code ? errors.name : ''}
                      type="text"
                      disabled
                    />
                  </div>
                )}
                <div className={classes.textField}>
                  <TextField
                    label="Name"
                    name="name"
                    variant="outlined"
                    id="name"
                    value={values.name}
                    type="text"
                    helperText={
                      nameExists
                        ? 'A call outcome with this name already exists'
                        : errors.name && touched.name
                        ? errors.name
                        : ''
                    }
                    error={!!(nameExists || (errors.name && touched.name))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.selectMenu}>
                  <Select
                    label="Type"
                    name="type"
                    id="type"
                    value={values.type}
                    error={!!(errors.type && touched.type)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="Inbound">Inbound</MenuItem>
                    <MenuItem value="Outbound">Outbound</MenuItem>
                    <MenuItem value="Inbound/Outbound">Inbound/Outbound</MenuItem>
                    <MenuItem value="All">All</MenuItem>
                  </Select>
                  <FormHelperText className={classes.selectHelper}>
                    {errors.type && touched.type ? errors.type : ''}
                  </FormHelperText>
                </div>
                <div className={classes.textField}>
                  <TextField
                    label="Description"
                    name="description"
                    id="description"
                    value={values.description}
                    type="text"
                    helperText={errors.description && touched.description ? errors.description : ''}
                    error={!!(errors.description && touched.description)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                  />
                </div>
                <div className={classes.footer}>
                  <Button styleType="SECONDARY" disabled={isSubmitting} onClick={() => props_.toggleAddOutcome()}>
                    Cancel
                  </Button>
                  <Button
                    busy={isSubmitting}
                    type="submit"
                    disabled={Boolean(
                      !values.name || !values.description || !values.type || nameExists || (errors.name && touched.name)
                    )}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};

const ManageOutcomesPage: React.FC<IProps> = (props_) => {
  if (!props_.show) {
    return null;
  }
  return <ManageOutcomesPageInner {...props_} />;
};

export default ManageOutcomesPage;
