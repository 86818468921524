import React from 'react';
import { GithubPicker } from 'react-color';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';
import { Modal } from 'lib/common/components';
import { TextField, Typography } from '@material-ui/core';
import DASHBOARD_COLOURS from '../constants/dashboardBgColours';
import '../styles/dashboard-action.scss';

export default function AddDB(props: any) {
  const { copyDB } = props;
  const { curData } = props;
  const [title, setTitle] = React.useState(`${curData.name} Copy`);
  const [backgroundColor, setbackgroundColor] = React.useState(curData.backgroundcolor);
  const [description, setDescription] = React.useState<string>();

  const titleChange = (event) => {
    setTitle(event.target.value as string);
  };

  const descriptionChange = (event) => {
    setDescription(event.target.value as string);
  };

  const handleChangeColor = (color) => {
    /* values["backgroundcolor"] = color.hex */
    setbackgroundColor(color.hex);
  };

  const submitDB = () => {
    if (!title) {
      return;
    }
    const trimmedTitle = title?.trim();

    const tempData = {
      status: true,
      data: {
        id: `d${Date.now()}`,
        name: trimmedTitle,
        description,
        backgroundcolor: backgroundColor,
        permission: curData.permission,
        theme: curData.theme,
        layout: curData.layout,
        components: curData.components
      }
    };

    return copyDB(tempData);
  };

  const cancelDB = () => {
    copyDB(curData);
  };

  return (
    <Modal
      open
      contentTopPadded
      title="Duplicate Dashboard"
      onSave={submitDB}
      onClose={cancelDB}
      saveDisabled={!title?.trim()}
    >
      <div className="dashboard-action">
        <TextField
          id="name"
          label="Name"
          onChange={titleChange}
          value={title}
          variant="outlined"
          onKeyPress={onEnterKeyPressed(submitDB)}
          data-testid="duplicate-dashboard-name"
        />
      </div>

      <Typography className="edit-widget-label">Theme</Typography>

      <div className="dashboard-action ">
        <GithubPicker
          colors={DASHBOARD_COLOURS}
          color={backgroundColor}
          onChangeComplete={handleChangeColor}
          width="100%"
          triangle="hide"
        />
      </div>

      <div className="dashboard-action">
        <TextField
          id="description"
          label="Description"
          onChange={descriptionChange}
          value={description}
          variant="outlined"
          data-testid="duplicate-dashboard-description"
        />
      </div>
    </Modal>
  );
}
