import { useMediaQuery } from 'react-responsive';
import { Redirect } from 'react-router-dom';
import VARS from 'css/export-vars.module.scss';

export default function RedirectIfSoftphone({ to }) {
  const isSmall = useMediaQuery({ query: `(max-width: ${VARS.softphoneWidth})` });

  if (isSmall) {
    return <Redirect to={to} />;
  }

  return null;
}
