import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import connectGetter from 'lib/common/utils/connectGetter';
import toast from 'lib/common/utils/toast';
import connectAction from 'lib/common/utils/connectAction';
import { AGENT_TASK_TRANSFER_FAILURE } from 'lib/common/constants/connectExceptions';

const TIME_WAIT_FOR_TRANSFER_FAIL_MS = 5000;

function getTaskContact(tasks, taskId) {
  return tasks.find((task) => task.taskId === taskId)?.contact;
}

export default function useCallActions({ tasks, handleContactChange }) {
  const holdCall = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      const activeInitialConnection = connectGetter('getActiveInitialConnection', contact);

      if (!activeInitialConnection) {
        throw new Error('No active connection found');
      }

      await connectAction('hold', activeInitialConnection);
      handleContactChange({ contact, connectionState: CONNECTION_STATES.HOLD });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const resumeCall = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      const activeInitialConnection = connectGetter('getActiveInitialConnection', contact);

      if (!activeInitialConnection) {
        throw new Error('No active connection found');
      }

      await connectAction('resume', activeInitialConnection);
      handleContactChange({ contact, connectionState: CONNECTION_STATES.LIVE });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const holdOutboundCall = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      const activeThirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', contact);

      if (!activeThirdPartyConnection) {
        throw new Error('No active third party connection found');
      }

      await connectAction('hold', activeThirdPartyConnection);
    } catch {}
  };

  const resumeOutboundCall = async (taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      const activeThirdPartyConnection = connectGetter('getSingleActiveThirdPartyConnection', contact);

      if (!activeThirdPartyConnection) {
        throw new Error('No active third party connection found');
      }

      await connectAction('resume', activeThirdPartyConnection);
    } catch {}
  };

  const onTransferToQueueOrAgent = async (endpoint: connect.Endpoint, taskId) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    if (connectGetter('getType', contact) === connect.ContactType.TASK) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          const agentConnection = connectGetter('getAgentConnection', contact);
          const taskSession = await connectGetter('getMediaController', agentConnection);

          taskSession.onTransferFailed((message) => {
            toast('error', AGENT_TASK_TRANSFER_FAILURE);
            reject(message);
          });

          await connectAction('addConnection', contact, endpoint);

          setTimeout(resolve, TIME_WAIT_FOR_TRANSFER_FAIL_MS);
        } catch (e) {
          return reject(e);
        }
      });
    }

    try {
      await connectAction('addConnection', contact, endpoint);
      handleContactChange({ contact, connectionState: CONNECTION_STATES.HOLD });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  return {
    holdCall,
    resumeCall,
    onTransferToQueueOrAgent,
    holdOutboundCall,
    resumeOutboundCall
  };
}
