export default async function getInstanceQueues({ fetch_, config }) {
  try {
    const allQueueResponse = await fetch_(
      `${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/all-queues`,
      { method: 'GET' }
    );

    return allQueueResponse.json();
  } catch {
    return [];
  }
}
