import { useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faFolders, faPage, faDogLeashed } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { ClickableIcon } from 'lib/common/components';

import helpUrl from 'lib/common/constants/helpUrl';
import { desktopSteps } from 'lib/common/components/Tour/constants/desktopTour';
import DOCUMENTS from '../constants/documents';

export default function HelpMenu({ pagename, match }) {
  const { pathname } = useLocation();
  const { setSteps, setCurrentStep, setIsOpen } = useTour();
  const [anchorElDoc, setAnchorElDoc] = useState(null);
  const { setting } = match.params;
  const selectedItem = `${pagename}${setting ? ` ${setting}` : ''}`;
  const isDocOpen = Boolean(anchorElDoc);
  const hasTour = Object.keys(desktopSteps).includes(pathname);
  const hasHelpDocs = Object.keys(DOCUMENTS).includes(selectedItem);

  const handleDocOpen = (event: any) => {
    setAnchorElDoc(event.currentTarget);
  };

  const handleDocClose = async (e: any) => {
    e.preventDefault();
    setAnchorElDoc(null);
  };

  const tourHelpClicked = () => {
    setIsOpen(true);
    setCurrentStep(0);
    setSteps(desktopSteps[pathname]);
  };

  return (
    <>
      <ClickableIcon icon={faCircleQuestion} className="header__documents" onClick={handleDocOpen} tooltip="Help" />
      <Menu
        anchorEl={anchorElDoc}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            marginTop: 16
          }
        }}
        open={isDocOpen}
        onClose={handleDocClose}
        elevation={1}
        getContentAnchorEl={null}
        disableAutoFocusItem
      >
        {hasTour && (
          <MenuItem button onClick={tourHelpClicked}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faDogLeashed} />
            </ListItemIcon>
            <ListItemText primary="Tour This Page" />
          </MenuItem>
        )}
        {hasTour && <Divider light variant="middle" />}
        <a
          href={helpUrl}
          target="blank"
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          <MenuItem button>
            <ListItemIcon>
              <FontAwesomeIcon icon={faFolders} />
            </ListItemIcon>
            <ListItemText primary="All NEON Help" />
          </MenuItem>
        </a>
        {hasHelpDocs &&
          DOCUMENTS[selectedItem].map((doc) => (
            <a
              key={doc.uri}
              href={doc.uri}
              target="blank"
              style={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              <MenuItem>
                <ListItemIcon>
                  <FontAwesomeIcon icon={faPage} />
                </ListItemIcon>
                <ListItemText primary={doc?.title ?? ''} />
              </MenuItem>
            </a>
          ))}
      </Menu>
    </>
  );
}
