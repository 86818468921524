import { ReactNode } from 'react';
import { Modal as MUIModal, Slide } from '@material-ui/core';
import cx from 'classnames';

import { Button } from 'lib/common/components';

import styles from './softphone-modal.module.scss';

interface ISoftphoneModalProps {
  children: ReactNode;
  isModalOpen: boolean;
  closeModal: () => void;
  handleClearContent: () => void;
  onSave?: () => void;
  saveDisabled?: boolean;
  primaryButtonText?: string;
  isReversedLayout: boolean;
}

const LEFT_DIRECTION = 'left';
const RIGHT_DIRECTION = 'right';
const MODAL_CONTENT_TIMEOUT = 300;

export default function ({
  children,
  isModalOpen,
  closeModal,
  handleClearContent,
  onSave,
  saveDisabled,
  primaryButtonText,
  isReversedLayout
}: ISoftphoneModalProps) {
  return (
    <MUIModal
      open={Boolean(isModalOpen)}
      className={cx(styles['softphone-modal'], { [styles['softphone-modal--reversed']]: isReversedLayout })}
      onClose={closeModal}
      hideBackdrop
    >
      <Slide
        direction={isReversedLayout ? RIGHT_DIRECTION : LEFT_DIRECTION}
        in={Boolean(isModalOpen)}
        timeout={MODAL_CONTENT_TIMEOUT}
        onExited={handleClearContent}
      >
        <div className={styles['softphone-modal__content']}>
          <div className={styles['softphone-modal__content__children']}>{children}</div>
          {Boolean(onSave) && (
            <div className={styles['softphone-modal__content__footer']}>
              <Button
                className={styles['softphone-modal__content__footer__button']}
                onClick={closeModal}
                styleType="SECONDARY"
              >
                Cancel
              </Button>
              <Button
                data-testid="modal-save-button"
                disabled={saveDisabled}
                onClick={saveDisabled ? void 0 : onSave}
                onSuccess={closeModal}
                asyncAction
              >
                {primaryButtonText || 'Save'}
              </Button>
            </div>
          )}
        </div>
      </Slide>
    </MUIModal>
  );
}
