import { Button } from 'lib/common/components/index';

export default function ConferenceDisconnectButton({ onDisconnect }) {
  return (
    <Button
      small
      round
      onClick={onDisconnect}
      icon="faTimes"
      styleType="DANGER"
      asyncAction
      successTimeoutSeconds={0}
      tooltip="End the connection"
    />
  );
}
