import { useContext, useState, createRef, RefObject, useEffect } from 'react';
import EventEmitter from 'lib/common/utils/EventEmitter';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import Widget from './components/Widget';

const Widgets = () => {
  const {
    state: { widgetModules }
  } = useModulesContext();
  const { config, fetch_ } = useContext(AuthContext);
  const [iframeRefs, setIframeRefs] = useState<RefObject<HTMLIFrameElement>[]>([]);

  const fetchAgentService = (urlSuffix, options) =>
    fetch_(`${config.AGENT_SERVICE_HOST}/connect/${config.TENANT_ID}/${urlSuffix}`, options);

  useEffect(() => {
    setIframeRefs(widgetModules.map(() => createRef<HTMLIFrameElement>()));
  }, [widgetModules]);

  useEffect(() => {
    const sendMessage = (contact, event: string) => {
      iframeRefs.forEach((iframeRef) => {
        iframeRef?.current?.contentWindow?.postMessage?.({ data: contact?.toSnapshot()?.contactData, msg: event }, '*');
      });
    };

    EventEmitter.on('onContactConnecting', (contact: connect.Contact) => {
      sendMessage(contact, 'onContactConnecting');
    });

    EventEmitter.on('onContactConnected', (contact: connect.Contact) => {
      sendMessage(contact, 'onContactConnected');
    });

    EventEmitter.on('onContactEnded', (contact: connect.Contact) => {
      sendMessage(contact, 'onContactEnded');
    });
  }, [iframeRefs]);

  return (
    <div>
      {widgetModules.map((widgetModule, index) => (
        <Widget
          key={widgetModule?.moduleId}
          fetchAgentService={fetchAgentService}
          widgetDetails={widgetModule}
          iframeRef={iframeRefs[index]}
          modulesUrl={config.MODULES_S3DOMAIN}
        />
      ))}
    </div>
  );
};

export default Widgets;
