import { Modal, Text } from 'lib/common/components';

import './hangup-modal.scss';

interface IHangupModal {
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
  onSave: () => void;
  primaryText: string;
  secondaryText?: string;
}

const HangupModal = ({ open, onClose, onSave, onSuccess, primaryText, secondaryText }: IHangupModal) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={onSave}
      onSuccess={onSuccess}
      title="Hang Up & Call?"
      primaryButtonText="Call"
    >
      <Text>{primaryText}</Text>
      {secondaryText ? <Text className="hangup-modal__secondary-text">{secondaryText}</Text> : null}
    </Modal>
  );
};

export default HangupModal;
