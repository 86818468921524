import cx from 'classnames';

import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { ReactComponent as Logo } from 'assets/vectors/neon-logo.svg';
import { Text, ClickableIcon, Loader } from 'lib/common/components';
import TASK_TITLES from 'lib/common/constants/tasks/taskTitles';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { AgentStatusTimer } from 'lib/common/components/molecules';
import './softphone-header.scss';

interface ISoftphoneHeaderProps {
  pageTitle: string;
  hasLoader?: boolean;
  isReversedLayout: boolean;
}

const SoftphoneHeader = ({ pageTitle, hasLoader, isReversedLayout }: ISoftphoneHeaderProps) => {
  const {
    state: { tasks, selectedTaskId }
  } = useContactContext();
  const {
    state: { isModalOpen, modalTitle },
    actions: { closeModal }
  } = useSoftphoneModalContext();

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const taskTitle = selectedTask ? TASK_TITLES[selectedTask.type]?.[selectedTask.status] : '';

  const title = (isModalOpen && modalTitle) || pageTitle || taskTitle;

  return (
    <div className={cx('softphone-header', { 'softphone-header--reversed': isReversedLayout })}>
      <Logo className="softphone-header__icon" />
      {<AgentStatusTimer className="softphone-header__agent-status-timer" />}
      {title && (
        <div className="softphone-header__title" data-tour={title}>
          {hasLoader && <Loader size={25} minimised />}
          <Text className="softphone-header__title__text" color="darkBlue" type="heading2">
            {title}
          </Text>
          {isModalOpen && (
            <ClickableIcon className="softphone-header__close-button" onClick={() => closeModal()} icon={faClose} />
          )}
        </div>
      )}
    </div>
  );
};

export default SoftphoneHeader;
