import { createContext, ReactNode, useContext } from 'react';
import sizeMe from 'react-sizeme';

const LayoutContext = createContext<{
  contentWidth: number;
  contentHeight: number;
  setHeaderLoading: (boolean) => void;
}>({
  contentHeight: 0,
  contentWidth: 0,
  setHeaderLoading: (_: boolean) => {}
});

const sizeMeHOC = sizeMe({ monitorWidth: true, monitorHeight: true, refreshRate: 16 });

const CONTENT_PADDING = 15;

export const LayoutProvider = sizeMeHOC(
  ({
    children,
    size,
    setHeaderLoading
  }: {
    children: ReactNode;
    size: { height: number; width: number };
    setHeaderLoading: (boolean) => void;
  }) => (
    <LayoutContext.Provider
      value={{
        contentHeight: size.height - CONTENT_PADDING * 2,
        contentWidth: size.width - CONTENT_PADDING * 2,
        setHeaderLoading
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
);

export const useSoftphoneLayout = () => useContext(LayoutContext);
