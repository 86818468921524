import React from 'react';
import cx from 'classnames';
import { faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader, Text } from 'lib/common/components';
import useData from '../../../../hooks/useData';
import Error from '../Error/Error';
import './count.scss';

interface IProps {
  block: any;
  extraClass?: string;
  refresh: number;
  small: boolean;
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
}

const TIME_PROCESSOR = 'getTimeFmt';

const isNullishValue = (value) => value === void 0 || value === null;

function failsThreshold(data, processor, { enabled, value, calc }) {
  if (!enabled || isNullishValue(data)) {
    return false;
  }

  const dataValue = parseInt(processor === TIME_PROCESSOR ? data.match(/\d+/g)?.[0] : data, 10);

  if (isNaN(dataValue) || isNullishValue(dataValue)) {
    return false;
  }

  const thresholdNumberValue = parseInt(value, 10);

  return calc === 'lte' ? dataValue < thresholdNumberValue : dataValue > thresholdNumberValue;
}

export default function Count(props: IProps) {
  const {
    extraClass,
    block: {
      threshold: { warning, alert },
      processor,
      element,
      queueid,
      dataModule,
      name
    },
    block: raw,
    refresh,
    small,
    fetch,
    config
  } = props;
  const { loading, data, error } = useData({
    dataModule,
    element,
    raw,
    processor,
    refresh,
    queueid,
    fetch,
    config
  });

  const showWarning = failsThreshold(data, processor, warning);
  const showAlert = failsThreshold(data, processor, alert);

  const icon =
    showWarning || showAlert ? (
      <FontAwesomeIcon
        icon={showAlert ? faExclamationTriangle : faExclamationCircle}
        color={showAlert ? '#F83B2F' : '#ffdd19'}
      />
    ) : null;

  const allowNaN = processor === TIME_PROCESSOR;

  return (
    <div
      className={cx('grid-item', 'count', extraClass, {
        'count--warning': showWarning && !showAlert,
        'count--alert': showAlert
      })}
    >
      <Text type={small ? void 0 : 'heading2'} color="darkBlue" bold={small}>
        {name}
      </Text>
      <div className="count__icon">{icon}</div>
      {loading && <Loader small />}
      {!loading && error && <Error small={small} />}
      {(allowNaN || !isNaN(data || 0)) && !loading && !error && (
        <div className={cx('count__number', { 'count__number--small': small })} data-testid="count-widget">
          {data}
        </div>
      )}
    </div>
  );
}
