import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

function mapStatus(status) {
  if ([PRESENCE_STATES.AVAILABLE, PRESENCE_STATES.OFFLINE].includes(status)) {
    return status;
  }
  return PRESENCE_STATES.BUSY;
}

export default async function storeAgentStatus({ status, fetch, tokens, config }) {
  if (!tokens || !config || !Object.keys(config).length || !fetch) {
    return;
  }

  try {
    await fetch(
      `${config.AGENT_SERVICE_HOST}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/status`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status: mapStatus(status) })
      },
      tokens
    );
  } catch (e) {
    console.error('Error saving agent status', e);
  }
}
