import { Directory as PureDirectory } from 'lib/common/components/molecules';
import getQueryParam from 'lib/common/utils/getQueryParam';
import { useSoftphoneLayout } from 'lib/common/contexts/SoftphoneLayoutContext';

import styles from './directory.module.scss';

export default function Directory({ user }) {
  const { contentHeight, contentWidth } = useSoftphoneLayout();
  const directoryIndex = getQueryParam({ param: 'directory' });

  return (
    <div className={styles['softphone-directory']} style={{ height: contentHeight, minHeight: contentHeight }}>
      <PureDirectory
        user={user}
        contentHeight={contentHeight}
        contentWidth={contentWidth}
        initialSelectedTab={directoryIndex}
        fillContainer
      />
    </div>
  );
}
