import { useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as NeonLogo } from 'assets/vectors/neon-logo.svg';
import { Loader, Icon } from 'lib/common/components';
import helpUrl from 'lib/common/constants/helpUrl';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { ReactComponent as LoginImage } from './assets/login.svg';

import './login.scss';

const LOGIN_HELP_TIMEOUT_S = 10000;

export default function Login() {
  const { agent } = useAgentContext();

  const [extendedLoad, setExtendedLoad] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setExtendedLoad(true);
    }, LOGIN_HELP_TIMEOUT_S);

    return () => {
      clearTimeout(timeout);
    };
  });

  // If we have the agent, we're logged in. Show a loader while we fetch the user
  if (agent) {
    return <Loader elevated fixed />;
  }

  return (
    <div className="login">
      <div className="login__content">
        <div className="login__content__left">
          <NeonLogo />
          <h1>Sign In</h1>
          <h2>We're opening another window for you to sign in using Amazon Connect.</h2>
          <p>
            <strong>You’ll need to allow popups.</strong> If you’re already signed in to Connect, we’ll sign you in to
            NEON automatically.
          </p>
          <CSSTransition in={extendedLoad} timeout={300} classNames="login__content__left__extended-load">
            <div className="login__content__left__extended-load">
              <Icon icon={faInfoCircle} size={25} />
              <div className="login__content__left__extended-load__text">
                <p>We're still working hard to sign you in. It may take a few more moments.</p>
                <p>
                  Sign in not working? Try <a href={window.location.origin}>reloading the page</a>.
                </p>
              </div>
            </div>
          </CSSTransition>
          <a href={`${helpUrl}help/why-do-users-sign-in-to-neon-with-amazon-connect/`} target="_blank" rel="noreferrer">
            Why do I need to sign in with Amazon Connect?
          </a>
          <a href={`${helpUrl}help/the-amazon-connect-sign-in-window-doesnt-appear/`} target="_blank" rel="noreferrer">
            Sign in window not appearing?
          </a>
        </div>
        <div className="login__content__right">
          <LoginImage />
        </div>
      </div>
    </div>
  );
}
