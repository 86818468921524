import filterDisabledQueues from 'lib/common/utils/filterDisabledQueues';

const STORED_QUEUE_ID_KEY = 'selected-wallboard-queue';
const QUEUE_TYPE = 'STANDARD';

export default async function getQueues({ fetch, config }) {
  const res = await fetch(`${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/list/queues`, {
    method: 'GET'
  });
  const result = await res.json();
  const responseQueues = result.filter((q) => q.QueueType === QUEUE_TYPE);
  const filteredQueues = filterDisabledQueues({
    allQueues: responseQueues,
    disabledQueuePrefix: config.DISABLED_QUEUE_PREFIX
  });

  const storedQueueId = localStorage.getItem(STORED_QUEUE_ID_KEY);
  const allQueues = storedQueueId === 'all';

  const defaultQueue = allQueues
    ? { Id: 'all', Name: 'All Queues' }
    : filteredQueues.find((q) => !storedQueueId || storedQueueId === q.Id) || filteredQueues[0];

  return {
    queueData: filteredQueues,
    queueSelected: {
      id: defaultQueue.Id,
      name: defaultQueue.Name
    }
  };
}
