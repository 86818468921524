import CONTACT_TYPES from '../contactTypes';
import CONTACT_STATES from '../contactStates';

export default {
  [CONTACT_TYPES.CALL]: {
    [CONTACT_STATES.CONNECTING]: 'Incoming Call',
    [CONTACT_STATES.CONNECTED]: 'Connected Call',
    [CONTACT_STATES.MISSED]: 'Missed Call',
    [CONTACT_STATES.ACW]: 'After Call Work',
    [CONTACT_STATES.REJECTED]: 'Rejected Call'
  },
  [CONTACT_TYPES.QUEUE_CALLBACK]: {
    [CONTACT_STATES.CONNECTING]: 'Incoming Call',
    [CONTACT_STATES.CONNECTED]: 'Connected Call',
    [CONTACT_STATES.MISSED]: 'Missed Callback',
    [CONTACT_STATES.ACW]: 'After Call Work',
    [CONTACT_STATES.REJECTED]: 'Rejected Callback'
  },
  [CONTACT_TYPES.CHAT]: {
    [CONTACT_STATES.CONNECTING]: 'Incoming Chat',
    [CONTACT_STATES.CONNECTED]: 'Connected Chat',
    [CONTACT_STATES.MISSED]: 'Missed Chat',
    [CONTACT_STATES.ACW]: 'After Chat Work',
    [CONTACT_STATES.REJECTED]: 'Rejected Chat'
  },
  [CONTACT_TYPES.TASK]: {
    [CONTACT_STATES.CONNECTING]: 'Incoming Task',
    [CONTACT_STATES.CONNECTED]: 'Active Agent Task',
    [CONTACT_STATES.MISSED]: 'Missed Task',
    [CONTACT_STATES.ACW]: 'After Task Work',
    [CONTACT_STATES.REJECTED]: 'Rejected Task'
  },
  [CONTACT_TYPES.CONFERENCE_CALL]: {
    [CONTACT_STATES.CONNECTING]: 'Incoming Conference',
    [CONTACT_STATES.CONNECTING]: 'Connecting Conference',
    [CONTACT_STATES.CONNECTED]: 'Connected Conference',
    [CONTACT_STATES.ACW]: 'After Call Work',
    [CONTACT_STATES.REJECTED]: 'Rejected Conference'
  }
};
