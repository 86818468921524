import CHAT_EVENTS from 'lib/common/constants/chatEvents';

function getTaskChatSession(tasks, taskId): connect.ChatSession | undefined {
  return tasks.find((task) => task.taskId === taskId)?.chatSession;
}

export default function useChatActions(tasks) {
  const sendMessage = async (taskId, message) => {
    const session = getTaskChatSession(tasks, taskId);

    if (!session) {
      return;
    }

    return session.sendMessage({ contentType: 'text/plain', message });
  };

  const sendTypingEvent = (taskId) => {
    const session = getTaskChatSession(tasks, taskId);

    if (!session) {
      return;
    }

    session.sendEvent({ contentType: CHAT_EVENTS.TYPING });
  };

  return {
    sendMessage,
    sendTypingEvent
  };
}
