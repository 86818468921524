import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { Accordion, Icon } from 'lib/common/components';

import styles from './task-references.module.scss';

const ReferencesWrapper = ({ children }) => (
  <div className={styles['task-references__list']}>
    <h3 className={styles['task-references__list__title']}>
      <Icon icon={faLink} /> References
    </h3>
    {children}
  </div>
);

const ReferenceList = ({ list }: { list: connect.ReferenceDictionary }) => (
  <div className={styles['task-references']}>
    {Object.entries(list).map(([key, { value }], index) => (
      <div className={styles['task-references__item']} key={index}>
        <p className={styles['task-references__item__title']}>
          <strong>{key}</strong>
        </p>
        <a className={styles['task-references__item__link']} rel="noreferrer" target="_blank" href={value}>
          {value}
        </a>
      </div>
    ))}
  </div>
);

const TaskReferences = ({ contact, isSoftphone = false }: { contact: connect.Contact; isSoftphone?: boolean }) => {
  // @ts-ignore Connect has incorrectly typed toSnapshot's return as Contact, but it's actually { contactData: connect.Contact }
  const { references } = contact.toSnapshot().contactData;
  const hasReferences = references && Boolean(Object.entries(references).length);

  if (!hasReferences) {
    return null;
  }

  if (isSoftphone) {
    return (
      <Accordion title="References" className="mt-10">
        <ReferenceList list={references} />
      </Accordion>
    );
  }

  return (
    <ReferencesWrapper>
      <ReferenceList list={references} />
    </ReferencesWrapper>
  );
};

export default TaskReferences;
