import {
  faClockRotateLeft,
  faGauge,
  faAddressCard,
  faLayerGroup,
  faObjectsColumn
} from '@fortawesome/pro-regular-svg-icons';

import { PAGE_ROUTES } from './pageConfigurations';
import { PERMISSIONS } from './permissions';

const DASHBOARDS_OPTION = {
  link: PAGE_ROUTES.DASHBOARDS,
  icon: faGauge,
  name: 'Dashboards',
  permission: PERMISSIONS.DASHBOARDS,
  activeClassName: 'nav-active',
  permissionType: 'user'
};

const WORKSPACE_OPTION = {
  link: PAGE_ROUTES.WORKSPACE,
  icon: faLayerGroup,
  name: 'Agent Workspace',
  activeClassName: 'nav-active'
};

export const DEFAULT_NAV_OPTIONS = (isAdmin: boolean) => [
  isAdmin ? DASHBOARDS_OPTION : WORKSPACE_OPTION,
  isAdmin ? WORKSPACE_OPTION : DASHBOARDS_OPTION,
  {
    link: PAGE_ROUTES.CUSTOMER_PROFILES,
    icon: faAddressCard,
    name: 'Customer Profiles',
    permission: PERMISSIONS.CUSTOMER_PROFILES,
    activeClassName: 'nav-active'
  },
  {
    link: PAGE_ROUTES.INTERACTION_HISTORY,
    icon: faClockRotateLeft,
    name: 'Interaction History',
    permission: PERMISSIONS.INTERACTION_HISTORY,
    activeClassName: 'nav-active'
  },
  {
    link: PAGE_ROUTES.WALLBOARD,
    icon: faObjectsColumn,
    name: 'Wallboard',
    permission: PERMISSIONS.WALLBOARD,
    activeClassName: 'nav-active'
  }
];
