import cx from 'classnames';
import ReactGridLayout from 'react-grid-layout';
import React, { useEffect, useState } from 'react';
import sizeMe from 'react-sizeme';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

function GridLayout({ size: { width }, children, className, ...props }) {
  const [resizeClassName, setResizeClassName] = useState(Date.now());

  useEffect(() => {
    setResizeClassName(Date.now());
  }, [width]);

  return (
    <ReactGridLayout {...props} className={cx(className, resizeClassName)} width={width}>
      {children}
    </ReactGridLayout>
  );
}

export default sizeMeHOC(GridLayout);
