import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import getQueryParam from 'lib/common/utils/getQueryParam';
import { Button, CustomerProfileForm, ProfileActions } from 'lib/common/components';
import createUpdateCustomerProfile from 'lib/common/api/customerProfiles/createUpdateCustomerProfile';
import TProfile from 'lib/common/types/CustomerProfile';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';
import './create-customer-profile.scss';
import { ConfigContext } from '../../core/config';

type TProfileState = {
  profile: TProfile;
  profileValid: boolean;
};

const INITIAL_PROFILE = (phoneNumber) => ({
  phoneNumber: phoneNumber || void 0,
  partyType: PROFILE_TYPES.INDIVIDUAL
});

export default function CreateCustomerProfile() {
  const { fetch_ } = useAuthContext();
  const { config } = useContext(ConfigContext);
  const history = useHistory();

  const initialNumber = getQueryParam({ param: 'number' });

  const [{ profile, profileValid }, setProfileValues] = useState<TProfileState>({
    profile: INITIAL_PROFILE(initialNumber),
    profileValid: Boolean(initialNumber)
  });

  const onSave = () => {
    return createUpdateCustomerProfile({ config, fetch_, profile });
  };

  const onSaveComplete = (profileId: string) => {
    history.push(`/profiles/${profileId}`);
  };

  return (
    <div className="panel">
      <CustomerProfileForm onChangeProfile={setProfileValues} profile={profile} action={ProfileActions.CREATE} />
      <div className="create-customer-profile__footer">
        <p className="mt-40 mb-20">You can add notes to this profile on the contact's profile page once created.</p>
        <Button
          onClick={profileValid ? onSave : void 0}
          onSuccess={onSaveComplete}
          disabled={!profileValid}
          data-testid="save-profile-btn"
          asyncAction
        >
          Save
        </Button>
      </div>
    </div>
  );
}
