import React, { useContext } from 'react';
import { TextField, makeStyles, createStyles, MenuItem, FormHelperText } from '@material-ui/core';
import { Formik, Form, FormikProps, FieldProps, Field } from 'formik';
import { string as YupString, object as YupObject } from 'yup';
import { OptionsType, ValueType } from 'react-select/src/types';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Toggle, Button, Select as MUISelect, Modal, Label, ReactSelect } from 'lib/common/components';

interface Option {
  label: number;
  value: number;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  defaultValue: OptionsType<Option>;
  className: string;
  classNamePrefix: string;
  id: string;
}

export const CustomSelect = ({
  field,
  form,
  options,
  defaultValue,
  className,
  classNamePrefix,
  isMulti = false,
  id
}: CustomSelectProps) => {
  const onChange = (changedOptions: ValueType<Option | Option[], boolean>) => {
    const options = changedOptions || [];

    form.setFieldValue(
      field.name,
      isMulti ? (options as Option[]).map((item: Option) => item) : (options as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) =>
              field.value
                .map(function (e) {
                  return e.value;
                })
                .indexOf(option.value) >= 0
          )
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : ('' as any);
  };

  return (
    <ReactSelect
      defaultValue={defaultValue}
      className={className}
      classNamePrefix={classNamePrefix}
      id={id}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      maxMenuHeight={150}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginBottom: '40px'
    },
    selectMenu: {
      '& > *': {
        width: '100%'
      },
      marginBottom: '40px'
    },
    selectHelper: {
      marginBottom: '1vw'
    },
    selectLabel: {
      marginTop: '0.5vw'
    },
    progressLoader: {
      marginTop: '10px'
    },
    title: { textAlign: 'center', marginBottom: '2vw' },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
    paper: {
      width: '100%'
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '40px',

      '&>.button:first-of-type': {
        marginRight: '20px'
      }
    }
  })
);

interface IProps {
  getCampaigns: (...args: any) => any;
  editMode: boolean;
  rowSelected: any;
  outcomes: any;
  routingProfiles: any;
  toggleAddCampaign: (...args: any) => any;
  campaigns: Record<string, any>[];
  show: boolean;
}

interface ICampaignForm {
  tenantId: string;
  objectType: string;
  objectId: string;
  name: string;
  type: string;
  callOutcomes: any;
  routingProfile: any;
  isOutcomeCompulsary: boolean;
  isActive: boolean;
}

const lower = (str) => (str ? str.toLowerCase() : '');

function campaignExists(campaigns, { newName, existingName }) {
  const newLower = lower(newName);
  const existingLower = lower(existingName);

  return campaigns.some(
    ({ name }) =>
      (!existingName && lower(name) === newLower) ||
      (existingName && newLower !== existingLower && lower(name) !== existingLower && newLower === lower(name))
  );
}

const ManageCampaignsInner = (props_: IProps) => {
  const classes = useStyles();

  const { config } = useContext(AuthContext);
  const { fetch_ } = useContext(AuthContext);
  const tenantID = process.env.REACT_APP_TENANT_ID || '';

  const createNewCampaign = async (data: ICampaignForm, { resetForm, setSubmitting }) => {
    try {
      data.routingProfile = [data.routingProfile.Id];
      data.callOutcomes = data.callOutcomes.map((c: any) => c.value);

      // API call integration will be here. Handle success / error response accordingly.
      fetch_(`${config.CALL_SERVICE_HOST}/addcampaign`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data)
      })
        .then((res) => res.json())
        .then(() => {
          props_.toggleAddCampaign();
          !props_.editMode && resetForm({});
          props_.getCampaigns();
        });
    } catch (error) {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <Formik
        initialValues={
          !props_.editMode
            ? {
                tenantId: tenantID,
                objectType: 'campaign',
                objectId: '',
                name: '',
                type: '',
                callOutcomes: [],
                routingProfile: '',
                isOutcomeCompulsary: true,
                isActive: true
              }
            : {
                tenantId: tenantID,
                objectType: 'campaign',
                objectId: props_.rowSelected.objectId,
                name: props_.rowSelected.name,
                type: props_.rowSelected.type,
                callOutcomes: props_.rowSelected.callOutcomes,
                routingProfile: props_.rowSelected.routingProfile,
                isOutcomeCompulsary: props_.rowSelected.isOutcomeCompulsary,
                isActive: props_.rowSelected.isActive
              }
        }
        onSubmit={(data, actions) => {
          createNewCampaign(data, actions);
        }}
        validationSchema={YupObject().shape({
          name: YupString().required('Please enter a campaign name.'),
          type: YupString().required('Please select a campaign type.'),
          routingProfile: YupString().required('Please select a routing profile.')
        })}
      >
        {(props: FormikProps<ICampaignForm>) => {
          const { values, touched, errors, handleBlur, handleChange, isSubmitting, setFieldValue } = props;

          const nameExists = campaignExists(props_.campaigns, {
            newName: values.name,
            existingName: props_.rowSelected && props_.rowSelected.name
          });

          return (
            <Modal
              open
              small
              onClose={props_.toggleAddCampaign}
              title={`${props_.editMode ? 'Edit A' : 'Add A New'} Campaign`}
              hideFooter
              contentTopPadded
            >
              <Form translate="yes">
                <div className={classes.textField}>
                  <TextField
                    variant="outlined"
                    label="Name"
                    name="name"
                    id="name"
                    value={values.name}
                    type="text"
                    helperText={
                      nameExists
                        ? 'A Campaign with this name already exists'
                        : errors.name && touched.name
                        ? errors.name
                        : ''
                    }
                    error={!!(nameExists || (errors.name && touched.name))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className={classes.selectMenu}>
                  <MUISelect
                    label="Type"
                    name="type"
                    id="type"
                    value={values.type}
                    error={!!(errors.type && touched.type)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="Inbound">Inbound</MenuItem>
                    <MenuItem value="Outbound">Outbound</MenuItem>
                    <MenuItem value="Chat">Chat</MenuItem>
                    <MenuItem value="Email">Email</MenuItem>
                  </MUISelect>
                  <FormHelperText className={classes.selectHelper}>
                    {errors.type && touched.type ? errors.type : ''}
                  </FormHelperText>
                </div>
                <div className={classes.selectMenu}>
                  <MUISelect
                    label="Routing Profile"
                    name="routingProfile"
                    id="routingProfile"
                    value={JSON.stringify({
                      Id: values.routingProfile.Id,
                      Name: values.routingProfile.Name
                    })}
                    error={!!(errors.routingProfile && touched.routingProfile)}
                    onChange={(e: any) =>
                      setFieldValue('routingProfile', {
                        Id: JSON.parse(e.target.value).Id,
                        Name: JSON.parse(e.target.value).Name
                      })
                    }
                    onBlur={handleBlur}
                  >
                    {props_.routingProfiles.map((r) => {
                      return (
                        <MenuItem
                          value={JSON.stringify({
                            Id: r.Id,
                            Name: r.Name
                          })}
                          key={r.Id}
                        >
                          {r.Name}
                        </MenuItem>
                      );
                    })}
                  </MUISelect>
                  <FormHelperText className={classes.selectHelper}>
                    {errors.routingProfile && touched.routingProfile ? errors.routingProfile : ''}
                  </FormHelperText>
                </div>
                <div className={classes.textField}>
                  <Label id="callOutcomes" text="Interaction Outcomes">
                    <Field
                      name="callOutcomes"
                      id="callOutcomes"
                      component={CustomSelect}
                      isMulti
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={props_.outcomes.map((o) => ({
                        value: o.code,
                        label: o.name
                      }))}
                    />
                  </Label>
                </div>
                <Label id="isOutcomeCompulsary" text="Compulsory">
                  <Toggle
                    id="isOutcomeCompulsary"
                    name="isOutcomeCompulsary"
                    checked={Boolean(values.isOutcomeCompulsary)}
                    onChange={(value) => setFieldValue('isOutcomeCompulsary', value)}
                  />
                </Label>
                <Label id="isActive" text="Active">
                  <Toggle
                    id="isActive"
                    name="isActive"
                    checked={Boolean(values.isActive)}
                    onChange={(value) => setFieldValue('isActive', value)}
                  />
                </Label>
                <div className={classes.footer}>
                  <Button disabled={isSubmitting} onClick={props_.toggleAddCampaign} styleType="SECONDARY">
                    Cancel
                  </Button>
                  <Button
                    busy={isSubmitting}
                    type="submit"
                    disabled={Boolean(
                      !values.name ||
                        !values.routingProfile ||
                        !values.type ||
                        nameExists ||
                        (values.isOutcomeCompulsary && !values.callOutcomes.length)
                    )}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
};

const ManageCampaigns = (props: IProps) => {
  if (!props.show) {
    return null;
  }

  return <ManageCampaignsInner {...props} />;
};

export default ManageCampaigns;
