import {
  faCloudDownloadAlt,
  faPhoneAlt,
  faSquareArrowUpRight,
  faTrash,
  faUserEdit
} from '@fortawesome/pro-regular-svg-icons';
import TProfile from 'lib/common/types/CustomerProfile';
import { ClickableIcon, RequiresPermission } from 'lib/common/components';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { useHistory } from 'react-router-dom';

import saveProfileCSV from './utils/saveProfileCSV';

import './styles/actions.scss';

type TActions = {
  showOnCall: boolean;
  showExternalLink?: boolean;
  profile?: TProfile | null;
  profileNotes?: string;
  onCall?: () => void;
  onDelete: () => void;
  onEdit: () => void;
};

export default function Actions({
  showOnCall,
  showExternalLink,
  profile,
  profileNotes,
  onCall,
  onDelete,
  onEdit
}: TActions) {
  const history = useHistory();

  const onClickExternal = () => {
    history.push(`/profiles/${profile?.profileId}`);
  };

  if (!profile) {
    return null;
  }

  return (
    <div className="actions">
      {showExternalLink && (
        <ClickableIcon
          onClick={onClickExternal}
          icon={faSquareArrowUpRight}
          size={22}
          tooltip="Navigate to Customer Profile"
        />
      )}
      {showOnCall && (
        <ClickableIcon data-testid="call-contact" onClick={onCall} icon={faPhoneAlt} tooltip="Call contact" />
      )}
      <ClickableIcon
        data-testid="save-profile-csv"
        onClick={() => saveProfileCSV({ ...profile, notes: profileNotes })}
        icon={faCloudDownloadAlt}
        size={20}
        tooltip="Download the customer profile in a CSV file"
      />
      <RequiresPermission permission={PERMISSIONS.CUSTOMER_PROFILES_WRITE}>
        <>
          <ClickableIcon
            data-testid="edit-profile"
            onClick={onEdit}
            icon={faUserEdit}
            size={20}
            tooltip="Edit the customer profile"
          />
          <ClickableIcon
            data-testid="delete-profile"
            icon={faTrash}
            color="danger"
            onClick={onDelete}
            tooltip="Delete the customer profile"
          />
        </>
      </RequiresPermission>
    </div>
  );
}
