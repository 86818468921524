import PhoneNumber from 'awesome-phonenumber';
import connectGetter from './connectGetter';

const DEFAULT_USER_NAME = 'Agent';

export const isConference = (contact: connect.Contact) => (connectGetter('getConnections', contact) || []).length > 2;

export const getConferenceUserName = (contact: connect.Contact) => {
  const contactConnections = connectGetter('getConnections', contact);

  if (!contactConnections) {
    return DEFAULT_USER_NAME;
  }

  const outboundConnection = contactConnections.find(
    (connection) =>
      !connectGetter('isInitialConnection', connection) &&
      connectGetter('getType', connection) === connect.ConnectionType.OUTBOUND &&
      connectGetter('getState', connection)?.type !== connect.ConnectionStateType.DISCONNECTED
  );

  const phoneNumber = connectGetter('getEndpoint', outboundConnection)?.phoneNumber ?? '';

  return PhoneNumber(phoneNumber).isValid() ? phoneNumber : DEFAULT_USER_NAME;
};

export const isThirdPartyConnectionOnHold = (contact: connect.Contact) => {
  const contactConnections = connectGetter('getConnections', contact);

  if (!contactConnections) {
    return false;
  }

  const connectionOnHold = contactConnections.find(
    (connection) =>
      !connectGetter('isInitialConnection', connection) &&
      connectGetter('getType', connection) === connect.ConnectionType.OUTBOUND &&
      connectGetter('getState', connection)?.type === connect.ConnectionStateType.HOLD
  );

  return Boolean(connectionOnHold);
};

export const getConferenceConnectionStates = (contact: connect.Contact) => {
  const contactConnections = connectGetter('getConnections', contact);

  if (!contactConnections) {
    return [];
  }

  const connections = contactConnections.filter(
    (connection) =>
      connectGetter('getType', connection) !== connect.ConnectionType.AGENT &&
      connectGetter('getState', connection)?.type !== connect.ConnectionStateType.DISCONNECTED
  );

  return connections.map((connection) => connectGetter('getState', connection)?.type).filter(Boolean);
};

// All conference connections on hold
export const areAllConnectionsOnHold = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact);

  if (!conferenceStates?.length) {
    return false;
  }

  return conferenceStates.every((connectionState) => connectionState === connect.ConnectionStateType.HOLD);
};

// All conference connections connected
export const areAllConnectionsConnected = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact);

  if (!conferenceStates?.length) {
    return false;
  }

  return conferenceStates.every((connectionState) => connectionState === connect.ConnectionStateType.CONNECTED);
};

// Customer connection is disconnected
export const isInitialConnectionDisconnected = (contact: connect.Contact) => {
  const initialConnection = connectGetter('getInitialConnection', contact);

  return connectGetter('getState', initialConnection)?.type === connect.ConnectionStateType.DISCONNECTED;
};

// Third party (Outbound) calls are all disconnected
export const areThirdPartyConnectionsDisconnected = (contact: connect.Contact) => {
  const thirdPartyConnections = connectGetter('getThirdPartyConnections', contact);

  if (!thirdPartyConnections?.length) {
    return false;
  }

  return thirdPartyConnections.every(
    (connection) => connectGetter('getState', connection)?.type === connect.ConnectionStateType.DISCONNECTED
  );
};
