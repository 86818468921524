import { Switch, Redirect, Route } from 'react-router-dom';

// pages
import Landing from 'lib/pages/softphone/Landing';
import InteractionHistory from 'lib/pages/softphone/InteractionHistory';
import AgentStats from 'lib/pages/softphone/AgentStats';
import Directory from 'lib/pages/softphone/Directory';
import { Widgets, Preferences } from 'lib/common/components/molecules';
import Task from 'lib/pages/softphone/Task';
import DesktopOnly from 'lib/common/components/DesktopOnly';

// components
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import { SoftphoneLayout } from 'lib/common/layouts';
import { AuthorisedRoute, NotFound, PageModule } from 'lib/common/components';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import TUser from 'lib//common/types/User';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import RedirectIfSoftphone from 'lib/common/components/atoms/RedirectIfSoftphone';

interface ISoftphoneLayoutRoute {
  user: TUser;
  isReversedLayout: boolean;
}

function withUser(user, Component, extraProps = {}) {
  return (props) => <Component {...props} user={user} {...extraProps} />;
}

export default function SoftphoneLayoutRoutes({ user, isReversedLayout }: ISoftphoneLayoutRoute) {
  const {
    state: { pageModules }
  } = useModulesContext();

  return (
    <SoftphoneLayout user={user} isReversedLayout={isReversedLayout}>
      <Switch>
        <Redirect exact from={SOFTPHONE_PAGE_ROUTES.BASE} to={SOFTPHONE_PAGE_ROUTES.LANDING} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.LANDING} render={withUser(user, Landing)} />
        <AuthorisedRoute
          permission={PERMISSIONS.INTERACTION_HISTORY}
          exact
          path={SOFTPHONE_PAGE_ROUTES.INTERACTION_HISTORY}
          render={withUser(user, InteractionHistory)}
        />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.AGENT_STATS} render={withUser(user, AgentStats)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.DIRECTORY} render={withUser(user, Directory)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.WIDGETS} render={withUser(user, Widgets)} />
        <Route exact path={SOFTPHONE_PAGE_ROUTES.TASK} render={withUser(user, Task)} />
        <Route
          exact
          path={SOFTPHONE_PAGE_ROUTES.PREFERENCES}
          render={withUser(user, Preferences, { isSoftphone: true })}
        />
        {pageModules.map((pageModule: ConnectorModuleType, i) => (
          <Route
            render={(props) => <PageModule {...props} pageModuleProps={pageModule} user={user} />}
            path={`/${pageModule.pathName}`}
            key={i}
          />
        ))}
        {/* To handle race condition where both routers are being simultaneously rendered causing an infinite loop */}
        <Route path={PAGE_ROUTES.WORKSPACE}>
          <RedirectIfSoftphone to="/" />
        </Route>
        <Route
          path={[PAGE_ROUTES.CUSTOMER_PROFILES, PAGE_ROUTES.SETTINGS, PAGE_ROUTES.WALLBOARD, PAGE_ROUTES.DASHBOARDS]}
          component={DesktopOnly}
        />
        <Route component={NotFound} />
      </Switch>
    </SoftphoneLayout>
  );
}
