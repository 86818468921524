import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { Directory } from 'lib/common/components/molecules';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useDirectoryContext } from 'lib/common/contexts/DirectoryContext';
import { useSoftphoneLayout } from 'lib/common/contexts/SoftphoneLayoutContext';
import TUser from 'lib/common/types/User';

const DIALPAD_TRANSFER = 'DIALPAD_TRANSFER';
const DIRECTORY_TRANSFER = 'DIRECTORY_TRANSFER';

interface UseTransferModalProps {
  user: TUser;
  isSoftphone?: boolean;
}

function getTabIndex(type, hasPersonalContactPermission) {
  if (type === DIALPAD_TRANSFER) {
    return 2;
  }

  return hasPersonalContactPermission ? 1 : 0;
}

export default function useTransferModal({ user, isSoftphone }: UseTransferModalProps) {
  const { contentHeight, contentWidth } = useSoftphoneLayout();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();
  const { hasPermission } = usePermissionsContext();
  const { openDirectory } = useDirectoryContext();

  const hasPersonalContactPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.PERSONAL_CONTACTS
  });

  const handleOpenSoftphoneModal = (type: typeof DIRECTORY_TRANSFER | typeof DIALPAD_TRANSFER) => {
    openModal({
      title: type === DIRECTORY_TRANSFER ? 'Transfer' : 'Dialpad',
      content: (
        <Directory
          user={user}
          fillContainer
          onClose={closeModal}
          contentHeight={contentHeight}
          contentWidth={contentWidth}
          isDirectoryTransfer={type === DIRECTORY_TRANSFER}
          isDialPadTransfer={type === DIALPAD_TRANSFER}
          initialSelectedTab={getTabIndex(type, hasPersonalContactPermission)}
        />
      )
    });
  };

  const onTransferToAgent = () => {
    if (isSoftphone) {
      return void handleOpenSoftphoneModal(DIRECTORY_TRANSFER);
    }

    openDirectory({ selectedTabIndex: hasPersonalContactPermission ? 1 : 0, isDirectoryTransfer: true });
  };

  const onTransferToDialpad = () => {
    if (isSoftphone) {
      return void handleOpenSoftphoneModal(DIALPAD_TRANSFER);
    }

    openDirectory({ selectedTabIndex: 0, isDialPadTransfer: true });
  };

  return { onTransferToDialpad, onTransferToAgent };
}
