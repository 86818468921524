export default async function getNotes({ fetch_, config, profileId }) {
  try {
    const notesRes = await fetch_(
      `${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/contacts/${profileId}/notes`,
      {
        method: 'GET'
      }
    );

    return notesRes.json();
  } catch {
    return '';
  }
}
