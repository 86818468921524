import { MAX_GRID_COLUMNS } from '../constants/dashboardGrid';

function doItemsOverlap(itemOne, itemTwo) {
  return !(
    itemOne.x > itemTwo.x + itemTwo.w - 1 ||
    itemOne.x + itemOne.w - 1 < itemTwo.x ||
    itemOne.y > itemTwo.y + itemTwo.h - 1 ||
    itemOne.y + itemOne.h - 1 < itemTwo.y
  );
}
export default function getFirstEmptyGridSpace({ layout, minW, minH }) {
  if (!layout.length) {
    return { x: 0, y: 0 };
  }

  const highestY = layout.reduce((y, layoutItem) => {
    const itemHighestY = layoutItem.h + layoutItem.y;

    if (itemHighestY > y) {
      return itemHighestY;
    }

    return y;
  }, 0);

  if (highestY === 0) {
    return { x: 0, y: 0 };
  }

  let suitableX;
  let suitableY;

  for (let y = 0; y <= highestY; y++) {
    if (suitableX || suitableY) {
      break;
    }

    for (let x = 0; x <= MAX_GRID_COLUMNS - minW; x++) {
      const hasOverlap = layout.some((layoutItem) => doItemsOverlap(layoutItem, { x, y, w: minW, h: minH }));

      if (hasOverlap) {
        continue;
      }

      suitableX = x;
      suitableY = y;

      break;
    }
  }

  return {
    x: suitableY === void 0 ? 0 : suitableX,
    y: suitableY === void 0 ? highestY + 1 : suitableY
  };
}
