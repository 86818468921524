import { createStyles, Theme } from '@material-ui/core';
import { cloudwavePink } from 'lib/common/constants/colours';

export default (theme: Theme) =>
  createStyles({
    root: {
      width: 35,
      height: 20,
      padding: 0
    },
    switchBase: {
      padding: 3,
      '&$checked': {
        transform: 'translateX(15px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: cloudwavePink,
          border: 'none',
          opacity: 1
        }
      }
    },
    hoveredSwitchBase: {
      '& + $track': {
        backgroundColor: '#DBDBDB',
        opacity: 1
      },
      '&$checked': {
        '& + $track': {
          backgroundColor: '#F2006F',
          opacity: 1
        }
      }
    },
    thumb: {
      width: 14,
      height: 14,
      boxShadow: 'none',
      backgroundColor: 'white'
    },
    track: {
      borderRadius: 15,
      backgroundColor: '#E5E5E5',
      opacity: 1
    },
    checked: {},
    focusVisible: {},
    disabled: {
      '& + $track': {
        opacity: 0.6,
        backgroundColor: '#E5E5E5'
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      marginLeft: 10
    }
  });
