import cx from 'classnames';
import { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import './content-panel.scss';

interface IContentPanel {
  EmptyImage?: any;
  isEmpty?: boolean | null;
  children: any;
  title: string;
  subtitle?: string;
  emptyMessage?: string;
  icon?: IconDefinition;
  alwaysShowChildren?: boolean;
  className?: string;
  actions?: ReactNode;
}

export default function ContentPanel({
  EmptyImage,
  isEmpty,
  children,
  title,
  subtitle,
  emptyMessage,
  icon,
  alwaysShowChildren,
  className,
  actions
}: IContentPanel) {
  return (
    <div className={cx('content-panel', className)}>
      <div className="content-panel__header">
        <div className="content-panel__header__title">
          <h2>
            {title} {icon && <FontAwesomeIcon className="content-panel__icon" icon={icon} />}
          </h2>
          {actions}
        </div>
        {subtitle && <p className="content-panel__subtitle">{subtitle}</p>}
      </div>
      {isEmpty && (EmptyImage || emptyMessage) && (
        <div className="content-panel--empty">
          {EmptyImage && <EmptyImage />}
          <h2>{emptyMessage}</h2>
        </div>
      )}
      {(!isEmpty || (isEmpty && alwaysShowChildren)) && children}
    </div>
  );
}
