import { useEffect } from 'react';

import { Modal } from 'lib/common/components';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import TransferToQueue from './TransferToQueue';
import useTransferToQueue from './useTransferToQueue';

interface ITransferQueue {
  open: boolean;
  connectionValue: string;
  taskId: string;
  onClose: () => void;
  isSoftphone?: boolean;
}

const TransferToQueueModal = ({ open, onClose, connectionValue, taskId, isSoftphone }: ITransferQueue) => {
  const { onSave, selectedEndpoint, setSelectedEndpoint, endpoints } = useTransferToQueue({ taskId });
  const {
    state: { isModalOpen },
    actions: { openModal, updateModalOptions }
  } = useSoftphoneModalContext();

  useEffect(() => {
    if (!isSoftphone || !open) {
      return;
    }

    if (!isModalOpen) {
      openModal({
        title: 'Queue Transfer',
        content: (
          <TransferToQueue
            connectionValue={connectionValue}
            setSelectedEndpoint={setSelectedEndpoint}
            selectedEndpoint={selectedEndpoint}
            endpoints={endpoints}
          />
        ),
        onSave: onSave,
        saveDisabled: !selectedEndpoint?.value,
        primaryButtonText: 'Transfer',
        onClose
      });
    }
  }, [isSoftphone, open, onSave]);

  useEffect(() => {
    updateModalOptions({ saveDisabled: !selectedEndpoint?.value });
  }, [selectedEndpoint]);

  // Modals on Softphone are rendered by function calls and nothing needs to be rendered here
  if (isSoftphone) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSave={onSave}
      onSuccess={onClose}
      title="Transfer to Another Queue"
      saveDisabled={!selectedEndpoint?.value}
      primaryButtonText="Transfer"
    >
      <TransferToQueue
        connectionValue={connectionValue}
        setSelectedEndpoint={setSelectedEndpoint}
        selectedEndpoint={selectedEndpoint}
        endpoints={endpoints}
      />
    </Modal>
  );
};

export default TransferToQueueModal;
