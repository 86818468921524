import SEARCHABLE_ATTRIBUTES from 'lib/common/constants/searchableProfileAttributes';
import connectGetter from './connectGetter';

export default function getMatchableAttribute(contact?: connect.Contact) {
  if (!contact) {
    return null;
  }

  if (connectGetter('getType', contact) === connect.ContactType.VOICE) {
    const activeInitialConnection = connectGetter('getActiveInitialConnection', contact);
    return {
      phoneNumber: connectGetter('getEndpoint', activeInitialConnection)?.phoneNumber as string
    };
  }

  const contactAttributes = connectGetter('getAttributes', contact) || {};

  const matchingAttribute = SEARCHABLE_ATTRIBUTES.find((attribute) => contactAttributes[attribute]?.value);

  return matchingAttribute ? { [matchingAttribute]: contactAttributes[matchingAttribute].value } : null;
}
