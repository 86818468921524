import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import { useBooleanState } from 'webrix/hooks';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import Button from 'lib/common/components/Button';
import connectGetter from 'lib/common/utils/connectGetter';
import { ReactComponent as AuthErrorImage } from '../Login/assets/auth-error.svg';

import './auth-error.scss';

const SITE_DATA_URL = 'https://www.pcmag.com/how-to/how-to-clear-your-cache-on-any-browser';
const INCOGNITO_URL =
  'https://www.computerworld.com/article/3356840/how-to-go-incognito-in-chrome-firefox-safari-and-edge.html';

const HELP_ITEMS = [
  {
    key: 'correct-user',
    icon: faArrowRight,
    content: (
      <p>
        Making sure you’re signed in to Amazon Connect with the <strong>correct user</strong>. The user above should be
        the one you’re expecting.
      </p>
    )
  },
  {
    key: 'user-added',
    icon: faArrowRight,
    content: (
      <p>
        Making sure your <strong>Amazon Connect user</strong> has been <strong>added to NEON</strong>.
      </p>
    )
  },
  {
    key: 'clear-data',
    icon: faArrowRight,
    content: (
      <p>
        <a href={SITE_DATA_URL} target="_blank" rel="noreferrer">
          Clearing site data
        </a>{' '}
        for NEON.
      </p>
    )
  },
  {
    key: 'incognito',
    icon: faArrowRight,
    content: (
      <p>
        Signing in with an{' '}
        <a href={INCOGNITO_URL} target="_blank" rel="noreferrer">
          Incognito window.
        </a>
      </p>
    )
  },
  {
    key: 'cookies',
    icon: faArrowRight,
    content: (
      <p>
        Making sure <strong>cookies are enabled</strong>
      </p>
    )
  },
  {
    key: 'admin-contact',
    icon: faEnvelope,
    content: (
      <p>
        Getting in touch with your <strong>Administrator</strong>.
      </p>
    )
  }
];

export default function AuthError() {
  const { agent } = useAgentContext();
  const { value: signingOut, setTrue: setSigningOut } = useBooleanState();
  const { signOut } = useContext(AuthContext);

  const agentConfig = connectGetter('getConfiguration', agent);

  const onSignOut = () => {
    setSigningOut();
    signOut();
  };

  return (
    <div className="auth-error">
      <div className="auth-error__user">
        <div className="auth-error__user__avatar">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="auth-error__user__info">
          <h2>{agentConfig?.name}</h2>
          <p>{agentConfig?.username}</p>
        </div>
        <Button busy={signingOut} onClick={onSignOut}>
          Sign Out
        </Button>
      </div>
      <div className="auth-error__content">
        <div className="auth-error__content__right">
          <h1>Houston, we have a problem.</h1>
          <h2>Here's some things you can try to get you going:</h2>
          <div className="auth-error__content__right__help">
            {HELP_ITEMS.map(({ icon, content, key }) => (
              <div key={key} className="auth-error__content__right__help__item">
                <FontAwesomeIcon icon={icon} />
                {content}
              </div>
            ))}
          </div>
        </div>
        <AuthErrorImage />
      </div>
    </div>
  );
}
