import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';

const hasIncomingContactAttributes = (attributes: Record<string, ContactAttributesValue> | undefined) => {
  if (!attributes) {
    return false;
  }

  return Object.values(attributes).some((attr) => Array.isArray(attr.value) && Boolean(attr?.value?.length));
};

export default hasIncomingContactAttributes;
