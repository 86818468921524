import { useState } from 'react';
import { InputAdornment, Paper, TextField } from '@material-ui/core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Searchbar({ onChange, currentTab }) {
  const [search, setSearch] = useState('');

  const onSearch = ({ target: { value: newSearchValue } }) => {
    setSearch(newSearchValue);
    onChange(newSearchValue);
  };

  return (
    <Paper className="searchbar-wrapper" square>
      <TextField
        className="searchbar-textfield"
        onFocus={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onChange={onSearch}
        variant="outlined"
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={search}
        data-tour="phonebook-search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon icon={faSearch} color="midGrey" />
            </InputAdornment>
          )
        }}
      />
    </Paper>
  );
}
