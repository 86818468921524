import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

type Props = {
  children: ReactNode;
};

type Context = {
  isSidebarOpen: boolean;
  toggleIsSidebarOpen: () => void;
  fullscreenEnabled: boolean;
  toggleFullScreen: Dispatch<SetStateAction<boolean>>;
};

const defaultLayout: Context = {
  isSidebarOpen: false,
  toggleIsSidebarOpen: () => undefined,
  fullscreenEnabled: false,
  toggleFullScreen: () => undefined
};

export const LayoutContext = createContext<Context>(defaultLayout);

export const LayoutProvider = ({ children }: Props) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(localStorage.getItem('nav-expanded') === 'true');
  const [fullscreenEnabled, toggleFullScreen] = useState(false);

  const toggleIsSidebarOpen = () => {
    const newState = !isSidebarOpen;
    localStorage.setItem('nav-expanded', newState ? 'true' : '');
    setIsSidebarOpen(newState);
  };

  return (
    <LayoutContext.Provider
      value={{
        ...defaultLayout,
        isSidebarOpen,
        toggleIsSidebarOpen,
        fullscreenEnabled,
        toggleFullScreen
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default function useLayout() {
  return useContext(LayoutContext);
}
