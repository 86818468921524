import { Fragment, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { faGripLinesVertical } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components';
import './resizable-group.scss';

type LayoutOption = {
  preventResize?: boolean;
  id: string;
  minSize: number;
  defaultSizePercent: number;
  hide?: boolean;
  forceDefaultSize?: boolean;
};

const getBodyDraggingClass = ({ vertical }) => `body--dragging${vertical ? '-vertical' : ''}`;

function getInitialSize({ defaultSizePercent, id }) {
  const storedValue = localStorage.getItem(id);

  if (!storedValue) {
    return defaultSizePercent;
  }

  return parseFloat(storedValue);
}

function getColumnSizePercent(sizePercent) {
  if (sizePercent > 100) {
    return 100;
  }

  if (sizePercent < 0) {
    return 0;
  }

  return sizePercent;
}

function columnResizeLessThanThreshold({ sizePercent, workspaceSize, columnIndex, layoutOptions }) {
  return (sizePercent / 100) * workspaceSize < layoutOptions[columnIndex]?.minSize + 50;
}

function buildSizesArray({ currentSizes, columnIndex, columnSizePercent, adjacentColumnSizePercent }) {
  return currentSizes.map((size, index) => {
    if (index === columnIndex) {
      return getColumnSizePercent(columnSizePercent);
    }

    if (index === columnIndex + 1) {
      return getColumnSizePercent(adjacentColumnSizePercent);
    }

    return size;
  });
}

export default function ResizableGroup({
  children: allChildren,
  layoutOptions,
  containerClassName,
  vertical
}: {
  children: ReactNode[];
  layoutOptions: LayoutOption[];
  containerClassName: string;
  vertical?: boolean;
}) {
  const cssClientProp = vertical ? 'clientY' : 'clientX';
  const cssSizeProperty = vertical ? 'height' : 'width';

  const children = allChildren.filter(Boolean);
  const [sizes, updateSizes] = useState(children.map((_, index) => getInitialSize(layoutOptions[index])));
  const [currentDragProps, setDragProps] = useState<null | {
    columnIndex: number;
    initialPosition: number;
    adjacentColumnSizePercent: number;
  }>(null);

  const staticSizes: { current: number[] } = useRef(sizes);

  const setSizes = (sizes) => {
    staticSizes.current = sizes;
    updateSizes(sizes);
  };

  const onMouseMove = useCallback(
    (e) => {
      if (!currentDragProps) {
        return;
      }

      const workspaceEl = document.querySelector(`.${containerClassName}`);

      if (!workspaceEl) {
        return;
      }

      const adjacentColumnIndex = currentDragProps.columnIndex + 1;
      const workspaceSize = workspaceEl.getBoundingClientRect()[cssSizeProperty];
      const originalSizePercent = sizes[currentDragProps.columnIndex];

      const pxDifference = e[cssClientProp] - currentDragProps.initialPosition;
      const percentChange = (pxDifference / workspaceEl.getBoundingClientRect()[cssSizeProperty]) * 100;

      const columnSizePercent = originalSizePercent + percentChange;
      const adjacentColumnSizePercent = currentDragProps.adjacentColumnSizePercent - percentChange;

      if (
        columnResizeLessThanThreshold({
          sizePercent: columnSizePercent,
          workspaceSize,
          columnIndex: currentDragProps.columnIndex,
          layoutOptions
        }) ||
        columnResizeLessThanThreshold({
          sizePercent: adjacentColumnSizePercent,
          workspaceSize,
          columnIndex: adjacentColumnIndex,
          layoutOptions
        })
      ) {
        return;
      }

      setSizes(
        buildSizesArray({
          currentSizes: staticSizes.current,
          adjacentColumnSizePercent,
          columnSizePercent,
          columnIndex: currentDragProps.columnIndex
        })
      );
    },
    [currentDragProps]
  );

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove);
    window.removeEventListener('mouseup', onMouseUp);

    document.body.classList.remove(getBodyDraggingClass({ vertical }));

    setDragProps(null);
  }, [onMouseMove]);

  const onMouseDown = (columnIndex) => (e) => {
    const adjacentColumnIndex = columnIndex + 1;

    setDragProps({
      columnIndex,
      initialPosition: e[cssClientProp],
      adjacentColumnSizePercent: sizes[adjacentColumnIndex]
    });
  };

  useEffect(() => {
    updateSizes(children.map((_, index) => getInitialSize(layoutOptions[index])));
  }, [layoutOptions]);

  useEffect(() => {
    if (layoutOptions.length !== sizes.length) {
      return;
    }

    sizes.forEach((_, columnIndex) => localStorage.setItem(layoutOptions[columnIndex].id, `${sizes[columnIndex]}`));
  }, [sizes]);

  const onDoubleClickResize = (columnIndex) => (e) => {
    // Check for 2 clicks in a short period of time because onDoubleClick does nothing
    if (e.detail !== 2) {
      return;
    }

    const adjacentColumnIndex = columnIndex + 1;
    const columnSizePercentChange = staticSizes.current[columnIndex] - layoutOptions[columnIndex].defaultSizePercent;

    setSizes(
      buildSizesArray({
        currentSizes: staticSizes.current,
        adjacentColumnSizePercent: staticSizes.current[adjacentColumnIndex] + columnSizePercentChange,
        columnSizePercent: layoutOptions[columnIndex].defaultSizePercent,
        columnIndex: columnIndex
      })
    );
    return;
  };

  useEffect(() => {
    if (!currentDragProps) {
      return;
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);

    document.body.classList.add(getBodyDraggingClass({ vertical }));
  }, [currentDragProps]);

  const group = children.map((child, index) => {
    const layoutProps = layoutOptions[index];
    const isLastChild = children.length - 1 === index;

    if (layoutProps.hide) {
      return null;
    }

    const cssMinProp = `min${vertical ? 'Height' : 'Width'}`;
    const cssPaddingProp = `padding${vertical ? 'Top' : 'Left'}`;

    return (
      <Fragment key={layoutProps.id}>
        <div
          className={cx('resizable-group__container', {
            'resizable-group__container--vertical': vertical
          })}
          style={{
            [cssPaddingProp]: index === 0 ? void 0 : '25px',
            [cssMinProp]: `${index === 0 ? layoutProps.minSize : layoutProps.minSize + 50}px`,
            [cssSizeProperty]: `${!layoutProps.forceDefaultSize ? sizes[index] : layoutProps.defaultSizePercent}%`
          }}
        >
          {child}
          {!layoutOptions[index].preventResize && !isLastChild && (
            <div
              data-testid="resizable-group-resizer"
              className="resizable-group__resizer"
              onMouseDown={onMouseDown(index)}
              onClick={onDoubleClickResize(index)}
            >
              <Icon icon={faGripLinesVertical} color="midGrey" />
            </div>
          )}
        </div>
      </Fragment>
    );
  });

  if (vertical) {
    return <div className="resizable-group--vertical">{group}</div>;
  }

  return <>{group}</>;
}
