export enum PAGE_ROUTES {
  BASE = '/',
  WALLBOARD = '/wallboard',
  DASHBOARDS = '/dashboards',
  INTERACTION_HISTORY = '/interactions',
  CUSTOMER_PROFILES = '/profiles',
  CREATE_CUSTOMER_PROFILE = '/profiles/new',
  CUSTOMER_PROFILE = '/profiles/:profileId',
  SETTINGS = '/settings',
  USER_SETTINGS = '/settings/users',
  METRICS_SETTINGS = '/settings/metrics-config',
  OUTCOME_SETTINGS = '/settings/outcomes',
  CAMPAIGN_SETTINGS = '/settings/campaigns',
  WORKSPACE = '/workspace'
}

export const PAGE_CONFIGURATIONS = {
  [PAGE_ROUTES.WORKSPACE]: {
    pagename: 'Agent Workspace'
  },
  [PAGE_ROUTES.INTERACTION_HISTORY]: {
    pagename: 'Interaction History',
    className: 'interaction-history',
    pageTitle: 'Interaction History'
  },
  [PAGE_ROUTES.CUSTOMER_PROFILE]: {
    pagename: 'customer-profile',
    className: 'customer-profile'
  },
  [PAGE_ROUTES.CUSTOMER_PROFILES]: {
    pagename: 'customer-profiles',
    className: 'customer-profiles',
    pageTitle: 'Customer Profiles'
  },
  [PAGE_ROUTES.CREATE_CUSTOMER_PROFILE]: {
    pagename: 'create-customer-profile',
    pageTitle: 'Create A Customer Profile'
  },
  [PAGE_ROUTES.METRICS_SETTINGS]: {
    pagename: 'Admin',
    className: 'admin',
    pageTitle: 'Metrics'
  },
  [PAGE_ROUTES.WALLBOARD]: {
    pagename: 'Wallboard',
    className: 'wallboard'
  },
  [PAGE_ROUTES.USER_SETTINGS]: {
    pagename: 'Admin',
    className: 'admin',
    pageTitle: 'Users'
  },
  [PAGE_ROUTES.DASHBOARDS]: {
    pagename: 'Dashboards',
    className: 'dashboards'
  },
  [PAGE_ROUTES.OUTCOME_SETTINGS]: {
    pagename: 'Admin',
    className: 'admin',
    pageTitle: 'Interaction Outcomes'
  },
  [PAGE_ROUTES.CAMPAIGN_SETTINGS]: {
    pagename: 'Admin',
    className: 'admin',
    pageTitle: 'Campaigns'
  }
};
