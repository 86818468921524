import { Modal } from 'lib/common/components';

export default function DeleteProfileDialog({ open, onClose, onConfirm, onSuccess }) {
  return (
    <Modal
      open={open}
      small
      secondaryButtonText="Cancel"
      title="Are You Sure?"
      onClose={onClose}
      onDelete={onConfirm}
      onSuccess={onSuccess}
    >
      <p>Confirm you'd like to delete this customer profile.</p>
    </Modal>
  );
}
