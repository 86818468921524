import { Menu } from '@material-ui/core';
import { cloneElement, useEffect, useState, ReactNode, ReactElement } from 'react';

interface IPopoverMenu {
  children: ReactNode;
  anchor?: ReactNode | null;
  offset?: number;
  offsetLeft?: number;
  reverse?: boolean;
  className?: string;
}

export default function PopoverMenu({
  children,
  anchor,
  offset = 10,
  offsetLeft = 0,
  reverse,
  className = void 0
}: IPopoverMenu) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(null);
    e?.preventDefault();
  };

  useEffect(() => {
    if (anchor) {
      return;
    }

    setAnchorEl(null);
  }, [anchor]);

  return (
    <>
      {anchor && cloneElement(anchor as ReactElement, { onClick: handleAnchorClick })}
      <Menu
        className={className}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: (offsetLeft && 'center') || (reverse && 'top') || 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: (offsetLeft && 'center') || (reverse && 'bottom') || 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            marginTop: reverse ? -offset : offset,
            marginLeft: reverse ? -offsetLeft : offsetLeft
          }
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        disableAutoFocusItem
      >
        {typeof children === 'function' ? children({ onClose: handleClose }) : children}
      </Menu>
    </>
  );
}
