import { FUSION_DATA_BLOCK_TYPES } from 'lib/common/constants/fusionDataBlockTypes';
import getMappedContactAttributes from './getMappedContactAttributes';
import normaliseAttributeKey from './normaliseAttributeKey';

const fusionDataMap = {
  intents: {
    type: FUSION_DATA_BLOCK_TYPES.BADGE_LIST,
    label: 'Intents'
  },
  transcript: {
    type: FUSION_DATA_BLOCK_TYPES.ACCORDION,
    label: 'Transcript'
  },
  phonenumber: {
    type: FUSION_DATA_BLOCK_TYPES.PHONE_NUMBER,
    label: 'Phone Number'
  }
};

function getFusionDataFromMap(type: string, data: any) {
  const { type: blockType, label } = fusionDataMap[type] || {};

  return {
    type: blockType || FUSION_DATA_BLOCK_TYPES.DYNAMIC,
    label: normaliseAttributeKey((label || type).replace(/CW-NEON_|CW-NEON-ACTIVE_|CW-NEON-INC_/gi, '')),
    data
  };
}

const getContactAttributes = (contact?: connect.Contact) => {
  if (!contact) {
    return;
  }

  const attributes = getMappedContactAttributes(contact);

  if (!attributes) {
    return;
  }

  const validAttribute = ([, attribute]) => {
    if (!Boolean(attribute?.isActive)) {
      return false;
    }

    return Array.isArray(attribute?.value) ? attribute?.value.length > 0 : Boolean(attribute?.value);
  };

  const mappedAttributes = Object.entries(attributes)
    .filter(validAttribute)
    .map(([key, attribute]) => getFusionDataFromMap(key, attribute?.value));

  return mappedAttributes;
};

export default getContactAttributes;
