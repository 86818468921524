import { Component } from 'react';
import { components } from 'react-select';
import { InputBase } from '@material-ui/core';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { parsePhoneNumber, getCountryCodeForRegionCode } from 'awesome-phonenumber';
import Text from '../Text';
import ReactSelect from '../ReactSelect';
import COUNTRIES from './constants/countries.json';
import { IPhoneInput, IPhoneInputState } from './PhoneInputTypes';
import selectStyles from './selectStyles';
import CountryFlag from './components/CountryFlag';

import './phone-input.scss';

interface IPhoneInputProps extends IPhoneInput {
  agent?: connect.Agent | null;
  autoFocus?: boolean;
}

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faAngleDown} />
    </components.DropdownIndicator>
  );
};

function countryOption({ name, dialCode, countryCode }) {
  return {
    value: countryCode,
    label: (
      <div className="phone-input__countries__country-option">
        <CountryFlag code={countryCode} />
        <div className="country-details-selected">
          <div className="phone-input__countries__country-details">
            <div>{name}</div>
            <div>{dialCode}</div>
          </div>
        </div>
      </div>
    )
  };
}

const unsupportedCountry = () => {
  return {
    value: '',
    label: (
      <div className="phone-input__countries__country-option">
        <CountryFlag />
      </div>
    )
  };
};

function generateCountryOptions(countries) {
  const availableCountries = countries
    ? COUNTRIES.filter((country) => countries.includes(country.countryCode.toLowerCase()))
    : COUNTRIES;

  return availableCountries.map((country) => countryOption(country));
}

function findCountry(countries, countryCode) {
  return countries.find((countryOption) => countryOption.value === countryCode) || unsupportedCountry();
}

class PhoneInput extends Component<IPhoneInputProps, IPhoneInputState> {
  constructor(props) {
    super(props);

    const availableCountries = generateCountryOptions(props.countries);
    const selectedCountry = findCountry(availableCountries, this.props.country || 'AU');

    this.state = {
      value: props.initialValue || '',
      selectedCountry: selectedCountry,
      availableCountries: availableCountries
    };
  }

  handleCountryChange = () => {
    const { value, selectedCountry, availableCountries } = this.state;
    const { onChange } = this.props;

    if (!value) {
      return;
    }

    const internationalNumber = parsePhoneNumber(value).getCountryCode();

    if (internationalNumber) {
      const isCountrySupported = availableCountries.find((country) => country.value === selectedCountry.value);
      const newNumber = isCountrySupported
        ? value.replace(
            `${parsePhoneNumber(value).getCountryCode()}`,
            `${getCountryCodeForRegionCode(selectedCountry.value)}`
          )
        : value;

      this.setState({ value: newNumber });

      return onChange(newNumber);
    }

    return onChange(`+${getCountryCodeForRegionCode(selectedCountry.value)}${value.slice(1)}`);
  };

  handleValueChange = (latestValue) => {
    const { availableCountries, selectedCountry } = this.state;
    const { onChange } = this.props;

    this.setState({ value: latestValue });

    if (!latestValue) {
      return onChange('');
    }

    const numberHasCountryCode = parsePhoneNumber(latestValue).getCountryCode();

    if (numberHasCountryCode) {
      this.setState(
        {
          selectedCountry: findCountry(availableCountries, parsePhoneNumber(latestValue).getRegionCode())
        },
        this.handleCountryChange
      );

      return onChange(latestValue);
    }

    return onChange(`+${getCountryCodeForRegionCode(selectedCountry.value)}${latestValue.slice(1)}`);
  };

  render() {
    const { onBlur, onEnterKeyPress, id, helperText, onFocus, disabled, error, testId, inputRef, autoFocus } =
      this.props;
    const { value, availableCountries, selectedCountry } = this.state;

    const computedClassName = cx('phone-input', {
      'phone-input--error': error,
      'phone-input--disabled': disabled
    });

    return (
      <>
        <div className={computedClassName}>
          <ReactSelect
            name="select-flag"
            isSearchable={false}
            placeholder=""
            options={availableCountries}
            styles={selectStyles}
            classNamePrefix="phone-input__countries"
            className="phone-input__countries"
            onChange={(country) => {
              this.setState({ selectedCountry: country }, this.handleCountryChange);
            }}
            value={selectedCountry}
            isDisabled={disabled}
            onBlur={onBlur}
            onFocus={onFocus}
            components={{
              DropdownIndicator
            }}
            data-testid="country-select"
          />
          <InputBase
            autoFocus={autoFocus}
            className="phone-input__input"
            type="tel"
            autoComplete="off"
            id={id}
            value={value}
            onChange={(e) => this.handleValueChange(e.target.value)}
            onBlur={onBlur}
            disabled={disabled}
            onFocus={onFocus}
            inputRef={inputRef}
            onKeyDown={(e) => e.key === 'Enter' && onEnterKeyPress()}
            fullWidth
            inputProps={{
              'data-testid': testId
            }}
          />
        </div>
        {helperText && (
          <Text
            type="helper"
            className={cx('helper-text', {
              'helper-text--disabled': disabled
            })}
            color={error ? 'danger' : void 0}
          >
            {helperText}
          </Text>
        )}
      </>
    );
  }
}

export default PhoneInput;
