import { useEffect, useState, useContext } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { faArrowRightFromBracket, faCircleQuestion, faFileLines, faSliders } from '@fortawesome/pro-regular-svg-icons';
import { STATUS_TO_COLOUR_MAP } from 'lib/common/constants/presenceStates';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import TUser from 'lib/common/types/User';
import getUserName from 'lib/common/utils/getUserName';
import HELP_URL from 'lib/common/constants/helpUrl';
import COLOURS from 'css/export-vars.module.scss';
import { Avatar, PopoverMenu, PopoverMenuItem } from '../';
import Presence from './components/Presence';

import './styles/user-profile.scss';

interface IUserProfileProps {
  user: TUser;
  isSoftphone?: boolean;
  isReversedPopover?: boolean;
}

const PROFILE_MENU_LEFT_OFFSET = 30;
const NO_PROFILE_MENU_LEFT_OFFSET = 0;
const PROFILE_MENU_OFFSET = 20;
const SOFTPHONE_PROFILE_MENU_OFFSET = 3;

function getPopoverMenuLeftOffset({ isSoftphone, isReversedPopover }) {
  if (!isSoftphone) {
    return NO_PROFILE_MENU_LEFT_OFFSET;
  }

  if (isReversedPopover) {
    return -PROFILE_MENU_LEFT_OFFSET;
  }

  return PROFILE_MENU_LEFT_OFFSET;
}

const UserProfile = ({ user, isSoftphone, isReversedPopover }: IUserProfileProps) => {
  const { signOut }: any = useContext(AuthContext);
  const history = useHistory();
  const [statusColor, setStatusColor] = useState(STATUS_TO_COLOUR_MAP['Offline']);
  const { agentPresence, agentNextState, agentPresenceNextState } = usePresenceContext();
  const {
    state: { tasks }
  } = useContactContext();

  const name = getUserName(user);

  useEffect(() => {
    const nextState = agentNextState || agentPresenceNextState;
    const presence = nextState ? nextState?.name : agentPresence;
    const mappedStatusColor = STATUS_TO_COLOUR_MAP[presence] || COLOURS.yellow;

    const statusColorToSet = nextState
      ? `linear-gradient(90deg, ${COLOURS.danger} 52%, ${mappedStatusColor} 48%)`
      : mappedStatusColor;
    setStatusColor(statusColorToSet);
  }, [agentPresence, agentNextState, agentPresenceNextState]);

  const avatar = <Avatar name={name} size={isSoftphone ? 30 : 40} statusProps={{ statusColor }} />;

  const status = (
    <div
      className={cx('user-profile__username-status-wrapper', {
        'user-profile__username-status-wrapper--softphone': isSoftphone
      })}
    >
      <div className="user-profile__username">{name}</div>
      <Presence />
    </div>
  );

  const onNavigate =
    ({ link, external }: { link: string; external?: boolean }) =>
    () => {
      if (!external) {
        return void history.push(link);
      }

      window.open(link, '_blank')?.focus();
    };

  const downloadLogs = () => {
    connect.getLog()?.download();
  };

  return (
    <div className="user-profile__wrapper">
      <PopoverMenu
        anchor={avatar}
        offset={isSoftphone ? SOFTPHONE_PROFILE_MENU_OFFSET : PROFILE_MENU_OFFSET}
        offsetLeft={getPopoverMenuLeftOffset({ isSoftphone, isReversedPopover })}
        reverse={false}
      >
        {isSoftphone && (
          <>
            {status}
            <hr className="user-profile__divider" />
          </>
        )}
        {isSoftphone && (
          <PopoverMenuItem
            onClick={onNavigate({ link: SOFTPHONE_PAGE_ROUTES.PREFERENCES })}
            icon={faSliders}
            text="Preferences"
          />
        )}
        {isSoftphone && (
          <PopoverMenuItem
            onClick={onNavigate({ link: HELP_URL, external: true })}
            icon={faCircleQuestion}
            text="Help Docs"
          />
        )}
        <PopoverMenuItem onClick={downloadLogs} icon={faFileLines} text="Download Logs" />
        <hr className="user-profile__divider" />
        <PopoverMenuItem
          disabled={Boolean(tasks.length)}
          tooltipId={tasks.length ? 'sign-out' : void 0}
          tooltip="You'll need to clear all of your active tasks before you can sign out."
          tooltipDelay={0}
          onClick={!tasks.length ? signOut : void 0}
          text="Sign Out"
          icon={faArrowRightFromBracket}
          highlighted
        />
      </PopoverMenu>
      {!isSoftphone && status}
    </div>
  );
};

export default UserProfile;
