import { useEffect, useMemo, useState } from 'react';
import Select, { components, OptionTypeBase, StylesConfig, ValueType } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import presenceStates, { PRESENCE_STATES, STATUS_TO_COLOUR_MAP } from 'lib/common/constants/presenceStates';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { useAgentContext } from 'lib/common/contexts/AgentContext';

import COLOURS from 'css/export-vars.module.scss';
import '../styles/presence.scss';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faAngleDown} />
    </components.DropdownIndicator>
  );
};

const SingleValue = (props) => {
  const { agentNextState, agentPresenceNextState, ...restProps } = props;
  const { data } = restProps || {};
  const nextState = agentNextState || agentPresenceNextState;

  return (
    <components.SingleValue {...restProps}>
      <div>{nextState ? `Next Status: ${nextState?.name}` : data?.value}</div>
    </components.SingleValue>
  );
};

const colourStyles: StylesConfig<OptionTypeBase, false> = {
  control: (styles, { isFocused }) => ({
    ...styles,
    ...(isFocused
      ? {
          outline: 'none',
          borderColor: ` ${COLOURS.midGrey} !important`,
          boxShadow: 'none !important'
        }
      : {}),
    height: '18px',
    minHeight: 'unset !important'
  }),
  menu: (styles) => ({
    ...styles,
    width: 120,
    minWidth: 120
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0 2px !important',
    fontSize: '12px'
  }),
  singleValue: (styles) => ({
    ...styles,
    maxWidth: '100% !important',
    position: 'inherit',
    transform: 'unset !important',
    flexBasis: 0,
    flexGrow: 1,
    marginRight: '5px'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: `${COLOURS.black}`,
    padding: 0,
    paddingRight: '4px'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    color: COLOURS.text,
    backgroundColor: COLOURS.white,
    fontSize: '12px',
    textAlign: 'start',
    fontWeight: isSelected ? 'bold' : 400,

    '&:hover': {
      backgroundColor: COLOURS.lightGrey
    }
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 })
};

function getPresenceOptions(agentStates) {
  const defaultStates = Object.values(presenceStates);
  const defaultStatesWithoutOffline = defaultStates.filter((state) => state !== PRESENCE_STATES.OFFLINE);
  const customStates = agentStates.filter(({ name: state }) => !defaultStates.includes(state)).map(({ name }) => name);
  const orderedStates = [...defaultStatesWithoutOffline, ...customStates, PRESENCE_STATES.OFFLINE];

  return {
    presenceOptions: orderedStates.map((state: string) => ({
      value: state,
      label: (
        <div className="select__control__presence-states__option">
          <span
            style={{ backgroundColor: STATUS_TO_COLOUR_MAP[state] || COLOURS.yellow }}
            className="select__control__presence-states__option__indicator"
          />
          {state}
        </div>
      )
    })),
    hasBusyCustomStatus: Boolean(agentStates.find((state) => state?.name === PRESENCE_STATES.BUSY))
  };
}

export default function Presence() {
  const { agentPresence, agentNextState, agentPresenceNextState, setConnectPresence } = usePresenceContext();
  const { states: agentStates } = useAgentContext();

  // Having a local state to overcome the lag caused by the context to re render
  const [selectedState, setSelectedState] = useState(agentPresence);

  const { presenceOptions, hasBusyCustomStatus } = getPresenceOptions(agentStates);

  const selectedValue = useMemo(() => {
    return presenceOptions.find((option) => option.value === selectedState);
  }, [selectedState]);

  const onChange = (selectedPresence: ValueType<{ value: PRESENCE_STATES; label: string }, false>) => {
    if (!selectedPresence) {
      return;
    }

    setConnectPresence(selectedPresence.value);
  };

  useEffect(() => {
    setSelectedState(agentPresence);
  }, [agentPresence]);

  return (
    <Select
      id="presence-status-select"
      data-testid="presence-status-select"
      name="presence"
      isSearchable={false}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        SingleValue: (singleValueProps) => (
          <SingleValue
            {...singleValueProps}
            agentNextState={agentNextState}
            agentPresenceNextState={agentPresenceNextState}
          />
        )
      }}
      options={presenceOptions}
      className="select__control__presence-states"
      classNamePrefix="select"
      onChange={(selectedPresence) =>
        onChange(selectedPresence as ValueType<{ value: PRESENCE_STATES; label: string }, false>)
      }
      value={selectedValue}
      aria-label="presence-status-select"
      styles={colourStyles}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      filterOption={!hasBusyCustomStatus ? (option) => option.value !== PRESENCE_STATES.BUSY : undefined}
    />
  );
}
