export const cloudwavePink = '#e83e8c';
export const lightPink = '#f8a8c9';

export const lightBlue = '#11b5e7';
export const darkBlue = '#101e47';
export const darkGrey = '#8C929A';
export const headerBlack = '#101E47';

export const backgroundColor = '#F2F8FE';

export const adminColor = '#44465A';

export const success = '#34C659';
export const danger = '#F83B2F';

export default {
  cloudwavePink,
  lightPink,
  lightBlue,
  darkBlue,
  darkGrey,
  headerBlack,
  backgroundColor,
  adminColor,
  success,
  danger
};
