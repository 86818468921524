import { faPause, faPlay, faUserXmark } from '@fortawesome/pro-regular-svg-icons';
import { PopoverMenuItem } from '../components';

interface IActionMenuProps {
  onDisconnect: () => void;
  allConnectionsConnected: boolean;
  holdConnection: () => void;
  resumeConnection: () => void;
}

export default function getConferenceActions({
  onDisconnect,
  allConnectionsConnected,
  holdConnection,
  resumeConnection
}: IActionMenuProps) {
  return [
    <PopoverMenuItem key="end-connection" onClick={onDisconnect} text="End Connection" icon={faUserXmark} />,
    <PopoverMenuItem
      key="toggle-connection"
      onClick={allConnectionsConnected ? holdConnection : resumeConnection}
      text={allConnectionsConnected ? 'Hold Connection' : 'Resume Connection'}
      icon={allConnectionsConnected ? faPause : faPlay}
    />
  ];
}
