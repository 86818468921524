import customerProfileSearchTypes, { ATTRIBUTE_FIELDS } from 'lib/common/constants/customerProfileSearchTypes';
import SEARCHABLE_ATTRIBUTES from 'lib/common/constants/searchableProfileAttributes';
import getMatchableAttribute from 'lib/common/utils/getMatchableAttribute';
import connectGetter from 'lib/common/utils/connectGetter';

import searchCustomerProfiles from 'lib/common/api/customerProfiles/searchCustomerProfiles';
import getContactConnection from '../../../utils/getContactConnection';

type TMatchContactArgs = {
  contactAttributes: { [key: string]: string };
  phoneNumber?: string;
  config: any;
  fetch_: (url, options) => Promise<any>;
};

type TMatchContactByTypeArgs = {
  type: string;
  value: string;
  config: any;
  fetch_: (url, options) => Promise<any>;
};

type THandleContact = {
  contact: connect.Contact;
  handleContactChange: (any) => void;
  config: any;
  fetch_: (url, options) => Promise<any>;
  hasCustomerProfilesPermission: boolean;
  skipStateChanges?: boolean;
};

async function getMatchedContactByType({ type, value, fetch_, config }: TMatchContactByTypeArgs) {
  return searchCustomerProfiles({
    config,
    fetch_,
    value,
    type,
    maxResults: 1
  });
}

async function getMatchedContact({ contactAttributes, phoneNumber, fetch_, config }: TMatchContactArgs) {
  // Search on contact attributes before falling back to phone number
  for (const attribute of SEARCHABLE_ATTRIBUTES) {
    const attributeValue = contactAttributes[attribute];

    if (!attributeValue) {
      continue;
    }

    const result = await getMatchedContactByType({
      type: customerProfileSearchTypes[attribute],
      value: attributeValue,
      config,
      fetch_
    });

    if (!result) {
      continue;
    }

    return result;
  }

  if (!phoneNumber) {
    return null;
  }

  return getMatchedContactByType({
    type: customerProfileSearchTypes[ATTRIBUTE_FIELDS.PHONE_NUMBER],
    value: phoneNumber,
    config,
    fetch_
  });
}

export default async function resolveCustomerProfile({
  contact,
  handleContactChange,
  config,
  fetch_,
  hasCustomerProfilesPermission
}: THandleContact) {
  // If the contact has been destroyed by the time the profile call is made, this will break, so let's handle
  try {
    const isCall = connectGetter('getType', contact) === connect.ContactType.VOICE;
    const contactAttributes = getMatchableAttribute(contact);

    if (!hasCustomerProfilesPermission || (!isCall && !contactAttributes)) {
      return handleContactChange({ contact, profile: null });
    }

    if (!isCall) {
      return handleContactChange({
        contact: contact,
        profile: await getMatchedContact({
          contactAttributes: contactAttributes || {},
          fetch_,
          config
        })
      });
    }

    const connection = getContactConnection(contact);

    if (!connection) {
      return;
    }

    const { phoneNumber } = connectGetter('getEndpoint', connection) || {};

    const matchedContact = await getMatchedContact({
      contactAttributes: contactAttributes || {},
      phoneNumber,
      fetch_,
      config
    });

    handleContactChange({ contact, profile: matchedContact });
  } catch {}
}
