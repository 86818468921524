import { Component, ContextType, useContext, useEffect, useState } from 'react';
import {
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField
} from '@material-ui/core';
import { faChevronDown, faClock, faEdit, faLayerGroup, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from 'lib/core/context/AuthProvider';
import { Loader, EmptyPlaceholder, Button, Badge, Alert, DataTable, TourControl } from 'lib/common/components';
import ManageMetricsConfig from './ManageMetricsConfig';
import './metrics-config.scss';

type IProps = Record<string, unknown>;

interface IStates {
  loading: boolean;
  showManagePage: boolean;
  metricsConfigs: any[];
  metricsGroups: any[];
  rowSelected: any;
  editMode: boolean;
  modalStatus: boolean;
  showAlert: boolean;
  alertSeverity: 'error' | 'info' | 'success' | 'warning' | undefined;
  alertMsg: string;
}

const PollingConfig = () => {
  const [originalTimer, setOriginalTimer] = useState<string | undefined>(undefined);
  const [pollingTimer, setPollingTimer] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { config } = useContext(AuthContext);
  const { fetch_ } = useContext(AuthContext);
  const metricsPollUrl = `${config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/poll`;

  useEffect(() => {
    fetch_(metricsPollUrl)
      .then((res) => res.json())
      .then((data) => {
        setPollingTimer(data.poll);
        setOriginalTimer(data.poll);
        setLoading(false);
      });
  }, []);

  const updateTimer = () => {
    return fetch_(metricsPollUrl, {
      method: 'POST',
      body: JSON.stringify({
        poll: pollingTimer
      })
    })
      .then((res) => res.json())
      .then(() => {
        setOriginalTimer(pollingTimer);
      });
  };

  return (
    <div className="metrics-config__interval">
      <FormControl variant="outlined" style={{ width: '100%', maxWidth: 300, height: 45, minHeight: 45 }}>
        <TextField
          SelectProps={{ IconComponent: () => <FontAwesomeIcon icon={faChevronDown} /> }}
          select
          variant="outlined"
          disabled={pollingTimer === undefined}
          value={pollingTimer || ''}
          onChange={(e) => setPollingTimer(e.target.value)}
        >
          <MenuItem value={60}>60s</MenuItem>
          <MenuItem value={30}>30s</MenuItem>
          <MenuItem value={20}>20s</MenuItem>
          <MenuItem value={15}>15s</MenuItem>
          <MenuItem value={10}>10s</MenuItem>
          <MenuItem value={5}>5s</MenuItem>
        </TextField>
      </FormControl>
      <Button
        className="ml-20"
        onClick={updateTimer}
        disabled={loading || originalTimer === pollingTimer}
        busy={loading}
        asyncAction
      >
        Save
      </Button>
    </div>
  );
};

export default class MetricsConfig extends Component<IProps, IStates> {
  static contextType = AuthContext;

  context!: ContextType<typeof AuthContext>;

  config: any;

  fetch_: any;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showManagePage: false,
      metricsConfigs: [],
      metricsGroups: [],
      rowSelected: null,
      editMode: false,
      modalStatus: false,
      alertSeverity: 'success',
      alertMsg: '',
      showAlert: false
    };
  }

  toProperCase = (s: string) => {
    return s
      .split(' ')
      .map((elem) => {
        return elem
          .toLocaleLowerCase()
          .split('')
          .map((x, i) => {
            return i === 0 ? x.toUpperCase() : x;
          })
          .join('');
      })
      .join(' ');
  };

  getRowSelected = (selected: any) => {
    const [tenantId, uuid] = selected.data;
    return this.state.metricsGroups.filter((u) => u.tenantId === tenantId && u.uuid === uuid)[0];
  };

  filterItemColumns = (value: any) => {
    if (value) {
      if (Array.isArray(this.state.metricsConfigs)) {
        return value
          .filter((v) => this.state.metricsConfigs.filter((c) => c.id === v.value).length > 0)
          .map((i) => i.label)
          .join(', ');
      }
      return value.map((i) => i.label).join(', ');
    }
    return '';
  };

  UNSAFE_componentWillMount() {
    this.config = this.context.config;
    this.fetch_ = this.context.fetch_;
  }

  componentDidMount() {
    this.setState({
      loading: true
    });
    this.loadData();
  }

  toggleAddMetricsGroup = () => {
    this.setState({
      showManagePage: !this.state.showManagePage,
      rowSelected: null,
      editMode: false
    });
  };

  loadData = async () => {
    this.setState({
      alertMsg: '',
      alertSeverity: undefined,
      showAlert: false
    });

    const metricsConfigsUrl = `${this.config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/metrics-configs`;
    const metricsGroupsUrl = `${this.config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/groups`;

    try {
      const responses = await Promise.all([this.fetch_(metricsConfigsUrl), this.fetch_(metricsGroupsUrl)]);
      const results = await Promise.all(responses.map((r) => r.json()));

      if (Array.isArray(results[0])) {
        return void this.setState({
          metricsConfigs: results[0],
          metricsGroups: results[1].items,
          loading: false
        });
      }

      this.setState({
        alertMsg: 'Failed to load metrics configuration. Please refresh the page.',
        alertSeverity: 'error',
        showAlert: true,
        loading: false
      });
    } catch (e) {
      this.setState({
        loading: false,
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getMetricGroups = async () => {
    const metricsGroupsUrl = `${this.config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/groups`;

    try {
      const res = await this.fetch_(metricsGroupsUrl);
      const result = await res.json();

      this.setState({ metricsGroups: result.items });
    } catch (e) {
      this.setState({
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getOptions() {
    return {
      filterType: 'checkbox',
      selectableRows: 'single',
      responsive: 'vertical',
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      sortOrder: {
        name: 'groupName',
        direction: 'asc'
      },
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: 10,
      rowsSelected: this.state.rowSelected,
      customToolbarSelect: (selectedRows, displayData) => {
        const selected = displayData[selectedRows.data[0].index];
        const rowSelected = this.getRowSelected(selected);

        return (
          <Grid container direction="column" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
              <Tooltip title="Edit Metrics Group">
                <IconButton onClick={() => this.editMetricsGroup(rowSelected)}>
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Metrics Group">
                <IconButton onClick={() => this.deleteMetricsGroup(rowSelected)}>
                  <FontAwesomeIcon icon={faTrash} color="red" size="sm" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
      downloadOptions: {
        filename: 'Neon Metrics Groups.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: false
        }
      }
    };
  }

  handleClose(toDelete) {
    if (toDelete) {
      this.setState({ alertSeverity: undefined });
      const item = {
        tenantId: this.state.rowSelected.tenantId,
        uuid: this.state.rowSelected.uuid,
        groupName: this.state.rowSelected.groupName,
        groupType: this.state.rowSelected.groupType
      };
      const url = `${this.config.AGENT_SERVICE_HOST}/metrics/${process.env.REACT_APP_TENANT_ID}/group`;
      this.fetch_(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'DELETE',
        body: JSON.stringify(item)
      })
        .then((res) => {
          if (res.status !== 200) {
            this.setState({
              alertMsg: 'There was an error deleting the metrics group. Please try again.',
              alertSeverity: 'error',
              showAlert: true
            });
          } else {
            this.setState({
              alertMsg: 'Metrics group has been deleted successfully.',
              alertSeverity: 'success',
              showAlert: true
            });
          }
        })
        .catch(() => {
          const errMsg = 'There was an error deleting the metrics group. Please try again.';
          this.setState({
            alertMsg: errMsg,
            alertSeverity: 'error',
            showAlert: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            if (this.state.alertSeverity === 'success') {
              this.getMetricGroups();
            }
            this.setState({
              alertSeverity: undefined,
              showAlert: false
            });
          }, 1000);
        });
    }
    this.setState({
      modalStatus: false
    });
  }

  deleteMetricsGroup(rowSelected) {
    this.setState({
      modalStatus: true,
      rowSelected
    });
  }

  addMetricsGroup(rowSelected) {
    this.setState({
      editMode: false,
      rowSelected,
      showManagePage: true
    });
  }

  editMetricsGroup(rowSelected) {
    this.setState({
      editMode: true,
      rowSelected,
      showManagePage: true
    });
  }

  getColumns() {
    return [
      {
        name: 'tenantId',
        label: 'Tenant Id',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'uuid',
        label: 'UUID',
        options: {
          display: false,
          filter: false
        }
      },
      {
        name: 'groupName',
        label: 'Group Name',
        options: {
          filterType: 'dropdown'
        }
      },
      {
        name: 'groupType',
        label: 'Group Type',
        options: {
          customBodyRender: (value) => <span>{this.toProperCase(value.split('-').join(' '))}</span>,
          filterType: 'dropdown'
        }
      },
      {
        name: 'gItems',
        label: 'Items',
        options: {
          setCellProps: () => ({ style: { minWidth: '150px', maxWidth: '300px' } }),
          filter: false
        }
      },
      {
        name: 'items',
        label: 'Items',
        options: {
          customBodyRender: () => <></>,
          display: 'excluded',
          filterType: 'dropdown',
          filterOptions: {
            names: this.state.metricsConfigs.map((c) => c.name),
            logic: (item, filters) => {
              if (item.length > 0) {
                return !item.map((i) => i.label).includes(filters[0]);
              }
              return true;
            }
          },
          filter: true
        }
      },
      {
        name: 'active',
        label: 'Active',
        options: {
          customBodyRender: (value) => (
            <div>
              <Badge label={value ? 'Yes' : 'No'} type={value ? 'SUCCESS' : 'ERROR'} />
            </div>
          ),
          filterType: 'dropdown'
        }
      }
    ];
  }

  render() {
    const {
      showManagePage,
      metricsConfigs,
      metricsGroups,
      rowSelected,
      editMode,
      modalStatus,
      showAlert,
      alertSeverity,
      alertMsg,
      loading
    } = this.state;

    return (
      <div className="panel">
        <TourControl />
        <div className="metrics-config">
          <>
            <div data-tour="metricsconfig-updateinterval">
              <h2 className="metrics-config__header">
                Update Interval
                <FontAwesomeIcon className="ml-10" icon={faClock} />
              </h2>
              <PollingConfig />
            </div>
            <hr />
            <h2 className="metrics-config__header">
              Groups
              <FontAwesomeIcon className="ml-10" icon={faLayerGroup} />
              {!loading && (
                <Button className="metrics-config__add-group" onClick={this.toggleAddMetricsGroup} icon="faPlus" />
              )}
            </h2>
            <div className="relative min-height-300">
              {loading && <Loader />}
              {!loading && !metricsGroups.length ? (
                <EmptyPlaceholder error={showAlert && alertSeverity === 'error'} />
              ) : null}
              {!loading && metricsGroups.length ? (
                <DataTable
                  data={metricsGroups.map((g) => ({
                    ...g,
                    gItems: this.filterItemColumns(g.items)
                  }))}
                  columns={this.getColumns()}
                  options={this.getOptions()}
                  title={showAlert && <Alert severity={alertSeverity} message={alertMsg} />}
                />
              ) : null}
            </div>
          </>
          <ManageMetricsConfig
            show={showManagePage}
            getMetricGroups={this.getMetricGroups}
            metricsConfigs={metricsConfigs}
            editMode={editMode}
            rowSelected={rowSelected}
            toggleAddMetricsGroup={this.toggleAddMetricsGroup}
          />
          <Dialog
            open={modalStatus}
            keepMounted
            onClose={() => this.handleClose(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Delete Metrics Group?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete this metrics group?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleClose(false)} styleType="SECONDARY">
                Cancel
              </Button>
              <Button onClick={() => this.handleClose(true)} styleType="DANGER">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
