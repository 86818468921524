import { ComponentType, useContext } from 'react';

import { useAuthContext as useFullAuthContext } from 'lib/core/context/AuthProvider';
import { useIsolatedAuthContext } from 'lib/core/context/IsolatedAuthProvider';
import { ConfigContext } from 'lib/core/config';
import TUser from 'lib/common/types/User';

/**
 * This is used for pages/components that are supported in both desktop & isolated modes. We need to change
 * the auth context based on the mode and inject the correct props in to the page. User is optional based
 * on whether the child page/component needs it.
 *
 * This component is not required for components that are desktop/softphone only. They can use the standard
 * auth context.
 */
export default function WithApiContexts({
  isIsolated,
  component: Component,
  user
}: {
  isIsolated?: boolean;
  component: ComponentType<any>;
  user?: TUser;
}) {
  const useAuthContext = !isIsolated ? useFullAuthContext : useIsolatedAuthContext;

  const { fetch_ } = useAuthContext();
  const { config } = useContext(ConfigContext);

  return <Component user={user} fetch={fetch_} config={config} />;
}
