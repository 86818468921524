import { CONTACT_NOT_FOUND_EXCEPTION } from 'lib/common/constants/connectExceptions';
import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';
import parseJSON from 'lib/common/utils/parseJSON';
import getContactChatSession from './getContactChatSession';

export default async function endTaskHelper(contact, handleContactChange) {
  if (!contact) {
    return Promise.resolve();
  }

  try {
    const activeInitialConnection = connectGetter('getActiveInitialConnection', contact);

    if (activeInitialConnection) {
      await connectAction('destroy', activeInitialConnection);
    }

    (await getContactChatSession(contact))?.cleanUpOnParticipantDisconnect();
  } catch (e: any) {
    const { type } = parseJSON(e) || {};
    // If the error thrown corresponds to connect's contact not found, remove it from task list
    if (type === CONTACT_NOT_FOUND_EXCEPTION) {
      handleContactChange({ contact, isDestroy: true });
    }
  }
}
