import { ReactNode } from 'react';
import '../../../../../pages/AgentWorkspace/components/TaskDetail/styles/task-footer.scss';

interface ITaskFooterProps {
  children: ReactNode;
  isSmallWidth?: boolean;
}

function TaskFooter({ children, isSmallWidth }: ITaskFooterProps) {
  return (
    <div
      className="task-footer"
      style={{
        flexDirection: isSmallWidth ? 'column' : 'row',
        gap: !isSmallWidth ? '10px' : '5px'
      }}
    >
      {children}
    </div>
  );
}

export default TaskFooter;
