import { useEffect, useState } from 'react';
import isURL from 'validator/lib/isURL';
import cx from 'classnames';

import Badge from 'lib/common/components/Badge';
import Accordion from 'lib/common/components/Accordion';
import { FUSION_DATA_BLOCK_TYPES } from 'lib/common/constants/fusionDataBlockTypes';
import { ClickToCall, Text } from 'lib/common/components/index';
import TTask from 'lib/common/types/Task';
import TChatTask from 'lib/common/types/ChatTask';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

import styles from './fusion-data.module.scss';

interface FusionData {
  data: any;
  label: string;
  callback?: () => void;
  type: 'string' | 'phone-number' | 'badge-list' | 'accordion' | 'dynamic';
}

const AccordionBlock = ({ fusionData, isSoftphone }: { fusionData: FusionData[]; isSoftphone?: boolean }) => {
  const accordionDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.ACCORDION);

  if (!accordionDetails.length) {
    return null;
  }

  return (
    <>
      {accordionDetails.map(({ data, label }) => (
        <Accordion
          key={label}
          title={label}
          className={cx(styles['fusion-data__block'], styles['fusion-data__accordion'])}
          childClassName={cx({ [styles['fusion-data__accordion__child--nested']]: isSoftphone })}
        >
          {data.map((dataLine) => (
            <div key={dataLine} className={styles['fusion-data__accordion-line']}>
              {dataLine}
            </div>
          ))}
        </Accordion>
      ))}
    </>
  );
};

const PhoneNumberBlock = ({ fusionData }: { fusionData: FusionData[] }) => {
  const phoneNumberDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.PHONE_NUMBER);

  if (!phoneNumberDetails.length) {
    return null;
  }

  return (
    <>
      {phoneNumberDetails.map(({ data, label }) => (
        <div key={label} className={styles['fusion-data__block']}>
          <Text color="darkBlue" className={styles['fusion-data__label']}>
            {label}
          </Text>
          <ClickToCall>{data}</ClickToCall>
        </div>
      ))}
    </>
  );
};

const BadgeListBlock = ({ fusionData, invert }: { fusionData: FusionData[]; invert?: boolean }) => {
  const badgeDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.BADGE_LIST);

  if (!badgeDetails.length) {
    return null;
  }

  return (
    <>
      {badgeDetails.map(({ data, label }) => (
        <div key={label} className={styles['fusion-data__block']}>
          <Text color="darkBlue" className={styles['fusion-data__label']}>
            {label}
          </Text>
          <div className={styles['fusion-data__block__badges']}>
            {data.map((item) => (
              <Badge key={item} label={item} type="NEUTRAL" invert={invert} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

const DynamicAttributesBlock = ({ fusionData }: { fusionData: FusionData[] }) => {
  const dynamicAttributeDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.DYNAMIC);

  if (!dynamicAttributeDetails.length) {
    return null;
  }

  return (
    <>
      {dynamicAttributeDetails.map(({ data, label }) => (
        <div key={label} className={styles['fusion-data__block']}>
          <Text color="darkBlue" className={styles['fusion-data__label']}>
            {label}
          </Text>
          {data && isURL(data) ? (
            <a className={styles['fusion-data__link']} rel="noreferrer" target="_blank" href={data}>
              {data}
            </a>
          ) : (
            <p>{data}</p>
          )}
        </div>
      ))}
    </>
  );
};

const FusionData = ({
  task,
  invert = false,
  isSoftphone = false
}: {
  task?: TTask | TChatTask;
  invert?: boolean;
  isSoftphone?: boolean;
}) => {
  const [fusionData, setFusionData] = useState<any[]>([]);

  useEffect(() => {
    if (!task || !task.contact) {
      return;
    }

    const attributes = getContactAttributes(task?.contact);

    if (!attributes) {
      setFusionData([]);
      return;
    }

    setFusionData(attributes);
  }, [task, setFusionData]);

  if (!fusionData.length) {
    return null;
  }

  const attachedData = (
    <div className={styles['fusion-data']}>
      <>
        <PhoneNumberBlock fusionData={fusionData} />
        <BadgeListBlock fusionData={fusionData} invert={invert} />
        <DynamicAttributesBlock fusionData={fusionData} />
        <AccordionBlock fusionData={fusionData} isSoftphone={isSoftphone} />
      </>
    </div>
  );

  if (isSoftphone) {
    return (
      <Accordion title="Attached Data" className="mt-10">
        {attachedData}
      </Accordion>
    );
  }

  return attachedData;
};

export default FusionData;
