import { ReactNode } from 'react';
import cx from 'classnames';

import { RequiresPermission } from 'lib/common/components';
import useLayout from 'lib/common/contexts/LayoutContext';

import usePageProps from '../../hooks/usePageProps';
import Header from './Header';
import Sidebar from './Sidebar';

import '../styles/core-layout.scss';

interface ICoreLayout {
  user: any;
  children: ReactNode;
}

export default function CoreLayout({ user, children }: ICoreLayout) {
  const { fullscreenEnabled } = useLayout();
  const { pagename, className, pageTitle } = usePageProps();

  return (
    <div
      className={cx({
        'core-layout': !fullscreenEnabled,
        'core-layout--fullscreen': fullscreenEnabled
      })}
    >
      {!fullscreenEnabled && <Header user={user} pagename={pagename} />}
      <div
        className={cx({
          'core-layout__content': !fullscreenEnabled
        })}
      >
        {!fullscreenEnabled && (
          <RequiresPermission permissionType="user" permission="active">
            <div className="sidebar">
              <Sidebar user={user} />
            </div>
          </RequiresPermission>
        )}
        <div
          className={cx(className, {
            'core-layout__content__right': !fullscreenEnabled
          })}
        >
          {pageTitle && (
            <div className="core-layout__content__right__title component-title" data-testid="page-title">
              {pageTitle}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
