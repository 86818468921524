import { useEffect, useRef } from 'react';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import { NONE as NO_RINGTONE } from 'lib/common/constants/ringtoneOptions';

const AWS_APPS_CONNECT_HOST = 'awsapps';
const LOG_LEVEL = 'ERROR';

export default function ConnectStreams({ config }) {
  const {
    state: { ringtone }
  } = usePreferencesContext();

  const ccpEl = useRef(null);
  const instanceAlias = config.CONNECT_HOST.split('.')[0].split('/')[2];
  const connectHost = config.CONNECT_HOST.split('.')[1];
  const instanceDomain = connectHost !== AWS_APPS_CONNECT_HOST ? 'my.connect.aws' : connectHost;

  const instanceUrl = `https://${instanceAlias}.${instanceDomain}`;

  const ccpURL = instanceUrl + (instanceDomain === AWS_APPS_CONNECT_HOST ? '.com/connect/ccp-v2' : '/ccp-v2');
  const ccpLoginURL =
    config.SSO_URL || instanceUrl + (instanceDomain === AWS_APPS_CONNECT_HOST ? '.com/connect/login' : '/login');

  useEffect(() => {
    if (!ccpEl || !ccpEl.current) {
      return;
    }

    try {
      // This is used to ignore all [INFO] messages coming from CCP.
      // @ts-ignore
      connect.getLog().setEchoLevel(LOG_LEVEL);

      connect.core.initCCP(ccpEl.current, {
        ccpUrl: ccpURL,
        loginPopup: true,
        loginUrl: ccpLoginURL,
        softphone: {
          allowFramedSoftphone: true,
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        chat: {
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        loginOptions: {
          autoClose: true,
          top: 0,
          left: 0,
          height: 472.867,
          width: 355
        },
        loginPopupAutoClose: true,
        region: config.CONNECT_REGION,
        // @ts-ignore
        ccpAckTimeout: 5000,
        ccpLoadTimeout: 5000
      });
    } catch (e) {
      console.error('Error initialising connect streams', e);
    }
  }, [ccpEl]);

  return <div style={{ display: 'none' }} ref={ccpEl} />;
}
