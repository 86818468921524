import { useLocation, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { usePrevious } from 'webrix/hooks';
import sizeMe from 'react-sizeme';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { TASK_TYPES } from 'lib/common/constants/taskStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { ClickableIcon, PopoverMenu, PopoverMenuItem } from 'lib/common/components';

import './softphone-task-list.scss';

const MORE_TASKS_MENU_OFFSET = 30;
const TASK_LIST_MINIMUM_HEIGHT = 90;
const TASK_HEIGHT = 40;
const MINIMUM_VISIBLE_TASKS = 1;

const sizeMeHOC = sizeMe({ monitorHeight: true, refreshRate: 16 });

interface ISoftphoneTaskListProps {
  isCreateTaskDisabled: boolean;
  isReversedLayout: boolean;
  size: { height: number };
}

const SoftphoneTaskList = ({ isCreateTaskDisabled, isReversedLayout, size: { height } }: ISoftphoneTaskListProps) => {
  const history = useHistory();
  const location = useLocation();
  const [visibleTasks, setVisibleTasks] = useState<number>(0);

  const {
    actions: { setSelectedTaskId },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  useEffect(() => {
    const availableTasksSpace = Math.floor((height - TASK_LIST_MINIMUM_HEIGHT) / TASK_HEIGHT);
    const numberOfVisibleTasks = availableTasksSpace + MINIMUM_VISIBLE_TASKS;

    if (!Boolean(tasks.length)) {
      return;
    }

    // avoid showing +1 icon as we show the actual task instead.
    if (tasks?.length - numberOfVisibleTasks <= 1) {
      return setVisibleTasks(tasks.length);
    }

    setVisibleTasks(numberOfVisibleTasks);
  }, [tasks, height]);

  const lastSelectedTaskId = usePrevious(selectedTaskId);

  const pushToTask = () => {
    if (history.location.pathname === SOFTPHONE_PAGE_ROUTES.TASK) {
      return history.replace(SOFTPHONE_PAGE_ROUTES.TASK);
    }

    history.push(SOFTPHONE_PAGE_ROUTES.TASK);
  };

  const handleTaskClick = (taskId: string) => {
    setSelectedTaskId(taskId);

    pushToTask();
  };

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const incomingTask = tasks.find(
    (task) => task.status === CONTACT_STATES.CONNECTING && task.type !== CONTACT_TYPES.CONFERENCE_CALL
  );

  useEffect(() => {
    if (lastSelectedTaskId || !selectedTaskId) {
      return;
    }

    pushToTask();
  }, [selectedTaskId]);

  useEffect(() => {
    if (!Boolean(tasks.length)) {
      return;
    }

    const taskToSelect = Boolean(incomingTask) ? incomingTask : tasks[0];

    setSelectedTaskId(taskToSelect?.taskId);

    pushToTask();
  }, [tasks?.length]);

  return (
    <div className="softphone-task-list">
      {!isCreateTaskDisabled && Boolean(tasks.length) && <hr />}
      {tasks.slice(0, visibleTasks).map(({ taskId, connectionType, status, type }) => (
        <div
          key={taskId}
          className={cx('softphone-task-list__item', {
            'softphone-task-list__item--active':
              selectedTask?.taskId === taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK,
            'softphone-task-list__item--incoming': incomingTask?.taskId === taskId
          })}
        >
          <ClickableIcon
            icon={status === CONTACT_STATES.ACCEPTED ? faSpinnerThird : TASK_TYPES(connectionType)[status][type]?.icon}
            withHover={false}
            onClick={() => handleTaskClick(taskId)}
            size={18}
            spin={status === CONTACT_STATES.ACCEPTED}
          />
        </div>
      ))}
      {tasks.length > visibleTasks && (
        <PopoverMenu
          anchor={
            <p
              className={cx('softphone-task-list__more-tasks', {
                'softphone-task-list__more-tasks--active': tasks
                  .slice(visibleTasks)
                  .find(
                    ({ taskId }) => taskId === selectedTask?.taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK
                  )
              })}
            >{`+${tasks.length - visibleTasks}`}</p>
          }
          offset={0}
          offsetLeft={isReversedLayout ? -MORE_TASKS_MENU_OFFSET : MORE_TASKS_MENU_OFFSET}
        >
          {tasks.slice(visibleTasks).map(({ taskId, connectionType, status, type }) => (
            <div
              key={taskId}
              className={cx('softphone-task-list__more-tasks__item', {
                'softphone-task-list__more-tasks__item--active':
                  selectedTask?.taskId === taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK
              })}
            >
              <PopoverMenuItem
                onClick={() => handleTaskClick(taskId)}
                text={TASK_TYPES(connectionType)[status][type]?.subtitle}
                icon={TASK_TYPES(connectionType)[status][type]?.icon}
              />
            </div>
          ))}
        </PopoverMenu>
      )}
    </div>
  );
};

export default sizeMeHOC(SoftphoneTaskList);
