import cx from 'classnames';
import sizeMe from 'react-sizeme';
import { Text } from 'lib/common/components';
import VARS from 'css/export-vars.module.scss';
import { ReactComponent as EmptyImage } from 'assets/vectors/empty.svg';
import { ReactComponent as ErrorImage } from 'assets/vectors/error.svg';
import './empty-placeholder.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

function EmptyPlaceholder({
  size: { width },
  text,
  subText = '',
  error,
  Image
}: {
  size: { width: number };
  text?: string;
  Image?: any;
  subText?: string;
  error?: boolean;
}) {
  const DefaultImage = error ? ErrorImage : EmptyImage;
  const defaultText = error
    ? 'Oops, something went wrong. Try reloading the page.'
    : `There's nothing in here yet. ${subText}`;

  return (
    <div
      data-testId="empty-placeholder-text"
      className={cx('empty-placeholder', {
        'empty-placeholder--small': width <= parseInt(VARS.softphoneWidthNumber, 10)
      })}
    >
      {Image ? <Image /> : <DefaultImage />}
      <Text
        type="heading2"
        className={cx('empty-placeholder__message', { 'empty-placeholder__message--error': error })}
        data-testid="empty-placeholder-text"
      >
        {text || defaultText}
      </Text>
    </div>
  );
}

export default sizeMeHOC(EmptyPlaceholder);
