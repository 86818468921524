import { IContact } from '../types';

export const defaultContact: IContact = {
  tenantId: '',
  uuid: '',
  objectKey: '',
  objectId: '',
  firstName: '',
  lastName: '',
  organization: '',
  phoneNumber: '',
  numberType: ''
};
