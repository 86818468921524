import { faHeadSideHeadphones, faUser } from '@fortawesome/pro-regular-svg-icons';
import sizeMe from 'react-sizeme';

import getUserName from 'lib/common/utils/getUserName';
import Task from 'lib/common/types/Task';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import isActionableCallTask from 'lib/common/utils/tasks/isActionableCallContact';
import { getConferenceUserName, isThirdPartyConnectionOnHold } from 'lib/common/utils/conferenceConnections';
import taskActionWithCatch from 'lib/common/utils/taskActionWithCatch';
import useTaskConnections from 'lib/common/hooks/useTaskConnections';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import connectGetter from 'lib/common/utils/connectGetter';
import { TaskFooter } from 'lib/common/components/molecules';
import QueueNameAndTimer from '../../QueueNameAndTimer';
import UserInformation from './UserInformation';
import StatusIcon from './StatusIcon';

import '../styles/timed-task.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_WIDTH_PX = 400;

function BasicTimer({ time }) {
  const taskTime = useTaskTimer(time);

  return <p className="timed-task__time">{taskTime}</p>;
}

interface ITimedTaskProps extends Task {
  size: { width: number; height: number };
  user: any;
}

function TimedTask(props: ITimedTaskProps) {
  const {
    profile,
    connectionValue,
    connectionState,
    connectionType,
    time,
    type,
    status,
    taskId,
    contact,
    size,
    queueName,
    user
  } = props;
  const {
    actions: { holdCall, resumeCall, holdOutboundCall, resumeOutboundCall, endTask, endThirdPartyConnection }
  } = useContactContext();
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const {
    isNeglected,
    isConference,
    isConferenceConnecting,
    allConnectionsOnHold,
    allConnectionsConnected,
    callIsOnHold,
    initialConnectionDisconnected,
    thirdPartyConnectionsDisconnected,
    connectionsDisconnected
  } = useTaskConnections(props);

  const isSmallWidth = size?.width < SMALL_WIDTH_PX;

  const connectionsConnected = !connectionsDisconnected;

  return (
    <>
      <QueueNameAndTimer queueName={queueName} />
      <div className="timed-task">
        {(!isConference || connectionsDisconnected) && (
          <StatusIcon
            callIsOnHold={callIsOnHold}
            isNeglected={isNeglected}
            connectionType={connectionType}
            type={type}
            status={status}
          />
        )}
        {!initialConnectionDisconnected && (
          <UserInformation
            type={type}
            connectionType={connectionType}
            isNeglected={isNeglected}
            name={name}
            time={time}
            callIsOnHold={callIsOnHold}
            isRow={isConference && connectionsConnected}
            icon={faUser}
            isConferenceConnecting={false}
            allConnectionsOnHold={allConnectionsOnHold}
            allConnectionsConnected={allConnectionsConnected}
            onDisconnect={() => endTask(taskId)}
            holdConnection={taskActionWithCatch(holdCall, taskId)}
            resumeConnection={taskActionWithCatch(resumeCall, taskId)}
            isSmallWidth={isSmallWidth}
            status={status}
          />
        )}
        {isConference && !thirdPartyConnectionsDisconnected && (
          <UserInformation
            type={type}
            connectionType={connectionType}
            isNeglected={false}
            name={getConferenceUserName(contact)}
            time={time}
            callIsOnHold={isThirdPartyConnectionOnHold(contact)}
            isConferenceConnecting={isConferenceConnecting}
            isRow={isConference && !connectionsDisconnected}
            icon={faHeadSideHeadphones}
            allConnectionsOnHold={allConnectionsOnHold}
            allConnectionsConnected={allConnectionsConnected}
            onDisconnect={taskActionWithCatch(endThirdPartyConnection, taskId)}
            holdConnection={() => holdOutboundCall(taskId)}
            resumeConnection={() => resumeOutboundCall(taskId)}
            isSmallWidth={isSmallWidth}
            status={status}
          />
        )}
        {isConference && !connectionsDisconnected && <BasicTimer time={time} />}
      </div>
      {isActionableCallTask({ type, status }) && (
        <TaskFooter.ConnectedCall
          inConference={isConference}
          connectionState={connectionState}
          taskId={taskId}
          connectionValue={connectionValue}
          thirdPartyConnection={connectGetter('getSingleActiveThirdPartyConnection', contact)}
          isConferenceConnecting={isConferenceConnecting}
          allConnectionsOnHold={allConnectionsOnHold}
          allConnectionsConnected={allConnectionsConnected}
          thirdPartyConnectionsDisconnected={thirdPartyConnectionsDisconnected}
          initialConnectionDisconnected={initialConnectionDisconnected}
          type={type}
          isSmallWidth={isSmallWidth}
          user={user}
        />
      )}
      {isNeglected && <TaskFooter.ClearTask taskId={taskId} />}
    </>
  );
}

export default sizeMeHOC(TimedTask);
