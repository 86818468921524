import { createContext, useContext } from 'react';
import _uniq from 'lodash.uniq';
import _flatten from 'lodash.flatten';
import { ConfigContext } from 'lib/core/config';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import connectGetter from 'lib/common/utils/connectGetter';
import { useAgentContext } from '../AgentContext';
import userIsAdmin from '../../utils/userIsAdmin';

const ANY_PERMISSION_TYPE = 'any';

type TPermissionsContext = {
  hasPermission: ({ type, permission }: { type?: string; permission?: string | null }) => boolean;
  permissions: {
    organisation: string[];
    tenant: string[];
    user: string[];
    connect: string[];
  };
  isAdmin: boolean;
};

const Context = createContext<TPermissionsContext>({
  hasPermission: () => false,
  permissions: { organisation: [], tenant: [], user: [], connect: [] },
  isAdmin: false
});

function convertPermissionObjectToArray(permissions) {
  if (!permissions) {
    return [];
  }

  return Object.entries(permissions)
    .filter(([, hasPermission]) => hasPermission)
    .map(([permissionName]) => permissionName);
}

export default function PermissionsContext({ children, user }) {
  const { config: { PERMISSIONS: tenantPermissions = [] } = {} } = ({} = useContext(ConfigContext));
  const { agent } = useAgentContext();

  const isAdmin = userIsAdmin(user);

  const permissions = {
    organisation: [],
    tenant: tenantPermissions,
    user: [
      ..._uniq(_flatten(Object.values(user?.permissions || {}).map(convertPermissionObjectToArray))),
      isAdmin && PERMISSIONS.ADMIN,
      'active'
    ].filter(Boolean),
    connect: connectGetter('getPermissions', agent) || []
  };

  const hasPermission = ({ type, permission }: { type?: string; permission?: string | null }) => {
    if (!permission) {
      return true;
    }

    if (!type || type === ANY_PERMISSION_TYPE) {
      return Object.values(permissions).some((permissionArray) => permissionArray.includes(permission));
    }

    return permissions[type]?.includes(permission);
  };

  return <Context.Provider value={{ hasPermission, permissions, isAdmin }}>{children}</Context.Provider>;
}

export const { Consumer: PermissionsConsumer, Provider: PermissionsProvider } = Context;

export const usePermissionsContext = () => useContext(Context);
