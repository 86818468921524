import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Select } from 'lib/common/components';
import './dashboard-select.scss';

export default function DashboardSelect(props: any) {
  const { manageSel, dashboards, curDashboard } = props;

  const handleChange = (event) => {
    manageSel(event.target.value);
  };

  const selectOptions = dashboards.map((el, i) => (
    <MenuItem key={i} value={i}>
      {el.name}
    </MenuItem>
  ));

  return (
    <div className="dashboard-select">
      <Select id="dashboard-select" value={curDashboard} onChange={handleChange}>
        {selectOptions}
      </Select>
    </div>
  );
}
