import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-regular-svg-icons';

export default function ServiceLevelSelect(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { slIndex, setServiceLevel } = props;
  const handleClose = (event) => {
    const { myValue } = event.currentTarget.dataset;
    if (myValue !== undefined) {
      setServiceLevel(slIndex, myValue);
    }
    setAnchorEl(null);
  };

  const serviceLevels = [15, 20, 25, 30, 45, 60, 90, 120, 180, 240, 300, 600].filter(
    (i) => i !== props.slData.indexOf(i)
  );

  const menuOption = serviceLevels.map((el) => (
    <MenuItem onClick={handleClose} key={el} data-my-value={el}>
      {el}s
    </MenuItem>
  ));

  return (
    <>
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={faCaretDown} size="sm" />
      </IconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menuOption}
      </Menu>
    </>
  );
}
