export default function connectGetter(
  action: string,
  entity?: connect.Contact | connect.BaseConnection | connect.Agent | null,
  payload?: any,
  retries = 3,
  errorMsg?: string
) {
  if (!entity) {
    return;
  }

  try {
    return entity?.[action]?.();
  } catch (e: any) {
    if (retries === 0) {
      console.error(`${entity}`, `${action}`, e);
      return;
    }

    return connectGetter(action, entity, payload, retries - 1, errorMsg);
  }
}
