import { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { SizeMe } from 'react-sizeme';

import { Loader, TourControl } from 'lib/common/components';
import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';
import toast from '../../common/utils/toast';
import WallboardItem from './components';
import ServiceLevelSelect from './ServiceLevelSelect';
import QueueSelect from './QueueSelect';
import getStats from './api/getStats';
import getQueuesData from './api/getQueues';
import getDoughnutGraphOptions from './utils/getDoughnutGraphOptions';

import './styles/wallboard.scss';

const DEFAULT_FETCH_INTERVAL_S = 15;

const DEFAULT_STATS = {
  queueName: '',
  contactsInQueue: 0,
  oldestContactInQueue: 0,
  contactsHandledMidnight: 0,
  contactsAbandonedMidnight: 0,
  agentsOnline: 0,
  agentsAvailable: 0,
  handleRate: [0, 100],
  abandonRate: [0, 100],
  serviceLevel: [0, 100]
};

const DOUGHNUT_GRAPH_OPTIONS = {
  circumference: Math.PI,
  rotation: Math.PI,
  legend: { display: false },
  maintainAspectRatio: false,
  tooltips: {
    enabled: false
  }
};

const ALL_QUEUE_ID = 'all';
const ALL_QUEUES_LABEL = 'All Queues';

export default function Wallboard({ config, fetch }) {
  const [statsData, setStatsData] = useState<any>(DEFAULT_STATS);
  const [queueData, setQueueData] = useState<any>([]);
  const [queueSelected, setQueueSelected] = useState({ id: '', name: '' });
  const [loaded, setLoaded] = useState(false);
  const [serviceLevel, setServiceLevel] = useState([60, 60, 60]);
  const pollInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const getMetricData = async () => {
    try {
      setRefreshing(true);

      const stats = await getStats({ config, fetch, queueSelected, serviceLevel, queueData });

      if (!pollInterval.current) {
        pollInterval.current = setInterval(getMetricData, DEFAULT_FETCH_INTERVAL_S * 1000);
      }

      setStatsData(stats);
    } catch (e) {
      toast('error', 'Something went wrong fetching Wallboard metrics. Please try again.');
    } finally {
      setRefreshing(false);
      setLoaded(true);
    }
  };

  const onSelectQueue = (queueId) => {
    setQueueSelected({
      id: queueId,
      name: queueId === ALL_QUEUE_ID ? ALL_QUEUES_LABEL : queueData.find((queue) => queue.Id === queueId)?.Name
    });

    localStorage.setItem('selected-wallboard-queue', queueId);
  };

  const updateServiceLevel = (index, val) => {
    setServiceLevel(serviceLevel.fill(parseInt(val)));
    setLoaded(false);
  };

  const getQueues = async () => {
    try {
      const { queueData, queueSelected } = await getQueuesData({ fetch, config });

      setQueueData(queueData);
      setQueueSelected(queueSelected);
    } catch (e) {
      toast('error', `Something went wrong fetching wallboard metrics. Please try again.`);

      setLoaded(true);
    }
  };

  useEffect(() => {
    if (!queueSelected.id) {
      return;
    }

    pollInterval.current && clearInterval(pollInterval.current);

    getMetricData();
  }, [queueSelected, serviceLevel]);

  useEffect(() => {
    getQueues();

    return () => {
      pollInterval.current && clearInterval(pollInterval.current);
    };
  }, []);

  const doughnutGraphOptions = getDoughnutGraphOptions({ statsData, loaded });

  return (
    <>
      {!loaded && <Loader elevated />}
      {loaded && (
        <div className="wallboard" data-testid="wallboard">
          <span className="component-title">
            Wallboard
            {queueSelected && queueSelected.name ? (
              <>
                <TourControl />
                <span className="wallboard__stats-title">{queueSelected.name}</span>
                <QueueSelect onSelect={onSelectQueue} queueSelect={queueData} />
              </>
            ) : null}
            {refreshing && <Loader minimised size={30} relative className="ml-20" />}
          </span>
          <SizeMe>
            {({ size }) => (
              <Grid container spacing={2} data-tour="wallboard-widgets">
                <WallboardItem
                  containerSize={size}
                  title="Agents Available"
                  stats={statsData}
                  statKey="agentsAvailable"
                />
                <WallboardItem containerSize={size} title="Agents Online" stats={statsData} statKey="agentsOnline" />
                <WallboardItem
                  containerSize={size}
                  title="Contacts abandoned today"
                  stats={statsData}
                  statKey="contactsAbandonedMidnight"
                />
                <WallboardItem
                  containerSize={size}
                  title="Contacts handled today"
                  stats={statsData}
                  statKey="contactsHandledMidnight"
                />
                <WallboardItem
                  containerSize={size}
                  title="Contacts in queue"
                  stats={statsData}
                  statKey="contactsInQueue"
                />
                <WallboardItem
                  containerSize={size}
                  title="Oldest contact in queue"
                  stat={convertMSToReadableTime(statsData.oldestContactInQueue)}
                />
                <WallboardItem
                  containerSize={size}
                  doughnut
                  title="Abandon Rate"
                  stat={`${statsData.abandonRate[0]}%`}
                  doughnutProps={{ data: doughnutGraphOptions.abandonRate, options: DOUGHNUT_GRAPH_OPTIONS }}
                />
                <WallboardItem
                  containerSize={size}
                  doughnut
                  titleSuffix={
                    <ServiceLevelSelect slIndex={0} setServiceLevel={updateServiceLevel} slData={serviceLevel} />
                  }
                  title={`Service Level over ${serviceLevel[0]}s`}
                  stat={`${statsData.serviceLevel[0]}%`}
                  doughnutProps={{ data: doughnutGraphOptions.sls, options: DOUGHNUT_GRAPH_OPTIONS }}
                />
                <WallboardItem
                  containerSize={size}
                  doughnut
                  title="Handle Rate"
                  stat={`${statsData.handleRate[0]}%`}
                  doughnutProps={{ data: doughnutGraphOptions.handleRate, options: DOUGHNUT_GRAPH_OPTIONS }}
                />
              </Grid>
            )}
          </SizeMe>
        </div>
      )}
    </>
  );
}
