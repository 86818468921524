import { PermissionsConsumer } from 'lib/common/contexts/PermissionsContext';
import { LayoutContext } from 'lib/common/contexts/LayoutContext';
import PureDashboards from '../components/Dashboards';

export default function Dashboards(props) {
  return (
    <PermissionsConsumer>
      {({ hasPermission }) => (
        <LayoutContext.Consumer>
          {({ toggleFullScreen }) => (
            <PureDashboards {...props} hasPermission={hasPermission} toggleFullScreen={toggleFullScreen} />
          )}
        </LayoutContext.Consumer>
      )}
    </PermissionsConsumer>
  );
}
