import { ReactNode } from 'react';

import { PopoverMenu } from 'lib/common/components';
import { ConferenceDisconnectButton } from 'lib/common/components/atoms';
import getConferenceActions from 'lib/common/utils/getConferenceActions';

interface IActionMenuProps {
  children: ReactNode;
  onDisconnect: () => void;
  allConnectionsConnected: boolean;
  allConnectionsOnHold: boolean;
  holdConnection: () => void;
  resumeConnection: () => void;
}

const ConferenceActions = ({
  children,
  onDisconnect,
  allConnectionsConnected,
  allConnectionsOnHold,
  holdConnection,
  resumeConnection
}: IActionMenuProps) => {
  if (!allConnectionsConnected && !allConnectionsOnHold) {
    return <ConferenceDisconnectButton onDisconnect={onDisconnect} />;
  }

  return (
    <PopoverMenu anchor={children} reverse={false}>
      {getConferenceActions({
        onDisconnect,
        allConnectionsConnected,
        holdConnection,
        resumeConnection
      })}
    </PopoverMenu>
  );
};

export default ConferenceActions;
