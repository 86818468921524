import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';

import { Button, Icon, Text, TourControl } from 'lib/common/components';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import { SOFTPHONE_PAGE_ROUTES } from 'lib/common/constants/softphonePageConfigurations';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { SoftphoneCreateAgentTask } from 'lib/common/components/molecules';

import styles from './landing.module.scss';

function getPlaceholderText({ isBusyFromConnect, agentPresence }) {
  if (agentPresence === PRESENCE_STATES.AVAILABLE || isBusyFromConnect) {
    return 'You are ready to start receiving tasks.';
  }

  return 'Change your status to start receiving tasks.';
}

export default function Landing() {
  const { agentPresence, isBusyFromConnect } = usePresenceContext();
  const history = useHistory();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const handleOpenModal = () => {
    openModal({
      title: 'Add Agent Task',
      content: <SoftphoneCreateAgentTask onClose={closeModal} />
    });
  };

  const onMakeCall = () => {
    // Open directory on index 2 (dialpad)
    history.push(`${SOFTPHONE_PAGE_ROUTES.DIRECTORY}?directory=2`);
  };

  return (
    <>
      <TourControl isSoftphone />
      <div className={styles['softphone-landing']} data-tour="softphone-landing">
        <Icon icon={faLayerGroup} size={60} />
        <div className={cx(styles['softphone-landing__text'], 'mt-30')}>
          <Text type="heading2">No Active Tasks</Text>
          <Text className="mt-10">{getPlaceholderText({ agentPresence, isBusyFromConnect })}</Text>
        </div>
        <div className={cx(styles['softphone-landing__actions'], 'mt-30')}>
          <Button styleType="SECONDARY" icon="faPlus" onClick={handleOpenModal}>
            Create A Task
          </Button>
          <Button styleType="SECONDARY" icon="dialpad" onClick={onMakeCall}>
            Make A Call
          </Button>
        </div>
      </div>
    </>
  );
}
