import { Component, ContextType } from 'react';
import {
  Tooltip,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons';

import { AuthContext } from 'lib/core/context/AuthProvider';
import { Alert, DataTable, Loader, EmptyPlaceholder, Button, TourControl } from 'lib/common/components';
import ManageOutcomesModal from './ManageOutcomes';

import './outcomes.scss';

const columns = [
  {
    name: 'code',
    label: 'Code',
    options: {
      customBodyRender: (value) => `000${value}`.substr(-3, 3),
      filterType: 'dropdown'
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filterType: 'dropdown'
    }
  },
  {
    name: 'type',
    label: 'Type',
    options: {
      filterType: 'dropdown'
    }
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false
    }
  }
];

interface IProps {
  [key: string]: any;
}

interface IStates {
  loading: any;
  showOutcomesPage: boolean;
  showManageModal: boolean;
  data: any[];
  rowSelected: any;
  editMode: boolean;
  modalStatus: boolean;
  alertSeverity: 'error' | 'info' | 'success' | 'warning' | undefined;
  alertMsg: string;
  showAlert: boolean;
}

export default class Outcomes extends Component<IProps, IStates> {
  static contextType = AuthContext;

  context!: ContextType<typeof AuthContext>;

  config: any;

  fetch_: any;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showOutcomesPage: false,
      showManageModal: false,
      data: [],
      rowSelected: null,
      editMode: false,
      modalStatus: false,
      alertSeverity: 'success',
      alertMsg: '',
      showAlert: false
    };
  }

  componentDidMount() {
    this.config = this.context.config;
    this.fetch_ = this.context.fetch_;

    this.setState({ loading: true });
    this.getOutcomes();
  }

  toggleAddOutcome = () => {
    this.setState({
      showManageModal: !this.state.showManageModal,
      rowSelected: null,
      editMode: false
    });
  };

  getOutcomes = async (): Promise<any> => {
    try {
      const res = await this.fetch_(
        `${this.config['CALL_SERVICE_HOST']}/getoutcomes/?tenantId=${process.env.REACT_APP_TENANT_ID}`
      );
      const result = await res.json();

      this.setState({
        data: result.data !== null ? result.data : [],
        loading: false
      });

      return result.data;
    } catch (e) {
      this.setState({
        loading: false,
        alertSeverity: 'error',
        showAlert: true
      });
    }
  };

  getOptions() {
    return {
      filterType: 'checkbox',
      selectableRows: 'single',
      responsive: 'vertical',
      textLabels: {
        body: {
          noMatch: ''
        }
      },
      sortOrder: {
        name: 'code',
        direction: 'asc'
      },
      rowsPerPageOptions: [10, 20, 50, 100],
      rowsPerPage: 10,
      rowsSelected: this.state.rowSelected,
      customToolbarSelect: (selectedRows, displayData) => {
        return (
          <Grid container direction="column" justify="flex-end" alignItems="flex-end">
            <Grid item xs={12}>
              <Tooltip title="Edit Call Outcome">
                <IconButton onClick={() => this.editOutcome(displayData[selectedRows.data[0].index])}>
                  <FontAwesomeIcon icon={faEdit} size="sm" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Call Outcome">
                <IconButton onClick={() => this.deleteOutcome(displayData[selectedRows.data[0].index])}>
                  <FontAwesomeIcon icon={faTrash} color="red" size="sm" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
      onRowsDelete: () => {
        this.setState({
          rowSelected: null
        });
      }
    };
  }

  deleteOutcome(outcome) {
    this.setState({
      modalStatus: true,
      rowSelected: outcome
    });
  }

  editOutcome(outcome) {
    this.setState({
      editMode: true,
      rowSelected: {
        objectId: outcome.data[0],
        code: parseInt(outcome.data[1]),
        name: outcome.data[2],
        type: outcome.data[3],
        description: outcome.data[4]
      },
      showManageModal: true
    });
  }

  handleClose(deleteOutcome) {
    if (deleteOutcome) {
      this.fetch_(
        `${this.config.CALL_SERVICE_HOST}/deleteoutcome/${this.state.rowSelected.data[0]}/?code=${this.state.rowSelected.data[1]}&tenantId=${process.env.REACT_APP_TENANT_ID}`,
        {
          method: 'DELETE'
        }
      )
        .then((res) => res.json())
        .then((msg) => {
          if (msg.response.statusCode === 500) {
            // failed to delete
            this.setState({
              alertMsg: 'Failed to delete outcome.',
              alertSeverity: 'error',
              showAlert: true
            });
          } else {
            this.setState({
              alertMsg: msg.response.msg,
              alertSeverity: 'success',
              showAlert: true
            });
          }
          this.getOutcomes();
        })
        .catch((e) => {
          this.setState({
            alertMsg: `Error deleting outcome: ${e}`,
            alertSeverity: 'error',
            showAlert: true
          });
        })
        .finally(() => {
          setTimeout(() => {
            this.setState({
              showAlert: false
            });
          }, 10000);
        });
    }
    this.setState({
      modalStatus: false
    });
  }

  render() {
    const { showManageModal, data, rowSelected, editMode, modalStatus, alertSeverity, alertMsg, showAlert, loading } =
      this.state;

    return (
      <div className="outcomes">
        {loading && <Loader />}
        {!loading && (
          <Button
            data-testid="add-outcome-button"
            className="outcomes__add"
            onClick={this.toggleAddOutcome}
            icon="faPlus"
          />
        )}
        {!loading && !data.length ? <EmptyPlaceholder error={showAlert && alertSeverity === 'error'} /> : null}
        {!loading && data.length ? (
          <div data-tour="outcomes" className="panel">
            <TourControl />
            <DataTable
              data={data}
              columns={columns}
              options={this.getOptions()}
              title={<Alert show={showAlert} severity={alertSeverity} message={alertMsg} />}
            />
          </div>
        ) : null}
        <ManageOutcomesModal
          getOutcomes={this.getOutcomes}
          editMode={editMode}
          rowSelected={rowSelected}
          toggleAddOutcome={this.toggleAddOutcome}
          outcomes={data}
          show={showManageModal}
        />
        <Dialog
          open={modalStatus}
          keepMounted
          onClose={() => this.handleClose(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Delete Call Outcome</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to delete this outcome? For an outcome to be deleted, no campaign must be using it.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose(false)} styleType="SECONDARY">
              Cancel
            </Button>
            <Button onClick={() => this.handleClose(true)}>Continue</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
