import { ReactComponent as ErrorImage } from 'assets/vectors/error.svg';

type TError = {
  show: boolean;
};

export default function Error({ show }: TError) {
  if (!show) {
    return null;
  }

  return (
    <div className="customer-profile__error">
      <ErrorImage />
      <div className="customer-profile__error__text">
        <h2>Something went wrong.</h2>
        <p>Try reloading the page.</p>
      </div>
    </div>
  );
}
