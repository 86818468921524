import React, { MouseEventHandler } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClickableIcon } from 'lib/common/components';
import {
  faArrowsRotate,
  faCog,
  faCopy,
  faEdit,
  faGrid2Plus,
  faPlus,
  faTrash
} from '@fortawesome/pro-regular-svg-icons';
import { RequiresPermission } from 'lib/common/components';
import '../styles/actionMenu.scss';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

export default function ActionMenu(props: any) {
  const { actionMenuSelect } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null);

  const handleClick: MouseEventHandler<SVGSVGElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
    const { myValue } = event.currentTarget.dataset;
    actionMenuSelect(myValue);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="action-menu" className="action-menu" data-testid="action-menu">
      <ClickableIcon
        className="dashboards__actions__action"
        tooltip="Dashboard settings"
        onClick={handleClick}
        icon={faCog}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="action-menu__menu"
      >
        <RequiresPermission permissionType="user" permission="adddb">
          <StyledMenuItem onClick={handleSelect} data-testid="add-dashboard-menu-option" data-my-value="1">
            <ListItemText primary="New Board" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faPlus} />
            </ListItemIcon>
          </StyledMenuItem>
        </RequiresPermission>

        <RequiresPermission permissionType="user" permission="duplicate">
          <StyledMenuItem onClick={handleSelect} data-my-value="4" data-testid="duplicate-dashboard-menu-option">
            <ListItemText primary="Duplicate" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faCopy} />
            </ListItemIcon>
          </StyledMenuItem>
        </RequiresPermission>

        <RequiresPermission permissionType="user" permission="edit">
          <StyledMenuItem onClick={handleSelect} data-my-value="5" data-testid="edit-dashboard-menu-option">
            <ListItemText primary="Edit Board" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faEdit} />
            </ListItemIcon>
          </StyledMenuItem>
        </RequiresPermission>

        <RequiresPermission permissionType="user" permission="addwidgets">
          <StyledMenuItem
            disabled={props.hasNoMetricsGroups}
            onClick={handleSelect}
            data-my-value="6"
            data-testid="add-widget-menu-option"
          >
            <ListItemText primary="Add Widget" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faGrid2Plus} />
            </ListItemIcon>
          </StyledMenuItem>
        </RequiresPermission>

        <StyledMenuItem onClick={handleSelect} data-my-value="8">
          <ListItemText primary="Refresh Rate" />
          <ListItemIcon>
            <FontAwesomeIcon icon={faArrowsRotate} />
          </ListItemIcon>
        </StyledMenuItem>

        <RequiresPermission permissionType="user" permission="delete">
          <StyledMenuItem
            onClick={handleSelect}
            data-my-value="7"
            disabled={props.isLastDashboard}
            data-testid="delete-dashboard-menu-option"
          >
            <ListItemText primary="Delete" />
            <ListItemIcon>
              <FontAwesomeIcon icon={faTrash} color="red" />
            </ListItemIcon>
          </StyledMenuItem>
        </RequiresPermission>
      </StyledMenu>
    </div>
  );
}
