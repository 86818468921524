import { Button } from 'lib/common/components';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import TUser from 'lib/common/types/User';
import TaskFooter from '../TaskFooter';
import TaskFooterRow from '../TaskFooterRow';
import TransferFooterButtons from '../TransferFooterButtons';

import styles from './agent-task-footer.module.scss';

interface IAgentTaskProps {
  taskId: string;
  connectionValue: string;
  user: TUser;
  isSoftphone?: boolean;
}

export default function AgentTask({ taskId, connectionValue, user, isSoftphone }: IAgentTaskProps) {
  const {
    actions: { hangupAgent }
  } = useContactContext();

  return (
    <TaskFooter>
      <TaskFooterRow isSingleRow className={styles['agent-task-footer__row']}>
        <TransferFooterButtons
          taskId={taskId}
          connectionValue={connectionValue}
          user={user}
          isSoftphone={isSoftphone}
        />
        <Button
          round
          onClick={() => hangupAgent(taskId)}
          icon="faCheck"
          type="PRIMARY"
          tooltip="Close the task"
          large
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
      </TaskFooterRow>
    </TaskFooter>
  );
}
