import io from 'socket.io-client';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { AuthContext } from 'lib/core/context/AuthProvider';
import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';
import { useAgentContext } from '../AgentContext';
import { useContactContext } from '../ContactContext';
import { usePermissionsContext } from '../PermissionsContext';
import storeAgentStatus from './api/storeAgentStatus';
import useUnload from './hooks/useUnload';
import getMappedConnectState from './utils/getMappedConnectState';

interface IAgentPresenceNextState {
  name: string;
}

type PresenceContextType = {
  agentPresence: PRESENCE_STATES;
  setConnectPresence: (presence: PRESENCE_STATES) => void;
  isBusyFromConnect: boolean;
  agentStates: connect.AgentStateDefinition[];
  agentNextState?: connect.AgentState;
  agentPresenceNextState?: IAgentPresenceNextState;
  isRemotelyBusy: boolean;
};

let socket;

const SOCKET_IO_TRANSPORTS = ['websocket', 'polling', 'flashsocket'];

const EVENTS = {
  REMOTE_PRESENCE_CHANGE: 'PRESENCE_CHANGE',
  REMOTE_ERROR: 'ERROR',
  CONNECT_ERROR: 'connect_error'
};

const REMOTE_UPDATE_INTERVAL_S = 120;

const Context = createContext<PresenceContextType>({
  setConnectPresence: () => {},
  agentPresence: PRESENCE_STATES.OFFLINE,
  isBusyFromConnect: false,
  agentStates: [],
  agentNextState: undefined,
  agentPresenceNextState: undefined,
  isRemotelyBusy: false
});

export default function PresenceContext({ children }) {
  const { agent } = useAgentContext();
  const {
    state: { tasks }
  } = useContactContext();

  const { hasPermission } = usePermissionsContext();
  const [connectState, setConnectState] = useState<PRESENCE_STATES | null>(null);
  const [agentNextState, setAgentNextState] = useState<connect.AgentState | undefined>(
    connectGetter('getNextState', agent)
  );
  const [agentPresenceNextState, setAgentPresenceNextState] = useState<IAgentPresenceNextState | undefined>();

  const [remotePresences, updateRemotePresences] = useState({});
  const [storeStatusTrigger, setStoreStatusTrigger] = useState<null | number>(null);

  const { fetch_: fetch, tokens, config, email }: any = useContext(AuthContext);

  const emailAddress = email || (connectGetter('getConfiguration', agent) || {}).username;

  const isBusyFromConnect = Boolean(connectState === PRESENCE_STATES.BUSY && tasks.length);

  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_MISSED_CALL
  });

  const isRemotelyBusy = Object.values(remotePresences).some(
    (remotePresence) => remotePresence === PRESENCE_STATES.BUSY
  );

  const onConnectPresenceChange = ({ agent, newState }) => {
    if (!tasks.length || newState === PRESENCE_STATES.AVAILABLE) {
      setAgentNextState(undefined);
    }

    if (!newState || newState === connectState) {
      return;
    }

    setConnectState(getMappedConnectState(newState));
    setStoreStatusTrigger(Date.now());
    setAgentNextState(connectGetter('getNextState', agent) || undefined);
  };

  const onRemotePresenceChange = useCallback(
    (payload) => {
      const { type, presence: remotePresence } = JSON.parse(payload);
      const previousRemotePresence = remotePresences[type];

      if (!remotePresence || remotePresence === previousRemotePresence) {
        return;
      }

      const updatedRemotePresences = { ...remotePresences, [type]: remotePresence };
      updateRemotePresences(updatedRemotePresences);

      /**
       * If remote status is busy and there is no previous remote presence
       * set next state to offline
       */
      if (
        !previousRemotePresence &&
        Object.values(updatedRemotePresences).some((remotePresence) => remotePresence === PRESENCE_STATES.BUSY)
      ) {
        setAgentPresenceNextState({ name: PRESENCE_STATES.OFFLINE });
      }

      const remoteCallIncoming = connectState === PRESENCE_STATES.AVAILABLE && remotePresence === PRESENCE_STATES.BUSY;
      const remoteCallOver =
        connectState === PRESENCE_STATES.BUSY &&
        (remotePresence === PRESENCE_STATES.AVAILABLE || remotePresence === PRESENCE_STATES.OFFLINE);

      // If on a connect call, do nothing
      if (isBusyFromConnect || (!remoteCallIncoming && !remoteCallOver)) {
        return;
      }

      setConnectPresence(remoteCallIncoming ? PRESENCE_STATES.BUSY : PRESENCE_STATES.AVAILABLE);
    },
    [remotePresences, connectState, isBusyFromConnect]
  );

  const setConnectPresence = async (presence) => {
    if (!agent) {
      return;
    }

    const agentStates = connectGetter('getAgentStates', agent);
    const agentState = agentStates?.find((state) => state?.name === presence);

    if (!agentState) {
      return;
    }

    try {
      // This sends the request and returns success if the request is done. Then the server handles it async.
      await connectAction(
        'setState',
        agent,
        agentState,
        undefined,
        `We couldn't change your status to ${agentState.name}`,
        { enqueueNextState: true }
      );

      const currentState = getMappedConnectState(agentState.name as PRESENCE_STATES);

      if (currentState === PRESENCE_STATES.AVAILABLE) {
        setAgentNextState(undefined);
      }

      if (currentState === connectState) {
        return;
      }

      setConnectState(currentState);
      setStoreStatusTrigger(Date.now());
    } catch {}
  };

  useUnload({ fetch, tokens, config });

  useEffect(() => {
    if (isRemotelyBusy || !agentPresenceNextState) {
      return;
    }

    setAgentPresenceNextState(undefined);
  }, [agentPresenceNextState, isRemotelyBusy]);

  useEffect(() => {
    if (!isRemotelyBusy || !connectState) {
      return;
    }

    const busyOrAvailableState = [PRESENCE_STATES.AVAILABLE, PRESENCE_STATES.BUSY].includes(connectState);

    if (isRemotelyBusy && !busyOrAvailableState) {
      return setAgentPresenceNextState({ name: connectState });
    }

    if (isRemotelyBusy && busyOrAvailableState) {
      setAgentPresenceNextState(undefined);
    }

    setConnectPresence(PRESENCE_STATES.BUSY);
  }, [connectState]);

  // The interval will update the value of storeStatusTrigger, which will cause this effect to run and store our agent status
  useEffect(() => {
    if (!storeStatusTrigger) {
      return;
    }

    storeAgentStatus({
      status: connectState,
      fetch,
      tokens,
      config
    });
  }, [storeStatusTrigger]);

  useEffect(() => {
    if (!agent) {
      return;
    }

    // Set initial status
    const agentStatus = connectGetter('getState', agent)?.name;

    if (!agentStatus) {
      return;
    }

    setConnectState(getMappedConnectState(agentStatus as PRESENCE_STATES));
    setStoreStatusTrigger(Date.now());

    agent.onStateChange(onConnectPresenceChange);
    agent.onEnqueuedNextState(() => setAgentNextState(connectGetter('getNextState', agent)));
  }, [agent]);

  useEffect(() => {
    if (!tokens?.idToken?.jwtToken || socket || !emailAddress) {
      return;
    }

    socket = io(config.PRESENCE_URL, {
      transports: SOCKET_IO_TRANSPORTS,
      auth: { token: tokens.idToken.jwtToken },
      query: { emailAddress }
    });
  }, [tokens?.idToken?.jwtToken, emailAddress]);

  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.contact((contact) => {
      contact.onMissed(() => {
        if (hasMissedCallPermission) {
          return;
        }

        setConnectPresence(PRESENCE_STATES.AVAILABLE);
      });
    });

    const statusInterval = setInterval(() => {
      setStoreStatusTrigger(Date.now());
    }, REMOTE_UPDATE_INTERVAL_S * 1000);

    return () => {
      clearInterval(statusInterval);
    };
  }, []);

  // Setup intervals and sockets
  useEffect(() => {
    if (!socket || !connectState || !agent) {
      return;
    }

    socket.on(EVENTS.CONNECT_ERROR, console.error);
    socket.on(EVENTS.REMOTE_ERROR, console.error);
    socket.off(EVENTS.REMOTE_PRESENCE_CHANGE);
    socket.on(EVENTS.REMOTE_PRESENCE_CHANGE, onRemotePresenceChange);
  }, [socket, connectState, remotePresences]);

  return (
    <Context.Provider
      value={{
        setConnectPresence,
        agentPresence: connectState || PRESENCE_STATES.OFFLINE,
        isBusyFromConnect,
        agentStates: connectGetter('getAgentStates', agent) || [],
        agentNextState,
        agentPresenceNextState,
        isRemotelyBusy
      }}
    >
      {children}
    </Context.Provider>
  );
}

// Export the context as a HOC
export const { Consumer: PresenceConsumer, Provider: PresenceProvider } = Context;

// export the context hook
export const usePresenceContext = () => useContext(Context);
