import { faUser, faBuilding, faMobileButton } from '@fortawesome/pro-regular-svg-icons';

enum NUMBER_TYPES {
  MOBILE = 'Mobile',
  PERSONAL = 'Personal',
  COMPANY = 'Company'
}

export const ICON_MAP = {
  [NUMBER_TYPES.MOBILE]: faMobileButton,
  [NUMBER_TYPES.PERSONAL]: faUser,
  [NUMBER_TYPES.COMPANY]: faBuilding
};

export const NUMBER_TYPE_OPTIONS = Object.values(NUMBER_TYPES).map((option) => ({ value: option, label: option }));
