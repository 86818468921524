import { faInboxIn, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import Collapse from '@kunukn/react-collapse';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ClickableIcon, Icon, TaskActions } from 'lib/common/components/index';
import getUserName from 'lib/common/utils/getUserName';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import { TASK_TYPES } from 'lib/common/constants/taskStates';
import ChatTask from 'lib/common/types/ChatTask';
import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';
import Task from 'lib/common/types/Task';
import VARS from 'css/export-vars.module.scss';
import ContactAttributes from './components/ContactAttributes';

import './task-overlay-banner.scss';

type TTaskOverlayBanner = {
  task: ChatTask | Task;
  attributes?: Record<string, ContactAttributesValue>;
  showExpandAction?: boolean;
  overlayOpen?: boolean;
  showQueueDetails?: boolean;
  showContactAttributes?: boolean;
  onOverlayOpen?: (taskId: string) => void;
  isSoftphone?: boolean;
};

export default function TaskOverlayBanner({
  task,
  attributes,
  showExpandAction = false,
  overlayOpen = false,
  showQueueDetails = false,
  showContactAttributes = false,
  onOverlayOpen,
  isSoftphone
}: TTaskOverlayBanner) {
  const isSmallSoftphone = useMediaQuery({ query: `(max-width: ${VARS.softphoneSmallWidth})` });

  const [open, setIsOpen] = useState(false);
  const time = useTaskTimer(task?.time);

  useEffect(() => {
    setIsOpen(overlayOpen);
  }, [overlayOpen]);

  const onExpand = () => {
    setIsOpen(!open);

    if (open || !onOverlayOpen) {
      return;
    }

    onOverlayOpen(task.taskId);
  };

  if (!task) {
    return null;
  }

  const taskProps = TASK_TYPES(task.connectionType)?.[task.status]?.[task.type] || {};
  const userName = task.profile?.firstName ? getUserName(task?.profile) : task?.connectionValue;
  const { queueName } = task;

  const hasQueue = showQueueDetails && queueName;
  const showInlineButtons = !isSoftphone || (!isSmallSoftphone && !hasQueue);

  return (
    <div data-testid={`task-overlay-banner-${task.taskId}`} className="task-overlay-banner">
      <div
        className={cx('task-overlay-banner__header', {
          'task-overlay-banner__header--open': open,
          'task-overlay-banner__header--closed': !open
        })}
      >
        <Icon color="white" icon={taskProps.icon} size={isSoftphone ? 15 : 20} />
        <div className="task-overlay-banner__header__text">
          <h2>{userName}</h2>
          {isSoftphone ? (
            <div className="task-overlay-banner__header__text__connection task-overlay-banner__header__text__connection--softphone">
              <span className="task-overlay-banner__header__text__time">{time}</span>
              <span>
                {hasQueue && (
                  <>
                    |
                    <span className="task-overlay-banner__header__text__queue task-overlay-banner__header__text__queue--softphone">
                      <span>
                        <Icon color="darkGrey" icon={faInboxIn} size={12} />
                      </span>
                      <span>{queueName}</span>
                    </span>
                  </>
                )}
              </span>
            </div>
          ) : (
            <>
              <div className="task-overlay-banner__header__text__connection">
                <span>
                  <strong>{taskProps.subtitle}</strong>
                </span>
                <span>| {time}</span>
              </div>
              {hasQueue && (
                <div className="task-overlay-banner__header__text__queue">
                  <span>
                    <Icon color="darkGrey" icon={faInboxIn} size={12} />
                  </span>
                  <span>{queueName}</span>
                </div>
              )}
            </>
          )}
        </div>

        {showInlineButtons && (
          <div className={cx('task-overlay-banner__actions', 'task-overlay-banner__actions--inline')}>
            {task && <TaskActions {...task} round />}
            {showExpandAction && (
              <ClickableIcon
                data-testid={`task-overlay-banner-actions-expand-${task.taskId}`}
                color="primary"
                size={20}
                icon={open ? faMinus : faPlus}
                onClick={onExpand}
              />
            )}
          </div>
        )}
      </div>

      {!showInlineButtons && (
        <>
          <hr />
          <div className="task-overlay-banner__actions">{task && <TaskActions {...task} />}</div>
        </>
      )}

      {showContactAttributes && (
        <>
          <hr />
          <Collapse
            className={cx('task-overlay-banner__attributes', {
              'task-overlay-banner__attributes--open': open
            })}
            transition={`height 300ms cubic-bezier(.4, 0, .2, 1)`}
            isOpen={open}
          >
            <ContactAttributes attributes={attributes} />
          </Collapse>
        </>
      )}
    </div>
  );
}
