import connectAction from 'lib/common/utils/connectAction';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import parseJSON from 'lib/common/utils/parseJSON';
import { CONTACT_NOT_FOUND_EXCEPTION } from 'lib/common/constants/connectExceptions';

export default async function handleACWContact({ contact, hasACWPermission, handleContactChange }) {
  if (!hasACWPermission) {
    try {
      return await connectAction('clear', contact);
    } catch (e: any) {
      const { type } = parseJSON(e) || {};

      if (type !== CONTACT_NOT_FOUND_EXCEPTION) {
        return;
      }

      return handleContactChange({ contact, isDestroy: true });
    }
  }

  handleContactChange({
    contact,
    status: CONTACT_STATES.ACW
  });
}
