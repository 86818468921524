import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_TYPE from 'lib/common/constants/connectionType';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import COLOURS from 'css/export-vars.module.scss';
import {
  faCheckSquare,
  faCheckToSlot,
  faComment,
  faCommentCheck,
  faCommentXmark,
  faPhone,
  faPhoneArrowDown,
  faPhoneHangup,
  faPhoneMissed,
  faPhoneArrowUp,
  faUsers,
  faCalendarClock
} from '@fortawesome/pro-regular-svg-icons';
import CONNECTION_TYPES from './connectionType';

export const TASK_COLOURS = {
  [CONTACT_STATES.MISSED]: 'danger',
  [CONTACT_STATES.REJECTED]: 'danger',
  [CONTACT_STATES.CONNECTING]: 'darkBlue',
  [CONTACT_STATES.CONNECTED]: 'darkBlue',
  [CONTACT_STATES.ACW]: 'darkBlue'
};

const getContactPropsPerConnectionType = {
  [CONNECTION_TYPE.INBOUND]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Incoming Call` },
    [CONTACT_TYPES.QUEUE_CALLBACK]: {
      icon: faCalendarClock,
      subtitle: 'Incoming Callback'
    },
    [CONTACT_TYPES.CHAT]: { icon: faComment, subtitle: `Incoming Chat` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Incoming Agent Task` },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Connecting Transfer' }
  },
  [CONNECTION_TYPE.AGENT]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Incoming Call` },
    [CONTACT_TYPES.CHAT]: { icon: faComment, subtitle: `Incoming Chat` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Incoming Agent Task` }
  },
  [CONNECTION_TYPE.MONITORING]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowDown, subtitle: `Incoming Call` },
    [CONTACT_TYPES.CHAT]: { icon: faComment, subtitle: `Incoming Chat` },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: `Incoming Task` }
  },
  [CONNECTION_TYPE.OUTBOUND]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneArrowUp, subtitle: `Outgoing Call` },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Connecting Transfer' }
  }
};

export const TASK_TYPES = (connectionType: ValueOf<typeof CONNECTION_TYPES>) => ({
  [CONTACT_STATES.MISSED]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneMissed, subtitle: 'Missed Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhoneMissed, subtitle: 'Missed Callback' },
    [CONTACT_TYPES.CHAT]: { icon: faCommentXmark, subtitle: 'Missed Chat' },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Missed Agent Task' }
  },
  [CONTACT_STATES.CONNECTING]: getContactPropsPerConnectionType[connectionType],
  [CONTACT_STATES.ACCEPTED]: getContactPropsPerConnectionType[connectionType],
  [CONTACT_STATES.ACW]: {
    [CONTACT_TYPES.CALL]: { icon: faPhoneHangup, subtitle: 'After Call Work' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhoneHangup, subtitle: 'After Call Work' },
    [CONTACT_TYPES.CHAT]: { icon: faCommentCheck, subtitle: 'After Chat Work' },
    [CONTACT_TYPES.TASK]: { icon: faCheckToSlot, subtitle: 'After Task Work' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'After Call Work' }
  },
  [CONTACT_STATES.CONNECTED]: {
    [CONTACT_TYPES.CALL]: { icon: faPhone, subtitle: 'Connected Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhone, subtitle: 'Connected Call' },
    [CONTACT_TYPES.CHAT]: { icon: faComment, subtitle: 'Connected Chat' },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Active Agent Task' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Connected Transfer' }
  },
  [CONTACT_STATES.REJECTED]: {
    [CONTACT_TYPES.CALL]: { icon: faPhone, subtitle: 'Rejected Call' },
    [CONTACT_TYPES.QUEUE_CALLBACK]: { icon: faPhone, subtitle: 'Rejected Callback' },
    [CONTACT_TYPES.CHAT]: { icon: faComment, subtitle: 'Rejected Chat' },
    [CONTACT_TYPES.TASK]: { icon: faCheckSquare, subtitle: 'Rejected Agent Task' },
    [CONTACT_TYPES.CONFERENCE_CALL]: { icon: faUsers, subtitle: 'Rejected Transfer' }
  }
});
