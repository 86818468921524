import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import '../styles/create-agent-task.scss';

export default function TaskDateTime({ scheduledDateTime, handleDateTimeChange, invalidDateTimeError, isSoftphone }) {
  return (
    <div className={cx('create-agent-task__date-time', { 'create-agent-task--padding': isSoftphone })}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          inputVariant="outlined"
          label="Date"
          value={scheduledDateTime}
          onChange={(newValue) => handleDateTimeChange(newValue, 'date')}
          disablePast
          format="dd/MM/yyyy"
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          keyboardIcon={<Icon size={20} icon={faCalendar} color="midGrey" />}
          className="create-agent-task__date-time__date"
          invalidDateMessage={<></>}
          minDateMessage={<></>}
          error={!!invalidDateTimeError}
          inputProps={{ 'data-testid': 'create-task-date-input' }}
        />
        <KeyboardTimePicker
          inputVariant="outlined"
          label="Time"
          mask="__:__ _M"
          value={scheduledDateTime}
          onChange={(newValue) => handleDateTimeChange(newValue, 'time')}
          className="create-agent-task__date-time__time"
          invalidDateMessage={<></>}
          keyboardIcon={<></>}
          KeyboardButtonProps={{ disabled: true }}
          error={!!invalidDateTimeError}
          inputProps={{ 'data-testid': 'time-picker' }}
        />
      </MuiPickersUtilsProvider>
      {invalidDateTimeError && (
        <Text color="danger" type="helper">
          {invalidDateTimeError}
        </Text>
      )}
    </div>
  );
}
