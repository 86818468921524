import { useRef, useState } from 'react';
import { TextField } from '@material-ui/core';

import getUserName from 'lib/common/utils/getUserName';
import { TaskFooter, ChatMessages } from 'lib/common/components/molecules';
import { Accordion, Button } from 'lib/common/components';
import TChatTask from 'lib/common/types/ChatTask';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';

import TUser from 'lib/common/types/User';
import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';
import styles from './chat-task.module.scss';

type TChat = TChatTask & {
  minimised?: boolean;
  user: TUser;
};

const ChatTask = (props: TChat) => {
  const { profile, queueName, time, connectionValue, taskId, messages, customerTyping, minimised, status, user } =
    props;
  const customerName = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const [message, setMessage] = useState('');
  const [typing, setTyping] = useState(false);
  const [sending, setSending] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const messageInputRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<any>(null);

  const {
    actions: { sendMessage, sendTypingEvent }
  } = useContactContext();

  const onChangeInputMessage = ({ target: { value } }) => {
    setMessage(value);

    if (typing) {
      return;
    }

    sendTypingEvent(taskId);
    setTyping(true);
  };

  const onSendMessage = async () => {
    if (!message) {
      return;
    }

    setSending(true);
    setTyping(false);

    await sendMessage(taskId, message);

    setMessage('');
  };

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row
          text={customerName}
          id={customerName}
          badgeProps={{
            status
          }}
        />
      </InfoPanel>
      <Accordion title="Conversation" className="mt-10">
        <div className={styles['chat-task']}>
          <ChatMessages
            messages={messages}
            customerName={customerName}
            customerTyping={customerTyping}
            scrollContainerRef={scrollContainerRef}
          />
          {!minimised && (
            <>
              <div className={styles['chat-task__send']}>
                <TextField
                  className={styles['chat-task__send__input']}
                  variant="outlined"
                  placeholder="Type a message"
                  value={message}
                  onChange={onChangeInputMessage}
                  inputRef={messageInputRef}
                  disabled={sending}
                  onKeyDown={onEnterKeyPressed(() => submitButtonRef?.current?.click())}
                  data-testid="chat-message-input"
                />
                <Button
                  className={styles['chat-task__send__button']}
                  ref={submitButtonRef}
                  icon="faPaperPlane"
                  disabled={!message}
                  onClick={onSendMessage}
                  asyncAction
                  onFinally={() => {
                    setSending(false);
                    messageInputRef?.current?.focus();
                  }}
                  successTimeoutSeconds={0}
                  data-testid="send-chat-message-button"
                />
              </div>
            </>
          )}
        </div>
      </Accordion>
      <AttachedData task={props} />
      <TaskFooter.Chat taskId={taskId} connectionValue={connectionValue} user={user} isSoftphone />
    </>
  );
};

export default ChatTask;
