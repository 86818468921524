import helpUrl from 'lib/common/constants/helpUrl';

export default {
  'Agent Workspace': [
    {
      title: 'Agent Workspace',
      uri: `${helpUrl}help/category/neon-dashboard/features/agent-workspace/`
    },
    {
      title: 'Hotkeys',
      uri: `${helpUrl}help/hotkeys/`
    }
  ],
  Dashboard: [
    {
      title: 'The Dashboard',
      uri: `${helpUrl}help/the-dashboard/`
    },
    {
      title: 'Agent - Quick Start Guide',
      uri: `${helpUrl}help/agent-quick-start/`
    },
    {
      title: 'Admin – Quick Start Guide',
      uri: `${helpUrl}help/neon-settings-quick-start/`
    }
  ],
  'Interaction History': [
    {
      title: 'Interaction History',
      uri: `${helpUrl}help/call-history/`
    }
  ],
  Phonebook: [
    {
      title: 'The Phone Book',
      uri: `${helpUrl}help/the-phone-book/`
    }
  ],
  Dashboards: [
    {
      title: 'Dashboards',
      uri: `${helpUrl}help/tailored-dashboard/`
    }
  ],
  Performance: [
    {
      title: 'Wallboard',
      uri: `${helpUrl}help/perfomance/`
    }
  ],
  Admin: [
    {
      title: 'Admin Overview',
      uri: `${helpUrl}help/settings-overview/`
    }
  ],
  'Admin users': [
    {
      title: 'Users',
      uri: `${helpUrl}help/neon-users/`
    }
  ],
  'Admin metrics-config': [
    {
      title: 'Metrics Config',
      uri: `${helpUrl}help/metrics-config/`
    }
  ],
  'Admin campaigns': [
    {
      title: 'Campaigns',
      uri: `${helpUrl}help/campaigns/`
    }
  ],
  'Admin outcomes': [
    {
      title: 'Interaction Outcomes',
      uri: `${helpUrl}help/call-outcomes/`
    }
  ]
};
