import cx from 'classnames';
import React, { forwardRef, ReactNode } from 'react';
import { useBooleanState } from 'webrix/hooks';
import { LIGHTEST } from '../constants/dashboardBgColours';
import EditWidget from './EditWidget';
import Module from './Module';

interface IWidget {
  className?: string;
  dashboard;
  updateTime: number;
  widget: string;
  metricsGroups: {
    name: string;
    objectId: string;
  }[];
  changeWidget: (...args: any) => any;
  refresh: number;

  // This is the react-grid-layout resize handle
  children?: ReactNode;

  // These come from react-grid-layout
  gridProps?: any;
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
}

function Widget(
  {
    className,
    dashboard,
    updateTime,
    widget,
    metricsGroups,
    changeWidget,
    refresh,
    children: gridChild,
    config,
    fetch,
    ...gridProps
  }: IWidget,
  ref
) {
  const { value: hovered, setTrue: setHovered, setFalse: setNotHovered } = useBooleanState();

  const resizing = className?.includes('resizing');

  return (
    <div
      className={cx(
        className,
        `panel--small${dashboard.backgroundcolor === LIGHTEST.toLowerCase() ? '-elevated' : ''}`,
        'widget-module',
        dashboard.theme
      )}
      data-testid="dashboard-widget"
      data-time-stamp={updateTime}
      ref={ref}
      onMouseOver={setHovered}
      onMouseLeave={setNotHovered}
      {...gridProps}
    >
      {(hovered || resizing) && (
        <EditWidget
          changeWidget={changeWidget}
          widgetIndex={widget}
          widgetid={dashboard.components[widget]._uid.toString()}
          componentjson={dashboard.components[widget]}
          queues={metricsGroups}
          onClose={setNotHovered}
        />
      )}
      <Module block={dashboard.components[widget]} refresh={refresh} config={config} fetch={fetch} />
      {gridChild}
    </div>
  );
}

export default forwardRef(Widget);
