import { useMemo, useState, createContext, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DraggableOverlay, DraggableOverlaySize } from 'lib/common/components';
import { Directory } from 'lib/common/components/molecules';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { usePermissionsContext } from '../PermissionsContext';

interface DirectoryOptions {
  selectedTabIndex?: number;
  isDirectoryTransfer?: boolean;
  isDialPadTransfer?: boolean;
}

const DIRECTORY_ID = 'directory-overlay';
const DIRECTORY_ID_VISIBLE = 'directory-overlay-visible';

type DirectoryContextType = {
  openDirectory: (options?: DirectoryOptions) => void;
  closeDirectory: () => void;
  initialSelectedTab: number;
  isDirectoryOverlayOpen: boolean;
};

const Context = createContext<DirectoryContextType>({
  openDirectory: () => void 0,
  closeDirectory: () => void 0,
  initialSelectedTab: 0,
  isDirectoryOverlayOpen: false
});

export default function DirectoryContext({ children, user }) {
  const { hasPermission } = usePermissionsContext();
  const history = useHistory();

  const [isDirectoryOverlayOpen, setDirectoryOverlayOpen] = useState(
    Boolean(localStorage.getItem(DIRECTORY_ID_VISIBLE))
  );
  const [initialSelectedTab, setInitialSelectedTab] = useState(
    hasPermission({ type: 'tenant', permission: PERMISSIONS.PERSONAL_CONTACTS }) ? 0 : 1
  );
  const [isDirectoryTransfer, setDirectoryTransfer] = useState(false);
  const [isDialPadTransfer, setDialPadTransfer] = useState(false);

  const openDirectory = (options) => {
    const { selectedTabIndex = 0, isDirectoryTransfer = false, isDialPadTransfer = false } = options || {};

    setInitialSelectedTab(selectedTabIndex);
    setDirectoryTransfer(isDirectoryTransfer);
    setDialPadTransfer(isDialPadTransfer);

    localStorage.setItem(DIRECTORY_ID_VISIBLE, 'true');
    setDirectoryOverlayOpen(true);
  };

  const closeDirectory = () => {
    localStorage.removeItem(DIRECTORY_ID_VISIBLE);
    setDirectoryOverlayOpen(false);
  };

  const directoryTitle = useMemo(() => {
    if (isDirectoryTransfer) {
      return 'Transfer';
    }

    if (isDialPadTransfer) {
      return 'Dialpad';
    }

    return 'Directory';
  }, [isDirectoryTransfer, isDialPadTransfer]);

  return (
    <Context.Provider value={{ openDirectory, closeDirectory, initialSelectedTab, isDirectoryOverlayOpen }}>
      <>
        {children}
        <DraggableOverlay
          title={directoryTitle}
          open={isDirectoryOverlayOpen}
          id={DIRECTORY_ID}
          onClose={closeDirectory}
          size={DraggableOverlaySize.SMALL}
          triggerSelector=".header__directory-icon"
        >
          {isDirectoryOverlayOpen && (
            <Directory
              user={user}
              initialSelectedTab={initialSelectedTab}
              isDirectoryTransfer={isDirectoryTransfer}
              isDialPadTransfer={isDialPadTransfer}
              onClose={closeDirectory}
              pushToTask={history.push}
            />
          )}
        </DraggableOverlay>
      </>
    </Context.Provider>
  );
}

// Export the context as a HOC
export const { Consumer: DirectoryConsumer, Provider: DirectoryProvider } = Context;

// export the context hook
export const useDirectoryContext = () => useContext(Context);
