import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Drawer, CssBaseline, List } from '@material-ui/core';
import { far, faPage } from '@fortawesome/pro-regular-svg-icons';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import useLayout from 'lib/common/contexts/LayoutContext';
import { ADMIN_NAV_OPTIONS } from 'lib/common/constants/adminNavOptions';
import { DEFAULT_NAV_OPTIONS } from 'lib/common/constants/defaultNavOptions';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import SidebarMenuItem from './SidebarMenuItem';
import SidebarHotkey from './SidebarHotkey';

import './sidebar.scss';

const drawerWidth = '250px';

const useStyles = ({ relative }) =>
  makeStyles((theme: Theme) => {
    return createStyles({
      root: {
        display: 'flex',
        zIndex: 995,
        position: 'sticky',
        top: 55,
        marginLeft: relative ? 20 : 0,
        height: 'calc(100vh - 55px)'
      },
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'relative',
        paddingBottom: 15,
        paddingTop: 15,
        height: '100%'
      },
      drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        background: 'white',
        borderRight: 0,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;',
        position: 'relative',
        height: '100%'
      },
      drawerClose: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: 60,
        [theme.breakpoints.up('sm')]: {
          width: 60
        },
        background: 'white',
        borderRight: 0,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;',
        position: 'relative'
      },
      toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 5px'
      },
      navList: {
        width: drawerWidth
      },
      menuItem: {
        marginBottom: 20
      }
    });
  });

export default function Sidebar(props) {
  const {
    state: { pageModules }
  } = useModulesContext();
  const classes = useStyles({ relative: props.relative })();
  const { isSidebarOpen } = useLayout();
  const { isAdmin } = usePermissionsContext();
  const [navOptions, setNavOptions] = useState<any>(DEFAULT_NAV_OPTIONS(isAdmin));
  const [navLoaded, setNavLoaded] = useState(false);

  useEffect(() => {
    if (navLoaded) {
      return;
    }

    setNavOptions([
      ...navOptions,
      ...pageModules.map((pageModule: ConnectorModuleType) => ({
        link: `/${pageModule.pathName}`,
        name: pageModule.name,
        activeClassName: 'nav-active',
        icon: pageModule.icon ? far[pageModule.icon] || faPage : faPage
      })),
      ADMIN_NAV_OPTIONS
    ]);

    setNavLoaded(true);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={cx(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpen,
          [classes.drawerClose]: !isSidebarOpen
        })}
        classes={{
          paper: cx({
            [classes.drawerOpen]: isSidebarOpen,
            [classes.drawerClose]: !isSidebarOpen
          })
        }}
      >
        {navLoaded && (
          <List component="nav" disablePadding>
            {navOptions.map((item, index) => (
              <React.Fragment key={item.link}>
                <SidebarMenuItem {...item} userdata={props.user} isSidebarOpen={isSidebarOpen} />
                <SidebarHotkey
                  link={item.link}
                  pageHasSubMenu={item.pageHasSubMenu}
                  items={item.items}
                  hotkey={`${index + 1}`}
                />
              </React.Fragment>
            ))}
          </List>
        )}
      </Drawer>
    </div>
  );
}
