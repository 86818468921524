import normaliseAWSObjectKeys from '../../utils/customerProfiles/normaliseAWSObjectKeys';

type TSearchOptions = {
  config: any;
  fetch_: (url, options) => Promise<any>;
  value: string;
  type: string;
  maxResults?: number;
  rethrowError?: boolean;
};

const ONE = 1;
const NO_OF_RESULTS_TO_EVALUATE_FOR_BEST_RESULT = 30;

export default async function searchCustomerProfiles({
  config,
  fetch_,
  value,
  type,
  rethrowError,
  maxResults = ONE
}: TSearchOptions) {
  try {
    const response = await fetch_(
      `${config['AGENT_SERVICE_HOST']}/connect/${process.env.REACT_APP_TENANT_ID}/search-contact-profiles`,
      {
        method: 'POST',
        body: JSON.stringify({
          type,
          value,
          maxResults: maxResults === ONE ? NO_OF_RESULTS_TO_EVALUATE_FOR_BEST_RESULT : maxResults
        })
      }
    );

    const matched = await response.json();

    // single returned
    if (!Array.isArray(matched)) {
      return matched ? normaliseAWSObjectKeys(matched) : null;
    }

    if (!matched.length && maxResults === ONE) {
      return null;
    }

    const rawResult = matched.map((profile) => normaliseAWSObjectKeys(profile) || {});
    const results = sortCandidates(rawResult);

    if (maxResults === ONE) {
      return results[0];
    }

    return results.slice(0, maxResults);
  } catch (e) {
    if (rethrowError) {
      throw e;
    }

    return maxResults === ONE ? null : [];
  }
}

/*
 use this function to load more than 1 results and
  do a comparison to evaluate the top result when multiple are present.
  root cause is duplicated customer results, but this will fetch the most promising one as a temporary fix.
*/
const sortCandidates = (candidates) => {
  // sort candidates by how many values in object is not null
  const sorted = candidates.sort((a, b) => {
    const aKeys = Object.keys(a).filter((key) => !!a[key]);
    const bKeys = Object.keys(b).filter((key) => !!b[key]);
    return bKeys.length - aKeys.length;
  });

  return sorted;
};
