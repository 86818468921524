import 'what-input';
import { Route, Switch, withRouter } from 'react-router-dom';

// components
import { AuthorisedRoute, NotFound } from 'lib/common/components';

// contexts
import PermissionsContext from 'lib/common/contexts/PermissionsContext';

import { useIsolatedAuthContext } from 'lib/core/context/IsolatedAuthProvider';

import { PERMISSIONS } from 'lib/common/constants/permissions';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import DashboardsPage from 'lib/pages/Dashboards/containers/Dashboards';
import WallboardPage from 'lib/pages/Wallboard/Wallboard';
import { IsolatedLayout } from 'lib/common/layouts';
import { WithApiContexts } from '../common/components/atoms';

function Routes() {
  const { userData } = useIsolatedAuthContext();

  return (
    <IsolatedLayout>
      <PermissionsContext user={userData}>
        <Switch>
          <AuthorisedRoute
            exact
            permissionType="user"
            permission={PERMISSIONS.DASHBOARDS}
            path={PAGE_ROUTES.DASHBOARDS}
          >
            <WithApiContexts component={DashboardsPage} isIsolated user={userData} />
          </AuthorisedRoute>
          <AuthorisedRoute permission={PERMISSIONS.WALLBOARD} exact path={PAGE_ROUTES.WALLBOARD}>
            <WithApiContexts component={WallboardPage} isIsolated />
          </AuthorisedRoute>
          <Route component={NotFound} />
        </Switch>
      </PermissionsContext>
    </IsolatedLayout>
  );
}

export default withRouter(Routes);
