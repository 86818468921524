import { useState } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid, { GridJustification } from '@material-ui/core/Grid';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from 'lib/common/components/index';
import useStyles from '../../../pages/InteractionHistory/useStyles';

const DEFAULT_RANGE = 3; // 5 DAYS DEFAULT DATE RANGE
const MAX_RANGE = 7; // 7 DAYS MAX DATE RANGE

function getAdjustedDate(date, adjustment) {
  return new Date(date.setDate(date.getDate() + adjustment));
}

function getEndDateFromStartDate(date) {
  const today = new Date();
  const adjustedDate = getAdjustedDate(new Date(date), MAX_RANGE);

  return adjustedDate > today ? today : adjustedDate;
}

export default function DateSelector({
  onDateChange,
  justifyDirection = 'flex-end',
  defaultStartDate = getAdjustedDate(new Date(), -DEFAULT_RANGE),
  defaultEndDate
}: {
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onDateChange: (any) => void;
  justifyDirection?: GridJustification;
}) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate || getEndDateFromStartDate(startDate));

  const handleStartDateChange = (date) => {
    if (!date) {
      return;
    }

    const newEndDate = getEndDateFromStartDate(new Date(date));

    setStartDate(date);
    setEndDate(newEndDate);

    onDateChange({ start: date, end: newEndDate });
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      return;
    }

    setEndDate(date);

    onDateChange({ start: date, end: date });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        direction="row"
        justify={justifyDirection}
        alignItems="center"
        spacing={0}
        className={classes.grid}
      >
        <KeyboardDatePicker
          inputVariant="outlined"
          id="start-date-picker-dialog"
          inputProps={{
            'data-testid': 'start-date-picker-dialog'
          }}
          label="From"
          format="dd/MM/yyyy"
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          maxDate={new Date()}
          className={classes.datePicker}
          keyboardIcon={<Icon size={20} icon={faCalendar} color="midGrey" />}
        />
        <KeyboardDatePicker
          inputVariant="outlined"
          id="end-date-picker-dialog"
          inputProps={{
            'data-testid': 'end-date-picker-dialog'
          }}
          label="To"
          format="dd/MM/yyyy"
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          minDate={startDate}
          maxDate={endDate}
          className={classes.datePicker}
          keyboardIcon={<Icon size={20} icon={faCalendar} color="midGrey" />}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
