import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';

import { ReactComponent as SmallScreenImage } from 'assets/vectors/small-screens.svg';
import { PAGE_ROUTES } from '../constants/pageConfigurations';
import Error from './Error';
import Icon from './Icon';

const ISOLATED_PAGES: string[] = [PAGE_ROUTES.WALLBOARD, PAGE_ROUTES.DASHBOARDS];

export default function DesktopOnly() {
  const canBeOpenedInIsolation = ISOLATED_PAGES.includes(window.location.pathname);
  const suffixText = canBeOpenedInIsolation ? (
    <span>
      You can open this page in desktop view from the <Icon icon={faGrid2} size={15} /> menu in the sidebar.
    </span>
  ) : (
    'You can either change the page or view NEON on a bigger screen.'
  );

  return (
    <Error
      relative
      hidePrimaryAction
      title="The Screen Isn’t Big Enough"
      Image={SmallScreenImage}
      text={
        <span>
          This page isn’t supported yet in the NEON softphone view.{'\n\n'}
          {suffixText}
        </span>
      }
    />
  );
}
