import { MenuItem, Tooltip } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, Text } from '../';

import './popover-menu-item.scss';
import cx from 'classnames';

interface PopoverMenuItemProps {
  text: string;
  icon: IconProp | string;
  onClick?: () => void;
  highlighted?: boolean;
  disabled?: boolean;
  tooltip?: string;
  tooltipId?: string;
  tooltipDelay?: number;
}

export default function PopoverMenuItem({
  text,
  icon,
  onClick,
  highlighted,
  disabled,
  tooltip,
  tooltipId,
  tooltipDelay = 1000
}: PopoverMenuItemProps) {
  const handleOnClick = async () => {
    try {
      await onClick?.();
    } catch {}
  };

  const menuItem = (
    <MenuItem
      button
      onClick={!disabled ? handleOnClick : void 0}
      className={cx('popover-menu-item', {
        'popover-menu-item--disabled': disabled,
        'popover-menu-item--non-interactive': disabled && !tooltipId
      })}
      disableRipple={disabled}
      data-testid={`${text}-popover-menu-item`}
    >
      <Text bold={highlighted} className="popover-menu-item__text">
        {text}
      </Text>
      <div className="popover-menu-item__icon">
        <Icon size={15} icon={icon} />
      </div>
    </MenuItem>
  );

  if (!tooltipId) {
    return menuItem;
  }

  return (
    <Tooltip enterDelay={tooltipDelay} title={tooltip || ''}>
      {menuItem}
    </Tooltip>
  );
}
