import { useDirectoryContext } from 'lib/common/contexts/DirectoryContext';
import { Button } from 'lib/common/components/index';
import { useBooleanState } from 'webrix/hooks';
import { useHotkeys } from 'react-hotkeys-hook';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import TUser from 'lib/common/types/User';
import TransferToQueueModal from '../../../TransferToQueue';
import useTransferModal from './hooks/useTransferModal';

export default function TransferFooterButtons({
  taskId,
  connectionValue,
  user,
  isSoftphone,
  showDialpadTransfer
}: {
  taskId: string;
  connectionValue: string;
  user: TUser;
  isSoftphone?: boolean;
  showDialpadTransfer?: boolean;
}) {
  const {
    value: showTransferToQueue,
    setFalse: closeTransferToQueue,
    setTrue: openTransferToQueue
  } = useBooleanState();

  const { openDirectory } = useDirectoryContext();
  const { onTransferToAgent, onTransferToDialpad } = useTransferModal({
    user,
    isSoftphone
  });

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_CONTACT, onTransferToAgent, [openDirectory, onTransferToAgent]);
  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_QUEUE, openTransferToQueue, [openTransferToQueue]);
  useHotkeys(
    AGENT_WORKSPACE_HOTKEYS.IN_CALL_TRANSFER_DIAL_PAD,
    () => {
      if (!showDialpadTransfer) {
        return;
      }

      onTransferToDialpad();
    },
    [onTransferToDialpad, openDirectory]
  );

  return (
    <>
      <Button
        icon="faPeopleArrows"
        tooltip="Transfer to a directory contact"
        styleType="NEUTRAL"
        round
        onClick={onTransferToAgent}
      />
      <Button
        icon="faRectangleHistoryCircleUser"
        tooltip="Transfer to another queue"
        styleType="NEUTRAL"
        round
        onClick={openTransferToQueue}
      />
      {showDialpadTransfer && (
        <Button
          // This is a custom icon
          icon="dialpad"
          onClick={onTransferToDialpad}
          tooltip="Dialpad"
          styleType="NEUTRAL"
          round
        />
      )}
      {showTransferToQueue && (
        <TransferToQueueModal
          open={showTransferToQueue}
          onClose={closeTransferToQueue}
          connectionValue={connectionValue}
          taskId={taskId}
          isSoftphone={isSoftphone}
        />
      )}
    </>
  );
}
