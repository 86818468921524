import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { setContext } from '@sentry/browser';

import { AuthContext } from 'lib/core/context/AuthProvider';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import requestNotificationPermission from 'lib/common/utils/requestNotificationPermission';
import EventEmitter from 'lib/common/utils/EventEmitter';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import Task from 'lib/common/types/Task';
import ChatTask from 'lib/common/types/ChatTask';
import { useMediaQuery } from 'react-responsive';
import VARS from 'css/export-vars.module.scss';
import defaultContextValues from '../constants/defaultContextValues';
import resolveCustomerProfile from '../utils/resolveCustomerProfile';
import getUpdatedTasks from '../utils/getUpdatedTasks';
import buildReportableTasks from '../utils/buildReportableTasks';

import TContactContext from '../types/ContactContext';
import TCustomerProfile from '../types/CustomerProfile';

import useTaskActions from '../hooks/useTaskActions';
import useCallActions from '../hooks/useCallActions';
import useAgentActions from '../hooks/useAgentActions';
import useChatActions from '../hooks/useChatActions';
import useContactHandlers from '../hooks/useContactHandlers';
import useInitialSetup from '../hooks/useInitialSetup';
import useTaskRefresh from '../utils/useTaskRefresh';
import IncomingTaskOverlay from './IncomingTaskOverlay';

export const Context = createContext<TContactContext>(defaultContextValues);

export default function ContactContext({ children }) {
  const [tasks, setTasksState] = useState<(ChatTask | Task)[]>([]);
  const [selectedTaskId, setSelectedTaskId] = useState<string | undefined>();
  const [lastDialedNumber, setLastDialedNumber] = useState<string | undefined>('');
  const ref: { current: (Task | ChatTask)[] } = useRef(tasks);

  const isSmall = useMediaQuery({ query: `(max-width: ${VARS.softphoneWidth})` });

  const setTasks = (tasks) => {
    ref.current = tasks;
    setTasksState(tasks);

    setContext('tasks', buildReportableTasks(tasks));
  };

  const getTasks = () => ref.current;

  const handleContactChange = useCallback(
    async (changes) => {
      try {
        const newTasks = await getUpdatedTasks({ ...changes, getTasks, handleContactChange });

        setTasks(newTasks);
      } catch {}
    },
    [tasks]
  );

  const { fetch_, config, tokens } = useContext(AuthContext);
  const { hasPermission } = usePermissionsContext();
  const taskActions = useTaskActions({ tasks, handleContactChange, setSelectedTaskId });
  const chatActions = useChatActions(tasks);
  const callActions = useCallActions({ tasks, handleContactChange });
  const agentActions = useAgentActions({ tasks, handleContactChange });

  const searchForProfile = (contact) => {
    return resolveCustomerProfile({
      contact,
      handleContactChange,
      config,
      fetch_,
      hasCustomerProfilesPermission
    });
  };

  useInitialSetup({ setTasks, searchForProfile, handleContactChange });

  const hasCustomerProfilesPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.CUSTOMER_PROFILES
  });

  const hasMissedCallPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_MISSED_CALL
  });

  const hasACWPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.AGENT_ACW
  });

  const matchNewContact = async (contact: connect.Contact) => {
    EventEmitter.emit('onContactConnecting', contact);

    await handleContactChange({ contact, status: CONTACT_STATES.CONNECTING });

    searchForProfile(contact);

    const newTask = getTasks().find((task) => task.taskId === contact.contactId);

    if (!newTask || document.hasFocus()) {
      return;
    }

    try {
      await requestNotificationPermission();

      new Notification(`Incoming ${newTask.type.toLowerCase()}`, {
        body: newTask.connectionValue,
        icon: `${window.location.origin}/android-chrome-192x192.png`
      });
    } catch {}
  };

  useContactHandlers({
    matchNewContact,
    handleContactChange,
    fetch_,
    config,
    tokens,
    hasMissedCallPermission,
    hasACWPermission
  });

  useTaskRefresh({
    tasks: tasks.filter((task) => task.type === CONTACT_TYPES.TASK || task.type === CONTACT_TYPES.CHAT),
    hasACWPermission,
    handleContactChange,
    fetch_,
    config,
    tokens,
    hasMissedCallPermission
  });

  // This hook is run when the currently selected task is cleared, to select the first task in the list
  useEffect(() => {
    if (!selectedTaskId || tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  const deleteExistingCustomerProfile = (taskId: string) =>
    handleContactChange({
      contact: tasks.find((task) => task.taskId === taskId)?.contact,
      profile: null
    });

  const matchExistingCustomerProfile = ({ profile, taskId }: { profile?: TCustomerProfile; taskId: string }) => {
    const contact = tasks.find((task) => task.taskId === taskId)?.contact;

    if (!contact) {
      return;
    }

    if (!profile) {
      return searchForProfile(contact);
    }

    return handleContactChange({ contact, profile });
  };

  const getActiveCallTask = () => {
    // Any task with contact of type call and it is connected
    return tasks.find(
      (task) =>
        (task.type === CONTACT_TYPES.CALL ||
          task.type === CONTACT_TYPES.CONFERENCE_CALL ||
          task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
        task.status === CONTACT_STATES.CONNECTED
    );
  };

  const onActiveCall = Boolean(getActiveCallTask());
  const hasIncomingTask = tasks.some((task) => task.status === CONTACT_STATES.CONNECTING);

  return (
    <Context.Provider
      value={{
        actions: {
          setSelectedTaskId,
          matchExistingCustomerProfile,
          getActiveCallTask,
          deleteExistingCustomerProfile,
          setLastDialedNumber,
          ...agentActions,
          ...taskActions,
          ...callActions,
          ...chatActions
        },
        state: {
          tasks,
          selectedTaskId,
          onActiveCall,
          lastDialedNumber,
          hasIncomingTask
        }
      }}
    >
      {children}
      {!isSmall && <IncomingTaskOverlay tasks={tasks} />}
    </Context.Provider>
  );
}

export const { Consumer: ContactConsumer, Provider: ContactProvider } = Context;

export const useContactContext = () => useContext(Context);
