import { faLayerGroup, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import useHover from 'lib/common/hooks/useHover';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { ClickableIcon, TourControl } from 'lib/common/components';
import { SoftphoneCreateAgentTask } from 'lib/common/components/molecules';

import './softphone-add-agent-task-button.scss';

export default function SoftphoneAddAgentTaskButton() {
  const [hoverRef, isHovered] = useHover();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const handleOpenModal = () => {
    openModal({
      title: 'Add Agent Task',
      content: <SoftphoneCreateAgentTask onClose={closeModal} />
    });
  };

  return (
    <>
      <TourControl isSoftphone />
      <div
        className="softphone-add-agent-task-button"
        //@ts-ignore
        ref={hoverRef}
      >
        <ClickableIcon
          icon={isHovered ? faLayerGroup : faLayerPlus}
          withHover={false}
          tooltip="Add Agent Task"
          tooltipPlacement="right"
          onClick={handleOpenModal}
        />
      </div>
    </>
  );
}
