import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import getNameInitials from 'lib/common/utils/getNameInitials';
import './avatar.scss';

const BACKGROUND_BRIGHTNESS = 90;
const LETTERS_BRIGHTNESS = 30;

const STATUS_DOT_SIZE_MULTIPLIER = 0.3;

interface IAvatarProps {
  size?: number;
  name?: string;
  noBackground?: boolean;
  fallbackIcon?: IconDefinition;
  statusProps?: {
    statusColor: string;
  };
  onClick?: () => void;
}

function stringToHslColor(string: string, brightness: number) {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, 50%, ${brightness}%)`;
}

export default function Avatar({
  size = 50,
  name,
  noBackground,
  fallbackIcon = faUser,
  statusProps,
  onClick
}: IAvatarProps) {
  const pxSize = `${size}px`;

  return (
    <div
      style={{
        fontSize: `${size / 2.5}px`,
        height: pxSize,
        minHeight: pxSize,
        width: pxSize,
        minWidth: pxSize,
        background: name && !noBackground ? stringToHslColor(name, BACKGROUND_BRIGHTNESS) : void 0,
        cursor: onClick ? 'pointer' : 'default'
      }}
      className="avatar"
      onClick={onClick ? onClick : () => void 0}
      data-testid="avatar"
    >
      {!name ? (
        <FontAwesomeIcon icon={fallbackIcon} />
      ) : (
        <span className="avatar__initials" style={{ color: stringToHslColor(name, LETTERS_BRIGHTNESS) }}>
          {getNameInitials(name)}
        </span>
      )}
      {statusProps?.statusColor && (
        <div
          className="avatar__status-dot"
          data-testid="avatar-profile-status"
          style={{
            background: `${statusProps.statusColor}`,
            height: `${size * STATUS_DOT_SIZE_MULTIPLIER}px`,
            width: `${size * STATUS_DOT_SIZE_MULTIPLIER}px`
          }}
        />
      )}
    </div>
  );
}
