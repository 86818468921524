import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import 'amazon-connect-taskjs';

import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from 'react-router-dom';

import { Routes, IsolatedRoutes } from 'lib/routes';
import AuthProvider from 'lib/core/context/AuthProvider';
import ModulesContext from 'lib/common/contexts/ModulesContext';
import ConfigProvider from 'lib/core/config';
import AgentContext from 'lib/common/contexts/AgentContext';
import PreferencesContext from 'lib/common/contexts/PreferencesContext';
import InstanceContext from 'lib/common/contexts/InstanceContext';
import { ReactTourProvider, Offline } from 'lib/common/components';
import { LayoutProvider } from 'lib/common/contexts/LayoutContext';
import IsolatedAuthProvider from 'lib/core/context/IsolatedAuthProvider';
import getQueryParam from 'lib/common/utils/getQueryParam';

import 'lib/common/customIcons';

import packageJson from '../package.json';
import ErrorBoundary from './ErrorBoundary';

console.log(`~~~ [NEON Version v${packageJson.version}] ~~~`);

const TOAST_LIMIT = 4;
const ISOLATED_MODE_QUERY_PARAM = 'standalone';

function IsolatedApp() {
  return (
    <IsolatedAuthProvider>
      <IsolatedRoutes />
    </IsolatedAuthProvider>
  );
}

function FullApp() {
  return (
    <AgentContext>
      <PreferencesContext>
        <AuthProvider>
          <ModulesContext>
            <InstanceContext>
              <LayoutProvider>
                <Routes />
              </LayoutProvider>
            </InstanceContext>
          </ModulesContext>
        </AuthProvider>
      </PreferencesContext>
    </AgentContext>
  );
}

export default function App() {
  const isIsolatedApp = Boolean(getQueryParam({ param: ISOLATED_MODE_QUERY_PARAM }));
  const children = isIsolatedApp ? <IsolatedApp /> : <FullApp />;

  return (
    <ErrorBoundary>
      <Router>
        <Offline>
          <ConfigProvider>
            <ReactTourProvider>{children}</ReactTourProvider>
          </ConfigProvider>
          <ToastContainer theme="colored" position="bottom-left" limit={TOAST_LIMIT} />
        </Offline>
      </Router>
    </ErrorBoundary>
  );
}
