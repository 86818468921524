import COLOURS from 'css/export-vars.module.scss';

export enum PRESENCE_STATES {
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  OFFLINE = 'Offline'
}

const presenceStates: {
  [key: string]: string;
} = Object.keys(PRESENCE_STATES).reduce((states, key) => ({ ...states, [key]: PRESENCE_STATES[key] }), {});

export const STATUS_TO_COLOUR_MAP = {
  [PRESENCE_STATES.OFFLINE]: COLOURS.darkGrey,
  [PRESENCE_STATES.AVAILABLE]: COLOURS.success,
  [PRESENCE_STATES.BUSY]: COLOURS.danger
};

export default presenceStates;
