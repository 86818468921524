export default async function getInitialChatMessages(chatSession: connect.ChatSession) {
  if (!chatSession) {
    return [];
  }

  try {
    const awsSdkResponse = await chatSession.getTranscript({
      maxResults: 100,
      sortOrder: 'ASCENDING'
    });

    return awsSdkResponse?.data?.Transcript?.filter(({ Content }) => Boolean(Content)) || [];
  } catch {
    return [];
  }
}
