import { makeStyles, Switch as MUIToggle, SwitchClassKey, SwitchProps, withStyles } from '@material-ui/core';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { useBooleanState } from 'webrix/hooks';
import useActionState, { ActionState } from 'lib/common/hooks/useActionState';
import { danger, lightBlue, success } from 'lib/common/constants/colours';
import styles from './styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
  hoveredSwitchBase: any;
}

interface IToggle {
  classes: Styles;
  onSuccess?: (response: any) => void;
  onFailure?: () => void;
  onFinally?: () => void;
  loading?: boolean;
  onChange?: (checked: boolean) => void;
  onChangeAsync?: (checked: boolean) => Promise<any>;
}

interface ToggleProps extends IToggle, SwitchProps {
  onChange?: any;
  classes: any;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 15,
    marginLeft: 5,
    color: ({ iconColour }: { iconColour?: string }) => iconColour
  }
}));

export default withStyles(styles)(
  ({ classes, onSuccess, onFailure, onFinally, loading, onChange, onChangeAsync, checked, ...props }: ToggleProps) => {
    const { value: isHovering, setTrue, setFalse } = useBooleanState(true);

    const handleChange = async () => {
      if (onChange) {
        return void onChange(!checked);
      }

      // @ts-ignore
      await onChangeAsync?.(!checked);
    };

    const handleError = useCallback(() => {
      onFailure?.();
    }, [checked]);

    const { icon, handleInteraction, busy, icons }: ActionState = useActionState({
      onSuccess,
      onAction: handleChange,
      onFailure: handleError,
      onFinally,
      loading
    });

    const iconColours = {
      [icons.success.name]: success,
      [icons.error.name]: danger,
      [icons.busy.name]: lightBlue
    };
    const nonSwitchStyles = useStyles(icon ? { iconColour: iconColours[icon.name] } : {});

    return (
      <div className={nonSwitchStyles.container}>
        <MUIToggle
          data-testid="toggle"
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          onMouseOver={setTrue}
          onMouseOut={setFalse}
          classes={{
            root: classes.root,
            switchBase: cx(classes.switchBase, {
              [classes.hoveredSwitchBase as string]: isHovering
            }),
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled
          }}
          {...props}
          checked={checked}
          disabled={props.disabled || busy}
          onChange={onChangeAsync ? handleInteraction : handleChange}
        />
        {icon && (
          <FontAwesomeIcon
            data-testid="toggle-icon"
            spin={icon.name === icons.busy.name}
            className={nonSwitchStyles.icon}
            icon={icon.el}
          />
        )}
      </div>
    );
  }
);
