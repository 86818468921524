import { Redirect, Route, Switch } from 'react-router-dom';

// pages

// TODO move these pages into /desktop
import DashboardsPage from 'lib/pages/Dashboards';
import InteractionHistoryPage from 'lib/pages/InteractionHistory';
import WallboardPage from 'lib/pages/Wallboard';
import CustomerProfile from 'lib/pages/CustomerProfile';
import CustomerProfiles from 'lib/pages/CustomerProfiles';
import CreateCustomerProfile from 'lib/pages/CreateCustomerProfile';
import Users from 'lib/pages/Users';
import MetricsConfig from 'lib/pages/MetricsConfig';
import Outcomes from 'lib/pages/Outcomes';
import Campaigns from 'lib/pages/Campaigns';
import AgentWorkspace from 'lib/pages/AgentWorkspace';

// components
import { PageModule, AuthorisedRoute, NotFound, RoleRedirect } from 'lib/common/components';

import { CoreLayout } from 'lib/common/layouts';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { ConnectorModuleType } from 'lib/common/types/ConnectorModule';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { WithApiContexts } from 'lib/common/components/atoms';
import { LAYOUT_SWITCH_REDIRECTION_ROUTES } from '../constants';

// contexts

function withUser(user, Component) {
  return (props) => <Component {...props} user={user} />;
}

export default function CoreLayoutRoutes({ user }) {
  const {
    state: { pageModules }
  } = useModulesContext();

  return (
    <CoreLayout user={user}>
      <Switch>
        <Redirect from="/home" to="/" />
        <Redirect exact from={PAGE_ROUTES.SETTINGS} to={PAGE_ROUTES.USER_SETTINGS} />
        <RoleRedirect user={user} exact from={PAGE_ROUTES.BASE} />
        <Route exact render={withUser(user, AgentWorkspace)} path={PAGE_ROUTES.WORKSPACE} />
        <AuthorisedRoute exact permissionType="user" permission={PERMISSIONS.DASHBOARDS} path={PAGE_ROUTES.DASHBOARDS}>
          <WithApiContexts component={DashboardsPage} user={user} />
        </AuthorisedRoute>
        <AuthorisedRoute permission={PERMISSIONS.WALLBOARD} exact path={PAGE_ROUTES.WALLBOARD}>
          <WithApiContexts component={WallboardPage} user={user} />
        </AuthorisedRoute>
        <AuthorisedRoute
          permission={PERMISSIONS.INTERACTION_HISTORY}
          exact
          path={PAGE_ROUTES.INTERACTION_HISTORY}
          component={InteractionHistoryPage}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.CUSTOMER_PROFILES}
          exact
          path={PAGE_ROUTES.CUSTOMER_PROFILES}
          component={CustomerProfiles}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.CUSTOMER_PROFILES_WRITE}
          permissionType="any"
          exact
          path={PAGE_ROUTES.CREATE_CUSTOMER_PROFILE}
          component={CreateCustomerProfile}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.CUSTOMER_PROFILES}
          exact
          path={PAGE_ROUTES.CUSTOMER_PROFILE}
          component={CustomerProfile}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.ADMIN}
          exact
          permissionType="user"
          path={PAGE_ROUTES.USER_SETTINGS}
          component={Users}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.ADMIN}
          exact
          permissionType="user"
          path={PAGE_ROUTES.METRICS_SETTINGS}
          component={MetricsConfig}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.ADMIN}
          exact
          permissionType="user"
          path={PAGE_ROUTES.OUTCOME_SETTINGS}
          component={Outcomes}
        />
        <AuthorisedRoute
          permission={PERMISSIONS.ADMIN}
          exact
          permissionType="user"
          path={PAGE_ROUTES.CAMPAIGN_SETTINGS}
          component={Campaigns}
        />
        {pageModules.map((pageModule: ConnectorModuleType, i) => (
          <Route
            render={(props) => <PageModule {...props} pageModuleProps={pageModule} user={user} />}
            path={`/${pageModule.pathName}`}
            key={i}
          />
        ))}
        {LAYOUT_SWITCH_REDIRECTION_ROUTES.map((props, index) => (
          <Redirect key={index} {...props} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </CoreLayout>
  );
}
