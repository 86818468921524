import React, { useEffect } from 'react';
import { ChartData as NativeChartData } from 'chart.js';
import { ChartData, Pie } from 'react-chartjs-2';

import { Loader as Loader, EmptyPlaceholder, Text } from 'lib/common/components';
import useData from '../../../../hooks/useData';
import Error from '../Error';

import './pieGraph.scss';

const Chart = require('react-chartjs-2').Chart;

interface IProps {
  block: any;
  refresh: number;
  config: any;
  fetch: (url: string, options: any) => Promise<any>;
  small: boolean;
}

interface IDynamoData {
  labels: Array<string>;
  values: Array<unknown>;
}

const CHART_OPTIONS = {
  legend: { display: true },
  maintainAspectRatio: false,
  cutoutPercentage: 50,
  showAllTooltips: false
};

const CHART_COLOURS = ['#11b5e7', '#82c9eb', '#bdddee', '#f7bbc9', '#f583a4', '#ed3c80'];

function getGraphData(data): ChartData<NativeChartData> {
  return {
    labels: data.labels.map((label) => label || 'Unknown'),
    datasets: [
      {
        fill: true,
        data: data.values,
        backgroundColor: CHART_COLOURS,
        hoverBackgroundColor: CHART_COLOURS
      }
    ]
  };
}

function configureChartPluginService() {
  Chart.pluginService.register({
    beforeRender: (chart) => {
      const {
        getDatasetMeta,
        config: {
          data: { datasets },
          options: { showAllTooltips }
        }
      } = chart;

      if (!showAllTooltips) {
        return;
      }

      chart.pluginTooltips = datasets.reduce((allTooltips, dataset, datasetIndex) => {
        const tooltips = getDatasetMeta(datasetIndex).data.map(
          (sector) =>
            new Chart.Tooltip(
              {
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              },
              chart
            )
        );

        return [...allTooltips, ...tooltips];
      }, []);

      // turn off normal tooltips
      chart.options.tooltips.enabled = false;
    },
    afterDraw: (chart, easing) => {
      const {
        pluginTooltips,
        allTooltipsOnce,
        config: {
          options: { showAllTooltips }
        }
      } = chart;

      if (!showAllTooltips) {
        return;
      }

      // we don't want the permanent tooltips to animate, so don't do anything till the animation runs at least once
      if (!allTooltipsOnce && easing !== 1) {
        chart.allTooltipsOnce = true;
      }

      chart.options.tooltips.enabled = true;

      Chart.helpers.each(pluginTooltips, (tooltip) => {
        tooltip.initialize();
        tooltip.update(); // we don't actually need this since we are not animating tooltips
        tooltip.pivot();
        tooltip.transition(easing).draw();
      });

      chart.options.tooltips.enabled = false;
    }
  });
}

export default function PieGraph(props: IProps) {
  const {
    block: { processor, element, queueid, dataModule, _uid, name },
    block: raw,
    refresh,
    config,
    fetch,
    small
  } = props;

  const { loading, data, error } = useData({
    dataModule,
    element,
    raw,
    processor,
    refresh,
    queueid,
    config,
    fetch
  }) as { loading: boolean; data: IDynamoData | null; error: boolean };
  const graphData = data ? getGraphData(data) : null;
  const isEmpty = !loading && data && data.labels?.length === 0 && data.values.length === 0;

  useEffect(configureChartPluginService, []);

  return (
    <div className="pie-graph" key={_uid}>
      <Text type={small ? void 0 : 'heading2'} color="darkBlue" bold={small}>
        {name}
      </Text>
      {loading && <Loader small />}
      {isEmpty && <EmptyPlaceholder subText="Select a different filter or range." error={false} />}
      {!loading && error && <Error small={small} />}
      {!loading && !isEmpty && !error && graphData && (
        <div className="pie-graph__chart" data-testid="pie-graph-widget" id={_uid}>
          <Pie data={graphData} options={CHART_OPTIONS} />
        </div>
      )}
    </div>
  );
}
