import sizeMe from 'react-sizeme';
import { Button, RequiresPermission } from 'lib/common/components';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import getMatchableAttribute from 'lib/common/utils/getMatchableAttribute';
import cx from 'classnames';
import { ReactComponent as NoProfile } from '../assets/no-profile.svg';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_WIDTH = 450;

const NotFound = ({
  onCreate,
  task,
  size: { width = 0 }
}: {
  onCreate: () => void;
  task?: ChatTask | Task;
  size: { width?: number };
}) => {
  const isSmall = width < SMALL_WIDTH;
  const canCreate = Boolean(getMatchableAttribute(task?.contact));

  return (
    <>
      <div className={cx('customer-profile__not-found', { 'customer-profile__not-found--small': isSmall })}>
        <div className="customer-profile__not-found__info">
          <h2>The current customer doesn’t have a profile</h2>
          <RequiresPermission permission={[PERMISSIONS.CUSTOMER_PROFILES, PERMISSIONS.CUSTOMER_PROFILES_WRITE]}>
            <p>
              {!canCreate
                ? 'There isn’t any information we can use to associate them with a new profile.'
                : 'Creating a new profile for this contact will allow you to see previous interactions, add notes and more.'}
            </p>
          </RequiresPermission>
        </div>
        {canCreate && (
          <RequiresPermission permission={[PERMISSIONS.CUSTOMER_PROFILES, PERMISSIONS.CUSTOMER_PROFILES_WRITE]}>
            <Button icon={!isSmall ? 'faUserPlus' : ''} onClick={onCreate}>
              {isSmall ? 'Create Profile' : ''}
            </Button>
          </RequiresPermission>
        )}
      </div>
      {!isSmall && <NoProfile className="customer-profile__not-found__image mt-30" />}
    </>
  );
};

export default sizeMeHOC(NotFound);
