import ConnectionTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/ConnectionTimer';
import cx from 'classnames';
import styles from './queue-name-and-timer.module.scss';

interface IQueueNameProps {
  queueName?: string;
  time?: Date;
  className?: string;
}

const QueueNameAndTimer = ({ queueName, time, className }: IQueueNameProps) => (
  <div className={cx(styles['queue-name-and-timer'], className)}>
    {queueName && !time ? (
      queueName
    ) : (
      <>
        {queueName && <strong>{queueName}</strong>}
        {queueName && <span>{` | `}</span>}
        {time && <ConnectionTimer time={time} />}
      </>
    )}
  </div>
);

export default QueueNameAndTimer;
