import { faGear, faChartLine, faUsers, faHeadset, faSignsPost } from '@fortawesome/pro-regular-svg-icons';
import { PAGE_ROUTES } from './pageConfigurations';
import { PERMISSIONS } from './permissions';

export const ADMIN_NAV_OPTIONS = {
  icon: faGear,
  name: 'Settings',
  permission: PERMISSIONS.ADMIN,
  permissionType: 'user',
  pageHasSubMenu: true,
  link: PAGE_ROUTES.USER_SETTINGS,
  items: [
    {
      link: PAGE_ROUTES.USER_SETTINGS,
      icon: faUsers,
      name: 'Users',
      activeClassName: 'nav-active',
      permission: PERMISSIONS.ADMIN,
      permissionType: 'user'
    },
    {
      link: PAGE_ROUTES.METRICS_SETTINGS,
      icon: faChartLine,
      name: 'Metrics',
      activeClassName: 'nav-active',
      permission: PERMISSIONS.ADMIN,
      permissionType: 'user'
    },
    {
      link: PAGE_ROUTES.OUTCOME_SETTINGS,
      icon: faHeadset,
      name: 'Outcomes',
      activeClassName: 'nav-active',
      permission: PERMISSIONS.ADMIN,
      permissionType: 'user'
    },
    {
      link: PAGE_ROUTES.CAMPAIGN_SETTINGS,
      icon: faSignsPost,
      name: 'Campaigns',
      activeClassName: 'nav-active',
      permission: PERMISSIONS.ADMIN,
      permissionType: 'user'
    }
  ]
};
