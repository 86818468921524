import quickConnectTypes from '../constants/quickConnectTypes';
import toast from '../utils/toast';

export default async function getTransferEndpoint({ fetch_, config, type, identifier, asEndpointObject = false }) {
  try {
    const transferResponse = await fetch_(
      `${config.AGENT_SERVICE_HOST}/connect/${process.env.REACT_APP_TENANT_ID}/endpoint/${type}/${identifier}`,
      { method: 'GET' }
    );

    const endpointARN = await transferResponse.json();

    return asEndpointObject
      ? {
          agentLogin: null,
          endpointARN,
          endpointId: endpointARN,
          name: identifier,
          phoneNumber: null,
          queue: null,
          type: type === quickConnectTypes.AGENT ? connect.EndpointType.AGENT : connect.EndpointType.QUEUE
        }
      : endpointARN;
  } catch (e) {
    console.error('Error fetching transfer endpoint', e);

    toast('error', `Something went wrong transferring to this Agent/Queue. Please try again.`);

    return '';
  }
}
