import { Route, RouteProps } from 'react-router-dom';
import RequiresPermission from './RequiresPermission/RequiresPermission';
import NotFound from './NotFound';

type AuthorisedRouteProps = {
  permission?: string;
  permissionType?: string;
} & RouteProps;

export default function AuthorisedRoute({
  permission,
  permissionType = 'tenant',
  children,
  ...props
}: AuthorisedRouteProps) {
  return (
    <RequiresPermission
      permission={permission}
      permissionType={permissionType}
      fallback={<Route component={NotFound} />}
    >
      <Route {...props}>{children}</Route>
    </RequiresPermission>
  );
}
