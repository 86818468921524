import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPE from 'lib/common/constants/contactTypes';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import Task from 'lib/common/types/Task';
import ChatTask from 'lib/common/types/ChatTask';
import { isConference } from 'lib/common/utils/conferenceConnections';
import CONNECT_TYPE_MAP from '../constants/connectTypeMap';
import TCustomerProfile from '../types/CustomerProfile';
import getTaskFromContact from './getTaskFromContact';
import getContactChatSession from './getContactChatSession';
import registerChatHandlers from './registerChatHandlers';
import getInitialChatMessages from './getInitialChatMessages';
import getCallContactType from './getCallContactType';

type TGetUpdatedTasks = {
  contact?: connect.Contact;
  profile?: TCustomerProfile;
  status?: ValueOf<typeof CONTACT_STATES>;
  connectionState?: ValueOf<typeof CONNECTION_STATES>;
  isDestroy?: boolean;
  type?: ValueOf<typeof CONNECT_TYPE_MAP>;
  taskId?: string;
  message?: connect.ChatTranscriptItem;
  handleContactChange: (_: any) => void;
  customerTyping?: boolean;
  getTasks: () => (Task | ChatTask)[];
};

const isDefined = (value) => value !== void 0;

export default async function getUpdatedTasks({
  contact,
  isDestroy = false,
  getTasks,
  status,
  connectionState,
  profile,
  message,
  handleContactChange,
  customerTyping,
  taskId,
  ...taskProps
}: TGetUpdatedTasks) {
  const tasks = getTasks();

  if (!contact && !taskId) {
    return tasks;
  }

  const taskIndex = tasks.findIndex(
    ({ contact: existingContact, taskId: existingTaskId }) =>
      (taskId && existingTaskId === taskId) || (contact && existingContact.contactId === contact.contactId)
  );

  // If we're updating tasks after the contact has been destroyed
  if (taskIndex < 0 && (!status || status !== CONTACT_STATES.CONNECTING)) {
    return tasks;
  }

  if (isDestroy) {
    const newTasks = [...getTasks()];

    newTasks.splice(taskIndex, 1);

    return newTasks;
  }

  // When it's a new contact
  if (taskIndex < 0) {
    const newTask = {
      ...getTaskFromContact(contact),
      status,
      connectionState: CONNECTION_STATES.LIVE,

      // Set our own time here, because the connect timestamp is unreliable AF (sometimes get negative times/no reset)
      time: new Date()
    } as Task;

    if (!newTask.type) {
      return [...getTasks()];
    }

    const chatSession = await getContactChatSession(contact);

    if (!chatSession) {
      return [newTask, ...getTasks()];
    }

    registerChatHandlers({ session: chatSession, taskId: newTask.taskId, handleContactChange });

    const newChatTask: ChatTask = {
      ...newTask,
      chatSession,
      messages: await getInitialChatMessages(chatSession),
      customerTyping: false
    };

    return [newChatTask, ...getTasks()];
  }

  const existingTask = tasks[taskIndex];
  const updatedContact = contact || existingTask.contact;
  const type = isConference(updatedContact) ? getCallContactType(updatedContact) : existingTask.type;

  /**
   * Scenario - 1 for queue callbacks when we get two new contacts with the same id
   * Scenario - 2 bypass conference call because the parent call is connected but status of the call has changed to connecting
   * The above scenario shouldn't be considered as a new task.
   */
  if (status === CONTACT_STATES.CONNECTING && type !== CONTACT_TYPE.CONFERENCE_CALL) {
    const newTasks = [...getTasks()];

    newTasks.splice(taskIndex, 1, { ...existingTask, contact: updatedContact });

    return newTasks;
  }

  // Base Task
  const updatedTask = {
    ...existingTask,
    ...taskProps,
    contact: updatedContact,
    status: status || existingTask.status,
    connectionState: connectionState || existingTask.connectionState,
    profile: isDefined(profile) ? profile : existingTask.profile,
    type,
    // When a task ends, connect resets the connections and we lose the connection timestamp, so need to make our own copy
    connectedAtTime:
      status === CONTACT_STATES.CONNECTED && existingTask.status !== CONTACT_STATES.CONNECTED
        ? new Date()
        : existingTask.connectedAtTime,

    // Set our own time here, because the connect timestamp is unreliable AF (sometimes get negative times/no reset)
    time: (status && existingTask.status !== status) || existingTask.type !== type ? new Date() : existingTask.time
  };

  if ('messages' in existingTask) {
    const newTasks = [...getTasks()];

    const updatedChatTask = {
      ...updatedTask,
      messages: message ? [...(existingTask.messages || []), message] : existingTask.messages,
      customerTyping: isDefined(customerTyping) ? customerTyping : existingTask.customerTyping
    } as ChatTask;

    newTasks.splice(taskIndex, 1, updatedChatTask);

    return newTasks;
  }

  const newTasks = [...getTasks()];

  newTasks.splice(taskIndex, 1, updatedTask);

  return newTasks;
}
