import { Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { faLightbulb, faLightbulbOn, faSquareMinus } from '@fortawesome/pro-regular-svg-icons';
import { useBooleanState } from 'webrix/hooks';
import { CSSTransition } from 'react-transition-group';
import cx from 'classnames';
import { Button, ClickableIcon, Icon } from 'lib/common/components';
import { PAGE_ROUTES } from 'lib/common/constants/pageConfigurations';
import helpUrl from 'lib/common/constants/helpUrl';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import useHover from 'lib/common/hooks/useHover';
import Column from '../../../Column/Column';
import NoTasksPlaceholder from '../../../NoTasksPlaceholder';
import AddAgentTaskButton from '../AddAgentTaskButton';

import './no-task-view.scss';

const PANEL_MINIMISED_ID = 'no-tasks-panel-minimised';

const InspirationIcon = () => {
  const [hoverRef, isHovered] = useHover();

  return (
    // @ts-ignore Why the ref mismatch?
    <div className="no-task-view__column__content__header__maximise" ref={hoverRef}>
      <Icon icon={isHovered ? faLightbulbOn : faLightbulb} size={isHovered ? 32 : 25} />
    </div>
  );
};

export default function NoTaskView({ user, setCreateAgentTaskOpen, width }) {
  const history = useHistory();
  const { hasPermission } = usePermissionsContext();
  const { value: minimised, toggle: toggleMinimised } = useBooleanState(
    Boolean(localStorage.getItem(PANEL_MINIMISED_ID))
  );

  const onToggleMinimised = () => {
    toggleMinimised();

    if (minimised) {
      return void localStorage.removeItem(PANEL_MINIMISED_ID);
    }

    localStorage.setItem(PANEL_MINIMISED_ID, 'true');
  };

  const hasWallboardPermission = hasPermission({
    type: 'tenant',
    permission: PERMISSIONS.WALLBOARD
  });

  const hasDashboardsPermission = hasPermission({
    type: 'user',
    permission: PERMISSIONS.DASHBOARDS
  });

  const isCreateTaskDisabled = hasPermission({ type: 'connect', permission: PERMISSIONS.CREATE_TASK_DISABLED });

  return (
    <div className={cx('no-task-view', { 'no-task-view--small': width < 900 })}>
      <NoTasksPlaceholder user={user} />
      <Column className={cx('no-task-view__column', { 'no-task-view__column--minimised': minimised })}>
        <button className="no-task-view__column__content" onClick={minimised ? onToggleMinimised : void 0}>
          <div className="no-task-view__column__content__header">
            {!minimised && <h2>Looking for things to do? Here’s some inspiration.</h2>}
            {minimised && <InspirationIcon />}
            {!minimised && <ClickableIcon icon={faSquareMinus} onClick={onToggleMinimised} size={25} />}
          </div>
          {!minimised && (
            <>
              {!isCreateTaskDisabled && (
                <>
                  <div className="no-task-view__column__content__row__content">
                    <div className="no-task-view__column__content__row__content__text">
                      <h3 className="no-task-view__column__content__row__content__header">Create An Agent Task</h3>
                      <p>
                        Create a task and assign it to another agent or a queue. You can schedule the task for
                        completion later.
                      </p>
                    </div>
                    <div className="no-task-view__column__content__row__content__button">
                      <Button
                        small
                        fullWidth
                        styleType="SECONDARY"
                        icon="faLayerPlus"
                        onClick={() => setCreateAgentTaskOpen(true)}
                      >
                        Create A Task
                      </Button>
                    </div>
                  </div>
                  <Divider light variant="middle" />
                </>
              )}
              <div className="no-task-view__column__content__row__content">
                <div className="no-task-view__column__content__row__content__text">
                  <h3 className="no-task-view__column__content__row__content__header">Check Out The Help Docs</h3>
                  <p>Have a look through some useful information on how to get the most of CloudWave NEON.</p>
                </div>
                <div className="no-task-view__column__content__row__content__button">
                  <Button
                    small
                    fullWidth
                    styleType="SECONDARY"
                    icon="faUpRightFromSquare"
                    onClick={() => window.open(helpUrl)}
                  >
                    Open The Docs
                  </Button>
                </div>
              </div>
              {(hasDashboardsPermission || hasWallboardPermission) && (
                <>
                  <Divider light variant="middle" />
                  <div className="no-task-view__column__content__row__content">
                    <div className="no-task-view__column__content__row__content__text">
                      <h3 className="no-task-view__column__content__row__content__header">View Metrics & Stats</h3>
                      <p>
                        View numbers and data about the contact centre you’re in, such as <b>average queue time</b> and{' '}
                        <b>calls handled</b>.
                      </p>
                    </div>
                    <div className="no-task-view__column__content__row__content__button">
                      {hasDashboardsPermission ? (
                        <Button
                          small
                          fullWidth
                          styleType="SECONDARY"
                          icon="faGauge"
                          onClick={() => history.push(PAGE_ROUTES.DASHBOARDS)}
                        >
                          Open Dashboards
                        </Button>
                      ) : (
                        <Button
                          small
                          fullWidth
                          styleType="SECONDARY"
                          icon="faGrid2"
                          onClick={() => history.push(PAGE_ROUTES.WALLBOARD)}
                        >
                          Open Wallboard
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </button>
        {!isCreateTaskDisabled && (
          <CSSTransition
            in={minimised}
            timeout={minimised ? 400 : 0}
            classNames="no-task-view__column__add-agent-task-button"
            appear={minimised}
          >
            <div className="no-task-view__column__add-agent-task-button">
              <AddAgentTaskButton setCreateAgentTaskOpen={setCreateAgentTaskOpen} />
            </div>
          </CSSTransition>
        )}
      </Column>
    </div>
  );
}
