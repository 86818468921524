import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { Profile, Loader, RequiresPermission } from 'lib/common/components';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { PERMISSIONS } from 'lib/common/constants/permissions';
import useCustomerProfileActions from 'lib/common/hooks/useCustomerProfileActions';
import getContactDisplayName from 'lib/common/utils/customerProfiles/getContactDisplayName';

import NotFound from './NotFound';
import Summary from './Summary';
import Header from './Header';
import Error from './Error';

import '../styles/customer-profile.scss';

interface ICustomerProfileProps {
  profileId: string;
}

export default function CustomerProfile({ profileId }: ICustomerProfileProps) {
  const {
    state: { tasks, selectedTaskId },
    actions: { makeOutboundCall, matchExistingCustomerProfile }
  } = useContactContext();

  const history = useHistory();

  const matchedTask = tasks.find(({ profile }) => profile?.profileId === profileId);

  // In the case we've added a new profile and there's no current match, trigger a re-search when we know the contact
  const refetch = () => {
    if (matchedTask || !selectedTaskId) {
      return;
    }

    matchExistingCustomerProfile({ taskId: selectedTaskId });
  };

  const onDeleteProfile = () => {
    history.push('/profiles');
  };

  const {
    profile,
    error,
    profileHistory,
    profileNotes,
    showEditModal,
    showDeleteModal,
    profileDoesNotExist,
    profileLoading,
    actions: { deleteProfile, onEdit, onSaveNotes, onDeleteProfileSuccess, getMatchedContact },
    modalActions: {
      open: { openEditModal, openDeleteModal },
      close: { closeEditModal, closeDeleteModal }
    }
  } = useCustomerProfileActions({ profileId, refetch, onDeleteProfile });

  const callContact = () => {
    if (!profile) {
      return;
    }

    if (!profile.phoneNumber) {
      return;
    }

    makeOutboundCall(profile.phoneNumber);
  };

  return (
    <>
      {profile && (
        <RequiresPermission permission={PERMISSIONS.CUSTOMER_PROFILES_WRITE}>
          <Profile.EditProfileModal
            profile={profile}
            onSave={onEdit}
            open={showEditModal}
            onClose={closeEditModal}
            onSuccess={closeEditModal}
          />
        </RequiresPermission>
      )}
      <RequiresPermission permission={PERMISSIONS.CUSTOMER_PROFILES_WRITE}>
        <Profile.DeleteProfileDialog
          open={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={deleteProfile}
          onSuccess={onDeleteProfileSuccess}
        />
      </RequiresPermission>
      <div className="customer-profile__content">
        <Header
          profile={profile}
          profileNotes={profileNotes}
          currentContactIsViewed={Boolean(matchedTask)}
          onCall={callContact}
          onDelete={openDeleteModal}
          onEdit={openEditModal}
        />
        <div className={cx(`panel${profile ? '--small' : ''}`, 'customer-profile__content__profile')}>
          {profileLoading && <Loader relative />}
          <Summary profile={profile} currentContactIsViewed={Boolean(matchedTask)} />
          <Error show={error} />
          {profileDoesNotExist && <NotFound onReload={getMatchedContact} />}
        </div>
        <Profile.Notes
          className="panel--small"
          contactDisplayName={getContactDisplayName(profile, { capitalised: true })}
          notes={profileNotes}
          onSave={onSaveNotes}
        />
        <Profile.History
          className="panel"
          profileHistory={profileHistory}
          currentContactIsViewed={Boolean(matchedTask)}
          emptyMessage={
            Boolean(matchedTask)
              ? `This is the first time ${getContactDisplayName(profile)} has called.`
              : `${getContactDisplayName(profile, {
                  capitalised: true
                })} doesn't have any previous interactions.`
          }
        />
      </div>
    </>
  );
}
