import { Icon, PopoverMenu, PopoverMenuItem } from 'lib/common/components';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { SoftphoneNavOption, SoftphoneNavOptionChild } from 'lib/common/types/SoftphoneNavOption';

const MORE_TASKS_MENU_OFFSET = 30;

type TSoftphoneNavMenuFolder = SoftphoneNavOption & {
  childOptions: SoftphoneNavOptionChild[];
  isReversedLayout?: boolean;
};

export default function SoftphoneNavMenuFolder({
  icon,
  name,
  childOptions,
  isReversedLayout
}: TSoftphoneNavMenuFolder) {
  const { connectUserId, user } = useAuthContext();

  const openLink = ({ link, external, isolated }: { link: string; external?: boolean; isolated?: boolean }) => {
    if (!user) {
      return;
    }

    if (external) {
      return void window.open(link, '_blank');
    }

    const isolatedUrlParams = isolated ? `?standalone=true&user=${user.username}&token=${connectUserId}` : '';

    window.open(`${window.location.origin}${link}${isolatedUrlParams}`, '_blank');
  };

  return (
    <PopoverMenu
      anchor={
        <button className="softphone-nav-menu-item" data-testid="softphone-nav-menu-folder-button">
          <Icon tooltip={name} icon={icon} size={18} />
        </button>
      }
      offset={0}
      offsetLeft={isReversedLayout ? -MORE_TASKS_MENU_OFFSET : MORE_TASKS_MENU_OFFSET}
    >
      {childOptions.map(({ link, icon: childIcon, name, isolated, external }) => (
        <PopoverMenuItem
          data-testid={name}
          key={link}
          onClick={() => openLink({ link, isolated, external })}
          text={name}
          icon={childIcon}
        />
      ))}
    </PopoverMenu>
  );
}
